import React from 'react';
import { observer } from 'mobx-react';
import axios from 'axios';

@observer
class IFrameContent extends React.Component {
  state = {
    content: null,
  };

  componentDidMount() {
    const { url } = this.props;

    this.getIFrameContent(url);
  }

  componentDidUpdate(prevProps) {
    const { url } = this.props;

    if (prevProps.url !== url) {
      this.getIFrameContent(url);
    }
  }

  getIFrameContent(url) {
    url = `${url}?ts=${Date.now()}`;

    axios.get(url).then((response) => {
      let content = response.data;

      if (this.props.baseUrl) {
        console.log('Setting base url', this.props.baseUrl);
        content = content.replace('<head>', `<head> <base href='${this.props.baseUrl}' />`);
      }

      this.setState({ content });
    }).catch(() => {
      this.setState({ content: null });
    });
  }

  render() {
    const { content } = this.state;

    if (content) {
      return (
        <iframe title="markedskraft.com" id="mkplus-iframe" srcDoc={content} width="100%" height="1000" />
      );
    }

    return false;
  }
}

export default IFrameContent;
