// @flow

import { computed } from 'mobx';

class CHPCOM {
    uuid: string;

    plantCode: number;

    plantName: string;

    cdgcId: string;

    deviceId: string;

    onlineValueEnabled: boolean;

    productionPlanEnabled: boolean;

    frequencyPlanUpEnabled: boolean;

    frequencyPlanDownEnabled: boolean;

    constructor() {
      this.plantCode = null;
      this.plantName = '';
      this.cdgcId = '';
      this.deviceId = '';
      this.onlineValueEnabled = false;
      this.productionPlanEnabled = false;
      this.frequencyPlanUpEnabled = false;
      this.frequencyPlanDownEnabled = false;
    }

    fromJson(json) {
      for (const prop in json) if (json.hasOwnProperty(prop)) this[prop] = json[prop];
      return this;
    }

    @computed get isNew() {
      return (!this.uuid || this.uuid.length === 0);
    }

    @computed get isValid() {
      return this.errors.length === 0;
    }

    @computed get errors() {
      const errors = [];
      if (this.deviceId === '') { errors.push({ msg: 'device-required' }); }
      if (this.cdgcId === '') { errors.push({ msg: 'cdgc-id-required' }); }
      return errors;
    }
}

export default CHPCOM;
