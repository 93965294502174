import React from 'react';
import { inject, observer } from 'mobx-react';
import { Well } from 'react-bootstrap';
import { translate } from 'react-i18next';
import moment from 'moment';

@translate()
@inject('messageStore') @observer
class Messages extends React.Component {
  render() {
    const { t } = this.props;
    const dateFormat = 'DD/MM-YYYY HH:mm';
    return (
      <Well className="full-height messages">
        <strong>{t('messages')}</strong>

        { this.props.messageStore.messages.get().map(message => (
          <div key={message.key} className="message">
            <strong>{moment(message.created).format(dateFormat)}</strong>
                :
            <br />
            {message.title}
            <br />
          </div>
        ))}
      </Well>
    );
  }
}

export default Messages;
