import React from 'react';
import PropTypes from 'prop-types';
import { Col, FormControl, FormGroup } from 'react-bootstrap';
import { titleCase } from '../../../actions/CommonAction';

// const CheckboxField = ({ name, initValue, givenRef }) => (
//      <FormGroup controlId="{ name + 'Group' }">
//          <Col className="control-label" sm={2} xsOffset={1}>
//              { name }
//          </Col>
//          <Col sm={8}>
//              <Checkbox inputRef={ givenRef} defaultChecked={ initValue } />
//          </Col>
//      </FormGroup>
// );
export const TextFieldGroup = ({ name, initValue, givenRef, disabled }) => (
  <FormGroup controlId="{ name + 'Group' }">
    <Col className="control-label" sm={4} xsOffset={0}>
      { name }
    </Col>
    <Col sm={6}>
      <FormControl
        type="text"
        placeholder={titleCase(name)}
        disabled={disabled}
        inputRef={givenRef}
        defaultValue={initValue}
      />

    </Col>
  </FormGroup>
);

TextFieldGroup.propTypes = {
  name: PropTypes.string.isRequired,
  initValue: PropTypes.any.isRequired,
  givenRef: PropTypes.any.isRequired,
  disabled: PropTypes.bool,
};

TextFieldGroup.defaultProps = {
  disabled: false,
};
