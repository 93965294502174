import {
  action, computed, observable, reaction, toJS,
} from 'mobx';
import _ from 'lodash';
import moment from 'moment';
import { toast } from 'react-toastify';
import {
  blockBidMapper, convertHoursToDateObjForGraph, convertMinutesToDateObjForGraph, getHoursInDay,
} from '../actions/CommonAction';
import { copyPlans, deleteBidPlan } from '../actions/PlanAction';
import { warn } from '../services/logService';
import { getPlans, loadTemplate } from '../services/PlanService';
import plansTemplate from '../actions/plansTemplate';

import Plan from '../classes/Plan';
import { addMidnightEntry } from '../controlroom/classes/plans';

// Name Key Constants - Preventing Spell/typing errors throughout.
export const NAME_KEY_PRODUCTION_PLAN = 'production-plan';
export const NAME_KEY_ONLINE_VALUES = 'online-values';
export const NAME_KEY_BID_PLAN = 'bid-plan';
export const NAME_KEY_PRICE_FORECAST = 'price-forecast';
export const NAME_KEY_MARGINAL_PRICE = 'marginal-price';
export const NAME_KEY_MANUAL_PLAN_UP = 'manual-plan-up';
export const NAME_KEY_MANUAL_PLAN_DOWN = 'manual-plan-down';
export const NAME_KEY_FREQUENCY_PLAN_UP = 'frequency-plan-up';
export const NAME_KEY_FREQUENCY_PLAN_DOWN = 'frequency-plan-down';
export const NAME_KEY_INTRADAY_PLAN_BUY = 'intraday-plan-buy';
export const NAME_KEY_INTRADAY_PLAN_SELL = 'intraday-plan-sell';
export const NAME_KEY_REGULATING_POWER_UP = 'regulating-power-up';
export const NAME_KEY_REGULATING_POWER_DOWN = 'regulating-power-down';
export const NAME_KEY_PRICE_DEPENDENT_PLAN = 'price-dependent-plan';

export class PlanStore {
  // TODO perhaps change to one oberservable for each plan type to be able to trigger mobx updates when only one plan changes
  @observable plans = [];

  constructor(uiStore) {
    this.uiStore = uiStore;

    reaction(
      () => [this.uiStore.plansStateChanged, this.uiStore.selectedDate, this.uiStore.selectedItem],
      ([stateChangeId, selectedDate, selectedItem]) => {
        getPlans(selectedDate, selectedItem.code)
          .then((plans) => {
            const rinsedPlan = this.rinsePlans(plans); // rinsing plans when more than one plan for each namekey is available
            const allPlans = this.mergePlansAndAddZeroPPlan(rinsedPlan);
            this.resetPriceChanges();
            this.setPlans(allPlans);
          }).catch(warn);
      },
    );

    // FIXME construct more
  }

  @action
  reset = () => {
    this.plans = [];
  }

  @observable popoverPositionLeft = null;

  @observable popoverPositionTop = null;

  @observable showPopover = false;

  @observable showProductionPopover = false;

  @observable pricesChangedUp = false;

  @observable pricesChangedDown = false

  @action
  setPricesChanged(up, priceChange) {
    if (up) {
      this.pricesChangedUp = priceChange;
    } else {
      this.pricesChangedDown = priceChange;
    }
  }

  @action
  resetPriceChanges() {
    this.pricesChangedUp = false;
    this.pricesChangedDown = false;
  }

  @action
  setPopoverPosition(left, top) {
    this.popoverPositionLeft = left;
    this.popoverPositionTop = top;
  }

  @action
  displayPopover(boolean) {
    if (this.activePlan.nameKey === NAME_KEY_BID_PLAN) {
      this.showPopover = boolean;
    } else if (this.activePlan.nameKey === NAME_KEY_PRODUCTION_PLAN) {
      this.showProductionPopover = boolean;
    }
  }

  @observable selectedPlanIds = [];

  @computed
  get blockBids() {
    const bidPlan = this.plans.find(p => p.classOf === 'BidPlanDTO');
    return bidPlan ? blockBidMapper(this.uiStore.selectedDate, bidPlan.sourcePlans) : [];
  }

  @action
  addSelectedPlanId(planId) {
    this.selectedPlanIds.push(planId);
  }

  @action
  removeSelectedPlanId(planId) {
    this.selectedPlanIds = this.selectedPlanIds.filter(e => !(e === planId));
  }

  @action
  setActivePlanIndex(index) {
    this.uiStore.setActivePlanIndex(index);

    const { activePlan } = this;

    // Add zeroplans if no entries
    if (activePlan.entries.length === 0 && _.includes(
      [NAME_KEY_MANUAL_PLAN_UP, NAME_KEY_MANUAL_PLAN_DOWN, NAME_KEY_FREQUENCY_PLAN_UP, NAME_KEY_FREQUENCY_PLAN_DOWN, NAME_KEY_INTRADAY_PLAN_BUY, NAME_KEY_INTRADAY_PLAN_SELL, NAME_KEY_FREQUENCY_PLAN_DOWN, NAME_KEY_PRICE_DEPENDENT_PLAN], activePlan.nameKey,
    )) {
      for (let i = 0; i < getHoursInDay(this.uiStore.selectedDate) + 1; i++) {
        activePlan.entries.push(this.createEntry(i * 60, 0));
      }

      activePlan.status = 0;
    }


    // Hide popovers
    this.showPopover = false;
    this.showProductionPopover = false;
  }

  @computed
  get isPopoverShown() {
    return this.showPopover || this.showProductionPopover;
  }

  @observable shouldShowCopyPlansModal = false;

  @action
  toggleCopyPlansModal() {
    this.shouldShowCopyPlansModal = !this.shouldShowCopyPlansModal;
  }

  @action
  copyPlans(fromDate, toDate) {
    // TODO verify this is ALL plans!
    copyPlans(this.plansToBeCopied, this.uiStore, fromDate, toDate);
    this.toggleCopyPlansModal();
  }

  @action calculateManualPlans(up) {
    console.log(`calulating manual plans${up}`);
    const plant = this.uiStore.selectedPlant;

    const planIndex = up ? this.plans.findIndex(p => p.nameKey === NAME_KEY_MANUAL_PLAN_UP) : this.plans.findIndex(p => p.nameKey === NAME_KEY_MANUAL_PLAN_DOWN);

    const plan = this.plans[planIndex];
    plan.entries = new Plan().fromJson(plan).entries;
    this.plans[planIndex] = this.calculatePlan(plan, plant.intradayEnabled);
    this.plans[planIndex].status = 0;
  }

  /**
   * Sådan her regnes de (besked fra Jesper)
   *
   * For producent:
   *
   * iPlanSalg = Kapacitet (10) - production (5) - mPlanOp (2) -> iPlanSalg = 3
   * iPlanKøb = Production (5) - mPlanNed (3) -> iPlanKøb = 2
   *
   * For consumer:
   * iPlanSalg = - production (-3) - mPlanOp(2) -> iPlanSalg = 1
   * iPlanKøb = - Kapacitet (-10) + production (-3) - mPlanNed(3) -> iPlanKøb = 4     lav kapacitet positiv og så er det 10 + -3 + -3
   *
   * @param buy if true, is a buy plan, else sell plan
   */
  @action calculateIntradayPlans(buy) {
    const plant = this.uiStore.selectedPlant;
    const totalCapacity = plant.producer ? plant.capacityMegawatt : -1 * plant.capacityMegawatt;
    const price = buy ? plant.intradayBuyPrice : plant.intradaySellPrice;

    const planIndex = buy ? this.plans.findIndex(p => p.nameKey === NAME_KEY_INTRADAY_PLAN_BUY) : this.plans.findIndex(p => p.nameKey === NAME_KEY_INTRADAY_PLAN_SELL);

    const plan = this.plans[planIndex];
    plan.entries = new Plan().fromJson(plan).entries;

    if (plant.producer) {
      plan.entries.forEach((entry) => {
        const hour = entry.minute / 60;
        if (buy) {
          entry.amountPowerOutputMegawatt = (totalCapacity - this.getRestCapacity(hour)) + this.getMplanHour(hour, false);
        } else {
          entry.amountPowerOutputMegawatt = this.getRestCapacity(hour) - this.getMplanHour(hour, true);
        }
        entry.activationPrice = { amount: price, currency: 'EUR/MWh' };
      });
    } else {
      plan.entries.forEach((entry) => {
        const hour = entry.minute / 60;
        if (buy) {
          entry.amountPowerOutputMegawatt = -totalCapacity + (totalCapacity - this.getRestCapacity(hour)) + this.getMplanHour(hour, false);
        } else {
          entry.amountPowerOutputMegawatt = -(totalCapacity - this.getRestCapacity(hour)) - this.getMplanHour(hour, true);
        }
        entry.activationPrice = { amount: price, currency: 'EUR/MWh' };
      });
    }
    this.plans[planIndex].status = 0;
  }

  calculatePlan(plan, intradayEnabled) {
    if (intradayEnabled) {
      plan.entries.forEach((entry) => {
        const hour = entry.minute / 60;

        if (this.uiStore.selectedPlant.producer) {
          if (plan.up) {
            entry.amountPowerOutputMegawatt = this.getRestCapacity(hour) - this.getIplanHour(hour, false);
          } else {
            entry.amountPowerOutputMegawatt = this.getMinProduction(hour) - this.getIplanHour(hour, true);
          }
        } else if (plan.up) {
          entry.amountPowerOutputMegawatt = this.getMinProduction(hour) + this.getIplanHour(hour, false);
        } else {
          entry.amountPowerOutputMegawatt = this.getRestCapacity(hour) + this.getIplanHour(hour, true);
        }
        entry.amountPowerOutputMegawatt = Math.abs(Math.round(entry.amountPowerOutputMegawatt * 10) / 10.0);
        if (!plan.up) {
          entry.amountPowerOutputMegawatt = -1 * entry.amountPowerOutputMegawatt;
        }
      });
    } else {
      plan.entries.forEach((entry) => {
        const hour = entry.minute / 60;

        if (this.uiStore.selectedPlant.producer) {
          if (plan.up) {
            entry.amountPowerOutputMegawatt = this.getRestCapacity(hour);
          } else {
            entry.amountPowerOutputMegawatt = this.getMinProduction(hour);
          }
        } else if (plan.up) {
          entry.amountPowerOutputMegawatt = this.getMinProduction(hour);
        } else {
          entry.amountPowerOutputMegawatt = this.getRestCapacity(hour);
        }
        entry.amountPowerOutputMegawatt = Math.abs(Math.round(entry.amountPowerOutputMegawatt * 10) / 10.0);
        if (!plan.up) {
          entry.amountPowerOutputMegawatt = -1 * entry.amountPowerOutputMegawatt;
        }
      });
    }
    return plan;
  }

  getIplanHour(hour, buy) {
    const iPlan = buy ? this.plans.find(p => p.nameKey === NAME_KEY_INTRADAY_PLAN_BUY) : this.plans.find(p => p.nameKey === NAME_KEY_INTRADAY_PLAN_SELL);
    if (iPlan.status < 0) {
      return 0;
    }
    return iPlan.entries.find(e => e.minute === hour * 60) == null ? 0 : iPlan.entries.find(e => e.minute === hour * 60).amountPowerOutputMegawatt;
  }

  getMplanHour(hour, up) {
    const mplan = up ? this.plans.find(p => p.nameKey === NAME_KEY_MANUAL_PLAN_UP) : this.plans.find(p => p.nameKey === NAME_KEY_MANUAL_PLAN_DOWN);
    if (mplan.status < 0) {
      return 0;
    }
    return mplan.entries.find(e => e.minute === hour * 60) == null ? 0 : mplan.entries.find(e => e.minute === hour * 60).amountPowerOutputMegawatt;
  }

  getMinProduction(hour) {
    const startMinute = hour * 60;
    const endMinute = (hour + 1) * 60;

    const productionPlan = this.plans.find(p => p.nameKey === NAME_KEY_PRODUCTION_PLAN);

    let minProd = this.getProductionForMinute(startMinute);

    productionPlan.entries.forEach((entry) => {
      if (entry.minute >= startMinute && entry.minute < endMinute) {
        if (Math.abs(entry.amountPowerOutputMegawatt) < Math.abs(minProd)) minProd = entry.amountPowerOutputMegawatt;
      }
    });

    return minProd;
  }

  getRestCapacity(hour) {
    const plant = this.uiStore.selectedPlant;
    const startMinute = hour * 60;
    const endMinute = (hour + 1) * 60;
    const totalCapacity = plant.producer ? plant.capacityMegawatt : -1 * plant.capacityMegawatt;
    let maxBid = 0;

    const aggregatedEntries = this.getAggregatedSourcePlanEntries(this.plans.find(p => p.nameKey === NAME_KEY_BID_PLAN));

    aggregatedEntries.forEach((entry) => {
      if (entry.minute >= startMinute && entry.minute < endMinute && entry.amountPowerOutputMegawatt !== 0) {
        if (Math.abs(entry.amountPowerOutputMegawatt) > Math.abs(maxBid)) maxBid = entry.amountPowerOutputMegawatt;
      }
    });

    const productionPlan = this.plans.find(p => p.nameKey === NAME_KEY_PRODUCTION_PLAN);

    let maxProd = this.getProductionForMinute(startMinute);

    productionPlan.entries.forEach((entry) => {
      if (entry.minute >= startMinute && entry.minute < endMinute && entry.amountPowerOutputMegawatt !== 0) {
        if (Math.abs(entry.amountPowerOutputMegawatt) > Math.abs(maxProd)) maxProd = entry.amountPowerOutputMegawatt;
      }
    });

    let restCapity = totalCapacity - maxBid - maxProd;
    if ((plant.producer && restCapity < 0) || (!plant.producer && restCapity > 0)) {
      restCapity = 0;
    }

    return restCapity;
  }

  getProductionForMinute(minute) {
    let result = 0;

    const productionPlan = this.plans.find(p => p.nameKey === NAME_KEY_PRODUCTION_PLAN);

    productionPlan.entries.forEach((entry) => {
      if (entry.minute <= minute) result = entry.amountPowerOutputMegawatt;
    });

    return result;
  }

  rinsePlans(apiPlans) {
    const rinsedPlans = [];
    const grouped = _.groupBy(apiPlans, 'nameKey');

    _.forEach(grouped, (similarPlans, key) => {
      // Sanity check the plans.
      const filtered = similarPlans.filter(p => p.replacedWith === null);

      // If we are a BidPlan, all plans must be rendered.
      // Otherwise take the one with the lowest status.
      if (key === NAME_KEY_BID_PLAN) {
        // Use all BidPlans, as multiple allowable bid plans can exist for the same day.
        rinsedPlans.push(...filtered);
      } else if (similarPlans.length > 0) {
        // Make sure that we select the plan with lowest status.
        // For example do not select an active plan if a Indmeldt (3) exist.
        filtered.sort((a, b) => a.status - b.status);
        rinsedPlans.push(filtered[0]);
      }
    });

    return rinsedPlans;
  }

  mergePlansAndAddZeroPPlan(plans) {
    const allPlans = _.cloneDeep(plansTemplate);
    plans.forEach((plan) => {
      const planIndex = _.findIndex(allPlans, p => p.nameKey === plan.nameKey);
      // udvælger den rigtige plan for hver type
      allPlans[planIndex].classOf = plan.classOf;
      allPlans[planIndex].status = plan.status;
      allPlans[planIndex].day = plan.day;

      if (plan.nameKey === NAME_KEY_BID_PLAN) {
        allPlans[planIndex].sourcePlans.push(plan);
      } else if (plan.entries) {
        allPlans[planIndex].entries = plan.entries;
      }
    });

    // As default there must be a zero production plan - so add if missing
    const pPlanIndex = _.findIndex(allPlans, pp => pp.nameKey === NAME_KEY_PRODUCTION_PLAN);
    const pPlan = allPlans[pPlanIndex];
    if (pPlan.entries.length === 0) {
      const type = 'PlanEntryDTO';


      const zeroPlanEntries = [
        { minute: 0, amountPowerOutputMegawatt: 0, classOf: type },
        { minute: getHoursInDay(this.uiStore.selectedDate) * 60, amountPowerOutputMegawatt: 0, classOf: type },
      ];

      pPlan.status = 0;
      pPlan.entries = zeroPlanEntries;
    }

    return allPlans;
  }


  // TODO belongs in constructor?
  @action
  setPriceForecast(priceForecastEntries) {
    const priceForecastPlanIndex = _.findIndex(this.plans, p => p.nameKey === NAME_KEY_PRICE_FORECAST);
    this.plans[priceForecastPlanIndex].entries = priceForecastEntries;
  }

  // TODO belongs in constructor?
  @action
  setMarginalPrice(marginalPriceEntries) {
    const marginalPricePlanIndex = _.findIndex(this.plans, p => p.nameKey === NAME_KEY_MARGINAL_PRICE);
    this.plans[marginalPricePlanIndex].entries = marginalPriceEntries;
  }

  @action

  setRegulatingPowerUp(regulatingPowerUpEntries) {
    const regulatingPowerUpPlanIndex = _.findIndex(this.plans, p => p.NAME_KEY_REGULATING_POWER_UP);
    this.plans[regulatingPowerUpPlanIndex].entries = regulatingPowerUpEntries;
  }


  @action

  setRegulatingPowerDown(regulatingPowerDownEntries) {
    const regulatingPowerDownPlanIndex = _.findIndex(this.plans, p => p.NAME_KEY_REGULATING_POWER_DOWN);
    this.plans[regulatingPowerDownPlanIndex].entries = regulatingPowerDownEntries;
  }

  getCurrency() {
    const priceArea = this.uiStore.selectedPlant.priceAreaName.substring(0, 2).toLowerCase();
    if (priceArea === 'dk') {
      return 'DKK/MWh';
    }
    if (priceArea === 'se') {
      return 'SEK/MWh';
    }
    if (priceArea === 'no') {
      return 'NOK/MWh';
    }
    return '';
  }


  /**
  Output:
  const plans = [
  {minute: 0, pplan: 4000, mplan: 2400, prisprognose: 2400},
  {minute: 60, pplan: 3000, mplan: 1398, prisprognose: 2210}
]
*/

  createEntry(minute, amountPowerOutputMegawatt, updateCurrentPlan) {
    const currentPlan = this.plans[this.uiStore.activePlanIndex];
    if (currentPlan) {
      if (updateCurrentPlan) {
        currentPlan.status = 0; // redigeret og klar til at blive gemt og dermed oprettet
      }
      let type = 'PlanEntryDTO';
      if (_.includes(['BidPlanDTO', 'ManualPlanDTO', 'FrequencyPlanDTO', 'IntradayPlanDTO', 'PriceDependentPlanDTO'], currentPlan.classOf)) {
        type = 'PricePlanEntryDTO';
      }
      if (currentPlan.classOf === 'PriceDependentPlanDTO') {
        const price = this.uiStore.selectedPlant.priceDependentPriceValue;
        return {
          minute,
          amountPowerOutputMegawatt,
          classOf: type,
          activationPrice: {
            amount: Number(price),
            currency: this.getCurrency(),
          },
        };
      }
      if (currentPlan.classOf === 'IntradayPlanDTO') {
        const price = currentPlan.buy ? this.uiStore.selectedItem.intradayBuyPrice : this.uiStore.selectedItem.intradaySellPrice;
        return {
          minute,
          amountPowerOutputMegawatt,
          classOf: type,
          activationPrice: {
            amount: Number(price),
            currency: 'EUR/MWh',
          },
        };
      }
      return { minute, amountPowerOutputMegawatt, classOf: type };
    }
  }

// why is this called addEntry when it adds a LOT?
@action
  addEntry(minute, amountPowerOutputMegawatt, price) {
    const { activePlan } = this;
    if (!activePlan || activePlan.readonly) return;
    if (activePlan.entries.length === 0) {
      if (activePlan.nameKey === NAME_KEY_BID_PLAN || activePlan.nameKey === NAME_KEY_MANUAL_PLAN_UP || activePlan.nameKey === NAME_KEY_MANUAL_PLAN_DOWN || activePlan.nameKey === NAME_KEY_FREQUENCY_PLAN_UP || activePlan.nameKey === NAME_KEY_FREQUENCY_PLAN_DOWN) {
      // Adds an entry for each hour to bid-plan
        for (let i = 0; i < getHoursInDay(this.uiStore.selectedDate); i++) {
          activePlan.entries.push(this.createEntry(i * 60, 0));
        }
      } else {
      // Adds (0,0) and (max, 0) to plan
        activePlan.entries.push(this.createEntry(0, 0));

      // 26/06/19 (max, 0) should not be added
      // activePlan.entries.push(this.createEntry(this.uiStore.hoursInDay * 60, 0));
      }
    }

    // Round to 1 decimal point
    amountPowerOutputMegawatt = Math.round(amountPowerOutputMegawatt * 10) / 10;

    if (activePlan.nameKey === NAME_KEY_MANUAL_PLAN_UP || activePlan.nameKey === NAME_KEY_FREQUENCY_PLAN_UP) {
      amountPowerOutputMegawatt = Math.abs(amountPowerOutputMegawatt);
    } else if (activePlan.nameKey === NAME_KEY_MANUAL_PLAN_DOWN || activePlan.nameKey === NAME_KEY_FREQUENCY_PLAN_DOWN) {
      amountPowerOutputMegawatt = -1 * Math.abs(amountPowerOutputMegawatt);
    }

    let correctedMinute = minute;
    if (_.includes(['manual-plan-up', 'manual-plan-down', 'frequency-plan-up', 'frequency-plan-down', 'price-dependent-plan'], activePlan.nameKey)) {
      correctedMinute = Math.round(minute / 60) * 60;
    }

    // if entry allready exists then overwrite amountPowerOutputMegawatt

    const newEntry = this.createEntry(correctedMinute, amountPowerOutputMegawatt, true);

    if (price) {
      newEntry.activationPrice = price;
    }

    // SEJ tried using correctedMinute here, so maybe it helps on the issue with overrides
    const timedIndex = activePlan.entries.findIndex(e => e.minute === correctedMinute);
    if (timedIndex !== -1) {
      const currentEntry = activePlan.entries[timedIndex];
      // Merge the current entry with new entry, allowing existing fields (eg. price) to be carried over.
      const mergedEntry = { ...toJS(currentEntry), ...newEntry };
      console.log('New entry (a)', timedIndex, JSON.parse(JSON.stringify(currentEntry)), JSON.parse(JSON.stringify(newEntry)), JSON.parse(JSON.stringify(mergedEntry)));

      activePlan.entries[timedIndex] = mergedEntry;
    } else {
      console.log('New entry (b)', timedIndex, newEntry);
      activePlan.entries.push(newEntry);
    }

  // Check if the last entry should be corrected.
  }

@computed
get getMaxPrice() {
  const magnifierRate = 1.1;
  const defaultValue = 10;
  const priceForecast = _.find(this.plans, d => d.nameKey === NAME_KEY_PRICE_FORECAST);
  if (priceForecast && priceForecast.entries.length > 0) {
    const prices = priceForecast.entries.map(x => x.priceAmount);
    return Math.max(...prices, defaultValue) * magnifierRate;
  }
  return this.uiStore.selectedPlant.marginalPrice * magnifierRate;
}

@action
updateEntry(point, newPoint) {
  if (this.activePlan.readonly) return;

  const plan = this.plans[this.uiStore.activePlanIndex];
  plan.status = 0;

  Object.assign(point, newPoint);
}

@action
removeEntry(point) {
  if (this.activePlan.readonly) return;

  const plan = this.plans[this.uiStore.activePlanIndex];
  plan.status = 0;

  _.remove(plan.entries, o => o.minute === point.minute && o.amountPowerOutputMegawatt === point.amountPowerOutputMegawatt);
}

@action
drag(draggingPoint, endMinutes, power) {
  this.activePlan.status = 0;

  const entriesCopy = this.activePlan.entries.slice();

  if ((this.activePlan.nameKey === NAME_KEY_MANUAL_PLAN_DOWN || this.activePlan.nameKey === NAME_KEY_FREQUENCY_PLAN_DOWN) && power > 0) {
    power = -1 * power;
  } else if ((this.activePlan.nameKey === NAME_KEY_MANUAL_PLAN_UP || this.activePlan.nameKey === NAME_KEY_FREQUENCY_PLAN_UP) && power < 0) {
    power = -1 * power;
  }

  // Add this because iPlans should always be positive
  if ((this.activePlan.nameKey === NAME_KEY_INTRADAY_PLAN_BUY || this.activePlan.nameKey === NAME_KEY_INTRADAY_PLAN_SELL) && power < 0) {
    power = -1 * power;
  }

  entriesCopy.forEach((e) => {
    if ((e.minute >= draggingPoint.minute && e.minute <= endMinutes) || (e.minute >= endMinutes && e.minute < draggingPoint.minute)) {
      e.amountPowerOutputMegawatt = power;
    }
  });

  this.activePlan.entries = entriesCopy;
}

@computed
get dataHasChanged() {
  return _.find(this.plans.slice(), p => p.status === 0);
}

@computed
get productionPlanSaved() {
  const pPlan = _.find(this.plans.slice(), p => p.nameKey === NAME_KEY_PRODUCTION_PLAN);
  if (typeof pPlan !== 'undefined') {
    return pPlan.status === 0;
  }
  return false;
}

@computed
get dataReadyForApproval() {
  const statusOfAllPlans = this.getStatusOfAllPlans(this.plans.slice());
  return statusOfAllPlans.indexOf(1) > -1;
}

getStatusOfAllPlans(plans) {
  return plans.map(p => this.getStatusListOfPlan(p)).flat();
}

getStatusListOfPlan(plan) {
  if (plan.sourcePlans) {
    return plan.sourcePlans.map(sp => sp.status).sort();
  }
  return [plan.status];
}

getAggregatedSourcePlanEntries(bidPlanTemplate) {
  if (bidPlanTemplate && bidPlanTemplate.sourcePlans.length > 0) {
    const cBidPlans = bidPlanTemplate.sourcePlans.map(p => p.entries.map(e => e.amountPowerOutputMegawatt));

    const entriesSum = _.zip(...cBidPlans).map((powers, h) => ({
      amountPowerOutputMegawatt: powers.reduce((sum, v) => (sum + v * 10), 0) / 10, // 10 is used for fixing floating points
      standbyPrice: null,
      activationPrice: null,
      classOf: 'PricePlanEntryDTO',
      minute: h * 60,
      date: convertMinutesToDateObjForGraph(this.uiStore.selectedDate, h * 60),
    }));

    return entriesSum;
  }

  return [];
}

@computed
get dataForChart() {
  const { selectedPlans } = this;

  if (selectedPlans.length === 0) return [];

  /* NOT-BIDPLANS */
  const withoutBidPlans = this.selectedPlans.filter(p => p.nameKey !== NAME_KEY_BID_PLAN);

  const cWithoutBidPlans = withoutBidPlans.map((plan) => {
    const mPlan = Object.assign({}, plan);

    // Ensure that the plans day equals the selected day. Else just return empty plan.
    if (mPlan.day.date && !moment(mPlan.day.date).isSame(this.uiStore.dateObj, 'date')) {
      return {
        ...mPlan,
        entries: [],
      };
    }

    if (mPlan.entries && mPlan.entries.length > 0) {
      const myEntries = mPlan.entries.map((entry) => {
        let { amountPowerOutputMegawatt } = entry;
        const isIntradayPlan = (mPlan.nameKey === NAME_KEY_INTRADAY_PLAN_BUY || mPlan.nameKey === NAME_KEY_INTRADAY_PLAN_SELL);

        // Here we make the plan turn upside depending on which type of plant we are looking at, i.e. an iPlan can never be negative, but then we couldn't see it
        // for consumers as their chart goes from 0 to a minus capacity.
        if (mPlan.nameKey === NAME_KEY_MANUAL_PLAN_UP || mPlan.nameKey === NAME_KEY_FREQUENCY_PLAN_UP || isIntradayPlan) {
          amountPowerOutputMegawatt = Math.abs(amountPowerOutputMegawatt);
          if (!this.uiStore.selectedPlant.producer) {
            amountPowerOutputMegawatt = -1 * amountPowerOutputMegawatt;
          }
        } else if (mPlan.nameKey === NAME_KEY_MANUAL_PLAN_DOWN || mPlan.nameKey === NAME_KEY_FREQUENCY_PLAN_DOWN) {
          amountPowerOutputMegawatt = Math.abs(amountPowerOutputMegawatt);
          if (!this.uiStore.selectedPlant.producer) {
            amountPowerOutputMegawatt = -1 * amountPowerOutputMegawatt;
          }
        }
        if (entry.hour) {
          return {
            ...entry,
            amountPowerOutputMegawatt,
            date: convertHoursToDateObjForGraph(entry.hour),
          };
        } if (entry.date) {
          return entry;
        }
        return {
          ...entry,
          amountPowerOutputMegawatt,
          date: convertMinutesToDateObjForGraph(this.uiStore.selectedDate, entry.minute),
        };
      });

      let sorted = myEntries.sort((a, b) => a.date - b.date);

      sorted = addMidnightEntry(getHoursInDay(this.uiStore.selectedDate), sorted, mPlan.nameKey);

      return {
        ...mPlan,
        entries: sorted,
      };
    }
    return { ...mPlan };
  });

  /* BIDPLANS */
  const bidPlanTemplate = this.selectedPlans.find(p => p.nameKey === NAME_KEY_BID_PLAN);

  const aggregatedEntries = this.getAggregatedSourcePlanEntries(bidPlanTemplate);

  const bidPlan = {
    ...bidPlanTemplate,
    entries: aggregatedEntries,
  };

  // Need to make sure the bidPlan is above the other plans, so the m-plans are visually on top of bid-plan
  const result = [
    bidPlan,
    ...cWithoutBidPlans,
  ];

  return result;
}

@computed
get selectedPlans() {
  return this.plans.filter(plan => this.selectedPlanIds.indexOf(plan.nameKey) > -1);
}

@action.bound
setPlans(plans) {
  const a = [];
  plans.forEach((plan) => {
    a.push(plan.nameKey);
  });
  this.selectedPlanIds = a;
  if (this.uiStore.activePlanIndex === null) {
    this.setActivePlanIndex(0);
  }
  this.plans.replace(plans);
}

@computed
get activePlan() {
  return (this.plans.length > 0) ? this.plans[this.uiStore.activePlanIndex] : {};
}

@computed
get getBidPlanTableData() {
  const data = _.find(this.plans, p => p.nameKey === NAME_KEY_BID_PLAN);

  if (!data) return [];

  const tableData = [];
  let begin = 0;
  let end = 0;
  let beginFound = false;
  let endFound = false;
  let amountPowerOutputMegawatt = 0;
  let activationPrice = {};

  data.entries.forEach((e) => {
    if (!beginFound && e.amountPowerOutputMegawatt !== 0) {
      begin = e.minute;
      beginFound = true;
      amountPowerOutputMegawatt = e.amountPowerOutputMegawatt;
      activationPrice = e.activationPrice;
    }

    if (beginFound && e.amountPowerOutputMegawatt === 0) {
      end = e.minute;
      endFound = true;
    }

    if (beginFound && endFound) {
      tableData.push({
        begin,
        end,
        amountPowerOutputMegawatt,
        activationPrice,
      });

      beginFound = false;
      endFound = false;
    }
  });
  return tableData;
}

@action
removeBidPlan(indexToRemove) {
  const index = this.plans.findIndex(p => p.nameKey === NAME_KEY_BID_PLAN);

  const { sourcePlans } = this.plans[index];

  const bidPlanToRemove = sourcePlans[indexToRemove];
  const { status } = bidPlanToRemove;

  // If status is  0 < status < 4 it should be deleted instant
  if (status > 0 && status < 4) {
    deleteBidPlan(bidPlanToRemove.bidId, this.uiStore).then(() => {
      this.uiStore.triggerRefreshPlans();
      this.uiStore.triggerRefreshPlansCR();

      toast.success('Blokbud slettet');
    }, () => {
      toast.error('Fejl ved sletning af blokbud');
    });
  } else {
    sourcePlans.splice(indexToRemove, 1);

    this.plans[index].status = 0;
    this.plans[index].sourcePlans = sourcePlans;
  }
}

@computed get plansToBeSaved() {
  return _.filter(this.plans.slice(), p => p.status === 0);
}

@computed get plansToBeCopied() {
  return _.filter(this.plans.slice(), p => p.status === 3);
}

@action
loadTemplate(template) {
  loadTemplate(template)
    .then((res) => {
    // TODO trigger reload of plans.
    }).catch(warn);
}

@action
addBlockBid(begin, end, power, price) {
  const index = this.plans.findIndex(p => p.nameKey === NAME_KEY_BID_PLAN);

  const sourcePlans = this.plans[index].sourcePlans.splice(0);

  const newBidPlan = new Plan().fromJson({
    nameKey: NAME_KEY_BID_PLAN,
    status: 0,
    classOf: 'BidPlanDTO',
    replacedWith: null,
    up: false,
    entries: [],
    color: 'blue',
    markType: 'rect',
    shouldCurveStep: true,
    readonly: false,
    enableTable: false,
  }, getHoursInDay(this.uiStore.selectedDate));


  newBidPlan.entries.forEach((e) => {
    if (e.minute >= begin && e.minute < end) {
      e.amountPowerOutputMegawatt = power;
      e.activationPrice = {
        amount: price,
        currency: 'DKK/MW',
      };
    }
  });

  sourcePlans.push(newBidPlan);

  this.plans[index].status = 0;
  this.plans[index].sourcePlans = sourcePlans;
}

@action
editBlockBid(index, begin, end, power, price) {
  const planIndex = this.plans.findIndex(p => p.nameKey === NAME_KEY_BID_PLAN);
  const sourcePlans = this.plans[planIndex].sourcePlans.splice(0);

  sourcePlans[index].entries.forEach((e) => {
    if (e.minute >= begin && e.minute < end) {
      e.amountPowerOutputMegawatt = power;
      e.activationPrice = {
        amount: price,
        currency: 'DKK/MW',
      };
    } else {
      e.amountPowerOutputMegawatt = 0;
      delete e.activationPrice;
    }
  });

  sourcePlans[index].status = 0;

  this.plans[planIndex].status = 0;
  this.plans[planIndex].sourcePlans = sourcePlans;
}
}
