// @flow
import React from 'react';
import PropTypes from 'prop-types';
import {
  Form, FormGroup, FormControl, Col, ControlLabel, Modal, Button, Alert, Checkbox, DropdownButton,
} from 'react-bootstrap';
import { computed } from 'mobx';
import Select from 'react-select';
import _ from 'lodash';
import Box from '../classes/Box';
import { toast } from 'react-toastify';
import { inject, observer } from 'mobx-react';
import { translate } from 'react-i18next';
import * as ReactDOM from 'react-dom';

@translate()
@inject('controlRoomStore') @observer
class BoxFormModal extends React.Component {
  constructor(props) {
    super(props);
    this.onConfirm = this.onConfirm.bind(this);
    this.handleSignal = this.handleSignal.bind(this);

    this.state = {
      errors: [],
      box: new Box(),
    };
  }

  @computed get plants() {
    return _.map(this.props.controlRoomStore.plants, plant => ({ code: plant.code, name: plant.name }));
  }

  onConfirm = (event) => {
    event.preventDefault();
    const box = new Box().fromJson(this.state.box);

    if (box.isValid) {
      this.saveBox(box);
    } else {
      this.setState({
        errors: box.errors,
      });
    }
  };

  saveBox = (box) => {
    const { t } = this.props;

    this.props.controlRoomStore.saveBox(box)
      .then((res) => {
        toast(t('box-saved'));
        this.props.onClose();

        this.setState({
          box: new Box(),
        });

        return res;
      })
      .catch((err) => {
        console.log(err);
        toast(t('box-not-saved'));

        this.setState({
          errors: [{ msg: err.message }],
        });
        return err;
      });
  };

  onShow = () => {
    const { box } = this.props;

    this.setState({
      errors: [],
      box,
    });
  };

handleSignal(event) {
  let ampere;
 
  if(event.target.value === 'Ampere')
  {
    ampere = true;
  }
  else{
    ampere = false;
  }
  this.state.box.ampere = ampere;
  return event;
}

handleValue(event) {
  let stringToDisplay;
  if(event === true){
    stringToDisplay = 'Ampere';
  }
  else {
    stringToDisplay = 'Volt';
  }
  return stringToDisplay;
}

  render() {
    const { t } = this.props;

    const form = (
      <Form horizontal>
        <FormGroup controlId="plantSelectGroup">
          <Col className="control-label" sm={3}>
            {t('plant')}
          </Col>
          <Col sm={8}>
            <Select
              value={this.plants.filter(p => p.code === parseInt(this.state.box.plantCode, 10))}
              options={this.plants}
              getOptionLabel={r => r.name}
              getOptionValue={r => r.code}
              clearable={false}
              onChange={this.handleSelectedPlantChange}
              ref={(ref) => {
                this.select = ref;
              }}
            />
          </Col>
        </FormGroup>
        <FormGroup controlId="ipAddressGroup">
          <Col componentClass={ControlLabel} sm={3}>IP</Col>
          <Col sm={8}>
            <FormControl
              name="ipAddress"
              type="text"
              value={this.state.box.ipAddress}
              onChange={this.handleChange}
            />
          </Col>
        </FormGroup>

        <FormGroup controlId="portGroup">
          <Col componentClass={ControlLabel} sm={3}>Port</Col>
          <Col sm={8}>
            <FormControl
              name="port"
              type="text"
              value={this.state.box.port}
              onChange={this.handleChange}
            />
          </Col>
        </FormGroup>

        <FormGroup controlId="macGroup">
          <Col componentClass={ControlLabel} sm={3}>Mac adresse</Col>
          <Col sm={8}>
            <FormControl
              name="macAddress"
              type="text"
              value={this.state.box.macAddress}
              onChange={this.handleChange}
            />
          </Col>
        </FormGroup>

        <FormGroup controlId="serialNoGroup">
          <Col componentClass={ControlLabel} sm={3}>Ser. nr.</Col>
          <Col sm={8}>
            <FormControl
              name="serialNo"
              type="text"
              value={this.state.box.serialNo}
              onChange={this.handleChange}
            />
          </Col>
        </FormGroup>

        <FormGroup controlId="hardwareVersionGroup">
          <Col componentClass={ControlLabel} sm={3}>Hw. vers.</Col>
          <Col sm={8}>
            <FormControl
              name="hardwareVersion"
              type="text"
              value={this.state.box.hardwareVersion}
              onChange={this.handleChange}
            />
          </Col>
        </FormGroup>

        <FormGroup controlId="softwareVersionGroup">
          <Col componentClass={ControlLabel} sm={3}>Sw. vers.</Col>
          <Col sm={8}>
            <FormControl
              name="softwareVersion"
              type="text"
              value={this.state.box.softwareVersion}
              onChange={this.handleChange}
            />
          </Col>
        </FormGroup>
        <FormGroup controlId="signal">
        <Col componentClass={ControlLabel} sm={3}>Signal</Col>
        <Col sm={5}>
 <div>
        <select 
        className="form-control" 
        name='ampere' 
         value={this.handleValue(this.state.box.ampere)}
         onChange={this.handleSignal}>
          <option value="Ampere">4-20mA</option>
          <option value="Volt">5V</option>
        </select>
      </div>
        </Col>
       

        </FormGroup>
        <FormGroup controlId="configurations">
          <Col componentClass={ControlLabel} sm={3}>{t('config')}</Col>
           <Col sm={1}></Col>
           <Col  sm={2}  name="bMin">Min (MW)</Col> 
           <Col  sm={2}  name="bMax">Max (MW)</Col> 
        </FormGroup>

        <FormGroup controlId="adc00">
          <Col componentClass={ControlLabel} sm={3}> {t('adc00')}</Col>   
          <Col sm={1}>
                 <input 
                    name="adc00Enabled"
                    type="checkbox"
                    checked={this.state.box.adc00Enabled}
                    onChange={this.handleChange}
                    /></Col>
      
           <Col sm={2}>
            <FormControl
              name="min00"
              type="text"
              value={this.state.box.min00}
              onChange={this.handleChange}
            />
          </Col>
           <Col sm={2}>
            <FormControl
              
              name="max00"
              type="text"
              value={this.state.box.max00}
              onChange={this.handleChange}
            />
          </Col>
        </FormGroup>

         <FormGroup controlId="adc01">
          <Col componentClass={ControlLabel} sm={3}>{t('adc01')}</Col>
             <Col sm={1}>
                 <input
                    name="adc01Enabled"
                    type="checkbox"
                    checked={this.state.box.adc01Enabled}
                    onChange={this.handleChange}
                    /></Col>
       
           <Col sm={2}>
            <FormControl
              
              name="min01"
              type="text"
              value={this.state.box.min01}
              onChange={this.handleChange}
            />
          </Col>
           <Col sm={2}>
            <FormControl
              
              name="max01"
              type="text"
              value={this.state.box.max01}
              onChange={this.handleChange}
            />
          </Col>
        </FormGroup>

        <FormGroup controlId="adc02">
          <Col componentClass={ControlLabel} sm={3}>{t('adc02')}</Col>  
          <Col sm={1}>
                 <input 
                    name="adc02Enabled"
                    type="checkbox"
                    checked={this.state.box.adc02Enabled}
                    onChange={this.handleChange}
                    /></Col>
         
           <Col sm={2}>
            <FormControl
              
              name="min02"
              type="text"
              value={this.state.box.min02}
              onChange={this.handleChange}
            />
          </Col>
           <Col sm={2}>
            <FormControl
              
              name="max02"
              type="text"
              value={this.state.box.max02}
              onChange={this.handleChange}
            />
          </Col>
        </FormGroup>

        <FormGroup controlId="adc03">
          <Col componentClass={ControlLabel} sm={3}>{t('adc03')}</Col>     
          <Col sm={1}>
                 <input 
                    name="adc03Enabled"
                    type="checkbox"
                    checked={this.state.box.adc03Enabled}
                    onChange={this.handleChange}
                    /></Col>
         
           <Col sm={2}>
            <FormControl
              
              name="min03"
              type="text"
              value={this.state.box.min03}
              onChange={this.handleChange}
            />
          </Col>
           <Col sm={2}>
            <FormControl
              
              name="max03"
              type="text"
              value={this.state.box.max03}
              onChange={this.handleChange}
            />
          </Col>
        </FormGroup>
      </Form>
    );

    const errors = (
      <div>
        {this.state.errors.map((error, index) => (
          <Alert bsStyle="danger" key={index}>{error.msg}</Alert>
        ))}
      </div>
    );

    return (
      <Modal show={this.props.open} onHide={this.onClose} onShow={this.onShow}>
        <Modal.Header>{t('edit-box')}</Modal.Header>
        <Modal.Body>
          {errors}
          {form}
        </Modal.Body>
        <Modal.Footer>
          <Button bsStyle="primary" onClick={this.onConfirm}>Gem</Button>
          <Button onClick={this.onClose}>{t('close')}</Button>
        </Modal.Footer>
      </Modal>
    );
  }  



  handleChange = (event) => {
    const { target } = event;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const field = target.name;
    const { box } = this.state;
    box[field] = value;
    this.setState({ box });
  }

  onClose = (event) => {
    this.setState({ box: new Box() });
    this.props.onClose();
  }

  handleSelectedPlantChange = (plant) => {
    const { box } = this.state;
    box.plantCode = plant.code;
    box.plantName = plant.name;
    this.setState({ box });
  }
}

BoxFormModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  box: PropTypes.instanceOf(Box).isRequired,
};

export default BoxFormModal;
