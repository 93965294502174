import axios from 'axios';
import _ from 'lodash';
import { toast } from 'react-toastify';

import { API_DATE_FORMAT } from './CommonAction';
import { NAME_KEY_BID_PLAN } from '../stores/PlanStore';


export function submitPlans(savePrices, plans, uiStore, mkplusStore) {
  // Transform bidplan by taking sourcePlans and concat them into other plans
  const [bidPlans, otherPlans] = _.partition(plans, p => p.nameKey === NAME_KEY_BID_PLAN);
  const allPlans = otherPlans.concat(bidPlans.map(bp => bp.sourcePlans.toJS()).flat());

  const plansAsJson = JSON.stringify(allPlans);

  const up = savePrices.up ? 'saveUp=true' : '';
  const down = savePrices.down ? 'saveDown=true' : '';
  let withPrices = '';
  if (up !== '' && down !== '') {
    withPrices = `?${up}&${down}`;
  } else if (up !== '' || down !== '') {
    withPrices = `?${up}${down}`;
  }

  const url = `${'/api'
            + '/plants/'}${uiStore.selectedPlant.code
  }/plans/${uiStore.selectedDate.format(API_DATE_FORMAT)}` + withPrices;

  axios.post(url, plansAsJson).then((res) => {
    uiStore.triggerRefreshPlans();
    uiStore.triggerRefreshPlansCR();
    mkplusStore.refreshGraphData();
  });
}

export function deleteBidPlan(uuid, uiStore) {
  const url = `/api/plants/${uiStore.selectedPlant.code}/bidplans/${uiStore.selectedDate.format(API_DATE_FORMAT)}/${uuid}`;

  return axios.delete(url);
}

export function approvePlans(uiStore) {
  const url = `/api/plants/${uiStore.selectedPlant.code}/plans/${uiStore.selectedDate.format(API_DATE_FORMAT)}`;
  axios.put(url, {}).then((res) => {
    uiStore.triggerRefreshPlans();
    uiStore.triggerRefreshPlansCR();
  });
}

export function copyPlans(plans, uiStore, fromDate, toDate) {
  const plansAsJson = JSON.stringify(plans);
  const url = `/api/plants/${uiStore.selectedPlant.code}/plans/${uiStore.selectedDate.format(API_DATE_FORMAT)}/clone/from/${fromDate.format(API_DATE_FORMAT)}/to/${toDate.format(API_DATE_FORMAT)}`;
  console.warn('verify copyPlans in planAction');
  axios.post(url, plansAsJson)
    .then((res) => {
      uiStore.triggerRefreshPlans();
      uiStore.triggerRefreshPlansCR();
    })
    .catch((err) => toast.error(err.response.data.message));
}
