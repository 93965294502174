import { Alert } from 'react-bootstrap';
import React from 'react';
import { inject } from 'mobx-react';
import Sound from 'react-sound';

@inject('controlRoomStore')
class Alarm extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.handleDismiss = this.handleDismiss.bind(this);
  }

  handleDismiss() {
    this.props.controlRoomStore.disableAlarm();
  }

  render() {
    return (
      <Alert bsStyle="danger" onDismiss={this.handleDismiss}>
        <h4>Der er kommet aktiveringer!</h4>
        <Sound
          url="sound/alert.mp3"
          playStatus={Sound.status.PLAYING}
          loop
        />
      </Alert>
    );
  }
}

export default Alarm;
