// @flow

class CHPCOMStatus {
    setpoint: string = 'N/A';



    isRemote = -1;



    onlineMeasurement:string = 'N/A';



    alarm = -1;


    _timestamp: string;
    _lastTimestamp: string;



    inOperation = -1;



    fromJson(json) {
      const setpointKey = 'DRCC1.OutWSet.setMag.f';
      const isRemoteKey = 'DRCS1.AutoMan.stVal';
      const onlineMeasurementKey = 'MMXU1.TotW.mag.f';
      const alarmKey = 'DRCS1.AlmSt.stVal';
      const inOperationKey1 = 'DRCS1.ModOnConn.stVal';
      const inOperationKey2 = 'DRCS1.OpSt.stVal';
      const latestTimeStampKey ='MMXU1.TotW.mag.f';


      json.forEach((item) => {
        if (item.key === setpointKey) {
          this.setpoint = parseFloat(item.value).toFixed(1);

        }

        if (item.key === isRemoteKey) {
          this.isRemote = this.toBoolean(item.value);

        }

          if(item.key === latestTimeStampKey){
              this._timestamp = item.value;
              this._timestamp= item.whenWrittenOnPlant;
              this._lastTimestamp=item.whenWrittenToDatabase;

          }

        if(item.key === onlineMeasurementKey){
            this.onlineMeasurement = parseFloat(item.value).toFixed(1);

        }

        if (item.key === alarmKey) {
          this.alarm = this.toBoolean(item.value);

        }

        if (item.key === inOperationKey1) {
          this.inOperation = this.toBoolean(item.value);

        }

        if (item.key === inOperationKey2 && this.inOperation === -1) {
          this.inOperation = this.toBoolean(item.value);

        }
      });

      return this;
    }

    toBoolean(string) {
      return string === 'true' ? true : false;
    }



}

export default CHPCOMStatus;
