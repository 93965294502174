import { dateForApi } from '../actions/CommonAction';
import axios from 'axios/index';
import { getAs, getData, reject } from './Service';
import {
  ProductionPlan, ManualPlanUp, ManualPlanDown, FrequencyPlanUp,
  FrequencyPlanDown, BidPlan, IntradayPlanBuy, IntradayPlanSell,
} from '../controlroom/classes/plans';
import _ from 'lodash';


export function loadTemplate(template) {
  return new Promise((resolve, reject) => {
    console.warn(`loading template: ${template}`);
    resolve('ok');
  });
}

export function getPlans(selectedDate, plantCode) {
  if (selectedDate && plantCode) {
    return getData(`/api/plants/${plantCode}/plans/${dateForApi(selectedDate)}`);
  }
  return reject('missing params in #getPlans', selectedDate, plantCode);
}

export function getActivations(date) {
  return getData(`/api/control-room/activation-history/${dateForApi(date)}/`);
}

export function getApprovedPlans(date) {
  if (date) {
    return getData(`/api/priceareas/approved-plans/${dateForApi(date)}`);
  }
  return reject('missing params in #getApprovedPlans', date);
}


const aggregatedPlansUrl = (date, priceAreaName, plantCode) => {
  let url = `/api/priceareas/${priceAreaName}/aggregated-plans/${dateForApi(date)}`;
  if (plantCode) {
    url += `?plant-code=${plantCode}`;
  }
  return url;
};


export function getGraphPlans(item, date) {
  if (item && item.priceAreaName && date) {
    return getAs(aggregatedPlansUrl(date, item.priceAreaName, item.code), data => ({
      productionPlan: new ProductionPlan().fromJson(data.productionPlan, date),
      manualPlanUp: new ManualPlanUp().fromJson(data.manualPlanUp, date),
      manualPlanDown: new ManualPlanDown().fromJson(data.manualPlanDown, date),
      frequencyPlanUp: new FrequencyPlanUp().fromJson(data.frequencyPlanUp, date),
      frequencyPlanDown: new FrequencyPlanDown().fromJson(data.frequencyPlanDown, date),
      bidPlan: new BidPlan().fromJson(data.bidPlan, date),
      intradayPlanBuy: new IntradayPlanBuy().fromJson(data.intradayPlanBuy, date),
      intradayPlanSell: new IntradayPlanSell().fromJson(data.intradayPlanSell, date),
    }));
  }
  return reject('missing params in #getGraphPlans', item, date);
}

export function getDayInfo(date, plantOrPriceArea) {
  if (date && plantOrPriceArea) {
    let url = `/api/priceareas/${plantOrPriceArea.priceAreaName}/day/${dateForApi(date)}`;

    if (plantOrPriceArea.code) {
      url += `?plant-code=${plantOrPriceArea.code}`;
    }
    return getData(url);
  }
  return reject('missing params in #getDayInfo', date, plantOrPriceArea);
}

const initiatePlanUrl = (plan, date, priceAreas) => {
  let url = `/api/control-room/plans/initiate/${plan}/send/${dateForApi(date)}`;
  if (priceAreas) {
    const areasAsParams = _.map(priceAreas, area => `priceAreas=${area}`);
    url += `?${_.join(areasAsParams, '&')}`;
  }
  return url;
};

export function sendProductionPlan(date, priceAreas) {
  return axios.post(initiatePlanUrl('ProductionPlan', date, priceAreas));
}

export function sendManualPlan(date, priceAreas) {
  return axios.post(initiatePlanUrl('ManualPlan', date, priceAreas));
}

export function sendFrequencyPlan(date, priceAreas) {
  return axios.post(initiatePlanUrl('FrequencyPlan', date, priceAreas));
}

export function sendRegBid(date, priceAreas) {
  return axios.post(initiatePlanUrl('RegBid', date, priceAreas));
}

export function sendBlockBidToMarkedskraft(date, priceAreas) {
  return axios.post(initiatePlanUrl('BidPlanMK', date, priceAreas));
}

export function sendEffectPlan(date, priceAreas) {
  return axios.post(initiatePlanUrl('EffectPlan', date, priceAreas));
}

export function sendIntradayPlan(date, priceAreas) {
  return axios.post(initiatePlanUrl('IntradayPlan', date, priceAreas));
}
