// @flow

import React from 'react';
import { inject, observer } from 'mobx-react';
import { computed } from 'mobx';
import { Button, ButtonGroup, Glyphicon } from 'react-bootstrap';
import DeleteButton from '../../components/common/DeleteButton';
import { toast } from 'react-toastify';
import ReactTable from 'react-table';

import { translate } from 'react-i18next';

import UserFormModal from '../components/UserFormModal';
import ChangePasswordModal from '../components/ChangePasswordModal';
import User from '../classes/User';

@translate()
@inject('controlRoomStore') @observer
export default class UsersControl extends React.Component {
  constructor(props) {
    super(props);

    this.setupUserColumns();

    this.state = {
      userModalOpen: false,
      user: new User(),
      passwordModalOpen: false,
    };
  }

  componentDidMount() {
    this.props.controlRoomStore.getUsers();
    this.props.controlRoomStore.getUserRoles();
  }

  @computed get users() {
    return this.props.controlRoomStore.users.slice();
  }

  @computed get pendingUsers() {
    return this.props.controlRoomStore.pendingUsers.slice();
  }

  @computed get userRoles() {
    return this.props.controlRoomStore.userRoles.slice();
  }

  render() {
    const { t } = this.props;
    const { user, userModalOpen, passwordModalOpen } = this.state;

    const hasPendingUsers = this.pendingUsers.length !== 0;

    return (
      <div>
        {hasPendingUsers && (
          <div className="row">
            <div className="col-xs-6">
              <h4>{t('pending-users')}</h4>
            </div>
            <div className="col-xs-6">
              <div className="text-right">
                <Button onClick={() => this.showAddUserModal()}>{t('add-user')}</Button>
              </div>
            </div>
          </div>
        )}

        {hasPendingUsers && (
          <ReactTable
            data={this.pendingUsers}
            columns={this.userColumns}
            showPagination={false}
            pageSize={this.pendingUsers.length === 0 ? 2 : this.pendingUsers.length}
            className="-striped -highlight"
            defaultSorted={[
              {
                id: 'name',
              },
              {
                id: 'roles',
              },
            ]}
          />
        )}

        <div className="row">
          <div className="col-xs-6">
            <h4>{t('users')}</h4>
          </div>
          <div className="col-xs-6">
            {!hasPendingUsers && (
              <div className="text-right">
                <Button onClick={() => this.showAddUserModal()}>{t('add-user')}</Button>
              </div>
            )}
          </div>
        </div>

        <ReactTable
          data={this.users}
          columns={this.userColumns}
          showPagination={false}
          pageSize={this.users.length === 0 ? 2 : this.users.length}
          className="-striped -highlight"
          defaultSorted={[
            {
              id: 'name',
            },
            {
              id: 'roles',
            },
          ]}
        />

        <UserFormModal
          open={userModalOpen}
          user={user}
          roleOptions={this.userRoles}
          onClose={this.hideUserModal}
        />

        <ChangePasswordModal
          open={passwordModalOpen}
          user={user}
          onClose={this.hideChangePasswordModal}
        />
      </div>
    );
  }

  // User modals
  showAddUserModal() {
    const user = new User();
    user.isNew = true;
    user.validatePasswords = true;

    this.setState({
      user,
      userModalOpen: true,
    });
  }

  showEditUserModal(user) {
    this.setState({
      user,
      userModalOpen: true,
    });
  }

  hideUserModal = () => {
    this.setState({
      user: new User(),
      userModalOpen: false,
    });
  }

  deleteUser = (user) => {
    const { t } = this.props;
    this.props.controlRoomStore.deleteUser(user).then(() => {
      toast(t('user-deleted'));
    }, (err) => {
      toast(err.message);
    });
  }

  showChangePasswordModal(user) {
    const mUser = new User().fromJson(user);
    mUser.validatePasswords = true;

    this.setState({
      user: mUser,
      passwordModalOpen: true,
    });
  }

  hideChangePasswordModal = () => {
    this.setState({
      user: new User(),
      passwordModalOpen: false,
    });
  }

  setupUserColumns() {
    const { t } = this.props;
    const userTypes = ['API', 'UI', 'Old']; // Show in table what type of user it is
    // eslint-disable-next-line no-nested-ternary
    const status = statusInt => (statusInt === 1 ? 'Pending' : statusInt === 4 ? 'Inactive' : 'Active');
    this.userColumns = [
      {
        Header: t('email'),
        accessor: 'name',
      },
      {
        id: 'roles',
        Header: 'Roles',
        accessor: u => u.roles.join(', '),
      },
      {
        id: 'userType',
        Header: t('user-type'),
        accessor: u => userTypes[u.userType],
      },
      {
        id: 'userStatus',
        Header: 'Status',
        accessor: u => status(u.status),
      },
      {
        id: 'buttons',
        Header: '',
        minWidth: 200,
        maxWidth: 200,
        accessor: user => (
          <ButtonGroup>
            <Button
              className="btn-small"
              onClick={() => {
                this.showEditUserModal(user);
              }}
            >
              <Glyphicon glyph="pencil" />
            </Button>

            <DeleteButton
              className="btn-small"
              onConfirmation={() => {
                this.deleteUser(user);
              }}
              title={t('delete-user')}
              message={t('confirm-delete-user')}
              t={this.props.t}
            >
              <Glyphicon glyph="trash" />
            </DeleteButton>
          </ButtonGroup>
        ),
      },
    ];
  }
}
