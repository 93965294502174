import React from 'react';
import { Link } from 'react-router-dom';
import { confirmUser } from '../../actions/AuthAction';

export default class ConfirmationScreen extends React.Component {
  async componentDidMount() {
    const { match } = this.props;
    const confirmation = await confirmUser(match.params.uuid);
    if (!confirmation) {
      this.props.history.push('/login');
    }
    // User confirmed, allow to stay on page
  }

  render() {
    return (
      <div style={{
        position: 'fixed',
        marginTop: '5vh',
        marginLeft: '40vw',
        fontSize: '1.8vh',
        width: '20vw',
      }}
      >
        You have successfully confirmed your account.
        <br />
        Go to
        <Link to="/login"> Planner</Link>
        .
      </div>
    );
  }
}
