import React from 'react';
import {
  Well, Nav, Navbar, NavItem,
} from 'react-bootstrap';
import { inject, observer } from 'mobx-react';

import MyDatePicker from '../common/MyDatePicker';
import PlantsDropdown from './PlantsDropdown';
import PlanSelector from '../plan/PlanSelector';
import Plant from './Plant';
import PlanButtons from './PlanButtons';
import DateButtons from './DateButtons';
import Messages from '../common/Messages';
import { logout } from '../../actions/AuthAction';
import { toast } from 'react-toastify';
import { translate } from 'react-i18next';
import StaticMessage from "../common/StaticMessage";

@translate()
@inject('authStore') @observer
export default class PlantsPage extends React.Component {
  render() {
    const { t } = this.props;
    const { isAdmin } = this.props.authStore;

    if (!this.props.authStore.isAuthenticated) {
      this.handleLogout();
    }

    return (
      <div className="plants-page">
        <Navbar collapseOnSelect fluid>
          <Navbar.Header>
            <Navbar.Brand>
              <a href="/">
                <img src="img/volue_logo.png" className="logo" alt="logo" />
                <span>INDMELDING</span>
                <span />
              </a>
            </Navbar.Brand>
            <Navbar.Toggle />
          </Navbar.Header>
          <Navbar.Collapse>
            <Nav pullRight>
              {isAdmin ? (
                <NavItem eventKey={1} href="#" onSelect={this.gotoControlRoom}>{t('go-to-control-room')}</NavItem>
              ) : (
                <br />
              )}
              <NavItem eventKey={1} href="#">
                { t('user') }
:
                {' '}
                {this.props.authStore.username}
              </NavItem>
              <NavItem eventKey={2} href="#" onSelect={this.handleLogout}>{ t('sign-out') }</NavItem>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
        <div className="plants-page-table">
          <div className="plants-page-row">
            <div className="side-bar">
              <Well bsSize="small" className="full-height">
                <PlantsDropdown />
                <MyDatePicker />
                <PlanSelector />
                <PlanButtons />
                <DateButtons />
              </Well>
            </div>
            <div className="plant-container">
              <Plant />
            </div>
            <div className="messages">
              <Messages />
              <div id="box-static">
              <div className="static-messages">
                <StaticMessage />
              </div></div>
            </div>

          </div>

        </div>
      </div>
    );
  }

  handleLogout = () => {
    logout(this.props.authStore);
    toast(this.props.t('login-required'));
    this.props.history.push('/login');
  };

  gotoControlRoom = () => {
    this.props.history.push('/control-room');
  }
}
