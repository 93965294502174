import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { translate } from 'react-i18next';
import { inject, observer } from 'mobx-react';

@translate()
@inject('plantStore') @observer
class ResetDayElement extends React.Component {
  constructor(props) {
    super(props);
    this.toggleModal = this.toggleModal.bind(this);
    this.resetDay = this.resetDay.bind(this);
  }

  mapStateToProps() {
    return {
      plantStore: this.props.plantStore,
      shouldShowResetDayModal: this.props.plantStore.shouldShowResetDayModal,
    };
  }

  render() {
    const { t } = this.props;
    const myProps = this.mapStateToProps();

    return (
      <div>
        <Button onClick={this.toggleModal}>{t('reset-day')}</Button>
        <Modal
          show={myProps.shouldShowResetDayModal}
          onHide={this.toggleModal}
          className="reset-day-modal"
          bsSize="sm"
        >
          <Modal.Header closeButton>
            <Modal.Title>{t('reset-day')}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              { t('confirm-delete-all-plans-for-day') }
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.resetDay} bsStyle="primary">
              {t('ok')}
            </Button>
            <Button onClick={this.toggleModal}>{t('abort-close')}</Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }

  toggleModal() {
    const myProps = this.mapStateToProps();
    myProps.plantStore.toggleResetDayModal();
  }

  resetDay() {
    const myProps = this.mapStateToProps();
    myProps.plantStore.resetDay();
  }
}

export default ResetDayElement;
