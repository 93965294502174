// @flow
import Contact from '../classes/Contact';
import OnlineValue from '../classes/OnlineValue';
import Plant from '../classes/Plant';
import PriceArea from '../classes/PriceArea';
import axios from 'axios';
import PriceForecastEntry from '../classes/PriceForecastEntry';
import { dateForApi } from '../actions/CommonAction';
import { getAs, reject } from './Service';

const plantUrl = plantCode => `/api/plants/${plantCode}`;

const contactUrl = contactId => `/contacts/${contactId}`;

export function addContact(contact: Contact, plantCode: string) {
  return axios.post(`${plantUrl(plantCode)}/contacts`, contact);
}

export function updateContact(contact: Contact, plantCode: string) {
  return axios.put(plantUrl(plantCode) + contactUrl(contact.uuid), contact);
}

export function deleteContact(contact: Contact, plantCode: string) {
  return axios.delete(plantUrl(plantCode) + contactUrl(contact.uuid));
}

export function updateMarginalPrice(price: number, plantCode: number) {
  return new Promise((resolve) => {
    const payload = { marginalPrice: price };

    axios.put(`${plantUrl(plantCode)}/marginalPrice`, payload).then((res) => {
      resolve(price);
    });
  });
}

export function updateRegulatingPower(powerUp: number, powerDown: number, plantCode: number) {
  const payload = {
    regualatingPowerUp: powerUp,
    regualatingPowerDown: powerDown,
  };

  return axios.put(`${plantUrl(plantCode)}/regulatingPower`, payload);
}

export function updateIntradayPrices(buyPrice: number, sellPrice: number, plantCode: number) {
  return new Promise((resolve) => {
    const payload = {
      intradayBuyPrice: buyPrice,
      intradaySellPrice: sellPrice,
    };

    axios.put(`${plantUrl(plantCode)}/intradayPrices`, payload).then((res) => {
      resolve(payload);
    });
  });
}

export function updatePriceDependentPrice(price: number, plantCode: number) {
  return new Promise((resolve) => {
    const payload = {
      price,
    };

    axios.put(`${plantUrl(plantCode)}/dayAhead`, payload).then((res) => {
      resolve(payload);
    });
  });
}

export function getOnlinevalues(date, plantCode) {
  if (plantCode && date) {
    return axios.get(`${plantUrl(plantCode)}/online-values/${dateForApi(date)}`)
      .then((result) => {
        const onlineValues = result.data.map(e => new OnlineValue(e));
        return onlineValues;
      });
  }

  return reject('missing params in #getOnlineValues', plantCode, date);
}

export function getPlants() {
  return new Promise((resolve) => {
    axios.get('/api/plants').then((res) => {
      const plants = res.data.map(plant => new Plant().fromJson(plant));
      resolve(plants);
    });
  });
}

/**
 * copies hour 23 to hour 24 since graph is showing spans and not timestamps.
 */
function addLastHour(forecastEntries: Array) {
  const lastElementCopy = Object.assign({}, forecastEntries[forecastEntries.length - 1]);
  lastElementCopy.hour++;
  forecastEntries.push(lastElementCopy);
}

function getForecastEntryData(requestUrl, date, addLastValue): Array<PriceForecastEntry> {
  return axios.get(requestUrl)
    .then((res) => {
      let result = [];
      if (res.status === 204) {
        result = [];
      } else {
        const inputData = res.data;
        if (inputData.length === 0) {
          result = [];
        } else {
          const sortedData = inputData.sort((a, b) => a.hour - b.hour); // Make sure last added is final value
          if (addLastValue) {
            addLastHour(sortedData);
          }
          const priceForecast = sortedData.map(forecastData => new PriceForecastEntry(date).fromJson(forecastData));
          result = priceForecast;
        }
      }

      return result;
    });
}

export function getForecast(fromDate, priceAreaName): Array<PriceForecastEntry> {
  return getForecastEntryData(`/api/prices/forecast/${priceAreaName}/${dateForApi(fromDate)}`, fromDate, true);
}

export function getSpotPrices(fromDate, priceAreaName): Array<PriceForecastEntry> {
  return getForecastEntryData(`/api/prices/spot/${priceAreaName}/${dateForApi(fromDate)}`, fromDate, true);
}

export function getBalancePrices(fromDate, priceAreaName): Array<PriceForecastEntry> {
  return getForecastEntryData(`/api/prices/${priceAreaName}/Imbalance/${dateForApi(fromDate)}`, fromDate, false);
}

export function resetDay(plantCode, date) {
  if (plantCode && date) {
    return axios.post(`/api/plants/${plantCode}/plans/${dateForApi(date)}/reset`);
  }
  return reject('missing params in #resetDay', plantCode, date);
}

export function getFavorites(plantCode) {
  if (plantCode) {
    return axios.get(`/api/plants/${plantCode}/favorites/`)
      .then(res => res.data);
  }
  return reject('missing params in #getFavorites', plantCode);
}

export function saveFavorite(plantCode, date, template) {
  return axios.put(`/api/plants/${plantCode}/favorites/`, {
    plantCode,
    day: {
      date,
    },
    name: template,
  });
}

export function applyFavorite(favorite, targetFromDate, targetToDate) {
  if (targetToDate === undefined) {
    targetToDate = targetFromDate;
  }

  return axios.post(`/api/plants/${favorite.plantCode}/plans/${dateForApi(favorite.day.date)}/clone/from/${dateForApi(targetFromDate)}/to/${dateForApi(targetToDate)}`);
}

export function getPriceAreas(date) {
  return new Promise((resolve, reject) => {
    axios.get(`/api/priceareas/${dateForApi(date)}/plants-by-pricearea`).then(
      (res) => {
        resolve(res.data.map(priceAreaData => new PriceArea().fromJson(priceAreaData)));
      },
      (err) => {
        reject(err);
      },
    );
  });
}

export function checkPriceAreaUpdate(date, hash) {
  if (date && hash) {
    return getAs(`/api/priceareas/${dateForApi(date)}/${hash}/plans-status-updated`, data => data.updatedHash);
  }
  return reject('missing date or hash');
}
