import axios from 'axios';
import { isAuthorizationTokenSet, setAuthorizationToken } from '../utils/setAuthorizationToken';
import jwtDecode from 'jwt-decode';
import i18n from '../i18n';
import { toast } from 'react-toastify';

export function login(authStore, data) {
  const payload = {
    userId: data.identifier,
    password: data.password,
  };

  return axios.post('/login', payload).then((res) => {
    console.log(res);
    if (res.status === 201) {
      toast('Confirmation e-mail has been sent to e-mail account', { type: 'success', autoClose: 5800 });
    }
    const token = res.data;
    localStorage.setItem('jwtToken', token);
    localStorage.setItem('language', data.language.short);
    hydrateJwtToken(authStore, token);
  });
}

export function userChangePassword(uuid, password) {
  return axios({
    method: 'PUT',
    url: `/api/users/${uuid}/password`,
    headers: {
      'Content-Type': 'text/plain',
    },
    data: password,
  });
}

// Forgotten password
export function forgottenPassword(email) {
  return axios({
    method: 'POST',
    url: '/api/users/forgottenPassword',
    headers: {
      'Content-Type': 'text/plain',
    },
    data: email,
  });
}

export function hydrateJwtToken(authStore, token) {
  try {
    setAuthorizationToken(token);
    authStore.setUser(jwtDecode(token));
    i18n.changeLanguage(localStorage.language);
  } catch (e) {
    console.log('Could not hydrate token, force user logout', token);
    logout(authStore);
  }
}

export function logout(authStore) {
  if (!isAuthorizationTokenSet()) {
    console.log('Ignoring logout request, as we are already logged out.');
    // Do not start another logout procedure.
    return;
  }

  setAuthorizationToken();
  localStorage.removeItem('jwtToken');
  localStorage.removeItem('language');

  authStore.reset();
  // Make sure that all stores clear their state.
  authStore.stores.forEach(store => store.reset());
}
// Check if user is pending a password
export const checkPendingPassword = uuid => axios.get(`api/users/${uuid}/pending`).then(res => JSON.parse(res.data)).catch(() => false);

// Confirm user
export const confirmUser = uuid => axios.put(`api/users/${uuid}/confirm`).then(res => JSON.parse(res.data)).catch(() => false);
