import * as React from 'react';
import { translate } from 'react-i18next';


/**
 * Wrap other components in this, to provide generic error handling.
 */
@translate()
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, info) {
    this.setState({ hasError: true });
  }

  render() {
    const { t } = this.props;
    if (this.state.hasError) {
      return (
        <span>
          <h1>{ this.props.errorTitle || t('error-occurred-title') }</h1>
          <div>{ this.props.errorMessage || t('error-occurred-message')}</div>
        </span>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
