import React from 'react';
import ReactDOM from 'react-dom';
import { inject, observer } from 'mobx-react';
import { Button, ButtonGroup, Table } from 'react-bootstrap';
import NewContactModal, { typeOptions } from './NewContactModal';
import { translate } from 'react-i18next';
import DeleteButton from '../common/DeleteButton';
import Contact from '../../classes/Contact';
import { computed } from 'mobx';

@translate()
@inject('uiStore') @observer
class ContactsTable extends React.Component {
  constructor(props) {
    super(props);
    this.showCreateContact = this.showCreateContact.bind(this);
  }

  @computed get contacts() {
    return this.props.uiStore.selectedPlant.contacts.slice().sort((a, b) => a.priority - b.priority);
  }

  render() {
    const { t } = this.props;
    return (
      <div>
        <Table striped bordered condensed hover>
          <thead>
            <tr>
              <th>{t('priority')}</th>
              <th>{t('name')}</th>
              <th>{t('country-code')}</th>
              <th>{t('phone')}</th>
              <th>{t('email')}</th>
              <th>{t('type')}</th>
              <th>{t('primary')}</th>
              <th>&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            { this.contacts.map((contact: Contact) => {
              const type = typeOptions.find(t => t.value === contact.receiverType);
              return (
                <tr key={contact.uuid}>
                  <td>{contact.priority}</td>
                  <td>{contact.fullName}</td>
                  <td>{contact.countryCode}</td>
                  <td>{contact.phoneNumber}</td>
                  <td>{contact.email}</td>
                  <td>{type && type.name}</td>
                  <td>{contact.primaryContact ? t('yes') : t('no')}</td>
                  <td>
                    <ButtonGroup>
                      <Button onClick={() => this.editContact(contact, t)}>{t('edit-contact')}</Button>
                      <DeleteButton
                        onConfirmation={() => this.deleteContact(contact)}
                        title={t('confirm-delete-contact-title')}
                        message={t('confirm-delete-contact', { name: contact.name })}
                        t={t}
                      >
                        {t('delete-contact')}
                      </DeleteButton>
                    </ButtonGroup>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
        <div className="text-right">
          <Button onClick={() => this.showCreateContact(t)}>
            {t('add-contact')}
          </Button>
        </div>
      </div>
    );
  }

    deleteContact = (contact) => {
      this.props.uiStore.selectedPlant.deleteContact(contact);
    };

    editContact = (contact: Contact, t: func) => {
      const contactAction = (contact: Contact, uiStore: UiStore) => {
        uiStore.selectedPlant.updateContact(contact);
      };
      this.showContactForm(contact, contactAction, t);
    };

    showCreateContact = (t) => {
      const contactAction = (contact: Contact, uiStore: UiStore) => {
        uiStore.selectedPlant.addContact(contact);
      };
      this.showContactForm(new Contact(), contactAction, t);
    };

    showContactForm(contact: Contact, contactAction: func, t: func) {
      const { uiStore } = this.props;
      function createContact(returnedContact) {
        contactAction(returnedContact, uiStore);
        dispose();
      }
      function dispose() {
        ReactDOM.unmountComponentAtNode(wrapper);
        wrapper.remove();
      }
      const wrapper = document.body.appendChild(document.createElement('div'));
      const createContactModal = (
        <NewContactModal
          onConfirm={createContact}
          onClose={dispose}
          contact={contact}
          t={t}
        />
      );
      ReactDOM.render(createContactModal, wrapper);
    }
}

export default ContactsTable;
