// @flow

import {action, computed, observable, reaction,} from 'mobx';
import moment from 'moment-timezone';

import Plant from '../classes/Plant';
import {getDayInfo} from '../services/PlanService';

const initVisitedPages = [false, true, false, false, false, false];

export class UiStore {
    @observable selectedDate;

    @observable selectedItem;

    // : Plant;
    @observable plansStateChanged: number;

    @observable plantsStateChanged: number;

    @observable CRPlansStateChanged: number;

    @observable dayInfo = null;

    @observable activePlanIndex = 4;

    // Added visitedPages to enable lazy loading of tab content
    @observable visitedPages = initVisitedPages;

    @observable currentTabKey = 1;

    constructor() {
      this.reset();

      reaction(
        () => [this.selectedDate, this.selectedItem],
        ([date, plantOrPriceArea]) => {
          getDayInfo(date, plantOrPriceArea).then((day) => {
            this.setDayInfo(day);
          });
        },
      );
    }

    @action
    reset = () => {
      this.setSelectedDate(moment().add(1, 'day'));
      this.selectedItem = {};
      this.messages = [];
      this.plansStateChanged = 0;
      this.plantsStateChanged = 0;
      this.CRPlansStateChanged = 0;
    }

    @action
    setSelectedDate(date) {
      // Set to production plan as default
      this.setActivePlanIndex(4);
      this.clickOnPlanTab();

      this.selectedDate = date;
    }

    // This solves an issue where multiple plans are displayed as active when setting the active plan index programmatically
    clickOnPlanTab() {
      const pPlanTab = document.getElementById('plan-tabs-tab-4');

      if (pPlanTab) {
        pPlanTab.click();
      }
    }

    @action
    setDayInfo(dayInfo) {
      // TODO: RVA: This does not look good. Time zone should be selected differently (from price area) instead of being hardcoded.
      // TODO: RVA: And when data is fetched from the API, be sure to use UTC.
      moment.tz.setDefault('Europe/Copenhagen');

      this.dayInfo = dayInfo;
    }

    @action
    setSelectedItem(plantOrPriceArea) {
      this.resetVisitedPages();

      // Set to production plan as default
      this.setActivePlanIndex(4);
      this.clickOnPlanTab();

      this.selectedItem = plantOrPriceArea;
    }

    @action
    resetVisitedPages() {
      // Reset visited pages so we dont render unnecessary stuff
      const visitedPages = initVisitedPages.slice(0);
      visitedPages[this.currentTabKey] = true;
      this.setVisitedPages(visitedPages);
    }

    @action
    setSelectedPlant(plant: Plant) {
      this.resetVisitedPages();

      // Set to production plan as default
      this.setActivePlanIndex(4);
      this.clickOnPlanTab();

      this.selectedItem = plant;
    }

    @computed
    get hoursInDay() {
      if (this.selectedDate) {
        const selected = this.selectedDate.clone().startOf('day');
        const tomorrow = selected.clone().add(1, 'days');
        const diffHours = moment.duration(tomorrow.diff(selected)).asHours();
        return diffHours;
      } else {
        return 24;
      }
    }

    @computed
    get hash() {
      return this.dayInfo ? this.dayInfo.hash : undefined;
    }

    @action
    setHash(hash) {
      this.dayInfo.hash = hash;
    }

    @computed
    get dateObj() {
      return this.selectedDate;
    }

    @computed
    get selectedPlant() {
      if (this.selectedItem && this.selectedItem.code) {
        return this.selectedItem;
      }
      return new Plant();
    }

    @action
    triggerRefreshPlans() {
      this.plansStateChanged++;
    }

    @action
    triggerRefreshPlants() {
      this.plantsStateChanged++;
    }

    @action
    triggerRefreshPlansCR() {
      this.CRPlansStateChanged++;
    }

    @action
    setVisitedPages(visitedPages) {
      this.visitedPages = visitedPages;
    }

    @action
    setActivePlanIndex(index) {
      this.activePlanIndex = index;
    }

    @action
    setCurrentTabKey(key) {
      this.currentTabKey = key;
    }
}
