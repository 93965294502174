import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { computed } from 'mobx';
import { translate } from 'react-i18next';
import ReactTable from 'react-table';
import { getStatusName } from '../../actions/CommonAction';

@translate()
@inject('controlRoomStore') @observer
export default class BlockBid extends React.Component {
  constructor(props) {
    super(props);

    const { t } = props;

    this.columns = [
      {
        Header: t('plant'),
        accessor: 'plant',
      },
      {
        Header: t('status'),
        id: 'state',
        accessor: blockBid => t(getStatusName(blockBid.state)),
      },
      {
        Header: t('begin'),
        accessor: 'start',
      },
      {
        Header: t('end'),
        accessor: 'end',
      },
      {
        Header: t('production'),
        accessor: 'production',
      },
      {
        Header: t('price'),
        accessor: 'price.amount',
      },
    ];
  }

  @computed get blockBids() {
    return this.props.controlRoomStore.blockBids;
  }

  render() {
    return (
      <ReactTable
        data={this.blockBids.toJS()}
        columns={this.columns}
        keyfield="bidId"
        showPagination={this.blockBids.length > 20}
        pageSize={this.blockBids.length === 0 ? 2 : this.blockBids.length}
        className="-striped -highlight"
      />
    );
  }
}
