import React from 'react';
import { inject, observer } from 'mobx-react';
import { translate } from 'react-i18next';
import { computed } from 'mobx';
import { Glyphicon, Button, ButtonGroup } from 'react-bootstrap';
import ReactTable from 'react-table';
import DeleteButton from '../common/DeleteButton';
import NewBlockBidModal from './NewBlockBidModal';
import { getStatusName } from '../../actions/CommonAction';

@translate()
@inject('planStore', 'plantStore') @observer
export default class BidPlanTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modalOpen: false,
      editBid: null,
    };

    const { t } = props;

    this.columns = [
      {
        Header: t('status'),
        id: 'state',
        accessor: blockBid => t(getStatusName(blockBid.state)),
      },
      {
        Header: t('begin'),
        accessor: 'start',
      },
      {
        Header: t('end'),
        accessor: 'end',
      },
      {
        Header: t('production'),
        accessor: 'production',
      },
      {
        Header: t('price'),
        accessor: 'price.amount',
      },
      {
        id: 'buttons',
        Header: '',
        minWidth: 68,
        maxWidth: 68,
        accessor: blockBid => blockBid.state < 4 && (
          <ButtonGroup>
            <Button className="btn-small" onClick={() => this.editBlockBid(blockBid)}>
              <Glyphicon glyph="pencil" />
            </Button>
            <DeleteButton
              className="btn-small"
              onConfirmation={() => {
                this.deleteBlockBid(blockBid);
              }}
              title="Slet blokbud?"
              message="Klik bekræft for at slette blokbuddet"
              t={this.props.t}
            >
              <Glyphicon glyph="trash" />
            </DeleteButton>
          </ButtonGroup>
        ),
      },
    ];
  }

    @computed get blockBids() {
    return this.props.planStore.blockBids.map((entry, index) => (
      {
        index,
        ...entry,
      }
    ));
  }

    editBlockBid = ({
      index, startMinute, endMinute, price, production,
    }) => {
      this.setState({
        editBid: {
          index,
          begin: startMinute,
          end: endMinute,
          price: price.amount,
          power: production,
        },
        modalOpen: true,
      });
    }

    deleteBlockBid(blockBid) {
      this.props.planStore.removeBidPlan(blockBid.index);
    }

    showCreateBlockBid = () => {
      this.setState({
        editBid: null,
        modalOpen: true,
      });
    }

    onModalClose = () => {
      this.setState({ modalOpen: false });
    }

    onModalSubmit = ({
      index, begin, end, price, power,
    }) => {
      if (typeof index === 'undefined') {
        // NEW
        this.props.planStore.addBlockBid(
          begin,
          end,
          power,
          parseInt(price, 10),
        );
      } else {
        // EDIT
        this.props.planStore.editBlockBid(
          index,
          begin,
          end,
          power,
          parseInt(price, 10),
        );
      }

      this.setState({ modalOpen: false });
    }

    render() {
      const { modalOpen, editBid } = this.state;

      return (
        <div>
          <div className={['text-right']}>
            <Button onClick={this.showCreateBlockBid}>Tilføj blokbud</Button>
          </div>
          <ReactTable
            data={this.blockBids}
            columns={this.columns}
            showPagination={this.blockBids.length > 20}
            pageSize={this.blockBids.length === 0 ? 2 : this.blockBids.length}
            className="-striped -highlight"
          />

          <NewBlockBidModal
            open={modalOpen}
            init={editBid}
            onDismiss={this.onModalClose}
            onSubmit={this.onModalSubmit}
          />
        </div>

      );
    }
}
