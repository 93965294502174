import React from 'react';
import { inject, observer } from 'mobx-react';
import { translate } from 'react-i18next';
import {
  Modal, Button, Checkbox, ButtonToolbar, FormGroup,
} from 'react-bootstrap';
import { computed } from 'mobx';
import _ from 'lodash';

@translate()
@inject('controlRoomStore') @observer
export default class PriceAreaPickerModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      disabled: false,
    };

    this.priceAreaCheckboxRef = {};
  }


  @computed get priceAreas() {
    return this.props.controlRoomStore.priceAreas.slice().sort((a, b) => a.name.localeCompare(b.name));
  }

  onSubmit = (e) => {
    e.preventDefault();

    const selectedAreas = _.filter(this.priceAreas,
      priceArea => this.priceAreaCheckboxRef[priceArea.name].checked)
      .map(priceArea => priceArea.name);

    this.props.onSubmit(selectedAreas);
  }

  render() {
    const {
      t, onDismiss, open, disabled, submitTitle, defaultChosen,
    } = this.props;

    return (
      <Modal
        show={open}
        onHide={onDismiss}
        bsSize="sm"
      >
        <Modal.Header>Vælg prisområder</Modal.Header>
        <Modal.Body>
          <form onSubmit={this.onSubmit}>
            {defaultChosen === 'all' ? (
              <FormGroup controlId="priceAreas">
                { this.priceAreas.map(priceArea => (
                  <Checkbox key={priceArea.name} defaultChecked={priceArea.name === 'NO2' || priceArea.name === 'NO3' || priceArea.name === 'NO4' || priceArea.name === 'dk1' || priceArea.name === 'dk2'} inputRef={(ref) => { this.priceAreaCheckboxRef[priceArea.name] = ref; }}>{priceArea.name}</Checkbox>
                ))}
              </FormGroup>
            ) : (
              <FormGroup controlId="priceAreas">
                { this.priceAreas.map(priceArea => (
                  <Checkbox key={priceArea.name} defaultChecked={priceArea.name === 'dk1' || priceArea.name === 'dk2'} inputRef={(ref) => { this.priceAreaCheckboxRef[priceArea.name] = ref; }}>{priceArea.name}</Checkbox>
                ))}
              </FormGroup>
            )
            }

            <ButtonToolbar>
              <Button
                type="submit"
                bsStyle="primary"
                disabled={disabled}
              >
                {submitTitle}
              </Button>
              <Button onClick={onDismiss}>{t('cancel')}</Button>
            </ButtonToolbar>
          </form>
        </Modal.Body>
      </Modal>

    );
  }
}
