import React from 'react';
import { inject, observer } from 'mobx-react';
import { translate } from 'react-i18next';
import { Modal } from 'react-bootstrap';

import NewBlockBidForm from './NewBlockBidForm';

@translate()
@inject('controlRoomStore') @observer
export default class NewBlockBidModal extends React.Component {
  render() {
    const {
      t, onDismiss, onSubmit, open, init,
    } = this.props;

    return (
      <Modal
        show={open}
        onHide={onDismiss}
        bsSize="sm"
        backdrop="static"
      >
        <Modal.Header>{t('bid-plan')}</Modal.Header>
        <Modal.Body>
          <NewBlockBidForm
            init={init}
            onDismiss={onDismiss}
            onSubmit={onSubmit}
          />
        </Modal.Body>
      </Modal>

    );
  }
}
