import React from 'react';
import {
  FormControl,
} from 'react-bootstrap';

import { differenceInMinutes } from '../../actions/CommonAction';

export default class TimePicker extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      date: props.date,
      options: this.generateOptions(props.date),
    };
  }

  componentDidUpdate() {
    if (this.props.date !== this.state.date) {
      this.setState({
        date: this.props.date,
        options: this.generateOptions(this.props.date),
      });
    }
  }

  generateOptions(date) {
    if (date == null) return [];

    const options = [];

    let mutDate = date.clone().startOf('day');

    const startDate = date.startOf('day');

    let isSameDay = true;
    while (isSameDay) {
      if (date.isSame(mutDate, 'day')) {
        options.push({
          minutes: differenceInMinutes(startDate, mutDate),
          hour: mutDate.format('HH:mm'),
        });
        mutDate = mutDate.add(1, 'hour');
      } else {
        isSameDay = false;
      }
    }

    options.push({
      minutes: differenceInMinutes(startDate, mutDate),
      hour: mutDate.format('HH:mm'),
    });

    return options;
  }

  render() {
    const { date, options } = this.state;
    const { value } = this.props;

    if (date == null) return false;

    return (
      <FormControl componentClass="select" value={value} onChange={this.props.onChange}>
        {options.map((option, index) => (
          <option key={index} value={option.minutes}>{option.hour}</option>
        ))}
      </FormControl>
    );
  }
}
