// @flow
import React from 'react';
import PropTypes from 'prop-types';
import {
  Form, FormGroup, FormControl, Col, ControlLabel, Modal, Button, Alert,
} from 'react-bootstrap';
import User from '../classes/User';
import { toast } from 'react-toastify';
import { inject, observer } from 'mobx-react';
import { translate } from 'react-i18next';

@translate()
@inject('controlRoomStore') @observer
class ChangePasswordModal extends React.Component {
  constructor(props) {
    super(props);
    this.onConfirm = this.onConfirm.bind(this);

    this.state = {
      errors: [],
      user: new User(),
    };
  }

  onConfirm = (event) => {
    event.preventDefault();
    const { user } = this.state;

    const mUser = new User().fromJson(user);

    if (mUser.isValid) {
      this.saveUser(mUser);
    } else {
      this.setState({
        errors: mUser.errors,
      });
    }
  }

  saveUser = (user) => {
    const { t } = this.props;

    this.props.controlRoomStore.userChangePassword(user)
      .then((res) => {
        toast(t('password-changed'));
        this.props.onClose();

        this.setState({
          user: new User(),
        });

        return res;
      })
      .catch((err) => {
        console.log(err);
        toast(t('user-not-saved'));

        this.setState({
          errors: [{ msg: err.message }],
        });
        return err;
      });
  }

  onShow = () => {
    const { user } = this.props;

    this.setState({
      errors: [],
      user,
    });
  }

  render() {
    const { t } = this.props;
    const { user } = this.state;

    const form = (
      <Form horizontal onSubmit={this.preventSubmit}>
        <FormGroup controlId="passwordGroup">
          <Col componentClass={ControlLabel} sm={3}>{t('new-password')}</Col>
          <Col sm={8}>
            <FormControl
              name="password"
              type="password"
              value={user.password}
              onChange={this.handleChange}
            />
          </Col>
        </FormGroup>
        <FormGroup controlId="confirmPasswordGroup">
          <Col componentClass={ControlLabel} sm={3}>{t('confirm-password')}</Col>
          <Col sm={8}>
            <FormControl
              name="confirmPassword"
              type="password"
              value={user.confirmPassword}
              onChange={this.handleChange}
            />
          </Col>
        </FormGroup>
      </Form>
    );

    const errors = (
      <div>
        {this.state.errors.map((error, index) => (
          <Alert bsStyle="danger" key={index}>{t(error.msg)}</Alert>
        ))}
      </div>
    );

    return (
      <Modal show={this.props.open} onHide={this.onClose} onShow={this.onShow}>
        <Modal.Header>{t('change-password')}</Modal.Header>
        <Modal.Body>
          {`${t('change-password-text')} ${user.name}.`}
        </Modal.Body>
        <Modal.Footer>
          <Button bsStyle="primary" onClick={this.onConfirm}>Send</Button>
          <Button onClick={this.onClose}>{t('close')}</Button>
        </Modal.Footer>
      </Modal>
    );
  }

  handleChange = (event) => {
    const { target } = event;
    const value = target.type === 'checkuser' ? target.checked : target.value;
    const field = target.name;
    const { user } = this.state;
    user[field] = value;
    this.setState({ user });
  }

  onClose = (event) => {
    this.setState({ user: new User() });
    this.props.onClose();
  }

  preventSubmit = (e) => {
    e.preventDefault();
  }
}

ChangePasswordModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  user: PropTypes.instanceOf(User).isRequired,
};

export default ChangePasswordModal;
