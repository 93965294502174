// @flow
import React from 'react';
import { computed } from 'mobx';
import { inject, observer } from 'mobx-react';
import { Button, Glyphicon } from 'react-bootstrap';
import _ from 'lodash';
import ReactTable from 'react-table';

import Plant from '../../classes/Plant';
import PlantForm from './PlantForm';
import { translate } from 'react-i18next';

@translate()
@inject('controlRoomStore') @observer
export default class Plants extends React.Component {
  constructor(...args) {
    super(...args);

    this.showAddPlantForm = this.showAddPlantForm.bind(this);
    this.showEditPlantForm = this.showEditPlantForm.bind(this);
    this.hidePlantForm = this.hidePlantForm.bind(this);
    this.state = {
      plantModalOpen: false,
      plant: new Plant(),
    };
  }

  static contactPN(n, plant) {
    if (plant.contacts && plant.contacts.length >= n) {
      const contact = _.find(plant.contacts, { priority: n, receiverType: 'CONTACT' });
      if (contact !== undefined) {
        let result = contact.phoneNumber;
        // console.log("CONTACT", contact)
        if (contact.fullName !== undefined) {
          result = `${result} (${contact.fullName})`;
        }
        return result;
      }
    } else {
      return '';
    }
  }

  @computed get plants() {
    return this.props.controlRoomStore.plants;
  }

  getColumns() {
    const { t } = this.props;

    return [
      {
        Header: t('name'),
        accessor: 'name',
        minWidth: 250,
      },
      {
        Header: t('code'),
        accessor: 'code',
        minWidth: 70,
      },
      {
        id: 'contact1',
        Header: t('contact-1'),
        accessor: p => Plants.contactPN(1, p),
        minWidth: 160,
      },
      {
        id: 'contact2',
        Header: t('contact-2'),
        accessor: p => Plants.contactPN(2, p),
        minWidth: 160,
      },
      {
        id: 'contact3',
        Header: t('contact-3'),
        accessor: p => Plants.contactPN(3, p),
        minWidth: 160,
      },
      {
        Header: t('capacity'),
        accessor: 'capacityMegawatt',
        minWidth: 70,
      },
      {
        Header: t('activation-action'),
        accessor: 'activationAction',
        minWidth: 200,
      },
      {
        Header: t('static-activation-group'),
        accessor: 'staticActivationGroup',
        minWidth: 120,
      },
      {
        id: 'master',
        Header: 'Master',
        accessor: p => (p.staticActivationGroupMaster ? 'Ja' : 'Nej'),
        minWidth: 70,
      },
      {
        id: 'button',
        accessor: p => <Button className="btn-small" onClick={() => this.showEditPlantForm(p)}><Glyphicon glyph="pencil" /></Button>,
        minWidth: 65,
      },
    ];
  }

    columns = this.getColumns();

    render() {
      return (
        <div>
          <div className={['text-right']}>
            <Button onClick={this.showAddPlantForm}>Tilføj værk</Button>
          </div>
          <ReactTable
            data={this.plants}
            columns={this.columns}
            showPagination={false}
            pageSize={this.plants.length === 0 ? 2 : this.plants.length}
            className="-striped -highlight"
            defaultSorted={[
              {
                id: 'name',
              },
              {
                id: 'dbvNumber',
              },
            ]}
          />
          <PlantForm
            open={this.state.plantModalOpen}
            plant={this.state.plant}
            onClose={this.hidePlantForm}
          />
        </div>
      );
    }

    showAddPlantForm() {
      this.setState({
        plant: new Plant(),
        plantModalOpen: true,
      });
    }

    showEditPlantForm(plant) {
      this.setState({
        plant,
        plantModalOpen: true,
      });
    }

    hidePlantForm() {
      this.setState({
        plant: new Plant(),
        plantModalOpen: false,
      });
    }
}
