import React from 'react';
import ReactDOM from 'react-dom';
import { Button, Modal } from 'react-bootstrap';

const DeleteButton = ({
  onConfirmation, children, title, message, t, className,
}) => (
  <Button className={className || null} onClick={() => createConfirmationModal(onConfirmation, title, message, t)} bsStyle="danger">
    {children}
  </Button>
);

const createConfirmationModal = (onConfirmation, title, message, t) => {
  const wrapper = document.body.appendChild(document.createElement('div'));
  const modal = (
    <div className="static-modal">
      <Modal.Dialog bsSize="small">
        <Modal.Header closeButton onHide={dispose}>
          <Modal.Title>{ title || t('confirm-delete-title') }</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          { message || t('confirm-delete-body') }
        </Modal.Body>
        <Modal.Footer>
          <Button bsStyle="primary" onClick={resolve}>{t('confirm')}</Button>
          <Button onClick={dispose}>{t('cancel')}</Button>
        </Modal.Footer>
      </Modal.Dialog>
    </div>
  );

  function resolve() {
    onConfirmation();
    dispose();
  }

  function dispose() {
    ReactDOM.unmountComponentAtNode(wrapper);
    wrapper.remove();
  }

  ReactDOM.render(modal, wrapper);
};

export default DeleteButton;
