import React from 'react';
import { Treebeard, decorators } from 'react-treebeard';
import { computed } from 'mobx';
import { inject, observer } from 'mobx-react';

/*
*
*   Treebeard "inline" styles.
*
* */
const styles = {
  tree: {
    base: {
      border: 'solid 1px lightgray',
      listStyle: 'none',
      backgroundColor: '#ffffff',
      margin: 0,
      marginTop: '10px',
      padding: 0,
      cursor: 'default',
      fontSize: '12px',
    },
    node: {
      base: {
        position: 'relative',
      },
      link: {
        cursor: 'arrow',
        position: 'relative',
        padding: '0px 5px',
        display: 'block',
      },
      activeLink: {
        background: '#ededed',
        fontWeight: 'bold',
      },
      toggle: {
        base: {
          position: 'absolute',
          display: 'inline-block',
          verticalAlign: 'top',
          cursor: 'pointer',
          height: '24px',
          width: '19px',
          fontWeight: 'bold',
          float: 'left',

          paddingTop: '6px',

        },
        wrapper: {
          position: 'absolute',
          top: '50%',
          left: '50%',
          margin: '-10px 0 0 -5px',
          height: '14px',
        },
        height: 8,
        width: 8,
        arrow: {
          fill: 'black',
          strokeWidth: 0,
        },
      },
      header: {
        base: {
          display: 'inline-block',
          verticalAlign: 'top',
          color: 'black',
          cursor: 'pointer',
          fontWeight: 'bold',
        },
        connector: {
          width: '2px',
          height: '12px',
          borderLeft: 'solid 2px black',
          borderBottom: 'solid 2px black',
          position: 'absolute',
          top: '0px',
          left: '-21px',
        },
        title: {
          lineHeight: '24px',
          verticalAlign: 'middle',
          marginLeft: '10px',
          width: '100%',
        },
        floatTitle: {
          lineHeight: '24px',
          verticalAlign: 'middle',
          marginLeft: '20px',
          float: 'left',
        },
      },
      subtree: {
        listStyle: 'none',
        paddingLeft: '19px',
      },
      loading: {
        color: '#E2C089',
      },
    },
  },
};

/*
*
*   Overriding Header, Toggle and Container in decorators to create our own.
*   Using node.state to concat a string class value eg color[state] = color0
*
*   In the variables and tree-beard less file, colors and variables are adjusted.
*
*   Open and Close Icons are bootstrap icons are hardcoded in Toggle decorator.
*
*   The click event has been moved to only work on the icon wrapper. See Toggle decorator.
*   To Activate the activate the links see Header
*
*   NB: REMOVE STUB DATA CONST WHEN REAL DATA IS SUFFICIENT.
*       Use stub as data source to see the color and (numeric) changes in the treeview.
*
* */


decorators.Header = (props) => {
  let warning;
  let title;
  if (props.node.notes) {
    if (props.node.notes.length > 0) {
      title = props.node.notes[0];
      warning = <i className="glyphicon glyphicon-warning-sign" style={{ color: 'orange' }} />;
    } else {
      warning = <i className="glyphicon glyphicon-tag" />;
    }
  }

  return (
    <div style={props.style.header.base} className={props.node.active ? 'activeLink' : ''}>

      {props.node.currentPlanStatus !== undefined ? <div className={`state-status color${props.node.currentPlanStatus} no-pa`}>&nbsp;</div> : ''}

      <div style={props.terminal ? props.style.header.title : props.style.header.floatTitle} onClick={props.onClick} title={title}>
        {warning}
        {props.node.name}
        {' '}
        {props.node.prodSum !== undefined && props.node.prodSum !== -10000 ? `(${props.node.prodSum})` : ''}
      </div>

    </div>
  );
};

decorators.Toggle = (props) => {
  const iconClass = `glyphicon glyphicon-${props.node.toggled ? 'minus' : 'plus'}`;

  return (

    <div>

      <div style={props.style.toggle.base} onClick={props.onClick}>
        <div style={props.style.toggle.wrapper}>
          <i className={iconClass} />
        </div>
      </div>

    </div>

  );
};

decorators.Container = props => (
  <div style={props.style.base}>

    {!props.terminal ? <props.decorators.Toggle {...props} /> : null}

    <props.decorators.Header {...props} />
  </div>
);

@inject('controlRoomStore') @observer
export default class PriceAreaAndPlantSelector extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.scrollRef = React.createRef(); // Need reference so we can control scroll
  }

    setSelected = (item) => {
      this.props.controlRoomStore.selectItem(item);
      this.setState({ cursor: item });
    }

    onToggle = (node, toggled) => {
      this.props.controlRoomStore.setAllInactive();

      const { cursor } = this.state;
      if (cursor) {
        cursor.active = false;
        this.setState({ cursor });
      }
      node.active = true;
      if (node.children) {
        node.toggled = toggled;
      }
      this.setSelected(node);
    }


    @computed
    get priceAreas() {
      return this.props.controlRoomStore.priceAreas.slice().sort((a, b) => a.name.localeCompare(b.name));
    }

    // Deactivate old cursor, activate new cursor and set as selected to update data
    activateNewCursor(oldC, newC) {
      oldC.active = false;
      newC.active = true;
      this.setSelected(newC);
    }

    // Override scroll, as it moves too much per button click. We move it the size of a unit
    scroll(event, up) {
      event.preventDefault();
      if (up) {
        this.scrollRef.current.scrollBy(0, -24);
      } else {
        this.scrollRef.current.scrollBy(0, 24);
      }
    }

    // This enables arrow key navigation in the plant selector tree
    onKeyPressed = (event) => {
      const { cursor } = this.state;
      const paIndex = this.priceAreas.findIndex(pa => pa.name === cursor.name); // Check if cursor is price area

      if (event.key === 'ArrowUp') {
        this.scroll(event, true);

        if (paIndex > 0) {
          const potentialCursor = this.priceAreas[paIndex - 1];
          if (potentialCursor.toggled) {
            this.activateNewCursor(cursor, potentialCursor.children[potentialCursor.children.length - 1]);
          } else {
            this.activateNewCursor(cursor, potentialCursor);
          }
        } else {
          this.priceAreas.forEach((pa) => {
            const childIndex = pa.children.findIndex(c => c.code === cursor.code);
            if (childIndex > 0) {
              this.activateNewCursor(cursor, pa.children[childIndex - 1]);
            } else if (childIndex === 0) {
              this.activateNewCursor(cursor, pa);
            }
          });
        }
      } else if (event.key === 'ArrowDown') {
        this.scroll(event, false);

        if (paIndex !== -1 && paIndex < this.priceAreas.length - 1) {
          if (cursor.toggled) {
            this.activateNewCursor(cursor, cursor.children[0]);
          } else {
            this.activateNewCursor(cursor, this.priceAreas[paIndex + 1]);
          }
        } else if (paIndex !== -1) {
          if (cursor.toggled) {
            this.activateNewCursor(cursor, cursor.children[0]);
          }
        } else {
          this.priceAreas.forEach((pa, i) => {
            const childIndex = pa.children.findIndex(c => c.code === cursor.code);
            if (childIndex !== -1 && childIndex < pa.children.length - 1) {
              this.activateNewCursor(cursor, pa.children[childIndex + 1]);
            } else if (childIndex === pa.children.length - 1) {
              this.activateNewCursor(cursor, this.priceAreas[i + 1]);
            }
          });
        }
      } else if (event.key === 'ArrowRight') {
        if (cursor.children) {
          cursor.toggled = true;
          this.setSelected(cursor);
        }
      } else if (event.key === 'ArrowLeft') {
        if (cursor.children) {
          cursor.toggled = false;
          this.setSelected(cursor);
        }
      }
    }

    render() {
      return (
        <div
          ref={this.scrollRef}
          className="tree-view"
          onKeyDown={this.onKeyPressed}
          tabIndex="0"
        >
          <Treebeard
            style={styles}
            // data={stub} //Stub Data
            data={this.priceAreas} // Real Data
            onToggle={this.onToggle}
            decorators={decorators}
          />
        </div>
      );
    }
}
