import React from 'react';
import {Button, ButtonToolbar, Col, Form, FormControl, FormGroup, Row} from 'react-bootstrap';
import {translate} from 'react-i18next';
import {inject, observer} from 'mobx-react';
import moment from 'moment';


@translate()
@inject('uiStore', 'plantStore') @observer
export default class HeatForecast extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      heatData: this.props.heatData,
      submitDisabled: true,
      prevInit: {},
    };
  }


  render() {
    const {t, onDismiss} = this.props;
    const {heatData} = this.state;

    return (

      <Form>
        <Col sm={6} style={{paddingLeft: "5px", paddingRight: "0px", marginRight: "-130px"}}>
          <Row>
            <Col sm={6}><label style={{display: "block", textAlign: "center", marginRight: "1px"}}>Dato</label></Col>
          </Row>
          <FormGroup>
            {heatData.map((data, idx) => (<div
              key={data.consumption + idx}>
              <Row>
                <Col sm={6}>
                  <FormControl
                    readOnly
                    value={moment(data.time).format("YYYY-MM-DD")}
                  ></FormControl>
                </Col>
              </Row>
              <br></br>
            </div>))}
          </FormGroup>
        </Col>


        <Col sm={7} style={{paddingLeft: "5px", paddingRight: "0px", marginRight: "-130px"}}>
          <Row>
            <Col sm={7}><label style={{display: "block", textAlign: "center"}}>Forbrug [MWh/day]</label>
            </Col></Row>
          <FormGroup>
            {heatData.map((data, idx) => (<div
              key={data.consumption + idx}>
              <Row>
                <Col sm={7}>
                  <FormControl
                    readOnly
                    value={data.consumption.toFixed(2)}
                  ></FormControl>
                </Col>
              </Row>
              <br></br>
            </div>))}
          </FormGroup>
        </Col>

        <Col sm={6} style={{paddingLeft: "5px", paddingRight: "0px", marginRight: "-130px"}}>
          <Row>
          </Row>

        </Col>
        <Row>
          <Col sm={12}>
            <ButtonToolbar
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
              }}
            >
              <Button onClick={onDismiss}>{t('close')}</Button>
            </ButtonToolbar>
          </Col>
        </Row>

      </Form>
    );
  }
}

