// @flow

import { action, autorun, observable } from 'mobx';
import Plant from './Plant';

export default class PriceArea {
    name: string;

    @observable plants: Array = observable.array([]);

    currentDayHash: string;

    constructor() {
      // this.plants = observable.array([]);
      autorun(() => {
        this.children = this.plants.slice();
      });
    }

    @action
    fromJson(json) {
      this.name = json.name;
      this.plants = json.plants.map(plantData => new Plant().fromJson(plantData));
      this.currentDayHash = json.currentDayHash;
      return this;
    }

    get isPlant() {
      return false;
    }

    get isPriceArea() {
      return true;
    }

    get priceAreaName() {
      return this.name;
    }
}
