import React from 'react';
import { computed } from 'mobx';
import { inject, observer } from 'mobx-react';
import {
  Alert,
  Button, ButtonGroup, Col, Form, FormControl, FormGroup, Glyphicon, Modal, Table,
} from 'react-bootstrap';
import {convertMinutesToHours, findCorrectBalancePrice, findCorrectSpotPrice} from '../../actions/CommonAction';
import { toast } from 'react-toastify';
import { translate } from 'react-i18next';
import DeleteButton from '../common/DeleteButton';
import { NAME_KEY_MANUAL_PLAN_DOWN, NAME_KEY_MANUAL_PLAN_UP } from '../../stores/PlanStore';
import TimePicker from './TimePicker';
import { timePickerConfiguration, timePickerEndConfiguration } from '../../config/settings';


@translate()
@inject('planStore', 'plantStore', 'uiStore') @observer
export default class ManualPlanTable extends React.Component {
  state = {
    checked: false,
    editModalShow: false,
    editModalPoint: {},
    editModalPointCopy: {},
    editModalPrice: {},
    editModalPriceEnabled: false,
    editModalProductionEnabled: false,
    editModalTime: 0,
    editModalTimeEnd: 60,
    editModalShowPrice: false,
    effectError: false,
  }

  @computed get planEntries() {
    let data = (this.props.planStore.plans.length > 0) ? this.props.planStore.activePlan.entries : [];

    // Added Sorting to Make the table sort reflect the point minute.
    data = data.sort((a, b) => a.minute - b.minute);

    return data;
  }

  @computed get spotPrices() {
    const spotPrices = [];
    this.props.plantStore.spotPrices.forEach((spotPrice) => {
      spotPrices.push(spotPrice);
    });

    return spotPrices;
  }

  @computed get balancePrices() {
    const balancePrices = [];
    this.props.plantStore.balancePrices.forEach((balancePrice) => {
      balancePrices.push(balancePrice);
    });
    return balancePrices;
  }

  handleCheckChangePrice = (e) => { this.setState({ editModalPriceEnabled: e.target.checked }); };

  handleCheckChangeProduction = (e) => { this.setState({ editModalProductionEnabled: e.target.checked }); };

  render() {
    const { t } = this.props;
    const { effectError } = this.state;
    const data = this.planEntries;
    const { spotPrices, balancePrices } = this;
    const { selectedDate } = this.props.plantStore;
    const unit = t('mw');
    const priceUnit = t('price-mw');

    return (
      <div>
        <Table striped bordered condensed hover>
          <thead>
            <tr>
              <th>{t('time')}</th>
              <th>{t('spot-price')}</th>
              <th>{t('balance-prices')}</th>
              <th>
                {t('price')}
                {' '}
[
                {' '}
                {t('price-mw')}
                {' '}
]
              </th>
              <th>
                {t('production')}
                {' '}
[
                {' '}
                {t('mw')}
                {' '}
]
                {' '}
              </th>
              <th />
            </tr>
          </thead>
          <tbody>

            {data.map((point, index) => (
              <tr key={index}>
                <td>{convertMinutesToHours(point.minute, selectedDate)}</td>
                <td>{findCorrectSpotPrice(spotPrices, index)}</td>
                <td>{findCorrectBalancePrice(balancePrices, index)}</td>
                <td>
                  {
                  (point.activationPrice && !isNaN(point.activationPrice.amount)) ? `${point.activationPrice.amount.toFixed(2)} ` : findCorrectSpotPrice(spotPrices, index)
                }
                </td>
                <td>{isNaN(point.amountPowerOutputMegawatt) ? t('n-a') : point.amountPowerOutputMegawatt.toFixed(2)}</td>
                <td>
                  <ButtonGroup>
                    <Button className="btn-small" disabled={this.handleTimeCheck(point.minute)} onClick={() => this.handleRowClick(selectedDate, point)}>
                      <Glyphicon glyph="pencil" />
                    </Button>
                    <DeleteButton className="btn-small" onClick={this.onDeleteClick} onConfirmation={() => this.handleDeleteClick(point)} t={t}>
                      <Glyphicon glyph="trash" />
                    </DeleteButton>
                  </ButtonGroup>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>

        <Modal show={this.state.editModalShow}>
          <Modal.Header>
            <Modal.Title>{t(this.props.planStore.activePlan.nameKey)}</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <Form horizontal>

              <FormGroup>
                <Col sm={4} className="control-label">
                  {t('start-time')}
                  {' '}
                  (
                  {t('time-format')}
                  )
                </Col>
                <Col sm={8}>
                  <TimePicker
                    date={this.props.uiStore.selectedDate}
                    step={timePickerConfiguration.stepIntervalInMinutes}
                    format={timePickerConfiguration.timeHourFormat}
                    end={timePickerConfiguration.endTime}
                    onChange={this.handleStartTime}
                    value={this.state.editModalTime}
                  />
                </Col>
              </FormGroup>
              <FormGroup>
                <Col sm={4} className="control-label">
                  {t('end-time')}
                  {' '}
                  (
                  {t('time-format')}
                  )
                </Col>
                <Col sm={8}>
                  <TimePicker
                    date={this.props.uiStore.selectedDate}
                    step={timePickerConfiguration.stepIntervalInMinutes}
                    format={timePickerConfiguration.timeHourFormat}
                    end={timePickerConfiguration.endTime}
                    onChange={this.handleEndTime}
                    value={this.state.editModalTimeEnd}

                  />
                </Col>
              </FormGroup>
              <FormGroup>
                <Col sm={4} className="control-label">

                  {t('production')}

                  {' '}
                  [
                  {unit}
                  ]
                  {' '}
                  <input
                    type="checkbox"
                    checked={this.state.editModalProductionEnabled}
                    onChange={this.handleCheckChangeProduction}
                  />
                  {' '}
                </Col>
                <Col sm={8}>
                  <FormControl
                    style={{ borderColor: effectError ? '#a94442' : null }}
                    disabled={!this.state.editModalProductionEnabled}
                    name="amountPowerOutputMegawatt"
                    type="number"
                    value={this.state.editModalPointCopy.amountPowerOutputMegawatt}
                    onChange={this.handleChangeProduction}
                  />
                  {effectError && (
                  <span style={{ color: '#a94442' }}>
                    <div style={{ paddingTop: '5px' }}>{this.up() ? t('effect-error-up') : t('effect-error-down')}</div>
                  </span>
                  )}
                </Col>
              </FormGroup>
              <FormGroup>

                <Col sm={4} className="control-label">
                  {' '}
                  {t('price')}
                  {' '}
                   [
                  {priceUnit}
                ]
                  {' '}
                  <input
                    type="checkbox"
                    checked={this.state.editModalPriceEnabled}
                    onChange={this.handleCheckChangePrice}
                  />
                  {' '}

                </Col>
                <Col sm={8}>
                  <FormControl
                    disabled={!this.state.editModalPriceEnabled}
                    name="price"
                    type="number"
                    value={this.state.editModalPointCopy.amount}
                    onChange={this.handleChangePrice}

                  />
                </Col>
              </FormGroup>
            </Form>
          </Modal.Body>

          <Modal.Footer>
            <Button bsStyle="primary" disabled={this.state.effectError} onClick={this.handleEditModalConfirm}>{t('ok')}</Button>
            <Button onClick={this.handleEditModalClose}>{t('close')}</Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }


  handleRowClick = (date, point) => {
    const newState = {
      editModalShow: true,
      editModalPoint: point,
      editModalPointCopy: Object.assign({}, point),
      editModalPrice: point.price,
      editModalTime: point.minute,
      editModalTimeEnd: point.minute + 60,
      priceHasBeenEdited: false,
      effectError: false,
    };
    this.setState(newState);


    if (this.handleTimeCheck(point.minute) === true) {
      window.location.reload(true);
      toast.info('Please refresh the page!');
      console.log('This is the if statement');
    } else {
      // do nothing
      console.log('It is not and 05 minutes');
    }
  }

  handleTimeCheck = (date) => {
    const dateNow = new Date();
    const today = dateNow.getDate();
    const tomorrow = dateNow.getDate() + 1;
    const hour = dateNow.getHours();
    const minutes = dateNow.getMinutes();
    let restrictionTime = hour * 60 + 60;
    const { selectedDate } = this.props.plantStore.uiStore;

    // console.log("Selected" ,this.props.uiStore.selectedDate);
    // console.log("DateNow", dateNow)

    if (minutes < 5) {
      restrictionTime = restrictionTime - 1;
    }
    // console.log(restrictionTime, date)
    if (!(selectedDate > dateNow)) {
      if (date <= restrictionTime) {
        return true;
      }
      return false;
    }
  }

  handleChangePrice= (event) => {
    event.preventDefault();
    const { target } = event;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const field = target.name;

    const object = this.state.editModalPointCopy;
    const type = typeof object[field];
    if (type === 'number') {}
    object[field] = value;
    this.setState({ editModalPointCopy: object, priceHasBeenEdited: true });
  }

  handleChangeProduction = (event) => {
    event.preventDefault();
    const { target } = event;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const field = target.name;

    const object = this.state.editModalPointCopy;
    const error = this.validateEffect(Number(value));

    object[field] = value;
    this.setState({ editModalPointCopy: object, effectError: error });
  }

  up = () => {
    const { nameKey } = this.props.planStore.activePlan;
    const direction = nameKey.split('-').slice(-1);
    return direction[0] === 'up';
  }

  validateEffect = (effect) => {
    if (this.up()) {
      return effect < 0;
    }
    return effect > 0;
  }

  handleStartTime = (event) => {
    event.preventDefault();
    this.setState({
      editModalTime: event.target.value,
    });
  }

  handleEndTime = (event) => {
    event.preventDefault();
    this.setState({
      editModalTimeEnd: event.target.value,
    });
  }

  handleEditModalClose = (event) => {
    event.preventDefault();
    this.setState({
      editModalShow: false,
    });
  }


  validateTime = (timeStartInMinutes, timeEndInMinutes) => {
    if (timeStartInMinutes <= timeEndInMinutes) {
      return true;
    }
    toast.error(this.props.t('ending-time-must-be-after-starting-time'));
    return false;
  }


  validatePrice = (price, index) => {
    const spotPriceSelected = findCorrectSpotPrice(this.spotPrices, index);
    if (this.props.planStore.activePlan.nameKey === NAME_KEY_MANUAL_PLAN_UP) {
      if (price < spotPriceSelected) {
        toast.dismiss();
        toast.error(this.props.t('priceUpError'));
        return spotPriceSelected + 1;
      }

      return price;
    }

    if (this.props.planStore.activePlan.nameKey === NAME_KEY_MANUAL_PLAN_DOWN) {
      if (price >= spotPriceSelected) {
        toast.dismiss();
        toast.error(this.props.t('priceDownError'));
        return spotPriceSelected - 1;
      }

      return price;
    }
  }

  handleEditModalConfirm = (event) => {
    event.preventDefault();
    const { t } = this.props;

    const uiObject = this.state.editModalPointCopy;

    // Validate time.
    if (!this.validateTime(this.state.editModalTime, this.state.editModalTimeEnd)) {
      return;
    }


    // TODO: RVA: Also a check for violation of regulatoryPrice should be made.

    const changes = [];
    let index = this.state.editModalTime / 60;

    for (let currentEntryTime = this.state.editModalTime; currentEntryTime <= this.state.editModalTimeEnd - 60; currentEntryTime += 60) {
      const change = {};


      if (this.state.editModalProductionEnabled && !isNaN(uiObject.amountPowerOutputMegawatt)) {
        change.amountPowerOutputMegawatt = Number(uiObject.amountPowerOutputMegawatt);
      }
      if (this.state.editModalPriceEnabled && !isNaN(uiObject.price)) {
        change.activationPrice = {
          amount: Number(this.validatePrice(uiObject.price, index)),
          currency: 'DKK/MW',
        };
      }
      change.minute = currentEntryTime;
      changes.push(change);
      index = index + 1;
      console.log(index);
      console.log('timeend', this.state.editModalTimeEnd);
      console.log('timestart', this.state.editModalTime);
    }

    // Perform the actual update of mobx store.
    changes.forEach((element) => {
      const data = this.planEntries;
      const key = element.minute / 60;
      this.props.planStore.updateEntry(data[key], element);
    });
    // So we only try to save prices actively when they have been changed in the frontend. We differentiate between up and down
    if (this.state.priceHasBeenEdited) {
      this.props.planStore.setPricesChanged(this.props.planStore.activePlan.nameKey === NAME_KEY_MANUAL_PLAN_UP, true);
    }
    this.setState({
      editModalShow: false,
    });
    toast('Punkt blev opdateret.');
  }

  handleDeleteClick = (point) => {
    this.props.planStore.removeEntry(point);
  }
}
