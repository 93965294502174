export default class HeatForecastRow {
  consumption: number;
  time: string;

  fromJson(json) {
    if (json) {
      this.consumption = json.consumption;
      this.time = json.time;
    }
    return this;
  }
}