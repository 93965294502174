import React from 'react';
import { FormGroup } from 'react-bootstrap';
import FavouriteAndCopyPlans from '../plant/FavouriteAndCopyPlans';
import MarginalPriceElement from './MarginalPriceElement';
import { translate } from 'react-i18next';
import ResetDayElement from './ResetDayElement';
import { inject, observer } from 'mobx-react';
import RegulatingPower from './RegulatingPower';
import IntradayPrices from './IntradayPrices';
import { computed } from 'mobx';
import PriceDependentPriceModal from './PriceDependentPriceModal';

@translate()
@inject('planStore', 'uiStore') @observer
class DateButtons extends React.Component {
  intradayEnabled() {
    return this.props.uiStore.selectedPlant.intradayEnabled;
  }

  priceDependentEnabled() {
    return this.props.uiStore.selectedPlant.priceDependentEnabled;
  }

  render() {
    return (
      <div>
        <FormGroup className="text-center">
          <FavouriteAndCopyPlans />
        </FormGroup>
        <FormGroup className="text-center">
          <ResetDayElement />
          <br />
          <MarginalPriceElement />

        </FormGroup>
        <FormGroup className="text-center">
          <RegulatingPower />
        </FormGroup>
        { this.intradayEnabled() && (
          <FormGroup className="text-center">
            <IntradayPrices />
          </FormGroup>
        )}
        { this.priceDependentEnabled() && (
        <FormGroup className="text-center">
          <PriceDependentPriceModal />
        </FormGroup>
        )}
      </div>
    );
  }
}

export default DateButtons;
