import { inject, observer } from 'mobx-react';
import React from 'react';
import Select from 'react-select';
import { computed } from 'mobx';

@inject('controlRoomStore') @observer
export default class SearchBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      input: '',
    };
  }

  handleChange = (item) => {
    this.props.controlRoomStore.selectItem(item);
  }

  @computed get plants() {
    return this.props.controlRoomStore.plants.sort((a, b) => a.name.localeCompare(b.name));
  }

  render() {
    return (
      <Select
        value={this.props.controlRoomStore.selectedItem}
        options={this.plants}
        getOptionLabel={r => r.name}
        getOptionValue={r => r.code}
        clearable
        onChange={this.handleChange}
      />
    );
  }
}
