import React from 'react';
import { computed } from 'mobx';
import { inject, observer } from 'mobx-react';
import { Table } from 'react-bootstrap';
import { convertMinutesToHours } from '../../actions/CommonAction';

import { translate } from 'react-i18next';

/**
 * Bare-bones table for price dependent plans.
 */
@translate()
@inject('planStore', 'plantStore') @observer
export default class RestrictedPriceTable extends React.Component {
  @computed get planEntries() {
    let data = (this.props.planStore.plans.length > 0) ? this.props.planStore.activePlan.entries : [];

    // Added Sorting to Make the table sort reflect the point minute.
    data = data.sort((a, b) => a.minute - b.minute);

    return data;
  }

  render() {
    const { t } = this.props;
    const data = this.planEntries;
    const { selectedDate } = this.props.plantStore;
    const priceUnit = this.props.planStore.getCurrency();

    return (
      <div>
        <Table striped bordered condensed hover>
          <thead>
            <tr>
              <th>{t('time')}</th>
              <th>
                {t('price')}
                {' '}
              [
                {' '}
                {priceUnit}
                {' '}
              ]
              </th>
              <th>
                {t('production')}
                {' '}
              [
                {' '}
                {t('mw')}
                {' '}
              ]
                {' '}
              </th>
            </tr>
          </thead>
          <tbody>

            {data.map((point, index) => (
              <tr key={index}>
                <td>{convertMinutesToHours(point.minute, selectedDate)}</td>
                <td>
                  {
                  (point.activationPrice && !isNaN(point.activationPrice.amount)) ? `${point.activationPrice.amount.toFixed(2)} ` : undefined
                }
                </td>
                <td>{isNaN(point.amountPowerOutputMegawatt) ? t('n-a') : point.amountPowerOutputMegawatt.toFixed(2)}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    );
  }
}
