import { translate } from 'react-i18next';
import { inject, observer } from 'mobx-react';
import React from 'react';
import {
  NAME_KEY_FREQUENCY_PLAN_DOWN,
  NAME_KEY_FREQUENCY_PLAN_UP,
  NAME_KEY_INTRADAY_PLAN_BUY,
  NAME_KEY_INTRADAY_PLAN_SELL,
  NAME_KEY_MANUAL_PLAN_DOWN,
  NAME_KEY_MANUAL_PLAN_UP,
  NAME_KEY_PRICE_DEPENDENT_PLAN,
} from '../../stores/PlanStore';
import GenericPlanTable from './GenericPlanTable';
import ManualPlanTable from './ManualPlanTable';
import PricePlanNoRestrictionsTable from './PricePlanNoRestrictions';
import RestrictedPriceTable from './RestrictedPriceTable';

@translate()
@inject('planStore', 'plantStore') @observer
export default class PlanTable extends React.Component {
  render() {
    const planName = this.props.planStore.activePlan.nameKey;
    const isManualPlan = [NAME_KEY_MANUAL_PLAN_UP, NAME_KEY_MANUAL_PLAN_DOWN].indexOf(planName) > -1;
    const isFrequencyPlan = [NAME_KEY_FREQUENCY_PLAN_UP, NAME_KEY_FREQUENCY_PLAN_DOWN].indexOf(planName) > -1;
    const isIntradayPlan = [NAME_KEY_INTRADAY_PLAN_BUY, NAME_KEY_INTRADAY_PLAN_SELL].indexOf(planName) > -1;
    const isPriceDependentPlan = [NAME_KEY_PRICE_DEPENDENT_PLAN].indexOf(planName) > -1;
    if (isManualPlan) {
      return (<ManualPlanTable />);
    }
    if (isFrequencyPlan || isIntradayPlan) {
      return (<PricePlanNoRestrictionsTable />);
    }
    if (isPriceDependentPlan) {
      return (<RestrictedPriceTable />);
    }

    return (<GenericPlanTable />);
  }
}
