import axios from 'axios/index';


export function getData(url, params = {}) {
  return new Promise((resolve, reject) => {
    axios.get(url, { params }).then(
      (res) => {
        resolve(res.data);
      },
      (err) => {
        reject(err.response);
      },
    );
  });
}


export function getDataFromC(url, config = {}) {
  return new Promise((resolve, reject) => {
    axios.get(url, { config }).then(
      (res) => {
        resolve(res.data);
      },
      (err) => {
        reject(err.response);
      },
    );
  });
}

export function getAs(url, initiator) {
  return new Promise((resolve, reject) => {
    axios.get(url).then(
      (res) => {
        resolve(initiator(res.data));
      },
      (err) => {
        reject(err);
      },
    );
  });
}


// MOCK stuff
export function mockData(data) {
  return new Promise((resolve, reject) => {
    resolve(data);
  });
}

/**
 * Function for mocking http request with delay and reply.
 * @param delay
 * @param okReply
 * @returns {Promise<any>}
 */
export function delayedMockCall(delay, okReply) {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve(okReply);
    }, delay);
  });
}

export function reject(...msg) {
  const m = msg.map(value => JSON.stringify(value)).join(', ');
  return new Promise((resolve, reject) => { reject(m); });
}
