import axios from 'axios';
import Message from '../classes/Message';
import _ from 'lodash';
import { reject } from './Service';
import { API_DATE_FORMAT } from '../actions/CommonAction';

const RealService = {
  getMessages: (date) => {
    const url = `/api/messages/${date}`;
    return axios.get(url);
  },
};

const service = RealService;

export function getMessages(date): Array<Message> {
  if (date) {
    return service.getMessages(date)
      .then(resp => resp.data.map(msgData => new Message(msgData)));
  }
  return reject('missing params in getMessages', date);
}

export function controlRoomMessages(fromDate, toDate) {
  if (fromDate && toDate) {
    return service.getMessages(`${fromDate.format(API_DATE_FORMAT)}/${toDate.format(API_DATE_FORMAT)}`)
      .then(resp => _.map(resp.data, msg => new Message(msg)));
  }
  return reject('missing params in controlRoomMessages', fromDate, toDate);
}

export function deleteMessage(message) {
  return axios.delete(`/api/messages/${message.key}/delete`);
}
