import { Alert, Button } from 'react-bootstrap';
import React from 'react';
import { inject } from 'mobx-react';
import Sound from 'react-sound';

@inject('controlRoomStore')
class AlarmmFRR extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.handleDismiss = this.handleDismiss.bind(this);
    this.state = {
      playSound: true,
    };
  }

  // active is whether the alarm has already been handled by someone else
  // in NOT active case we will remove from observable list without talking to backend
  handleDismiss(active) {
    this.props.controlRoomStore.removemFRRAlarm(this.props.deleteToken, active);
  }

  soundOff = () => {
    this.setState({ playSound: false });
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let playSound;
    if (nextProps.handled) {
      playSound = false;
    } else {
      // eslint-disable-next-line prefer-destructuring
      playSound = prevState.playSound;
    }

    return {
      playSound,
    };
  }

  render() {
    return (
      <Alert bsStyle={this.props.handled ? 'info' : 'danger'}>
        <h4>Nordisk mFRR aktivering</h4>
        { this.props.handled
          ? (
            <div>
              <p>Er blevet håndteret</p>
              <Button onClick={() => this.handleDismiss(false)}>Luk</Button>
            </div>
          )
          : (
            <div>
              <p>{this.props.alarmMessage}</p>
              <Button disabled={!this.state.playSound} onClick={this.soundOff}>Lydløs</Button>
              <Button style={{ marginLeft: '5px' }} onClick={() => this.handleDismiss(true)}>Håndteret</Button>
              { this.state.playSound
                && (
                  <Sound
                    url="sound/alert.mp3"
                    playStatus={Sound.status.PLAYING}
                    loop
                  />
                )
              }
            </div>
          )
        }
      </Alert>
    );
  }
}

export default AlarmmFRR;
