import React from 'react';
import { observer, inject } from 'mobx-react';
import { submitPlans, approvePlans } from '../../actions/PlanAction';
import { Button, FormGroup } from 'react-bootstrap';
import { translate } from 'react-i18next';
import { toast } from 'react-toastify';
import { computed } from 'mobx';

@translate()
@inject('uiStore', 'planStore', 'mkPlusStore') @observer
class PlanButtons extends React.Component {
    @computed get plansReadyForApproval() {
    return this.props.planStore.dataReadyForApproval;
  }

    render() {
      const { t } = this.props;

      return (
        <FormGroup>
          <Button block disabled={this.props.planStore.productionPlanSaved} onClick={this.calculateManualPlansUpClickHandler}>{t('calc-m-plan-up')}</Button>
          <Button block disabled={this.props.planStore.productionPlanSaved} onClick={this.calculateManualPlansDownClickHandler}>{t('calc-m-plan-down')}</Button>
          {this.props.uiStore.selectedPlant.intradayEnabled
            && <Button block disabled={this.props.planStore.productionPlanSaved} onClick={this.calculateIntradayPlansBuyClickHandler}>{t('calc-iplan-buy')}</Button>
          }
          {this.props.uiStore.selectedPlant.intradayEnabled
            && <Button block disabled={this.props.planStore.productionPlanSaved} onClick={this.calculateIntradayPlansSellClickHandler}>{t('calc-iplan-sell')}</Button>
          }
          <Button block disabled={!this.props.planStore.dataHasChanged} onClick={this.submitClickHandler}>{t('save-plan')}</Button>
          <Button block disabled={!this.plansReadyForApproval} onClick={this.approveClickHandler}>{t('approve-plan')}</Button>
        </FormGroup>
      );
    }

    calculateManualPlansUpClickHandler = () => {
      this.props.planStore.calculateManualPlans(true);
    };

    calculateManualPlansDownClickHandler = () => {
      this.props.planStore.calculateManualPlans(false);
    };

    calculateIntradayPlansBuyClickHandler = () => {
      this.props.planStore.calculateIntradayPlans(true);
    };

    calculateIntradayPlansSellClickHandler = () => {
      this.props.planStore.calculateIntradayPlans(false);
    };

    submitClickHandler = () => {
      const { t } = this.props;
      toast(t('saving-plan'), {
        autoClose: 1400,
      });
      const savePrices = { up: this.props.planStore.pricesChangedUp, down: this.props.planStore.pricesChangedDown };
      submitPlans(savePrices, this.props.planStore.plansToBeSaved, this.props.uiStore, this.props.mkPlusStore);
    };

    approveClickHandler = () => {
      const { t } = this.props;
      toast(t('approving-plan'), {
        autoClose: 1400,
      });
      approvePlans(this.props.uiStore);
    }
}

export default PlanButtons;
