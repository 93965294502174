import { observable, computed, action } from 'mobx';

export default class AuthStore {
    /**
     {
         "sub": "id1",
         "nbf": 1488373710,
         "iss": "Markedskraft A/S",
         "exp": 1488805710,
         "iat": 1488373710,
         "jti": "98dcd232-1c69-43dd-b88e-ee1a6bd516be"
     }
     */
    @observable user = {};

    stores = []

    setStores = (stores) => {
      this.stores = stores;
    }

    @action
    reset = () => {
      this.user = {};
    }

    @computed get isAuthenticated() {
      return !(this.user.sub === undefined);
    }

    @computed get isAdmin() {
      return this.user.role === 'kontrolrum';
    }

    @computed get isUserAdmin() {
      return this.user.roles && this.user.roles.includes('user-admin');
    }

    @computed get username() {
      if (this.user.sub === undefined) {
        return '';
      }
      return this.user.sub;
    }

    @computed get homePage() {
      return this.isAdmin ? '/control-room' : '/plants';
    }

    @action setUser(user) {
      this.user = user;
    }
}
