import React from 'react';
import DayPicker from 'react-day-picker';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import { translate } from 'react-i18next';

@translate()
@inject('uiStore', 'planStore') @observer
class MyDatePicker extends React.Component {
  render() {
    const { uiStore } = this.props;
    const { t } = this.props;

    const MONTHS = [t('january'), t('february'), t('march'), t('april'), t('may'), t('june'), t('july'), t('august'), t('september'), t('october'), t('november'), t('december')];
    const WEEKDAYS_SHORT = [t('sunday-short'), t('monday-short'), t('tuesday-short'), t('wedensday-short'), t('thursday-short'), t('friday-short'), t('saturday-short')];

    return (
      <div className="custom-day-picker">
        <DayPicker
          firstDayOfWeek={1}
          months={MONTHS}
          weekdaysShort={WEEKDAYS_SHORT}
          initialMonth={uiStore.selectedDate.toDate()}
          selectedDays={uiStore.selectedDate.toDate()}
          onDayClick={this.handleSelectedDateChange}
        />
      </div>
    );
  }

    handleSelectedDateChange = (newDate) => {
      if (this.props.planStore.isPopoverShown) return;

      this.props.uiStore.setSelectedDate(moment(newDate));
    }
}

export default MyDatePicker;
