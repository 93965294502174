import React from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { translate } from 'react-i18next';
import { inject, observer } from 'mobx-react';
import { TextFieldGroup } from '../common/horizontal/TextFieldGroup';

@translate()
@inject('plantStore') @observer
class MarginalPriceElement extends React.Component {
  mapStateToProps() {
    return {
      plantStore: this.props.plantStore,
      shouldShowMarginalPriceModal: this.props.plantStore.shouldShowMarginalPriceModal,
      marginalPrice: this.props.plantStore.marginalPrice,
    };
  }

  render() {
    const { t } = this.props;
    const myProps = this.mapStateToProps();

    return (
      <div>
        <Button onClick={this.toggleModal}>{t('marginal-price')}</Button>
        <Modal show={myProps.shouldShowMarginalPriceModal} onHide={this.toggleModal} className="marginal-price-modal">
          <Modal.Header closeButton>
            <Modal.Title>{t('marginal-price')}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <Form horizontal onSubmit={this.submitPrice}>
                <TextFieldGroup
                  name={t('marginal-price')}
                  initValue={myProps.marginalPrice}
                  givenRef={(ref) => { this.marginalPriceField = ref; }}
                />
              </Form>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              onClick={this.submitPrice}
              bsStyle="primary"
              type="submit"
              disabled={false}
            >
              {t('save')}
            </Button>
            <Button onClick={this.toggleModal}>{t('abort-close')}</Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }

    toggleModal = () => {
      const myProps = this.mapStateToProps();
      myProps.plantStore.toggleMarginalPriceModal();
    }

    submitPrice= (event) => {
      event.preventDefault();
      const myProps = this.mapStateToProps();
      myProps.plantStore.submitMarginalPrice(this.marginalPriceField.value);
    }
}

export default MarginalPriceElement;
