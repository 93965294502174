// @flow
class Price {
    amount: number;

    currency: "DKK";

    constructor(args: { amount: number, currency: "DKK" }) {
      this.amount = args.amount;
      this.currency = args.currency;
    }
}
export default Price;
