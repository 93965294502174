// @flow
import React from 'react';
import {
  Alert, Button, Col, Form, FormControl, FormGroup, Modal,
} from 'react-bootstrap';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import { translate } from 'react-i18next';
import { toast } from 'react-toastify';
import moment from 'moment';
import { inject, observer } from 'mobx-react';

import { titleCase } from '../../actions/CommonAction';
import Message from '../../classes/Message';

@translate()
@inject('controlRoomStore') @observer
class NewMessageModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      show: false,
      errors: [],
      title: '',
      fromDate: undefined,
      toDate: undefined,
    };
  }

  handleClose = () => {
    this.setState({ show: false });
  };

  handleShow = () => {
    this.setState({ show: true, errors: [] });
  };

  handleConfirm = (event) => {
    // Validate
    const validationErrors = [];
    if (this.state.title.trim().length === 0) {
      validationErrors.push({ msg: 'Besked er krævet.' });
    }
    if (!this.state.fromDate) {
      validationErrors.push({ msg: 'Fra dato er krævet.' });
    }
    if (!this.state.toDate) {
      validationErrors.push({ msg: 'Til dato er krævet.' });
    }
    if (this.state.fromDate && this.state.toDate && moment(this.state.fromDate).isAfter(moment(this.state.toDate))) {
      validationErrors.push({ msg: 'Fra dato skal være før til dato.' });
    }

    if (validationErrors.length === 0) {
      const newObject = new Message();
      newObject.title = this.state.title;
      newObject.fromDate = this.state.fromDate;
      newObject.toDate = this.state.toDate;

      this.props.controlRoomStore.saveMessage(newObject)
        .then((response) => {
          toast('Besked blev oprettet');
          this.setState({ show: false });
        })
        .catch((error) => {
          console.log('Save message', error);
          this.setState({
            errors: [{
              msg: 'Besked kunne ikke oprettes. Prøv igen.',
            }],
          });
        });
    } else {
      this.setState({ errors: validationErrors });
    }
  };

  handleFromDayChange = (day) => {
    this.setState({ fromDate: day });
  };

  handleEndDayChange = (day) => {
    this.setState({ toDate: day });
  };

  handleTitleChange = (event) => {
    this.setState({ title: event.target.value });
  };

  render() {
    const { t } = this.props; // i18n

    const dateformat = 'DD/MM/YYYY';
    const MONTHS = [t('january'), t('february'), t('march'), t('april'), t('may'), t('june'), t('july'), t('august'), t('september'), t('october'), t('november'), t('december')];
    const WEEKDAYS_SHORT = [t('sunday-short'), t('monday-short'), t('tuesday-short'), t('wedensday-short'), t('thursday-short'), t('friday-short'), t('saturday-short')];
    const formatDate = date => moment(date).format(dateformat);

    const errors = (
      <div>
        {this.state.errors.map((error, index) => (
          <Alert key={index} bsStyle="danger">{error.msg}</Alert>
        ))}
      </div>
    );

    return (
      <div>
        <Button onClick={this.handleShow}>
          {titleCase(t('message-create'))}
        </Button>

        <Modal show={this.state.show} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>{titleCase(t('message-create'))}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {errors}
            <Form horizontal>
              <MessageTitle value={this.state.title} onChange={this.handleTitleChange} t={t} />
              <MyFormGroup name={t('from')}>
                <div className="custom-input-day-picker">
                  <DayPickerInput
                    onDayChange={day => this.handleFromDayChange(day)}
                    value={this.state.fromDate}
                    placeholder={t('from-date')}
                    formatDate={formatDate}
                    dayPickerProps={{
                      months: MONTHS,
                      weekdaysShort: WEEKDAYS_SHORT,
                      firstDayOfWeek: 1,
                    }}
                  />
                </div>
              </MyFormGroup>
              <MyFormGroup name={t('to')}>
                <div className="custom-input-day-picker">
                  <DayPickerInput
                    onDayChange={day => this.handleEndDayChange(day)}
                    placeholder={t('to-date')}
                    value={this.state.toDate}
                    formatDate={formatDate}
                    dayPickerProps={{
                      months: MONTHS,
                      weekdaysShort: WEEKDAYS_SHORT,
                      firstDayOfWeek: 1,
                    }}
                  />
                </div>
              </MyFormGroup>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button bsStyle="primary" onClick={this.handleConfirm}>{titleCase(t('make'))}</Button>
            <Button onClick={this.handleClose}>{titleCase(t('cancel'))}</Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

NewMessageModal.propTypes = {
};


const MyFormGroup = ({ name, children }) => (
  <FormGroup>
    <Col className="control-label" sm={2} xsOffset={1}>
      { name }
    </Col>
    <Col sm={8}>
      {children}
    </Col>
    <FormControl.Feedback />
  </FormGroup>
);

const FormTextField = ({ name, value, onChange }) => (
  <MyFormGroup name={name}>
    <FormControl
      type="text"
      placeholder={titleCase(name)}
      onChange={onChange}
      value={value}
    />
  </MyFormGroup>
);

const MessageTitle = ({ value, onChange, t }) => (
  <FormTextField name={t('message')} value={value} onChange={onChange} />
);

export default NewMessageModal;
