import { inject, observer } from 'mobx-react';
import { translate } from 'react-i18next';
import React from 'react';
import { select } from 'd3-selection';
import { computed } from 'mobx';
import moment from 'moment/moment';
import { scaleLinear, scaleTime } from 'd3-scale';
import { axisBottom, axisLeft } from 'd3-axis';

import * as d3 from 'd3';
import { line } from 'd3-shape';
import {
  BidPlan,
  FrequencyPlanDown,
  FrequencyPlanUp,
  IntradayPlanBuy,
  IntradayPlanSell,
  ManualPlanDown,
  ManualPlanUp,
  ProductionPlan,
} from '../classes/plans';
import _ from 'lodash';
import { OnlineValuesGraphData } from '../../classes/GraphData';

const margin = {
  top: 0, right: 0, bottom: 0, left: 0,
};
const fullWidth = 900;
const fullHeight = 500;
const width = fullWidth - margin.left - margin.right;
const height = fullHeight - margin.top - margin.bottom;

const x = scaleTime()
  .range([0, width]);
const y = scaleLinear()
  .range([0, height]);

const toolTipWidth = 180;
const toolTipHeight = 55;


const lineGenerator = line()
  .x(d => x(d.date))
  .y(d => y(d.amountPowerOutputMegawatt))
  .curve(d3.curveStepAfter);

const lineGeneratorNoCurveStep = line()
  .x(d => x(d.date))
  .y(d => y(d.amountPowerOutputMegawatt));


@translate()
@inject('uiStore', 'controlRoomStore') @observer
class ControlRoomChart extends React.Component<> {
    @computed get isSelectedItemAPlant() {
    if (this.props.uiStore.selectedItem.code) {
      return true;
    }
    return false;
  }

    invertIfNotProducer(planUp) {
      // RVA: The entries field is updated, even though it is an observable. Not good.
      if (this.isSelectedItemAPlant) {
        const myEntries = planUp.entries.map((entry) => {
          let amountPowerOutputMegawatt = Math.abs(entry.amountPowerOutputMegawatt);
          if (!this.props.uiStore.selectedPlant.producer) {
            amountPowerOutputMegawatt = -1 * amountPowerOutputMegawatt;
          }
          entry.amountPowerOutputMegawatt = amountPowerOutputMegawatt;
          return entry;
        });
        planUp.entries = myEntries;
      }
      return planUp;
    }

    invertIfProducer(planUp) {
      if (this.isSelectedItemAPlant) {
        const myEntries = planUp.entries.map((entry) => {
          let amountPowerOutputMegawatt = Math.abs(entry.amountPowerOutputMegawatt);
          if (!this.props.uiStore.selectedPlant.producer) {
            amountPowerOutputMegawatt = -1 * amountPowerOutputMegawatt;
          }
          entry.amountPowerOutputMegawatt = amountPowerOutputMegawatt;
          return entry;
        });
        planUp.entries = myEntries;
      }
      return planUp;
    }


    /**
   * Ensure that the plans day equals the selected day.
   * Otherwise return a default plan, typically empty plan.
   */
    validateDay(plan, defaultPlan) {
      return (plan && plan.day && moment(plan.day.date).isSame(this.props.uiStore.dateObj, 'day')) ? plan : defaultPlan();
    }

    @computed get productionPlan() {
      return this.validateDay(this.props.controlRoomStore.graphPlans.productionPlan, () => new ProductionPlan());
    }

    @computed get manualPlanUp() {
      return this.invertIfNotProducer(this.validateDay(this.props.controlRoomStore.graphPlans.manualPlanUp, () => new ManualPlanUp()));
    }

    @computed get manualPlanDown() {
      return this.invertIfNotProducer(this.validateDay(this.props.controlRoomStore.graphPlans.manualPlanDown, () => new ManualPlanDown()));
    }

    @computed get frequencyPlanUp() {
      return this.invertIfNotProducer(this.validateDay(this.props.controlRoomStore.graphPlans.frequencyPlanUp, () => new FrequencyPlanUp()));
    }

    @computed get frequencyPlanDown() {
      return this.invertIfNotProducer(this.validateDay(this.props.controlRoomStore.graphPlans.frequencyPlanDown, () => new FrequencyPlanDown()));
    }

    @computed get bidPlan() {
      return this.validateDay(this.props.controlRoomStore.graphPlans.bidPlan, () => new BidPlan());
    }

    @computed get intradayPlanBuy() {
      return this.validateDay(this.props.controlRoomStore.graphPlans.intradayPlanBuy, () => new IntradayPlanBuy());
    }

    @computed get intradayPlanSell() {
      return this.validateDay(this.props.controlRoomStore.graphPlans.intradayPlanSell, () => new IntradayPlanSell());
    }

    @computed get onlineValues() {
      const result = this.props.controlRoomStore.onlineValues || new OnlineValuesGraphData();
      result.color = 'red';
      return result;
    }

    @computed get graphMaxPower() {
      const maxPowers = _.map(this.props.controlRoomStore.graphPlans, plan => plan.maxPower);
      maxPowers.push(this.onlineValues.maxPower);
      return _.max(maxPowers) || 100;
    }

    @computed get graphMinPower() {
      const minPowers = _.map(this.props.controlRoomStore.graphPlans, plan => plan.minPower);
      minPowers.push(this.onlineValues.minPower);
      return _.min(minPowers) || -20;
    }

    @computed get isSelectedItemProducer() {
      const { selectedItem } = this.props.controlRoomStore;
      return selectedItem.producer;
    }

    @computed get plantMaxPower() {
      const defaultValue = 50;

      const { selectedItem } = this.props.controlRoomStore;
      if (selectedItem && selectedItem.capacityMegawatt) {
        const max = (selectedItem.producer || selectedItem.invertedConsumptionUnit) ? selectedItem.capacityMegawatt : selectedItem.capacityMegawatt / 10;
        return max;
      } if (selectedItem && selectedItem.children) {
        const producers = selectedItem.children.filter(plant => plant.producer);
        const aggCapacity = Math.round(_.sumBy(producers, p => p.capacityMegawatt));

        const aggMaxPower = Object.keys(this.props.controlRoomStore.graphPlans).reduce((acc, planKey) => {
          const plan = this.props.controlRoomStore.graphPlans[planKey];
          return acc + (plan && plan.maxPower ? plan.maxPower : 0);
        }, 0);

        let maxYPlant = aggCapacity * 0.7;

        maxYPlant = aggMaxPower < maxYPlant ? maxYPlant : aggMaxPower;

        return maxYPlant || defaultValue;
      }
      return defaultValue;
    }

    @computed get plantMinPower() {
      const defaultValue = -50;

      const { selectedItem } = this.props.controlRoomStore;
      if (selectedItem && selectedItem.capacityMegawatt) {
        const min = (selectedItem.producer || selectedItem.invertedConsumptionUnit) ? selectedItem.capacityMegawatt / 10 : selectedItem.capacityMegawatt;
        return -1 * min;
      } if (selectedItem && selectedItem.children) {
        const notProducers = selectedItem.children.filter(plant => !plant.producer);
        const minYPlant = Math.round(_.sumBy(notProducers, p => p.capacityMegawatt));
        return minYPlant ? -1 * minYPlant : defaultValue;
      }
      return defaultValue;
    }

    render() {
      const { t } = this.props;
      const dateString = this.props.uiStore.selectedDate.format('DD/MM YYYY');

      this.startDate = moment(this.props.uiStore.selectedDate).startOf('day')
        .toDate();
      this.endDate = moment(this.props.uiStore.selectedDate).startOf('day').add(1, 'day')
        .toDate();
      x.domain([this.startDate, this.endDate]);

      const buffer = 1.1;
      this.maxY = this.plantMaxPower * buffer;
      this.minY = this.plantMinPower * buffer;
      y.domain([this.maxY, this.minY]);

      const circleMarks = plan => (
        <g>
          {plan.entriesForChart.map((value, entryIndex) => (
            <circle
              key={entryIndex}
              cx={x(value.date)}
              cy={y(value.amountPowerOutputMegawatt)}
              r={2.5}
              data-plan-index={2}
              fill={plan.color}
              stroke={plan.color}
              onMouseOver={this.mouseOverPoint.bind(this, value, true, plan.name)}
              onMouseOut={this.mouseOutPoint.bind(this)}
            />
          ))}
        </g>
      );

      const triangles = plan => (
        <g>
          {plan.entriesForChart.map((value, entryIndex) => {
            const triDirection = typeof plan.up === 'undefined' ? !plan.buy : plan.up;
            const xVal = x(value.date);
            const yVal = y(value.amountPowerOutputMegawatt);
            const rectX = triDirection ? xVal + 4 : xVal - 4; // sideways correction.
            const rectY = yVal;
            const transformation = triDirection ? ', rotate(180)' : '';
            return (
              <polygon
                transform={`translate(${rectX}, ${rectY})${transformation}`}
                key={entryIndex}
                data-x={xVal}
                data-y={yVal}
                points="0,0  8,0  4,6"
                fill={plan.color}
                stroke={plan.color}
                onMouseOver={this.mouseOverPoint.bind(this, value, false, plan.name)}
                onMouseOut={this.mouseOutPoint.bind(this)}
              />
            );
          })}
        </g>
      );

      const rectanglePointSide = 5;
      const rectangles = plan => (
        <g>
          {plan.entriesForChart.map((value, entryIndex) => {
            const xVal = x(value.date);
            const yVal = y(value.amountPowerOutputMegawatt);
            const rectX = xVal - rectanglePointSide / 1.5; // sideways correction.
            return (
              <rect
                transform={`translate(${rectX}, ${yVal}), rotate(-45)`}
                key={entryIndex}
                data-x={xVal}
                data-y={yVal}
                width={rectanglePointSide}
                height={rectanglePointSide}
                fill={plan.color}
                stroke={plan.color}
                onMouseOver={this.mouseOverPoint.bind(this, value, false, plan.name)}
                onMouseOut={this.mouseOutPoint.bind(this)}
              />
            );
          })}
        </g>
      );

      const marksFor = (plan) => {
        if (plan.markType === 'circle') {
          return circleMarks(plan);
        } if (plan.markType === 'tri') {
          return triangles(plan);
        }
        return rectangles(plan);
      };


      const planGraph = (plan, strokeWidth) => (
        <g>
          <path
            className={plan.type}
            style={{ strokeWidth }}
            stroke={plan.color}
            key={plan.key}
            d={ControlRoomChart.generateLine(plan.entriesForChart, plan.shouldCurveStep)}
          />
          {marksFor(plan)}

        </g>
      );

      const xAxisI18nKey = this.isSelectedItemProducer ? 'x-axis-production' : 'x-axis-consumption';

      return (
        <div>
          <svg
            height="100%"
            width="100%"
            viewBox={`-45 -20 ${fullWidth + 100} ${fullHeight + 100}`}
            ref={r => this.svg = r}
            className="graph"
          >
            <g transform={`translate(${margin.left},${margin.top})`}>
              <g className="axis" ref={r => this.xAxis = select(r)} transform={`translate(0, ${height})`} />
              <g className="axis" ref={r => this.yAxis = select(r)} />
              <g className="grid" ref={r => this.xGrid = select(r)} />
              <g className="grid" ref={r => this.yGrid = select(r)} />
              <text x={width / 2 - 30} y={height + 40}>{dateString}</text>
              <text y="-35" x={-height / 2 - 30} transform="rotate(-90)">{t(xAxisI18nKey)}</text>
              <g className="time-rect">
                <rect
                  width={this.calculateDisabledWidth()}
                  height={height}
                  fill="black"
                  opacity=".04"
                />
              </g>
              <path className="crosshair-line" ref={r => this.verticalLine = select(r)} />
              <path className="crosshair-line" ref={r => this.horizontalLine = select(r)} />

              {planGraph(this.productionPlan, 3)}

              {planGraph(this.manualPlanUp, 1)}
              {planGraph(this.manualPlanDown, 1)}

              {planGraph(this.frequencyPlanUp, 1)}
              {planGraph(this.frequencyPlanDown, 1)}

              {planGraph(this.bidPlan, 1)}

              {planGraph(this.intradayPlanBuy, 1)}
              {planGraph(this.intradayPlanSell, 1)}

              {this.onlineValues.valid
                ? (
                  <path
                    className="line"
                    stroke={this.onlineValues.color}
                    key="42001"
                    d={ControlRoomChart.generateLine(this.onlineValues.entries,
                      this.onlineValues.shouldCurveStep)}
                  />
                ) : ''
              }

              <g visibility="hidden" ref={r => this.tooltip = select(r)} className="tooltip-container">
                <rect
                  width={toolTipWidth}
                  height={toolTipHeight}
                  rx="3"
                  ry="3"
                  ref={r => this.toolTipRect = select(r)}
                  opacity=".9"
                />
                <polygon
                  points="10,0  30,0  20,10"
                  opacity=".9"
                  ref={r => this.toolTipPolygon = select(r)}
                />
                <text ref={r => this.toolTipText = select(r)}>
                  <tspan
                    ref={r => this.toolTipTextTitle = select(r)}
                    x="0"
                    dy="-6"
                    textAnchor="middle"
                    fontSize="12px"
                    fontWeight="bold"
                    fill="#000"
                  />
                  <tspan
                    ref={r => this.toolTipTextLine1 = select(r)}
                    x="0"
                    textAnchor="middle"
                    dy="15"
                    fontSize="10px"
                    fill="#fff"
                  />
                  <tspan
                    ref={r => this.toolTipTextLine2 = select(r)}
                    x="0"
                    textAnchor="middle"
                    dy="15"
                    fontSize="10px"
                    fill="#fff"
                  />
                </text>
              </g>

              <g ref={r => this.crossHairCoords = select(r)} className="crosshair-coords">
                <text y="14" x="10" fill="#000" ref={r => this.crossHairCoordsText = select(r)} />
              </g>

            </g>
          </svg>
        </div>
      );
    }

    static generateLine(entries, shouldCurveStep) {
      return (shouldCurveStep) ? lineGenerator(entries) : lineGeneratorNoCurveStep(entries);
    }

    mouseOverPoint(point, isCircle, planName, e) {
      const { t } = this.props;
      const mark = d3.select(e.target);

      let pos;
      if (isCircle) {
        pos = {
          x: e.target.getAttribute('cx'),
          y: e.target.getAttribute('cy'),
        };
      } else {
        pos = {
          x: e.target.getAttribute('data-x'),
          y: e.target.getAttribute('data-y'),
        };
      }

      mark.attr('fill', 'white');

      let transform = ''; let
        transformArrow = '';

      // Calculate direction of tooltip
      if (pos.y > toolTipHeight) {
        transform = `translate(${pos.x - toolTipWidth / 2},${pos.y - toolTipHeight - 15})`; // TODO magic number
        transformArrow = `translate(${toolTipWidth / 2 - 20},${toolTipHeight - 2})`;
      } else if (pos.y < toolTipHeight) {
        transform = `translate(${pos.x - toolTipWidth / 2},${Math.round(pos.y) + 15})`; // TODO magic number
        transformArrow = `translate(${toolTipWidth / 2 - 20},0) rotate(180,20,0)`; // TODO magic number
      }

      this.tooltip
        .attr('transform', transform)
        .attr('visibility', 'visible');

      const color = mark.attr('stroke');

      this.toolTipRect
        .attr('fill', color);

      this.toolTipPolygon
        .attr('transform', transformArrow)
        .attr('fill', color);

      this.toolTipText
        .attr('transform', `translate(${toolTipWidth / 2},${toolTipHeight / 2 - 5})`); // TODO magic number

      this.toolTipTextTitle
        .text(planName);

      this.toolTipTextLine1
        .data([point])
        .html(d => `${t('time')}: ${moment(d.date).format('HH:mm')}`);

      this.toolTipTextLine2
        .data([point])
        .html(d => `${t('production')}: ${d.amountPowerOutputMegawatt.toFixed(1)} MW`);
    }

    mouseOutPoint(e) {
      const mark = d3.select(e.target);

      this.tooltip
        .attr('visibility', 'hidden');

      const color = mark.attr('stroke');
      mark.attr('fill', color);
    }


    componentDidMount() {
      // const {t} = this.props;
      this.isDragging = false;
      this.drawAxisAndGrid();
    }

    @computed get updateOnMove() {
      return this.props.uiStore.selectedDate && this.props.controlRoomStore.selectedItem;
    }

    componentDidUpdate() {
      this.drawAxisAndGrid();
    }

    drawAxisAndGrid() {
      this.xAxis.call(axisBottom().scale(x).ticks(24).tickFormat(d => moment(d).format('HH:mm')));
      this.yAxis.call(axisLeft().scale(y));
      this.xGrid.call(axisBottom().scale(x).ticks(24).tickSize(height, 0, 0)
        .tickFormat(''));
      this.yGrid.call(axisLeft().scale(y).ticks(24).tickSize(-width, 0, 0)
        .tickFormat(''));
    }

    calculateDisabledWidth = () => {
      const dateWidth = x(moment().toDate());

      if (dateWidth > width) {
        return width;
      } if (dateWidth > 0) {
        return dateWidth;
      }

      return 0;
    }
}

export default ControlRoomChart;
