// @flow

import { computed } from 'mobx';

class Box {
    uuid: string;

    plantCode: number;

    plantName: string;

    ipAddress: string;

    port: string;

    macAddress: string;

    serialNo: string;

    hardwareVersion: string;

    softwareVersion: string;

    min00: number;
    max00: number;    

    min01: number;
    max01: number;

    min02: number;
    max02: number;

    min03: number;
    max03: number;

    adc00Enabled: boolean;
    adc01Enabled: boolean;
    adc02Enabled: boolean;
    adc03Enabled: boolean;
    
    ampere : boolean;

    constructor() {
      this.plantCode = null;
      this.plantName = '';
      this.ipAddress = '';
      this.port = '';
      this.macAddress = '';
      this.serialNo = '';
      this.hardwareVersion = '';
      this.softwareVersion = '';
      this.min00 ='';
      this.max00 ='';
      this.min01 ='';
      this.max01 ='';
      this.min02 ='';
      this.max02 ='';
      this.min03 ='';
      this.max03 ='';
      this.adc00Enabled = false;
      this.adc01Enabled = false;
      this.adc02Enabled = false;
      this.adc03Enabled = false;
      this.ampere = false;
    
    }

    fromJson(json) {
      for (const prop in json) if (json.hasOwnProperty(prop)) this[prop] = json[prop];
      return this;
    }

    @computed get isNew() {
      return (!this.uuid || this.uuid.length === 0);
    }

    @computed get isValid() {
      return this.errors.length === 0;
    }

    @computed get errors() {
      const errors = [];
      if (this.serialNo === '') { errors.push({ msg: 'Ser. nr. mangler' }); }
      return errors;
    }


}

export default Box;
