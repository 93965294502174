// @flow
import axios from 'axios';

import { getAs, getData, reject } from '../services/Service';
import Box from './classes/Box';
import User from './classes/User';
import CHPCOM from './classes/CHPCOM';
import { API_DATE_FORMAT, dateForApi } from '../actions/CommonAction';
import moment from 'moment';
import Summary from './classes/Summary';
import { OnlineValuesGraphData } from '../classes/GraphData';
import Plant from '../classes/Plant';
// import {delayedMockCall} from "services/Service";


export function getLogs(fromDate, toDate) {
  if (fromDate && toDate) {
    return getData(`/api/control-room/activitylog/${dateForApi(fromDate)}/${dateForApi(toDate)}`);
  }
  reject('missing from- or to date');
}

export function getBoxes() {
  return getAs('/api/plants/plantboxes', data => data.map(boxData => new Box().fromJson(boxData)));
}

export function pingBox(box) {
  return getData(`/api/plants/${box.plantCode}/liabStatus`);
}

export function createBox(box) {
  // TODO should be post?
  return axios.put(`/api/plants/${box.plantCode}/box`, box);
}

export function updateBox(box) {
  return axios.put(`/api/plants/${box.plantCode}/box`, box);
}

export function deleteBox(box) {
  return axios.delete(`/api/plants/${box.plantCode}/deletebox/${box.serialNo}`);
}

export function sendPlanToBox(plantCode) {
  const today = moment().format(API_DATE_FORMAT);
  return axios.post(`/api/plants/${plantCode}/sendPlans/${today}`);
}

export function getCHPCOMs() {
  return getAs('/api/plants/chpcom', data => data.map(recordData => new CHPCOM().fromJson(recordData)));
}

export function createCHPCOM(chpcom) {
  return axios.put(`/api/plants/${chpcom.plantCode}/chpcom`, chpcom);
}

export function updateCHPCOM(chpcom) {
  return axios.put(`/api/plants/${chpcom.plantCode}/chpcom`, chpcom);
}

export function deleteCHPCOM(chpcom) {
  return axios.delete(`/api/plants/${chpcom.plantCode}/chpcom`);
}

export function pingCHPCOM(chpcom) {
  return getData(`/api/plants/${chpcom.plantCode}/chpcomStatus`);
}

export function getOnlineValuesStatusForSelection(priceAreaOrPlant) {
  if (priceAreaOrPlant) {
    const params = priceAreaOrPlant.isPriceArea ? { 'price-area': priceAreaOrPlant.name } : { 'plant-code': priceAreaOrPlant.code };
    return getData('/api/control-room/online-values/summary-details', params);
  }
  return reject('no selection given', priceAreaOrPlant);
}

export function getSummary(date) {
  return getAs(`/api/control-room/plans/summary-details/${dateForApi(date)}`, data => new Summary().fromJson(data));
}

/**
 * Make sure that the outgoing plant object only has mapped properties.
 * Otherwise the backend complains about unsupported fields.
 * @param plant
 */
function prepareForPost(plant) {
  const clone = Object.assign({}, plant);
  delete clone.active;
  return clone;
}

export function createPlant(plant: Plant) {
  const p = prepareForPost(plant);
  return axios.post('/api/plants', p);
}

export function savePlant(plant: Plant) {
  const p = prepareForPost(plant);
  return axios.put(`/api/plants/${plant.code}`, p);
}

export function savePlantMarginalPrice(plant: Plant) {
  return axios.put(`/api/plants/${plant.code}/marginalPrice`, plant);
}

export function getBlockBids(date) {
  return getData(`/api/control-room/blockbids?day=${dateForApi(date)}`);
}

export function getOnlineValues(priceAreaOrPlant, date, hoursInDay) {
  if (priceAreaOrPlant && priceAreaOrPlant.priceAreaName && date && hoursInDay) {
    let url = `/api/control-room/price-areas/${priceAreaOrPlant.priceAreaName}/online-values/${dateForApi(date)}`;
    if (!priceAreaOrPlant.isPriceArea) {
      url += `?plant-code=${priceAreaOrPlant.code}`;
    }
    return getAs(url, data => new OnlineValuesGraphData(data, date, hoursInDay));
  }
  return reject('plant/pricearea or date not yet selected', priceAreaOrPlant, date, hoursInDay);
}

export function getUsers() {
  return getAs('/api/users', data => data.map(data => new User().fromJson(data)));
}

export function createUser(user) {
  return axios.post('/api/users', user);
}

export function updateUser(user) {
  return axios.put(`/api/users/${user.id}`, user);
}

export function deleteUser(user) {
  return axios.delete(`/api/users/${user.id}`);
}

export function getUserRoles() {
  return getData('/api/users/roles');
}

export function userChangePassword(user) {
  return axios({
    method: 'PUT',
    url: `/api/users/${user.id}/password`,
    headers: {
      'Content-Type': 'text/plain',
    },
    data: user.password,
  });
}
