import React from 'react';
import _ from 'lodash';
import { inject, observer } from 'mobx-react';
import { convertMinutesToClock, convertDateObjToMinutes, getHoursInDay } from '../../actions/CommonAction';
// import '../styles/Chart.scss'
import * as d3 from 'd3';
import { curveStepAfter } from 'd3';
import { select } from 'd3-selection';
import { line } from 'd3-shape';
import { scaleLinear, scaleTime } from 'd3-scale';
import { axisBottom, axisLeft, axisRight } from 'd3-axis';
import 'd3-transition';
import 'd3-time';

import moment from 'moment';
import { toast } from 'react-toastify';
import { Popover } from 'react-bootstrap';
import { translate } from 'react-i18next';
import { OnlineValuesGraphData, PriceForecastGraphData } from '../../classes/GraphData';

import { NAME_KEY_BID_PLAN, NAME_KEY_ONLINE_VALUES, NAME_KEY_PRODUCTION_PLAN } from '../../stores/PlanStore';
import { computed } from 'mobx';

import NewBlockBidForm from './NewBlockBidForm';
import NewProductionPlanEntryForm from './NewProductionPlanEntryForm';

import {
  timePickerConfiguration, millisInASecond, secondsInAMinute, minutesInAnHour,
} from '../../config/settings';

const margin = {
  top: 0, right: 0, bottom: 0, left: 0,
};
const fullWidth = 900;
const fullHeight = 500;
const width = fullWidth - margin.left - margin.right;
const height = fullHeight - margin.top - margin.bottom;

const x = scaleTime()
  .range([0, width]);

const y = scaleLinear()
  .range([0, height]);

const y2 = scaleLinear()
  .range([0, height]);

const toolTipWidth = 180;
const toolTipHeight = 85;

const generateX = d => (isNaN(x(d.date)) ? 0 : x(d.date));

const generateY = d => (isNaN(d.priceAmount) ? y(d.amountPowerOutputMegawatt) : y2(d.priceAmount));

const lineGenerator = line()
  .x(generateX)
  .y(generateY)
  .curve(curveStepAfter);

const lineGeneratorNoCurveStep = line()
  .x(generateX)
  .y(generateY);

const rectanglePointSide = 5;

const yDomainForPlant = (plant) => {
  const paddingFactor = 1.1;
  let maxYVal;
  let minYVal;
  if (plant.producer || plant.invertedConsumptionUnit) {
    maxYVal = plant.capacityMegawatt * paddingFactor;
    minYVal = -1 * plant.capacityMegawatt / 10;
  } else {
    maxYVal = plant.capacityMegawatt / 10;
    minYVal = -1 * plant.capacityMegawatt * paddingFactor;
  }
  return {
    maxY: maxYVal,
    minY: minYVal,
  };
};


@translate()
@inject('controlRoomStore', 'uiStore', 'plantStore', 'planStore') @observer
export default class Chart extends React.Component<> {
  constructor(props) {
    super(props);
    this.state = {
      popoverPrice: '',
      popoverPoint: null,
      popoverBegin: 0,
      popoverEnd: 0,
      submitDisabled: true,
      popoverMwh: '',
    };
  }

    drawConfigurations = {
      crossHairStrokeWidth: 1, // The crosshair width.
      generalStrokeWidth: 1, // The general stroke line for all graphlines unless 'planline' see below.
      planlineStrokeWidth: 3, // Original : 1
      pointRadius: 3, // Original : 2.5 //Radius Of the Circle on the line in the chart
      triangle: {
        size: 8, // original : 8 // ===> Size of triangle, a bit "arbitrary" its a general size amount and not pixels. A size of 8 will result in 8 pixel wide triangle and 4 pixels height. Keep even number
      },
      blockTimeArea: {
        opacity: '0.15',
        color: 'black',

      },

    };

    @computed get isSelectedItemProducer() {
      const { selectedItem } = this.props.controlRoomStore;
      return selectedItem.producer;
    }


    @computed get onlineValues() {
      const result = this.props.controlRoomStore.onlineValues || new OnlineValuesGraphData();
      result.color = 'green';
      return result;
    }

    render() {
      const {
        planStore, plantStore, t, uiStore,
      } = this.props;
      const planChartData = planStore.dataForChart;

      const plant = uiStore.selectedItem;

      const dateString = uiStore.selectedDate.format(timePickerConfiguration.dateFormat);

      this.startDate = moment(uiStore.selectedDate).startOf('d').toDate();
      this.endDate = moment(uiStore.selectedDate).add(1, 'day').startOf('day').toDate();

      // TODO what if plant has not been selected
      // this code inverts the charts dependent on the nature of the plant, producer, consumer and invertedConsumption unit, the latter has to be manipulated in entries as well


      // Online Values can be a little confusing.
      // Data for the drawing are kept in onlineValuesGraphData just below this.
      // planChartData contains the data determine if the curve should be visible. If 'online-values' are part of the data it should be visible.
      // therefore we check if planChartData contains the 'online-values' key and return showOnlineValues as a boolean.
      this.showOnlineValues = planChartData.filter(x => x.nameKey === NAME_KEY_ONLINE_VALUES).length !== 0;

      const marginalPriceGraphData = plantStore.marginalPriceForGraph;

      // Check if the graph type is selected.
      let priceForecastGraphData = plantStore.priceForecastForGraph;
      const priceForecastMaxPrice = priceForecastGraphData.maxPrice;
      if (planStore.selectedPlans.find(plan => plan.nameKey === 'price-forecast')) {
        // The graph data should be rendered without changes.
      } else {
        priceForecastGraphData = new PriceForecastGraphData([]);
      }


      // Check if the graph type is selected.
      let spotPricesGraphData = plantStore.spotPricesForGraph;
      const spotPricesMaxPrice = spotPricesGraphData.maxPrice;
      if (planStore.selectedPlans.find(plan => plan.nameKey === 'spot-prices')) {
        // The graph data should be rendered without changes.
      } else {
        spotPricesGraphData = new PriceForecastGraphData([]);
      }

      let balancePricesGraphData = plantStore.balancePricesForGraph;
      const balancePricesMaxPrice = balancePricesGraphData.maxPrice;
      // eslint-disable-next-line no-empty
      if (planStore.selectedPlans.find(plan => plan.nameKey === 'balance-prices')) {

      } else {
        balancePricesGraphData = new PriceForecastGraphData([]);
      }

      const onlineValuesGraphData = this.onlineValues;

      const maxPrice = _.max([marginalPriceGraphData.maxPrice, priceForecastMaxPrice, spotPricesMaxPrice, balancePricesMaxPrice]);
      const minPrice = _.min([marginalPriceGraphData.minPrice, priceForecastGraphData.minPrice, spotPricesGraphData.minPrice, balancePricesGraphData.minPrice, 0]);
      const magnification = Math.abs(maxPrice - minPrice) * 0.1;
      // TODO y axis according to whether it is a producer, consumer or inverted.
      // x number of minutes for the day

      x.domain([this.startDate, this.endDate]);
      // this.maxY = 1.1 * plant.capacityMegawatt; //* Math.max(...megawatts, 10)
      // y.domain([this.maxY, 0]);

      const { maxY, minY } = yDomainForPlant(plant);
      this.maxY = maxY;
      this.minY = minY;
      y.domain([this.maxY, this.minY]);// yDomain);
      y2.domain([maxPrice + magnification, minPrice - magnification]);

      const disabledWidth = this.calculateDisabledWidth();

      const xAxisI18nKey = this.isSelectedItemProducer ? 'x-axis-production' : 'x-axis-consumption';

      return (
        <div>
          <Popover
            id="production-popover"
            title={t(this.props.planStore.activePlan.nameKey)}
            positionLeft={this.props.planStore.popoverPositionLeft}
            positionTop={this.props.planStore.popoverPositionTop}
            className={this.props.planStore.showProductionPopover ? '' : 'hide'}
            placement="bottom"
          >
            <NewProductionPlanEntryForm
              show={this.props.planStore.showProductionPopover}
              onDismiss={this.dismissPopover}
              init={{
                begin: this.state.popoverBegin,
                power: this.state.popoverMwh,
              }}
              onSubmit={this.onProductionPopOverSubmit}
            />
          </Popover>

          {
            /*
                            BID POPOVER
                        */
          }
          <Popover
            id="block-popover"
            title={t(this.props.planStore.activePlan.nameKey)}
            positionLeft={this.props.planStore.popoverPositionLeft}
            positionTop={this.props.planStore.popoverPositionTop}
            className={this.props.planStore.showPopover ? '' : 'hide'}
            placement="bottom"
          >
            <NewBlockBidForm
              show={this.props.planStore.showPopover}
              onDismiss={this.dismissPopover}
              init={{
                begin: this.state.popoverBegin,
                end: this.state.popoverEnd,
                power: this.state.popoverMwh,
              }}
              onSubmit={this.onBlockBidPopOverSubmit}
            />
          </Popover>


          {
            /*

                        Canvas SVG-Drawing

                        */
          }

          <svg height="100%" width="100%" viewBox={`-45 -20 ${fullWidth + 100} ${fullHeight + 100}`} ref="svg" className="graph">

            {/*
                        *
                        *
                        *   NB:     Remember that the elements are place z-index based on witch are defined first.
                        *           Therefore are the points above the crosshair etc.
                        *
                        *
                        *
                        */}


            <g transform={`translate(${margin.left},${margin.top})`}>


              {
                /*

                                Axis

                                */
              }

              <g className="axis" ref={r => this.xAxis = select(r)} transform={`translate(0, ${height})`} />
              <g className="axis" ref={r => this.yAxis = select(r)} />
              <g className="axis" ref={r => this.yAxisPrice = select(r)} transform={`translate(${width}, 0)`} />
              <g className="grid" ref={r => this.xGrid = select(r)} />
              <g className="grid" ref={r => this.yGrid = select(r)} />


              {
                /*

                                Axis Texts

                                */
              }
              <text x={width / 2 - 30} y={height + 40}>{dateString}</text>
              {/* TODO magic number */}

              <text y="-35" x={-height / 2 - 30} transform="rotate(-90)">{t(xAxisI18nKey)}</text>
              {/* TODO magic number */}

              <text y={width + 50} x={-height / 2 - 30} transform="rotate(-90)">{t('price-axis')}</text>
              {/* TODO magic number */}


              {
                /*

                                Disabled Time Area. The area on the graph where you are not allow to enter points.

                                */
              }

              <g className="time-rect">
                <rect width={disabledWidth} height={height} fill={this.drawConfigurations.blockTimeArea.color} opacity={this.drawConfigurations.blockTimeArea.opacity} />
              </g>

              {
                /*

                                Crosshair Text and Lines

                                */
              }
              <path style={{ strokeWidth: this.drawConfigurations.crossHairStrokeWidth }} className="crosshair-line" ref={r => this.verticalLine = select(r)} />
              <path style={{ strokeWidth: this.drawConfigurations.crossHairStrokeWidth }} className="crosshair-line" ref={r => this.horizontalLine = select(r)} />

              <g ref={r => this.crossHairCoords = select(r)} className="crosshair-coords">
                <text y="14" x="10" fill="#000" ref={r => this.crossHairCoordsText = select(r)} />
              </g>

              {/**
                * Online & Forecast Value Graphs.
                */}

              <path className="line" stroke="black" key="42000" d={Chart.generateLine(marginalPriceGraphData.entries, marginalPriceGraphData.shouldCurveStep)} />
              { onlineValuesGraphData.valid && this.showOnlineValues
                ? (
                  <path
                    className="line"
                    stroke={onlineValuesGraphData.color}
                    key="42001"
                    d={Chart.generateLine(onlineValuesGraphData.entries,
                      onlineValuesGraphData.shouldCurveStep)}
                  />
                ) : ''
              }
              { priceForecastGraphData.valid
                ? (
                  <path
                    className="line"
                    stroke={priceForecastGraphData.color}
                    key="42002"
                    d={Chart.generateLine(priceForecastGraphData.entries,
                      priceForecastGraphData.shouldCurveStep)}
                  />
                ) : ''
              }
              { spotPricesGraphData.valid
                ? (
                  <path
                    className="line"
                    stroke={spotPricesGraphData.color}
                    key="42003"
                    d={Chart.generateLine(spotPricesGraphData.entries,
                      spotPricesGraphData.shouldCurveStep)}
                  />
                ) : ''
              }
              { balancePricesGraphData.valid
                ? (
                  <path
                    className="line"
                    stroke={balancePricesGraphData.color}
                    key="42004"
                    d={Chart.generateBalancePriceLine(balancePricesGraphData.entries)}
                  />
                ) : ''
              }

              {/**
                * Editing / Manipulating Drawing
                */}

              {planChartData.map((plan, index) => (
                <g key={index}>

                  {/**
                  *
                  *   Stroke Path
                  *   Adjust "line-thickness" eg. strokeWidth.
                  *
                  *
                  */}
                  <path className="line" style={{ strokeWidth: plan.nameKey === NAME_KEY_PRODUCTION_PLAN ? this.drawConfigurations.planlineStrokeWidth : this.drawConfigurations.generalStrokeWidth }} stroke={plan.color} key={index} d={Chart.generateLine(plan.entries, plan.shouldCurveStep)} />


                  {/*
                                            *
                                            *   Point / Circle / Bullet
                                            *
                                            *
                                            */}
                  {plan.markType === 'circle'
                    ? (
                      <g>
                        {plan.entries.map((value, entryIndex) => (
                          <circle
                            key={entryIndex}
                            cx={x(value.date)}
                            cy={(value.priceAmount) ? y2(value.priceAmount) : y(value.amountPowerOutputMegawatt)}
                            r={this.drawConfigurations.pointRadius}
                            data-plan-key={plan.nameKey}
                            fill={plan.color}
                            stroke={plan.color}
                            onMouseOver={this.mouseOverPoint.bind(this, value, true)}
                            onMouseOut={this.mouseOutPoint.bind(this)}
                            onMouseDown={this.mouseDownPoint.bind(this, value)}
                          />
                        ))}
                      </g>
                    ) : false
                  }

                  {/*
                                            *
                                            *   Rectangle
                                            *
                                            *
                                            */}
                  {plan.markType === 'rect'
                    ? (
                      <g>
                        {plan.entries.map((value, entryIndex) => {
                          const xVal = x(value.date);
                          const yVal = (value.priceAmount) ? y2(value.priceAmount) : y(value.amountPowerOutputMegawatt);
                          const rectX = xVal - rectanglePointSide / 3 * 2; // TODO magic number
                          const rectY = yVal;


                          return (
                            <rect
                              transform={`translate(${rectX}, ${rectY}), rotate(-45)`}
                              key={entryIndex}
                              data-x={xVal}
                              data-y={yVal}
                              width={rectanglePointSide}
                              height={rectanglePointSide}
                              data-plan-key={plan.nameKey}
                              fill={plan.color}
                              stroke={plan.color}
                              onMouseOver={this.mouseOverPoint.bind(this, value, false)}
                              onMouseOut={this.mouseOutPoint.bind(this)}
                              onMouseDown={this.mouseDownPoint.bind(this, value)}
                            />
                          );
                        })}
                      </g>
                    ) : false
                  }

                  {/*
                                            *
                                            *   Triangle Pointing DOWN
                                            *
                                            *
                                            */}
                  {plan.markType === 'triDown'

                    ? (
                      <g>
                        {plan.entries.map((value, entryIndex) => {
                          const xVal = x(value.date);
                          const yVal = (value.priceAmount) ? y2(value.priceAmount) : y(value.amountPowerOutputMegawatt);
                          const rectX = xVal - (this.drawConfigurations.triangle.size) / 2; // TODO magic number
                          const rectY = yVal - (this.drawConfigurations.triangle.size) / 2;

                          return (
                            <polygon
                              transform={`translate(${rectX}, ${rectY})`}
                              key={entryIndex}
                              data-x={xVal}
                              data-y={yVal}
                              points={`0,0  ${this.drawConfigurations.triangle.size},0  ${this.drawConfigurations.triangle.size / 2},${this.drawConfigurations.triangle.size / 2}`}
                              data-plan-key={plan.nameKey}
                              fill={plan.color}
                              stroke={plan.color}
                              onMouseOver={this.mouseOverPoint.bind(this, value, false)}
                              onMouseOut={this.mouseOutPoint.bind(this)}
                              onMouseDown={this.mouseDownPoint.bind(this, value)}
                            />
                          );
                        })}
                      </g>
                    ) : false
                  }

                  {/*
                                            *
                                            *   Triangle Pointing UP
                                            *
                                            *
                                            */}
                  {plan.markType === 'triUp'
                    ? (
                      <g>
                        {plan.entries.map((value, entryIndex) => {
                          const xVal = x(value.date);
                          const yVal = (value.priceAmount) ? y2(value.priceAmount) : y(value.amountPowerOutputMegawatt);
                          const rectX = (xVal + (this.drawConfigurations.triangle.size / 2)); // TODO magic number
                          const rectY = (yVal);

                          return (
                            <polygon
                              transform={`translate(${rectX}, ${rectY}), rotate(180)`}
                              key={entryIndex}
                              data-x={xVal}
                              data-y={yVal}
                              points={`0,0  ${this.drawConfigurations.triangle.size},0  ${this.drawConfigurations.triangle.size / 2},${this.drawConfigurations.triangle.size / 2}`}
                              data-plan-key={plan.nameKey}
                              fill={plan.color}
                              stroke={plan.color}
                              onMouseOver={this.mouseOverPoint.bind(this, value, false)}
                              onMouseOut={this.mouseOutPoint.bind(this)}
                              onMouseDown={this.mouseDownPoint.bind(this, value)}
                            />
                          );
                        })}
                      </g>
                    ) : false
                  }
                </g>
              ))}


              {
                /*

                                Tooltip

                                */
              }
              <g visibility="hidden" ref={r => this.tooltip = select(r)} className="tooltip-container">
                <rect
                  width={toolTipWidth}
                  height={toolTipHeight}
                  rx="3"
                  ry="3"
                  ref={r => this.toolTipRect = select(r)}
                  opacity=".9"
                />
                <polygon
                  points="10,0  30,0  20,10"
                  opacity=".9"
                  ref={r => this.toolTipPolygon = select(r)}
                />
                <text ref={r => this.toolTipText = select(r)}>
                  <tspan
                    ref={r => this.toolTipTextTitle = select(r)}
                    x="0"
                    dy="-6"
                    textAnchor="middle"
                    fontSize="12px"
                    fontWeight="bold"
                    fill="#000"
                  />
                  <tspan
                    ref={r => this.toolTipTextLine1 = select(r)}
                    x="0"
                    textAnchor="middle"
                    dy="15"
                    fontSize="10px"
                    fill="#fff"
                  />
                  <tspan
                    ref={r => this.toolTipTextLine2 = select(r)}
                    x="0"
                    textAnchor="middle"
                    dy="15"
                    fontSize="10px"
                    fill="#fff"
                  />
                  <tspan
                    ref={r => this.toolTipTextLine3 = select(r)}
                    x="0"
                    textAnchor="middle"
                    dy="15"
                    fontSize="10px"
                    fill="#fff"
                  />
                </text>
              </g>


            </g>
          </svg>

        </div>
      );
    }

    onBlockBidPopOverSubmit = ({
      begin, end, price, power,
    }) => {
      this.props.planStore.addBlockBid(
        begin,
        end,
        power,
        parseInt(price, 10),
      );

      this.setState({ popoverPrice: '', popoverPoint: null });

      this.props.planStore.displayPopover(false);
    };

    onProductionPopOverSubmit = ({ begin, power }) => {
      this.props.planStore.addEntry(begin, power);

      this.setState({ popoverMwh: '', popoverPoint: null });

      this.props.planStore.displayPopover(false);
    };

    dismissPopover = () => {
      this.props.planStore.displayPopover(false);
    };

    static generateLine(entries, shouldCurveStep) {
      return (shouldCurveStep) ? lineGenerator(entries) : lineGeneratorNoCurveStep(entries);
    }

    // For balance prices, it can happen that a price in the middle of the series
    // has not yet been uploaded. Therefore, we create several graphs and let a hole appear in the
    // graph if there is a missing value. To not mislead anyone.
    // ie. for every consecutive sequence of values we create a graph.
    static generateBalancePriceLine(entries) {
      const HOUR = 3600000;
      const arr = entries.sort((a, b) => a.date - b.date);
      const result = [];
      let j = 0;
      while (j < arr.length) {
        if (j === 0) {
          result.push([arr[0]]);
        } else if (arr[j].date - arr[j - 1].date !== HOUR) {
          result.push([arr[j]]);
        } else {
          result[result.length - 1].push(arr[j]);
        }
        j += 1;
      }

      function addLastValue(entryList) {
        const lastValue = Object.assign({}, entryList[entryList.length - 1]);
        lastValue.date = new Date(lastValue.date.getTime() + HOUR);
        entryList.push(lastValue);
        return entryList;
      }
      console.log(result);
      return result.map(entryList => lineGenerator(addLastValue(entryList)));
    }

    mouseDownPoint(data, e) {
      const mark = d3.select(e.target);
      const planKey = mark.attr('data-plan-key');

      if (planKey !== this.props.planStore.activePlan.nameKey) return;

      if (this.props.planStore.activePlan.nameKey === NAME_KEY_BID_PLAN) return;

      this.isDragging = true;
      this.draggingPoint = data;
    }

    mouseOverPoint(point, isCircle, e) {
      const { t } = this.props;
      const mark = d3.select(e.target);
      const planKey = mark.attr('data-plan-key');

      if (planKey !== this.props.planStore.activePlan.nameKey) return;

      let pos;
      if (isCircle) {
        pos = {
          x: e.target.getAttribute('cx'),
          y: e.target.getAttribute('cy'),
        };
      } else {
        pos = {
          x: e.target.getAttribute('data-x'),
          y: e.target.getAttribute('data-y'),
        };
      }

      mark.attr('fill', 'white');

      let transform = ''; let
        transformArrow = '';

      // Calculate direction of tooltip
      if (pos.y > toolTipHeight) {
        transform = `translate(${pos.x - toolTipWidth / 2},${pos.y - toolTipHeight - 15})`; // TODO magic number
        transformArrow = `translate(${toolTipWidth / 2 - 20},${toolTipHeight - 2})`;
      } else if (pos.y < toolTipHeight) {
        transform = `translate(${pos.x - toolTipWidth / 2},${Math.round(pos.y) + 15})`; // TODO magic number
        transformArrow = `translate(${toolTipWidth / 2 - 20},0) rotate(180,20,0)`; // TODO magic number
      }

      this.tooltip
        .attr('transform', transform)
        .attr('visibility', 'visible');

      const color = mark.attr('stroke');

      this.toolTipRect
        .attr('fill', color);

      this.toolTipPolygon
        .attr('transform', transformArrow)
        .attr('fill', color);

      this.toolTipText
        .attr('transform', `translate(${toolTipWidth / 2},${toolTipHeight / 2 - 5})`); // TODO magic number

      const planName = t(this.props.planStore.activePlan.nameKey);

      this.toolTipTextTitle
        .text(planName);

      this.toolTipTextLine1
        .data([point])
        .html(d => `${t('time')}: ${convertMinutesToClock(this.props.uiStore.selectedDate, d.minute)}`);

      this.toolTipTextLine2
        .data([point])
        .html(d => `${t('production')}: ${d.amountPowerOutputMegawatt.toFixed(1)} MW`);

      this.toolTipTextLine3
        .data([point])
        .html((d) => {
          if (typeof d.activationPrice === 'undefined' || d.activationPrice == null) {
            return '';
          }
          return `${t('price')}: ${d.activationPrice.amount} ${d.activationPrice.currency}`;
        });
    }

    mouseOutPoint(e) {
      const mark = d3.select(e.target);
      const planKey = mark.attr('data-plan-key');
      if (planKey !== this.props.planStore.activePlan.nameKey) return;

      this.tooltip
        .attr('visibility', 'hidden');

      const color = mark.attr('stroke');
      mark.attr('fill', color);
    }

    isOutOfXAxis = x => (x < this.startDate || x > moment(this.endDate).subtract(5, 'minute').toDate());

    isOutOfYAxis = y => (y < this.minY || y > this.maxY);

    componentDidMount() {
      const { t } = this.props;
      this.isDragging = false;
      this.drawAxisAndGrid();
      const self = this;

      d3.select(this.refs.svg).on('mousemove', () => {
        const point = d3.mouse(this.refs.svg);

        const newData = {
          x: x.invert(point[0]),
          y: y.invert(point[1]),
        };

        const newHorizontalData = (!this.isOutOfXAxis(newData.x))
          ? [{ amountPowerOutputMegawatt: this.minY, date: newData.x }, { amountPowerOutputMegawatt: this.maxY, date: newData.x }]
          : [{ amountPowerOutputMegawatt: this.minY, date: this.startDate }, { amountPowerOutputMegawatt: this.maxY, date: this.startDate }];
        const newVerticalData = (!this.isOutOfYAxis(newData.y))
          ? [{ amountPowerOutputMegawatt: newData.y, date: this.startDate }, { amountPowerOutputMegawatt: newData.y, date: this.endDate }]
          : [{ amountPowerOutputMegawatt: 0, date: this.startDate }, { amountPowerOutputMegawatt: 0, date: this.endDate }];

        self.horizontalLine.attr('d', lineGenerator(newHorizontalData));
        self.verticalLine.attr('d', lineGenerator(newVerticalData));

        self.crossHairCoords
          .attr('transform', `translate(${x(newData.x)},${y(newData.y)})`);

        self.crossHairCoordsText
          .data([{ x: newData.x, y: newData.y.toFixed(1) }])
          .html((d) => {
            // Round to nearest 5 minutes
            const coeff = millisInASecond * secondsInAMinute * 5;
            const date = d.x; // or use any other date
            const rounded = new Date(Math.round(date.getTime() / coeff) * coeff);
            const string = moment(rounded).format('HH:mm');
            return (this.isOutOfXAxis(newData.x) || this.isOutOfYAxis(newData.y)) ? null : `(${string}, ${d.y})`;
          });
      }).on('click', () => {
        // Ignore the click event if it was suppressed
        if (d3.event.defaultPrevented) return;

        // Extract the click location
        const point = d3.mouse(this.refs.svg);

        const newData = {
          x: x.invert(point[0]), // Takes the pixel number to convert to number
          y: y.invert(point[1]),
        };

        if (this.props.planStore.activePlan.readonly) {
          toast.error(`${t('cannot-edit-plan')} ${t(this.props.planStore.activePlan.nameKey)}.`);
          return;
        }

        // Added isSame to prevent the error from showing when clicking outside of graph
        if (moment() > newData.x && moment(newData.x).isSame(this.props.uiStore.selectedDate, 'day')) {
          toast.error(t('cannot-edit-plan-late'));
          return;
        }

        if (this.isOutOfXAxis(newData.x) || this.isOutOfYAxis(newData.y)) return;

        // Convert to minutes and round to nearest 5 minutes
        const pointDate = moment(newData.x);
        let minutes = convertDateObjToMinutes(pointDate);
        const activationPrice = (['ManualPlanDTO', 'FrequencyPlanDTO'].includes(this.props.planStore.activePlan.classOf)) ? this.draggingPoint.activationPrice : undefined;
        if (this.isDragging) {
          if (moment() > this.draggingPoint.date) {
            this.isDragging = false;
            toast.error(t('cannot-edit-plan-late'));
            return;
          }

          if (['ManualPlanDTO', 'FrequencyPlanDTO', 'IntradayPlanDTO', 'PriceDependentPlanDTO'].includes(this.props.planStore.activePlan.classOf)) {
            this.isDragging = false;
            const nearestMinutes = this.findNearestMinutes(minutes);
            const roundedPower = Math.round(newData.y * 10) / 10;

            // Need to make sure that isn't just a click

            if (nearestMinutes !== this.draggingPoint.minute) {
              this.props.planStore.drag(this.draggingPoint, nearestMinutes, roundedPower);
              return;
            }
          }

          const draggingPointCopy = Object.assign({}, this.draggingPoint);
          const draggingPointMoment = moment(draggingPointCopy.date);
          let draggingPointMinutes = (draggingPointMoment.hour() * minutesInAnHour) + draggingPointMoment.minute();
          draggingPointMinutes = 5 * Math.round(draggingPointMinutes / 5);
          draggingPointCopy.minute = draggingPointMinutes;

          this.props.planStore.removeEntry(draggingPointCopy);
          this.isDragging = false;
        } else if (this.props.planStore.activePlan.nameKey === NAME_KEY_BID_PLAN || this.props.planStore.activePlan.nameKey === NAME_KEY_PRODUCTION_PLAN) {
          const multipleOfFive = (this.props.planStore.activePlan.nameKey === NAME_KEY_PRODUCTION_PLAN);

          this.setState({
            popoverPoint: newData,
            popoverBegin: this.findNearest(minutes, multipleOfFive).begin,
            popoverEnd: this.findNearest(minutes, multipleOfFive).end,
            popoverMwh: Math.round(newData.y * 10) / 10,
            popoverPrice: '',
          });

          const positionY = d3.event.clientY + document.body.scrollTop;
          let correctedPositionY = positionY;
          if (positionY > (window.innerHeight - 295)) { correctedPositionY = window.innerHeight - 295; }
          this.props.planStore.setPopoverPosition(d3.event.clientX - (230 / 2), correctedPositionY);

          this.props.planStore.displayPopover(true);

          return;
        }

        const multipleOfFive = !_.includes(['manual-plan-up', 'manual-plan-down', 'frequency-plan-up', 'frequency-plan-down', 'intraday-plan-buy', 'intraday-plan-sell', 'price-dependent-plan'], this.props.planStore.activePlan.nameKey);

        minutes = this.findNearestMinutes(minutes, multipleOfFive);

        this.props.planStore.addEntry(minutes, newData.y, activationPrice);
      }).on('mousedown', () => {
        if (['ManualPlanDTO', 'FrequencyPlanDTO', 'IntradayPlanDTO', 'PriceDependentPlanDTO'].includes(this.props.planStore.activePlan.classOf)) {
          const point = d3.mouse(this.refs.svg);

          const dataPoint = {
            x: x.invert(point[0]),
            y: y.invert(point[1]),
          };

          const m = moment(dataPoint.x);
          let minutes = (m.hour() * minutesInAnHour) + m.minute();

          minutes = this.findNearestMinutes(minutes);
          const power = Math.round(dataPoint.y * 10) / 10;

          this.draggingPoint = { amountPowerOutputMegawatt: power, minute: minutes, date: dataPoint.x };
          this.isDragging = true;
        }
      });
    }

    componentDidUpdate() {
      this.drawAxisAndGrid();
    }

    drawAxisAndGrid() {
      this.xAxis.call(axisBottom().scale(x).ticks(24).tickFormat(d => moment(d).format('HH:mm')));
      this.yAxis.call(axisLeft().scale(y));
      this.yAxisPrice.call(axisRight().scale(y2));
      this.xGrid.call(axisBottom().scale(x).ticks(24).tickSize(height, 0, 0)
        .tickFormat(''));
      this.yGrid.call(axisLeft().scale(y).ticks(24).tickSize(-width, 0, 0)
        .tickFormat(''));
    }

    findNearest(minutes, multipleOfFive = false) {
      const multiplier = multipleOfFive ? 5 : minutesInAnHour;

      const max = getHoursInDay(this.props.uiStore.selectedDate) * minutesInAnHour;

      const nearest = (Math.round(minutes / multiplier) * multiplier);
      const begin = nearest > max ? max : nearest;

      // Check if it exceeds hours in a day
      const end = Math.min(begin + (3 * minutesInAnHour), max);

      return { begin, end };
    }

    findNearestMinutes(minutes, multipleOfFive = false) {
      const multiplier = multipleOfFive ? 5 : minutesInAnHour;

      const max = getHoursInDay(this.props.uiStore.selectedDate) * minutesInAnHour - multiplier;

      const nearest = (Math.round(minutes / multiplier) * multiplier);

      const result = nearest > max ? max : nearest;

      return result;
    }

    calculateDisabledWidth = () => {
      const dateWidth = x(moment().toDate());

      if (dateWidth > width) {
        return width;
      } if (dateWidth > 0) {
        return dateWidth;
      }

      return 0;
    }
}
