// @flow
import ModalComp from '../../components/common/ModalComp';
import React from 'react';
import PropTypes from 'prop-types';
import Box from '../classes/Box';
import moment from 'moment/moment';
import { Button } from 'react-bootstrap';

class BoxStatusModal extends React.Component {
  constructor(props) {
    super(props);
    // this.onConfirm = this.onConfirm.bind(this);
    this.state = {
      boxPlantCode: this.props.box.plantCode,
      boxPlantName: this.props.box.plantName,
    };
  }


  render() {
    const { t } = this.props;

    const isRunning = this.props.status.running;

    const body = (
      <div>
        {isRunning ? 'On' : 'Off'}
        {' '}
        <br />
                    Status
        {' '}
        {this.props.status.status}
        {' '}
        <br />
                    Version
        {' '}
        {this.props.status.version}
        {' '}
        <br />
                    Tid
        {' '}
        {moment(this.props.status.time).format('DD/MM HH:mm')}
        {' '}
        <br />
                    Relæer
        {' '}
        {this.props.status.relayState}
        {' '}
        <br />
      </div>
    );

    const footer = (
      <div>
        <Button bsStyle="primary" onClick={this.props.onConfirm}>Ok</Button>
      </div>
    );


    return (
      <ModalComp
        title={`Boks status for ${this.state.boxPlantName}`}
        body={body}
        t={t}
        onClose={this.props.onClose}
        footer={footer}
      />
    );
  }
}

BoxStatusModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  box: PropTypes.instanceOf(Box).isRequired,
  status: PropTypes.object.isRequired,
};

export default BoxStatusModal;
