// @flow
// class DayHourPrice {
//  day: number; // YYYYMMDD;
//  hour: number; // 1-24
//  price: number;
// }

export default class PriceForecastEntry {
    hour: number;

    priceAmount: number;

    priceCurrency: string;

    constructor(date) {
      this.date = date;
    }

    fromJson(json) {
      if (json) {
        this.hour = json.hour;
        this.priceAmount = json.priceAmount;
        this.priceCurrency = json.priceCurrency;
      }
      return this;
    }
}
/*
const sampleData = [
    {"hour": 0, "priceAmount": 308.0, "priceCurrency": "KR"}, {
        "hour": 1,
        "priceAmount": 285.0,
        "priceCurrency": "KR"
    }, {"hour": 2, "priceAmount": 270.0, "priceCurrency": "KR"}, {
        "hour": 3,
        "priceAmount": 224.0,
        "priceCurrency": "KR"
    }, {"hour": 4, "priceAmount": 230.0, "priceCurrency": "KR"}, {
        "hour": 5,
        "priceAmount": 260.0,
        "priceCurrency": "KR"
    }, {"hour": 6, "priceAmount": 306.0, "priceCurrency": "KR"}, {
        "hour": 7,
        "priceAmount": 363.0,
        "priceCurrency": "KR"
    }, {"hour": 8, "priceAmount": 354.0, "priceCurrency": "KR"}, {
        "hour": 9,
        "priceAmount": 366.0,
        "priceCurrency": "KR"
    }, {"hour": 10, "priceAmount": 352.0, "priceCurrency": "KR"}, {
        "hour": 11,
        "priceAmount": 350.0,
        "priceCurrency": "KR"
    }, {"hour": 12, "priceAmount": 347.0, "priceCurrency": "KR"}, {
        "hour": 13,
        "priceAmount": 346.0,
        "priceCurrency": "KR"
    }, {"hour": 14, "priceAmount": 346.0, "priceCurrency": "KR"}, {
        "hour": 15,
        "priceAmount": 346.0,
        "priceCurrency": "KR"
    }, {"hour": 16, "priceAmount": 355.0, "priceCurrency": "KR"}, {
        "hour": 17,
        "priceAmount": 412.0,
        "priceCurrency": "KR"
    }, {"hour": 18, "priceAmount": 434.0, "priceCurrency": "KR"}, {
        "hour": 19,
        "priceAmount": 398.0,
        "priceCurrency": "KR"
    }, {"hour": 20, "priceAmount": 377.0, "priceCurrency": "KR"}, {
        "hour": 21,
        "priceAmount": 358.0,
        "priceCurrency": "KR"
    }, {"hour": 22, "priceAmount": 330.0, "priceCurrency": "KR"}, {
        "hour": 23,
        "priceAmount": 321.0,
        "priceCurrency": "KR"
    }, {"hour": 0, "priceAmount": 256.0, "priceCurrency": "KR"}, {
        "hour": 1,
        "priceAmount": 253.0,
        "priceCurrency": "KR"
    }, {"hour": 2, "priceAmount": 252.0, "priceCurrency": "KR"}, {
        "hour": 3,
        "priceAmount": 252.0,
        "priceCurrency": "KR"
    }, {"hour": 4, "priceAmount": 254.0, "priceCurrency": "KR"}, {
        "hour": 5,
        "priceAmount": 259.0,
        "priceCurrency": "KR"
    }, {"hour": 6, "priceAmount": 266.0, "priceCurrency": "KR"}, {
        "hour": 7,
        "priceAmount": 299.0,
        "priceCurrency": "KR"
    }, {"hour": 8, "priceAmount": 384.0, "priceCurrency": "KR"}, {
        "hour": 9,
        "priceAmount": 429.0,
        "priceCurrency": "KR"
    }, {"hour": 10, "priceAmount": 401.0, "priceCurrency": "KR"}, {
        "hour": 11,
        "priceAmount": 381.0,
        "priceCurrency": "KR"
    }, {"hour": 12, "priceAmount": 369.0, "priceCurrency": "KR"}, {
        "hour": 13,
        "priceAmount": 362.0,
        "priceCurrency": "KR"
    }, {"hour": 14, "priceAmount": 370.0, "priceCurrency": "KR"}, {
        "hour": 15,
        "priceAmount": 368.0,
        "priceCurrency": "KR"
    }, {"hour": 16, "priceAmount": 357.0, "priceCurrency": "KR"}, {
        "hour": 17,
        "priceAmount": 486.0,
        "priceCurrency": "KR"
    }, {"hour": 18, "priceAmount": 430.0, "priceCurrency": "KR"}, {
        "hour": 19,
        "priceAmount": 413.0,
        "priceCurrency": "KR"
    }, {"hour": 20, "priceAmount": 344.0, "priceCurrency": "KR"}, {
        "hour": 21,
        "priceAmount": 325.0,
        "priceCurrency": "KR"
    }, {"hour": 22, "priceAmount": 315.0, "priceCurrency": "KR"}, {
        "hour": 23,
        "priceAmount": 303.0,
        "priceCurrency": "KR"
    }, {"hour": 0, "priceAmount": 296.0, "priceCurrency": "KR"}, {
        "hour": 1,
        "priceAmount": 292.0,
        "priceCurrency": "KR"
    }, {"hour": 2, "priceAmount": 292.0, "priceCurrency": "KR"}, {
        "hour": 3,
        "priceAmount": 292.0,
        "priceCurrency": "KR"
    }, {"hour": 4, "priceAmount": 294.0, "priceCurrency": "KR"}, {
        "hour": 5,
        "priceAmount": 298.0,
        "priceCurrency": "KR"
    }, {"hour": 6, "priceAmount": 305.0, "priceCurrency": "KR"}, {
        "hour": 7,
        "priceAmount": 336.0,
        "priceCurrency": "KR"
    }, {"hour": 8, "priceAmount": 378.0, "priceCurrency": "KR"}, {
        "hour": 9,
        "priceAmount": 420.0,
        "priceCurrency": "KR"
    }, {"hour": 10, "priceAmount": 394.0, "priceCurrency": "KR"}, {
        "hour": 11,
        "priceAmount": 376.0,
        "priceCurrency": "KR"
    }, {"hour": 12, "priceAmount": 364.0, "priceCurrency": "KR"}, {
        "hour": 13,
        "priceAmount": 357.0,
        "priceCurrency": "KR"
    }, {"hour": 14, "priceAmount": 365.0, "priceCurrency": "KR"}, {
        "hour": 15,
        "priceAmount": 410.0,
        "priceCurrency": "KR"
    }, {"hour": 16, "priceAmount": 434.0, "priceCurrency": "KR"}, {
        "hour": 17,
        "priceAmount": 455.0,
        "priceCurrency": "KR"
    }, {"hour": 18, "priceAmount": 553.0, "priceCurrency": "KR"}, {
        "hour": 19,
        "priceAmount": 406.0,
        "priceCurrency": "KR"
    }, {"hour": 20, "priceAmount": 341.0, "priceCurrency": "KR"}, {
        "hour": 21,
        "priceAmount": 323.0,
        "priceCurrency": "KR"
    }, {"hour": 22, "priceAmount": 314.0, "priceCurrency": "KR"}, {
        "hour": 23,
        "priceAmount": 305.0,
        "priceCurrency": "KR"
    }, {"hour": 0, "priceAmount": 248.0, "priceCurrency": "KR"}, {
        "hour": 1,
        "priceAmount": 248.0,
        "priceCurrency": "KR"
    }, {"hour": 2, "priceAmount": 248.0, "priceCurrency": "KR"}, {
        "hour": 3,
        "priceAmount": 219.0,
        "priceCurrency": "KR"
    }, {"hour": 4, "priceAmount": 227.0, "priceCurrency": "KR"}, {
        "hour": 5,
        "priceAmount": 243.0,
        "priceCurrency": "KR"
    }, {"hour": 6, "priceAmount": 269.0, "priceCurrency": "KR"}, {
        "hour": 7,
        "priceAmount": 293.0,
        "priceCurrency": "KR"
    }, {"hour": 8, "priceAmount": 341.0, "priceCurrency": "KR"}, {
        "hour": 9,
        "priceAmount": 377.0,
        "priceCurrency": "KR"
    }, {"hour": 10, "priceAmount": 355.0, "priceCurrency": "KR"}, {
        "hour": 11,
        "priceAmount": 339.0,
        "priceCurrency": "KR"
    }, {"hour": 12, "priceAmount": 329.0, "priceCurrency": "KR"}, {
        "hour": 13,
        "priceAmount": 324.0,
        "priceCurrency": "KR"
    }, {"hour": 14, "priceAmount": 330.0, "priceCurrency": "KR"}, {
        "hour": 15,
        "priceAmount": 368.0,
        "priceCurrency": "KR"
    }, {"hour": 16, "priceAmount": 439.0, "priceCurrency": "KR"}, {
        "hour": 17,
        "priceAmount": 542.0,
        "priceCurrency": "KR"
    }, {"hour": 18, "priceAmount": 547.0, "priceCurrency": "KR"}, {
        "hour": 19,
        "priceAmount": 365.0,
        "priceCurrency": "KR"
    }, {"hour": 20, "priceAmount": 310.0, "priceCurrency": "KR"}, {
        "hour": 21,
        "priceAmount": 294.0,
        "priceCurrency": "KR"
    }, {"hour": 22, "priceAmount": 287.0, "priceCurrency": "KR"}, {
        "hour": 23,
        "priceAmount": 263.0,
        "priceCurrency": "KR"
    }, {"hour": 0, "priceAmount": 135.0, "priceCurrency": "KR"}, {
        "hour": 1,
        "priceAmount": 76.0,
        "priceCurrency": "KR"
    }, {"hour": 2, "priceAmount": 76.0, "priceCurrency": "KR"}, {
        "hour": 3,
        "priceAmount": 32.0,
        "priceCurrency": "KR"
    }, {"hour": 4, "priceAmount": 19.0, "priceCurrency": "KR"}, {
        "hour": 5,
        "priceAmount": 65.0,
        "priceCurrency": "KR"
    }, {"hour": 6, "priceAmount": 90.0, "priceCurrency": "KR"}, {
        "hour": 7,
        "priceAmount": 103.0,
        "priceCurrency": "KR"
    }, {"hour": 8, "priceAmount": 241.0, "priceCurrency": "KR"}, {
        "hour": 9,
        "priceAmount": 306.0,
        "priceCurrency": "KR"
    }, {"hour": 10, "priceAmount": 305.0, "priceCurrency": "KR"}, {
        "hour": 11,
        "priceAmount": 287.0,
        "priceCurrency": "KR"
    }, {"hour": 12, "priceAmount": 277.0, "priceCurrency": "KR"}, {
        "hour": 13,
        "priceAmount": 250.0,
        "priceCurrency": "KR"
    }, {"hour": 14, "priceAmount": 246.0, "priceCurrency": "KR"}, {
        "hour": 15,
        "priceAmount": 286.0,
        "priceCurrency": "KR"
    }, {"hour": 16, "priceAmount": 504.0, "priceCurrency": "KR"}, {
        "hour": 17,
        "priceAmount": 557.0,
        "priceCurrency": "KR"
    }, {"hour": 18, "priceAmount": 532.0, "priceCurrency": "KR"}, {
        "hour": 19,
        "priceAmount": 305.0,
        "priceCurrency": "KR"
    }, {"hour": 20, "priceAmount": 238.0, "priceCurrency": "KR"}, {
        "hour": 21,
        "priceAmount": 205.0,
        "priceCurrency": "KR"
    }, {"hour": 22, "priceAmount": 209.0, "priceCurrency": "KR"}, {
        "hour": 23,
        "priceAmount": 189.0,
        "priceCurrency": "KR"
    }, {"hour": 0, "priceAmount": 206.0, "priceCurrency": "KR"}, {
        "hour": 1,
        "priceAmount": 203.0,
        "priceCurrency": "KR"
    }, {"hour": 2, "priceAmount": 205.0, "priceCurrency": "KR"}, {
        "hour": 3,
        "priceAmount": 205.0,
        "priceCurrency": "KR"
    }, {"hour": 4, "priceAmount": 204.0, "priceCurrency": "KR"}, {
        "hour": 5,
        "priceAmount": 208.0,
        "priceCurrency": "KR"
    }, {"hour": 6, "priceAmount": 215.0, "priceCurrency": "KR"}, {
        "hour": 7,
        "priceAmount": 247.0,
        "priceCurrency": "KR"
    }, {"hour": 8, "priceAmount": 361.0, "priceCurrency": "KR"}, {
        "hour": 9,
        "priceAmount": 405.0,
        "priceCurrency": "KR"
    }, {"hour": 10, "priceAmount": 378.0, "priceCurrency": "KR"}, {
        "hour": 11,
        "priceAmount": 359.0,
        "priceCurrency": "KR"
    }, {"hour": 12, "priceAmount": 347.0, "priceCurrency": "KR"}, {
        "hour": 13,
        "priceAmount": 340.0,
        "priceCurrency": "KR"
    }, {"hour": 14, "priceAmount": 348.0, "priceCurrency": "KR"}, {
        "hour": 15,
        "priceAmount": 394.0,
        "priceCurrency": "KR"
    }, {"hour": 16, "priceAmount": 626.0, "priceCurrency": "KR"}, {
        "hour": 17,
        "priceAmount": 701.0,
        "priceCurrency": "KR"
    }, {"hour": 18, "priceAmount": 667.0, "priceCurrency": "KR"}, {
        "hour": 19,
        "priceAmount": 380.0,
        "priceCurrency": "KR"
    }, {"hour": 20, "priceAmount": 323.0, "priceCurrency": "KR"}, {
        "hour": 21,
        "priceAmount": 304.0,
        "priceCurrency": "KR"
    }, {"hour": 22, "priceAmount": 295.0, "priceCurrency": "KR"}, {
        "hour": 23,
        "priceAmount": 283.0,
        "priceCurrency": "KR"
    }, {"hour": 0, "priceAmount": 205.0, "priceCurrency": "KR"}, {
        "hour": 1,
        "priceAmount": 199.0,
        "priceCurrency": "KR"
    }, {"hour": 2, "priceAmount": 198.0, "priceCurrency": "KR"}, {
        "hour": 3,
        "priceAmount": 198.0,
        "priceCurrency": "KR"
    }, {"hour": 4, "priceAmount": 201.0, "priceCurrency": "KR"}, {
        "hour": 5,
        "priceAmount": 205.0,
        "priceCurrency": "KR"
    }, {"hour": 6, "priceAmount": 212.0, "priceCurrency": "KR"}, {
        "hour": 7,
        "priceAmount": 243.0,
        "priceCurrency": "KR"
    }, {"hour": 8, "priceAmount": 313.0, "priceCurrency": "KR"}, {
        "hour": 9,
        "priceAmount": 313.0,
        "priceCurrency": "KR"
    }, {"hour": 10, "priceAmount": 313.0, "priceCurrency": "KR"}, {
        "hour": 11,
        "priceAmount": 354.0,
        "priceCurrency": "KR"
    }, {"hour": 12, "priceAmount": 342.0, "priceCurrency": "KR"}, {
        "hour": 13,
        "priceAmount": 336.0,
        "priceCurrency": "KR"
    }, {"hour": 14, "priceAmount": 343.0, "priceCurrency": "KR"}, {
        "hour": 15,
        "priceAmount": 389.0,
        "priceCurrency": "KR"
    }, {"hour": 16, "priceAmount": 618.0, "priceCurrency": "KR"}, {
        "hour": 17,
        "priceAmount": 692.0,
        "priceCurrency": "KR"
    }, {"hour": 18, "priceAmount": 659.0, "priceCurrency": "KR"}, {
        "hour": 19,
        "priceAmount": 375.0,
        "priceCurrency": "KR"
    }, {"hour": 20, "priceAmount": 319.0, "priceCurrency": "KR"}, {
        "hour": 21,
        "priceAmount": 300.0,
        "priceCurrency": "KR"
    }, {"hour": 22, "priceAmount": 291.0, "priceCurrency": "KR"}, {
        "hour": 23,
        "priceAmount": 279.0,
        "priceCurrency": "KR"
    }] */
