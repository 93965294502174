import { inject, observer } from 'mobx-react';
import moment from 'moment/moment';
import { computed } from 'mobx';
import React from 'react';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import { translate } from 'react-i18next';
import {
  Button, FormGroup, Form, FormControl, Radio, Panel,
} from 'react-bootstrap';
import { titleCase } from '../../actions/CommonAction';
import _ from 'lodash';
import ReactTable from 'react-table';

@translate()
@inject('controlRoomStore') @observer
export default class Logs extends React.Component {
    categories = {
      0: 'DEBUG',
      1: 'INFO',
      2: 'WARNING',
      3: 'ERROR',
      4: 'FATAL',
    };

    columns = [
      {
        id: 'time',
        Header: 'Tid',
        minWidth: 70,
        accessor: l => moment(l.timestamp).format('DD/MM HH:mm:ss:SSS'),
        sortable: false,
      },
      {
        id: 'priority',
        Header: 'Prioritet',
        minWidth: 50,
        accessor: l => this.categories[l.category],
         sortable: false,
      },
      {
        Header: 'Kilde',
        minWidth: 50,
        accessor: 'source',
         sortable: false,
      },
      {
        Header: 'Besked',
        minWidth: 110,
        accessor: 'message',
         sortable: false,
      },
      {
        Header: 'Detaljer',
        accessor: 'details',
        sortable: false,
      },
    ]

    constructor(...args) {
      super(...args);
      this.handleFromDayChange = this.handleFromDayChange.bind(this);
      this.handleToDayChange = this.handleToDayChange.bind(this);
      this.handleFilterChange = this.handleFilterChange.bind(this);
      this.handleLogsSearch = this.handleLogsSearch.bind(this);
      const today = moment();
      const tomorrow = moment(today).add(1, 'days');
      const yesterday = moment(today).add(-1, 'days');
      this.state = {
        showTable: false,
        filter: '1',
        tomorrow,
        yesterday,
        expanded: null,
      };
     

      this.handleFromDayChange(this.state.yesterday);
      // this.handleToDayChange(this.state.today)
    }
  


    expandRow(row) {
      this.setState((prevState) => {
        const expanded = { ...prevState.expanded };
        if (expanded[row.index]) {
          expanded[row.index] = !expanded[row.index];
        } else {
          expanded[row.index] = true;
        }

        return ({ expanded });
      });
    }

    handleFromDayChange(date) {
      this.props.controlRoomStore.setLogsFromDate(date);
    }

    handleToDayChange(date) {
      this.props.controlRoomStore.setLogsToDate(date);
    }

    handleFilterChange(event) {
      this.setState({
        filter: event.target.value,
        expanded: null,
      });
    }

    handleLogsSearch(event) {
      if (this.props.controlRoomStore.logsFromDate && this.props.controlRoomStore.logsToDate) {
        this.setState({
          showTable: true,
          expanded: null,
        });
        this.props.controlRoomStore.refreshLogs();
      }
    }

    @computed get logs() {
      const logs = _.filter(this.props.controlRoomStore.logs.slice(), log => this.state.filter <= log.category);

      return _.orderBy(logs, ['timestamp'], ['desc']);
    }

    render() {
      const { t } = this.props;
      const { showTable, expanded, searchInput } = this.state;
      const dateformat = 'DD/MM/YYYY';
      const MONTHS = [t('january'), t('february'), t('march'), t('april'), t('may'), t('june'), t('july'), t('august'), t('september'), t('october'), t('november'), t('december')];
      const WEEKDAYS_SHORT = [t('sunday-short'), t('monday-short'), t('tuesday-short'), t('wedensday-short'), t('thursday-short'), t('friday-short'), t('saturday-short')];
      const formatDate = date => moment(date).format(dateformat);

      return (
        <div>
          <Panel>
            <Panel.Body>
              <div>Hent log</div>
              <br />
              <div className="custom-input-day-picker marg-bot">
                <DayPickerInput
                  onDayChange={day => this.handleFromDayChange(day)}
                  placeholder={formatDate(this.state.yesterday)}
                  formatDate={formatDate}
                  dayPickerProps={{
                    months: MONTHS,
                    weekdaysShort: WEEKDAYS_SHORT,
                    firstDayOfWeek: 1,
                  }}
                />
              </div>
              <div className="custom-input-day-picker marg-bot">
                <DayPickerInput
                  onDayChange={day => this.handleToDayChange(day)}
                  placeholder={formatDate(this.state.today)}
                  formatDate={formatDate}
                  dayPickerProps={{
                    months: MONTHS,
                    weekdaysShort: WEEKDAYS_SHORT,
                    firstDayOfWeek: 1,
                  }}
                />
              </div>
              <Button onClick={this.handleLogsSearch}>
                {titleCase(t('logs-search'))}
              </Button>
              <FormGroup className={['padded-top']}>
                { Object.keys(this.categories).reverse().map(key => (
                  <Radio key={key} onChange={this.handleFilterChange} checked={this.state.filter === key} value={key} name="filterLevel" inline>{this.categories[key]}</Radio>
                ))}
              </FormGroup>
            </Panel.Body>
          </Panel>

          {showTable
            && (
            <ReactTable
              data={this.logs}
              columns={this.columns}
              expanded={expanded}
              showPaginationTop={true}
              showPaginationBottom={false}
              
              defaultPageSize={200}
              minRows={3}

              //pageSize={this.logs.length === 0 ? 2 : this.logs.length}
              className="-striped -highlight"
              getTdProps={(state, rowInfo, column, instance) => ({
                onClick: (e) => {
                  this.expandRow(rowInfo);
                },
              })}
              SubComponent={row => (
                <div style={{ padding: '20px' }}>
                  <em>
                    {row.original.details}
                  </em>
                  <br />
                  <br />
                </div>
              )}
              loadingText="Henter logs"
              loading={this.props.controlRoomStore.logsLoading}
            />
            )
          }
        </div>
      );
    }
}
