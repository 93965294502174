import React from 'react';
import { Redirect, Route, Switch } from 'react-router';

import ControlRoomPage from './controlroom/components/ControlRoomPage';
import PlantsPage from './components/plants/PlantsPage';
import LoginForm from './components/auth/LoginForm';
import SetPassword from './components/auth/SetPassword';

import requireAuth from './utils/requireAuth';
import requireAdmin from './utils/requireAdmin';
import ConfirmationScreen from './components/auth/ConfirmationScreen';

const Routes = () => (
  <Switch>
    <Route exact path="/" component={LoginForm} />
    <Route path="/login" component={LoginForm} />
    <Route path="/plants" component={requireAuth(PlantsPage)} />
    <Route path="/control-room" component={requireAdmin(ControlRoomPage)} />
    <Route path="/setPwd/:uuid" component={SetPassword} />
    <Route path="/resetPwd/:uuid" component={SetPassword} />
    <Route path="/confirm/:uuid" component={ConfirmationScreen} />
    <Route path="*">
      <Redirect to="/login" />
    </Route>
  </Switch>
);

export default Routes;
