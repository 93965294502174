// @flow
import React from 'react';
import PropTypes from 'prop-types';
import {
  Form, FormGroup, FormControl, Col, ControlLabel, Modal, Button, Alert, Checkbox,
} from 'react-bootstrap';
import { computed } from 'mobx';
import Select from 'react-select';
import _ from 'lodash';
import CHPCOM from '../classes/CHPCOM';
import { toast } from 'react-toastify';
import { inject, observer } from 'mobx-react';
import { translate } from 'react-i18next';

@translate()
@inject('controlRoomStore') @observer
class CHPCOMFormModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      errors: [],
      chpcom: new CHPCOM(),
    };
  }

  @computed get plants() {
    return _.map(this.props.controlRoomStore.plants, plant => ({ code: plant.code, name: plant.name }));
  }

  onConfirm = (event) => {
    event.preventDefault();
    const chpcom = new CHPCOM().fromJson(this.state.chpcom);

    if (chpcom.isValid) {
      this.saveCHPCOM(chpcom);
    } else {
      this.setState({
        errors: chpcom.errors,
      });
    }
  }

  saveCHPCOM = (chpcom) => {
    const { t } = this.props;

    this.props.controlRoomStore.saveCHPCOM(chpcom)
      .then((res) => {
        toast(t('chpcom-saved'));
        this.props.onClose();

        this.setState({
          chpcom: new CHPCOM(),
        });

        return res;
      })
      .catch((err) => {
        console.log(err);
        toast(t('chpcom-not-saved'));

        this.setState({
          errors: [{ msg: err.message }],
        });
        return err;
      });
  }

  onShow = () => {
    const { chpcom } = this.props;

    this.setState({
      errors: [],
      chpcom,
    });
  }

  render() {
    const { t } = this.props;

    const form = (
      <Form horizontal>
        <FormGroup controlId="plantSelectGroup">
          <Col className="control-label" sm={3}>
            {t('plant')}
          </Col>
          <Col sm={8}>
            <Select
              value={this.plants.filter(p => p.code === this.state.chpcom.plantCode)}
              options={this.plants}
              getOptionLabel={r => r.name}
              getOptionValue={r => r.code}
              clearable={false}
              onChange={this.handleSelectedPlantChange}
              ref={(ref) => {
                this.select = ref;
              }}
            />
          </Col>
        </FormGroup>

        <FormGroup controlId="cdgcIdGroup">
          <Col componentClass={ControlLabel} sm={3}>CDGc Id</Col>
          <Col sm={8}>
            <FormControl
              name="cdgcId"
              type="text"
              value={this.state.chpcom.cdgcId}
              onChange={this.handleChange}
            />
          </Col>
        </FormGroup>

        <FormGroup controlId="deviceIdGroup">
          <Col componentClass={ControlLabel} sm={3}>{t('device')}</Col>
          <Col sm={8}>
            <FormControl
              name="deviceId"
              type="text"
              value={this.state.chpcom.deviceId}
              onChange={this.handleChange}
            />
          </Col>
        </FormGroup>

        <FormGroup controlId="productionPlanEnabledGroup">
          <Col componentClass={ControlLabel} sm={3}>{t('production-plan')}</Col>
          <Col sm={8}>
            <Checkbox
              checked={this.state.chpcom.productionPlanEnabled}
              name="productionPlanEnabled"
              onChange={this.handleChange}
            />
          </Col>
        </FormGroup>

        <FormGroup controlId="frequencyPlanUpEnabledGroup">
          <Col componentClass={ControlLabel} sm={3}>{t('frequency-plan-up')}</Col>
          <Col sm={8}>
            <Checkbox
              checked={this.state.chpcom.frequencyPlanUpEnabled}
              name="frequencyPlanUpEnabled"
              onChange={this.handleChange}
            />
          </Col>
        </FormGroup>

        <FormGroup controlId="frequencyPlanDownEnabledGroup">
          <Col componentClass={ControlLabel} sm={3}>{t('frequency-plan-down')}</Col>
          <Col sm={8}>
            <Checkbox
              checked={this.state.chpcom.frequencyPlanDownEnabled}
              name="frequencyPlanDownEnabled"
              onChange={this.handleChange}
            />
          </Col>
        </FormGroup>

         <FormGroup controlId="onlineValueEnabledGroup">
          <Col componentClass={ControlLabel} sm={3}>{t('online-value')}</Col>
          <Col sm={8}>
            <Checkbox
              checked={this.state.chpcom.onlineValueEnabled}
              name="onlineValueEnabled"
              onChange={this.handleChange}
            />
          </Col>
        </FormGroup>
      </Form>
    );

    const errors = (
      <div>
        {this.state.errors.map((error, index) => (
          <Alert bsStyle="danger" key={index}>{error.msg}</Alert>
        ))}
      </div>
    );

    return (
      <Modal show={this.props.open} onHide={this.onClose} onShow={this.onShow}>
        <Modal.Header>{t('edit-chpcom')}</Modal.Header>
        <Modal.Body>
          {errors}
          {form}
        </Modal.Body>
        <Modal.Footer>
          <Button bsStyle="primary" onClick={this.onConfirm}>Gem</Button>
          <Button onClick={this.onClose}>{t('close')}</Button>
        </Modal.Footer>
      </Modal>
    );
  }

  handleChange = (event) => {
    const { target } = event;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const field = target.name;
    const { chpcom } = this.state;
    chpcom[field] = value;
    this.setState({ chpcom });
  }

  onClose = () => {
    this.setState({ chpcom: new CHPCOM() });
    this.props.onClose();
  }

  handleSelectedPlantChange = (plant) => {
    const { chpcom } = this.state;
    chpcom.plantCode = plant.code;
    chpcom.plantName = plant.name;
    this.setState({ chpcom });
  }
}

CHPCOMFormModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  chpcom: PropTypes.instanceOf(CHPCOM).isRequired,
};

export default CHPCOMFormModal;
