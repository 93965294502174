import {action, computed, observable, reaction,} from 'mobx';
import {getGraphData, getHeatData, getPlantData, updatePlantData} from '../services/MKPlusService';
import Plantdata from "../classes/Plantdata";
import HeatForecastRow from "../classes/HeatForecastRow";
import {now} from "mobx-utils";

const GRAPH_SERIES = [{
  name: 'biogas',
  label: 'Biogas',
  color: 'red',
},
  {
    name: 'biomass',
    label: 'Biomass',
    color: 'green',
  },
  {
    name: 'boiler_biomass',
    label: 'Boiler biomass',
    color: 'blue',
  },
  {
    name: 'boiler_electric',
    label: 'Boiler electric',
    color: 'cyan',
  },
  {
    name: 'boiler_naturalgas',
    label: 'Boiler naturalgas',
    color: 'magenta',
  },
  {
    name: 'consumption',
    label: 'Consumption',
    color: 'fuchsia',
  },
  {
    name: 'heatpump',
    label: 'Heatpump',
    color: 'brown',
  },
  {
    name: 'motor_biogas',
    label: 'Motor biogas',
    color: 'silver',
  },
  {
    name: 'motor_naturalgas',
    label: 'Motor naturalgas',
    color: 'purple',
  },
  {
    name: 'solar',
    label: 'Solar',
    color: 'darkviolet',
  },
  {
    name: 'storage',
    label: 'Storage',
    color: 'darkviolet',
  },
];

export default class MKPlusStore {
  @observable graphData = [];

  @observable selectedGraphSeries =
    GRAPH_SERIES.map(({name}) => name);

  @observable heatData = [];

  nullGraphs = [];

  plant = new Plantdata();

  graphSeries = GRAPH_SERIES;

  constructor(uiStore) {
    this.uiStore = uiStore;
    this._setupRecurring();

    reaction(
      () => [this.uiStore.selectedItem],
      ([selectedItem]) => {
        if (selectedItem.mkplusEnabled) {
          getGraphData(selectedItem.code)
            .then(this._setGraphData)
            .catch((err) => {
                this._setGraphData([]);
              }
            );
          getPlantData(selectedItem.code)
            .then(this._setPlantData)
            .catch((err) => {
                this._setPlantData(undefined);
              }
            );
          getHeatData(selectedItem.code)
            .then(this._setHeatData)
            .catch((err) => {
                this._setHeatData([]);
              }
            );
        }
      },
    );
  }

  @action
  reset = () => {
    this.graphData = [];
    this.heatData = [];
    this.nullGraphs = [];
    this.plant = undefined;
  };

  @action
  postPlantData(plantData) {
    const plantCode = this.uiStore.selectedItem.code;
    return updatePlantData(plantCode, plantData.toJson());
  };

  @action
  refreshGraphData() {
    const plantCode = this.uiStore.selectedItem.code;
    if (this.uiStore.selectedItem.mkplusEnabled) {
      setTimeout(() => {
        getGraphData(plantCode)
          .then(this._setGraphData)
          .catch((err) => {
              this._setPlantData([]);
            }
          );

      }, 4500);
    }
  }

  @action.bound
  _setPlantData(plant) {
    this.plant = new Plantdata().fromJson(plant);
  }

  @action.bound
  _setHeatData(heatData) {
    this.heatData.replace(heatData.map(row => new HeatForecastRow().fromJson(row)));
  }

  @action.bound
  _setGraphData(data) {
    const nulls = [];
    if (data.length > 0) {
      // eslint-disable-next-line no-restricted-syntax
      for (var propName in data[0]) {
        if (data[0][propName] === null) {
          nulls.push(propName);
        }
      }
    }
    this.nullGraphs = nulls;
    this.graphData.replace(data);
  }

  @action
  addSelectedGraphSerie(name) {
    this.selectedGraphSeries.push(name);
  }

  @action
  removeSelectedGraphSerie(name) {
    this.selectedGraphSeries = this.selectedGraphSeries.filter(selectedSerie => selectedSerie !== name);
  }

  @computed
  get visibleGraphSeries() {
    return this.graphSeries.filter(({ name }) => !this.nullGraphs.includes(name)).filter(({name}) => this.selectedGraphSeries.indexOf(name) > -1);
  }

  _setupRecurring() {
    const refreshRateInMillis = 120000;
    reaction(
      () => [now(refreshRateInMillis), this.uiStore.selectedItem],
      ([timestamp, selectedItem]) => {
        if (selectedItem.mkplusEnabled) {
          getGraphData(selectedItem.code)
            .then(this._setGraphData)
            .catch((err) => {
                this._setGraphData([]);
              }
            );
        }
      },
    );
  }
}
