import React from 'react';
import { inject, observer } from 'mobx-react';
import {
  Checkbox, Nav, NavItem, Tab, Row, Col,
} from 'react-bootstrap';
import { translate } from 'react-i18next';

@translate()
@inject('uiStore', 'planStore') @observer
class PlanSelector extends React.Component {
  constructor(props) {
    super(props);
    this.handlePlanSelect = this.handlePlanSelect.bind(this);
    this.handlePlanVisibleChange = this.handlePlanVisibleChange.bind(this);
  }

  mapStateToProps() {
    const allPlans = this.props.planStore.plans.toJS();
    let hiddenPlans = [];
    if (this.props.uiStore.selectedPlant !== 'undefined' && !this.props.uiStore.selectedPlant.intradayEnabled) {
      const hidden = allPlans.reduce((res, plan, index) => {
        if (plan.nameKey.includes('intraday')) {
          res.push(index);
        }
        return res;
      }, []);
      hiddenPlans = hiddenPlans.concat(hidden);
    }
    if (this.props.uiStore.selectedPlant !== 'undefined' && !this.props.uiStore.selectedPlant.priceDependentEnabled) {
      const hidden = allPlans.reduce((res, plan, index) => {
        if (plan.nameKey.includes('price-dependent-plan')) {
          res.push(index);
        }
        return res;
      }, []);
      hiddenPlans = hiddenPlans.concat(hidden);
    }
    return {
      planStore: this.props.planStore,
      uiStore: this.props.uiStore,
      plans: allPlans,
      hiddenPlans,
    };
  }

  render() {
    const myProps = this.mapStateToProps();
    const { t } = this.props;

    return (
      <div className="plans">
        <Row>
          <Col
            xs={2}
          >
            {myProps.plans.map((plan, index) => (
              <Checkbox
                style={{ display: myProps.hiddenPlans.includes(index) ? 'none' : '' }}
                checked={myProps.planStore.selectedPlanIds.indexOf(plan.nameKey) > -1}
                value={plan.nameKey}
                onChange={this.handlePlanVisibleChange}
                key={index}
              />
            ))}
          </Col>
          <Col
            xs={10}
          >
            <Tab.Container
              id="plan-tabs"
              onSelect={this.handlePlanSelect}
            >
              <Nav bsStyle="pills" stacked>
                {myProps.plans.map((plan, index) => (
                  <NavItem
                    style={{ display: myProps.hiddenPlans.includes(index) ? 'none' : '' }}
                    eventKey={index}
                    key={index}
                    active={index === this.props.uiStore.activePlanIndex}
                    className={plan.readonly ? 'readonly' : null}
                  >
                    <i style={{ background: plan.color }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</i>
                    {' '}
                    <span style={{ color: PlanSelector.getPlanFontColor(myProps.planStore.getStatusListOfPlan(plan)[0]) }}>{t(plan.nameKey)}</span>
                  </NavItem>
                ))}
              </Nav>
            </Tab.Container>
          </Col>
        </Row>
      </div>
    );
  }

  handlePlanSelect(index) {
    const myProps = this.mapStateToProps();
    myProps.planStore.setActivePlanIndex(index);
  }

  static getPlanFontColor(status) {
    switch (status) {
      case -1:
        return 'black';
      case 0:
        return 'red';
      case 1:
        return 'yellow';
      case 3:
        return 'green';
      case 4:
        return 'green';
      case 5:
        return 'green';
      case 6:
        return 'green';
      case 7:
        return 'green';
      default:
        return 'black';
    }
  }

  handlePlanVisibleChange(event) {
    const myProps = this.mapStateToProps();
    if (event.target.checked) {
      myProps.planStore.addSelectedPlanId(event.target.value);
    } else {
      myProps.planStore.removeSelectedPlanId(event.target.value);
    }
  }
}

export default PlanSelector;
