// @flow
import { action } from 'mobx';
import { now } from 'mobx-utils';
import { getMessages } from '../services/MessageService';
import { API_DATE_FORMAT } from '../actions/CommonAction';
import { warn } from '../services/logService';
import { promisedComputed } from 'computed-async-mobx';

export class MessageStore {
  infrequentPoll = 60000;

  uiStore: UiStore;

  authStore: AuthStore;

  constructor(uiStore, authStore) {
    this.uiStore = uiStore;
    this.authStore = authStore;
  }

  @action
  reset = () => {
  }

  /**
   * Contains
   * @type {PromisedComputedValue<Array<Message>>}
   */
  messages = promisedComputed([], () => {
    now(this.infrequentPoll);
    const date = this.uiStore.selectedDate;
    return getMessages(date.format(API_DATE_FORMAT)).catch((failure) => {
      warn(failure);
      return [];
    });
  });
}
