import React from 'react';
import { inject, observer } from 'mobx-react';
import { FormGroup } from 'react-bootstrap';
import { computed } from 'mobx';

import Select from 'react-select';

@inject('uiStore', 'plantStore', 'planStore') @observer
class PlantsDropdown extends React.Component {
    @computed get plants() {
    return this.props.plantStore.plants;
  }

    @computed get selectedPlant() {
      return this.props.uiStore.selectedPlant;
    }

    render() {
      return (
        <FormGroup className="plants-dropdown">
          <Select
            value={this.plants.filter(p => p.code === this.selectedPlant.code)}
            options={this.plants.toJS()}
            getOptionLabel={r => r.name}
            getOptionValue={r => r.code}
            clearable={false}
            onChange={this.handleSelectedPlantChange}
          />
        </FormGroup>
      );
    }

    handleSelectedPlantChange = (plant) => {
      if (this.props.planStore.isPopoverShown || !plant) return;

      this.props.uiStore.setSelectedPlant(plant);
    }
}

export default PlantsDropdown;
