import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { Accordion, AccordionItem } from 'react-light-accordion';
import { computed } from 'mobx';
import { Table } from 'react-bootstrap';
import moment from 'moment';
import { convertMinutesToDate } from '../../actions/CommonAction';
import { ManualPlanDown, ManualPlanUp, ProductionPlan } from '../classes/plans';

@inject('controlRoomStore') @observer
export default class Plans extends React.Component {
    @computed get productionPlan() {
    const plan = this.props.controlRoomStore.graphPlans.productionPlan || new ProductionPlan();
    const sorted = plan.entries.sort((a, b) => a.minute - b.minute);
    if (sorted.length > 2) {
      const secondSecondLast = sorted[sorted.length - 3];
      const secondLast = sorted[sorted.length - 2];
      if (secondLast.minute === 1435 && secondLast.amountPowerOutputMegawatt === secondSecondLast.amountPowerOutputMegawatt) {
        sorted.splice(sorted.length - 2, 1);
      }
    }

    return {
      approvedTime: plan.approvedTime,
      entries: sorted,
    };
  }

    /* FIXME invert as in inmelding so that up is always positive and down is always negative */
    @computed get manualPlanUp() {
      const plan = this.props.controlRoomStore.graphPlans.manualPlanUp || new ManualPlanUp();
      const sorted = plan.entries.sort((a, b) => a.minute - b.minute);

      return {
        approvedTime: plan.approvedTime,
        entries: sorted,
      };
    }

    @computed get manualPlanDown() {
      const plan = this.props.controlRoomStore.graphPlans.manualPlanDown || new ManualPlanDown();
      const sorted = plan.entries.sort((a, b) => a.minute - b.minute);

      return {
        approvedTime: plan.approvedTime,
        entries: sorted,
      };
    }

    format(approvedTime) {
      return approvedTime && moment(approvedTime).format('DD/MM - YYYY [kl.] HH:mm');
    }

    // FIXME why not just use the plan date
    render() {
      return (
        <Accordion atomic={false}>

          <AccordionItem title={`Produktionsplan: ${this.format(this.productionPlan.approvedTime)}`}>
            <Table striped bordered condensed hover>
              <thead>
                <tr>
                  <th>Tid</th>
                  <th>Produktion</th>
                </tr>
              </thead>
              <tbody>
                {this.productionPlan.entries.map((point, index) => (
                  <tr key={index}>
                    <td>{convertMinutesToDate(point.minute, this.props.controlRoomStore.uiStore.selectedDate)}</td>
                    <td>{point.amountPowerOutputMegawatt === 0 || point.amountPowerOutputMegawatt ? `${point.amountPowerOutputMegawatt.toFixed(2)}MW` : 'n-a'}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </AccordionItem>

          <AccordionItem title={`Manual op plan: ${this.format(this.manualPlanUp.approvedTime)}`}>
            <Table striped bordered condensed hover>
              <thead>
                <tr>
                  <th>Tid</th>
                  <th>Produktion</th>
                </tr>
              </thead>
              <tbody>
                {this.manualPlanUp.entries.map((point, index) => (
                  <tr key={index}>
                    <td>{convertMinutesToDate(point.minute, this.props.controlRoomStore.uiStore.selectedDate)}</td>
                    <td>{point.amountPowerOutputMegawatt === 0 || point.amountPowerOutputMegawatt ? `${point.amountPowerOutputMegawatt.toFixed(2)}MW` : 'n-a'}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </AccordionItem>

          <AccordionItem title={`Manual ned plan: ${this.format(this.manualPlanDown.approvedTime)}`}>
            <Table striped bordered condensed hover>
              <thead>
                <tr>
                  <th>Tid</th>
                  <th>Produktion</th>
                </tr>
              </thead>
              <tbody>
                {this.manualPlanDown.entries.map((point, index) => (
                  <tr key={index}>
                    <td>{convertMinutesToDate(point.minute, this.props.controlRoomStore.uiStore.selectedDate)}</td>
                    <td>{point.amountPowerOutputMegawatt === 0 || point.amountPowerOutputMegawatt ? `${point.amountPowerOutputMegawatt.toFixed(2)}MW` : 'n-a'}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </AccordionItem>

        </Accordion>
      );
    }
}
