// @flow
import React from 'react';
import {
  Nav, Navbar, NavItem, Tab, Tabs, Well,
} from 'react-bootstrap';
import { toast } from 'react-toastify';
import { translate } from 'react-i18next';
import { inject, observer } from 'mobx-react';
import { logout } from '../../actions/AuthAction';

import MyDatePicker from '../../components/common/MyDatePicker';
import PriceAreaAndPlantSelector from './PriceAreaAndPlantSelector';
import PlanningSummary from './PlanningSummary';
import PlanningDetails from './PlanningDetails';
import Logs from './Logs';
import PlantControl from './PlantControl';
import Plants from './Plants';
import { computed } from 'mobx';
import Alarm from './Alarm';
import Users from './Users';
import SearchBar from './SearchBar';
import AlarmmFRR from './alarmmFRR';

@translate()
@inject('authStore', 'controlRoomStore') @observer
export default class ControlRoomPage extends React.Component {
    @computed get alarm() {
    return this.props.controlRoomStore.alarm;
  }

  @computed get mfrrAlarms() {
      return this.props.controlRoomStore.mfrrAlarms;
    }

  render() {
    const { authStore, t, controlRoomStore } = this.props;

    if (!authStore.isAuthenticated) {
      this.handleLogout();
    }

    const indmeldingDisabled = controlRoomStore.selectedItem.isPriceArea;

    return (
      <div className="plants-page">
        <Navbar collapseOnSelect fluid>
          <Navbar.Header>
            <Navbar.Brand>
              <a href="/">
                <img src="img/volue_logo.png" className="logo" alt="logo" />
                <span>KONTROLRUM</span>
                <span />
              </a>
            </Navbar.Brand>
            <Navbar.Toggle />
          </Navbar.Header>
          <Navbar.Collapse>
            <Nav pullRight>
              <NavItem eventKey={1} href="#" onSelect={this.gotoIndmelding} disabled={indmeldingDisabled}>Gå til Indmelding</NavItem>
              <NavItem eventKey={2} href="#">
                { t('user') }
:
                {' '}
                {this.props.authStore.username}
              </NavItem>
              <NavItem eventKey={3} href="#" onSelect={this.handleLogout}>{ t('sign-out') }</NavItem>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
        <div className="plants-page-table">
          <div className="plants-page-row">
            <div
              className="side-bar"
              style={{ minWidth: '300px' }}
            >
              <Well bsSize="small" className="full-height">
                <MyDatePicker />
                <SearchBar />
                <PriceAreaAndPlantSelector />
              </Well>
            </div>
            <div className="plant-container">
              { this.alarm ? <Alarm /> : '' }
              { this.mfrrAlarms.map(a => <AlarmmFRR key={a.key} deleteToken={a.key} alarmMessage={a.message} handled={a.handled} />)}
              <Tabs bsStyle="pills" id="pageNavigationTabs" defaultActiveKey={1}>
                <Tab eventKey={1} title={t('planning')}>
                  <PlanningSummary />
                  <PlanningDetails />
                </Tab>
                <Tab eventKey={2} title={t('plants')}>
                  <Plants />
                </Tab>
                <Tab eventKey={3} title={t('plant-control')}>
                  <PlantControl />
                </Tab>
                <Tab eventKey={4} title={t('log')}>
                  <Logs />
                </Tab>
                {authStore.isUserAdmin
                    && (
                    <Tab eventKey={5} title={t('users')}>
                      <Users />
                    </Tab>
                    )
                  }
              </Tabs>
            </div>
          </div>
        </div>
      </div>
    );
  }

    handleLogout = () => {
      logout(this.props.authStore);
      toast(this.props.t('login-required'));
      this.props.history.push('/login');
    };

    gotoIndmelding = () => {
      this.props.history.push('/plants');
    };
}
