import React from 'react';
import TextFieldGroup from '../common/TextFieldGroup';
import { forgottenPassword, login } from '../../actions/AuthAction';
import validator from 'validator';
import isEmpty from 'lodash/isEmpty';
import { inject, observer } from 'mobx-react';
import {
  Grid,
  Row,
  Col,
  Jumbotron,
  Button,
  Alert,
  FormGroup,
  DropdownButton,
  MenuItem,
  ButtonGroup,
} from 'react-bootstrap';
import { toast } from 'react-toastify';
import { translate } from 'react-i18next';

function validateInput(data, props) {
  const { t } = props;
  const errors = {};

  if (validator.isEmpty(data.identifier)) {
    errors.identifier = t('field-required');
  }

  if (validator.isEmpty(data.password)) {
    errors.password = t('field-required');
  }

  return {
    errors,
    isValid: isEmpty(errors),
  };
}

const languages = [
  {
    name: 'Dansk',
    short: 'da',
  },
  {
    name: 'Svensk',
    short: 'sv',
  },
  {
    name: 'Engelsk',
    short: 'en',
  },
  {
    name: 'Norsk',
    short: 'no',
  },
];

@translate()
@inject('authStore', 'plantStore', 'planStore')
@observer
export default class LoginForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      identifier: '',
      password: '',
      errors: {},
      isLoading: false,
      language: languages[0],
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  componentDidMount() {
    if (this.props.authStore.isAuthenticated) {
      this.props.history.push(this.props.authStore.homePage);
    }
  }

  isValid() {
    const { errors, isValid } = validateInput(this.state, this.props);

    if (!isValid) {
      this.setState({ errors });
    }

    return isValid;
  }

  onSubmit(e) {
    e.preventDefault();
    if (this.isValid()) {
      const { t } = this.props;

      this.setState({ errors: {}, isLoading: true });
      login(this.props.authStore, this.state)
        .then((res) => {
          this.props.history.push(this.props.authStore.homePage);
          toast.success(t('now-logged-in'));
        })
        .catch((err) => {
          // Make sure that errors are put in a form object, making the later
          // rendering easier (the same as client validation).
          toast.error(t('wrong-credentials'));
          this.setState({ errors: {}, isLoading: false });
        });
    }
  }

  onForgottenPassword() {
    const ok = 'If a valid user e-mail was present a password reset e-mail is sent. Please also check your spam folder.';
    const { identifier } = this.state;
    forgottenPassword(identifier).then(() => toast(ok, { type: 'success', autoClose: 4800 }));
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

    handleSelectedLanguage = (language) => {
      this.setState({ language });
    };

    render() {
      const {
        errors, identifier, password, isLoading, language,
      } = this.state;

      return (
        <div className="login-form">
          <Grid>
            <Row>
              <Col lg={4} lgOffset={4} md={6} mdOffset={3}>
                <div>
                  By logging on to Planner you accept that we keep cookies for technical purposes.
                  {' '}
                  <br />
                  Planner will not use cookies for any other purpose
                </div>
                <Jumbotron>
                  <h1>Volue Market Services</h1>
                  <p>
                    Planner
                  </p>
                </Jumbotron>
                <form onSubmit={this.onSubmit}>

                  {errors.form && <Alert bsStyle="danger">{errors.form}</Alert>}

                  <TextFieldGroup
                    field="identifier"
                    label="Username / Email"
                    value={identifier}
                    error={errors.identifier}
                    onChange={this.onChange}
                  />

                  <TextFieldGroup
                    field="password"
                    label="Password"
                    value={password}
                    error={errors.password}
                    onChange={this.onChange}
                    type="password"
                  />

                  <FormGroup>
                    <label>Sprog</label>
                    <ButtonGroup className="pull-right">
                      <DropdownButton key={1} title={language.name} id="dropdown-language">
                        {languages.map((lang, i) => (
                          <MenuItem
                            key={i}
                            eventKey={lang.short}
                            onSelect={() => this.handleSelectedLanguage(lang)}
                            active={language.short === lang.short}
                          >
                            {lang.name}
                          </MenuItem>
                        ))}
                      </DropdownButton>
                    </ButtonGroup>
                  </FormGroup>
                  <br />
                  <FormGroup style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Button type="submit" bsStyle="primary" disabled={isLoading}>Sign in</Button>
                    <Button onClick={() => this.onForgottenPassword()} disabled={isLoading}>Forgotten password</Button>
                  </FormGroup>
                  <div>
                    {' '}

                    Problems logging in? Contact
                    {' '}
                    <a href="mailto:247.ms@volue.com" target="_top">247.ms@volue.com</a>
                  </div>
                </form>
              </Col>
            </Row>
          </Grid>
        </div>
      );
    }
}
