// @flow
import { computed } from 'mobx';

class Contact {
    uuid: string;

    priority: number;

    fullName: string;

    countryCode: string;

    phoneNumber: string;

    email: string;

    receiverType: string;

    primaryContact: boolean;

    constructor(args?: { uuid: string, email: string, priority: number, fullName: string, countryCode: string, phoneNumber: string, email: string, receiverType: string, primaryContact: boolean }) {
      if (args) {
        this.uuid = args.uuid;
        this.priority = args.priority;
        this.fullName = args.fullName;
        this.countryCode = args.countryCode;
        this.phoneNumber = args.phoneNumber;
        this.email = args.email;
        this.receiverType = args.receiverType;
        this.primaryContact = args.primaryContact;
      }
    }

    @computed
    get asJson(): { priority: number, fullName: string, countryCode: string, phone: string, email: string, type: string, primary: boolean } {
      return {
        uuid: this.uuid,
        priority: Number(this.priority),
        fullName: this.fullName,
        countryCode: this.countryCode,
        phoneNumber: this.phoneNumber,
        email: this.email,
        receiverType: this.receiverType,
        primaryContact: this.primaryContact,
      };
    }

    @computed
    get isValid() {
      // TODO how many fields are required?
      return true;
    }
}

export default Contact;
