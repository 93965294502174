import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { computed } from 'mobx';
import { Table } from 'react-bootstrap';
import moment from 'moment';
import _ from 'lodash';

@inject('controlRoomStore') @observer
export default class ApprovedPlans extends React.Component {
  @computed
  get approvedPlans() {
    return _.orderBy(this.props.controlRoomStore.approvedPlans.slice(), 'name', 'asc');
  }

  getApprovedTime(plans, nameKey) {
    const plan = plans.find(p => p.nameKey === nameKey);

    if (plan) return moment(plan.approvedTime).format();
    return '';
  }

  formatDate(date) {
    return date && moment(date).format('DD/MM - YYYY [kl.] HH:mm');
  }

  render() {
    return (
      <div>
        <Table striped bordered>
          <thead>
            <tr>
              <th>Værk</th>
              <th>pplan</th>
              <th>mplan_op</th>
              <th>mplan_ned</th>
              <th>fplan_op</th>
              <th>fplan_ned</th>
            </tr>
          </thead>
          <tbody>
            {
              this.approvedPlans.map(plant => (
                <tr key={plant.name}>
                  <td>{plant.name}</td>
                  <td>{this.formatDate(plant.pPlan)}</td>
                  <td>{this.formatDate(plant.mPlanUp)}</td>
                  <td>{this.formatDate(plant.mPlanDown)}</td>
                  <td>{this.formatDate(plant.fPlanUp)}</td>
                  <td>{this.formatDate(plant.fPlanDown)}</td>
                </tr>
              ))
            }
          </tbody>
        </Table>
      </div>
    );
  }
}
