// @flow

import { computed } from 'mobx';

class User {
    // Id is just name
    id: string;

    name: string = '';

    status: number;

    userType: number = 1;

    roles: Array = [];

    isNew: boolean;

    fromJson(json) {
      for (const prop in json) if (json.hasOwnProperty(prop)) this[prop] = json[prop];

      if (this.id === undefined) this.id = `${this.name}`;

      return this;
    }

    fromRolesAndPlants(roles, plants) {
      const mRoles = roles.slice();

      if (mRoles.includes('indmelder')) {
        plants.forEach((code) => {
          mRoles.push(`p:${code}`);
        });
      }

      this.roles = mRoles;

      return this;
    }

    getRoles() {
      return this.roles.filter(r => !r.startsWith('p:'));
    }

    getPlants() {
      return this.roles.filter(r => r.startsWith('p:'))
        .map(r => parseInt(r.replace('p:', ''), 10));
    }

    @computed get isValid() {
      return this.errors.length === 0;
    }

    @computed get errors() {
      const errors = [];
      if (this.name === '') { errors.push({ msg: 'username-required' }); }
      return errors;
    }
}

export default User;
