import React from 'react';
import {Button, ButtonToolbar, Col, Form, FormControl, FormGroup, Row} from 'react-bootstrap';
import {translate} from 'react-i18next';
import {inject, observer} from 'mobx-react';
import moment from 'moment';
import {createMuiTheme, ThemeProvider} from "@material-ui/core/styles";
import MomentUtils from '@date-io/moment';
import {KeyboardDateTimePicker, MuiPickersUtilsProvider,} from '@material-ui/pickers';
import Plantdata from "../../classes/Plantdata";
import {toast} from "react-toastify";


@translate()
@inject('uiStore', 'plantStore', 'mkPlusStore') @observer
export default class PlantData extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      plant: new Plantdata().fromJson(this.props.plant),
      submitDisabled: true,
      prevInit: {},
    };
  }

  onShow = () => {
    const { plant } = this.props;

    this.setState({
      plant,
    });
  };

  onDismiss = () => {
    const { t, onDismiss } = this.props;
    {() => onDismiss;}; // Call this to hide modal
    this.setState({ plant: new Plantdata()});
  }

    validate = () => {
    // Price is always an int
      this.setState(
        prevState => ({
          submitDisabled: (moment(prevState.plant.biogas_stop_time).diff(moment(prevState.plant.biogas_start_time), 'minutes') < 180)
            || (moment(prevState.plant.biomass_stop_time).diff(moment(prevState.plant.biomass_start_time), 'minutes')) < 180,
        }),
      );
    };

  mapStateToProps() {
    return {
      plantStore: this.props.plantStore,
      shouldShowPlantDataModal: this.props.plantStore.shouldShowPlantDataModal,
      mkPlusStore: this.props.mkPlusStore.plant,
    };
  }

    handleChange = (e) => {
      const { target } = e;
      const value = target.value;
      if (isNaN(value)) { return;} // All fields are for numbers
      const field = target.name;
      const { plant } = this.state;
      plant[field] = value;
      this.setState({ plant }, () => {
        this.validate();
      });
    };

  handleDateChange = (name, date) => {
    const { plant } = this.state;
    plant[name] = moment(date).format('YYYY-MM-DDTHH:mm:ss');
    this.setState({plant: plant}, () => { this.validate();});
  }

  savePlantData = (plant) => {
    return this.props.mkPlusStore.postPlantData(plant);
  }

onSubmit = (e) => {
  e.preventDefault();

  const {
    plant,
  } = this.state;

  this.savePlantData(plant)
    .then((res) => {
        toast('Værksdataen blev gemt');
        this.props.onDismiss();
        this.props.mkPlusStore._setPlantData(res.data);
      }
    ).catch((err) => {
      console.log(err);
      toast('Værksdataen kunne ikke gemmes, prøv igen');
    }
  );

  this.setState({
    submitDisabled: true,
  });
}

render() {
  const { t, onDismiss } = this.props;
  const {
    plant, submitDisabled,
  } = this.state;

  // Styling for new datetimepicker
  const theme = createMuiTheme({
    overrides: {
      MuiFormControl: {
        root: {
          border: '1px solid #ccc',
          borderRadius: '4px',
          backgroundColor: '#fff'
        },
        marginNormal: {
          marginTop: '0px',
          marginBottom: '0px'
        }
      },
      MuiInput: {
        root: {
          borderRadius: 0,
          backgroundColor: "#fff",
          border: '1px solid pink',
          fontSize: '14px',
          padding: '10px 2px',
          height: '34px',
          width: 'calc(100% - 24px)',
        },
      },
      MuiInputBase: {
        root: {
          fontSize: '14px',
        },
        input: {
          borderBottomLeftRadius: '4px',
          borderTopLeftRadius: '4px',
          color: '#555555',
          height: '0.1em',
          backgroundColor: '#fff'
        },
      },
      MuiFilledInput: {
        input: {
          padding: '16px 12px'
        },
        root: {
          borderBottomLeftRadius: '4px',
          borderBottomRightRadius: '4px',
          transition: 'none',
          Mui: {
            '&$focused': 'none'
          },
          backgroundColor: '#fff',
        },
        adornedEnd: {
          paddingRight: '2px'
        }
      },
      MuiIconButton: {
        root: {
          transition: 'none'
        }
      },
      MuiTypography: {
        body1: {
          fontSize: '14px'
        },
        caption: {
          fontSize: '14px'
        },
        body2: {
          fontSize: '11px'
        }
      },
      MuiPickersDay: {
        daySelected: {
          backgroundColor: '#fdfdfd',
          color: '#000000'
        }
      },
      MuiPickersClockPointer: {
        pointer: {
          backgroundColor: '#337ab7'
        },
        noPoint: {
          backgroundColor: '#337ab7'
        },
        thumb: {
          border: '14px solid #337ab7',
          backgroundColor: '#337ab7'
        }
      },
      MuiPickersClock: {
        pin: {
          backgroundColor: '#337ab7'
        }
      }
    }
  });

  return (

    <Form onSubmit={this.onSubmit}>

      <Col sm={6}>
        <p style={{ textAlign: 'center', fontSize: '16px' }}>
Akkumuleringstankindhold [MWh]
        </p>
        <div className="hr">
          <hr style={{
            border: ' 0,5px solid', color: 'grey', borderColor: 'grey', marginTop: '0px',
          }}
          />
        </div>
        <Row>
          <FormGroup>
            <Col sm={5}><label>Minimum</label></Col>
            <Col sm={7}>
              <FormControl
                name="acu_min"
                onChange={this.handleChange}
                value={plant.acu_min}
                inputRef={(input) => { this.minimumInput = input; }}
              />
              {' '}

            </Col>
          </FormGroup>
        </Row>
        <br />
        <Row>
          <FormGroup>
            <Col sm={5}><label>Maksimum</label></Col>
            <Col sm={7}>
              <FormControl
                name="acu_max"
                onChange={this.handleChange}
                value={plant.acu_max}
              />

            </Col>
          </FormGroup>
        </Row>
        <br />
        <Row>
          <FormGroup>
            <Col sm={5}><label>Kapacitet</label></Col>
            <Col sm={7}>
              <FormControl
                name="acu_cap"
                onChange={this.handleChange}
                value={plant.acu_cap}
                inputRef={(input) => { this.capacityInput = input; }}
              />

            </Col>
          </FormGroup>
        </Row>
      </Col>

      <Col sm={6}>
        <p style={{ textAlign: 'center', fontSize: '16px' }}>
Gaskedel
        </p>
        <div className="hr">
          <hr style={{
            border: ' 0,5px solid', color: 'grey', borderColor: 'grey', marginTop: '0px',
          }}
          />
        </div>
        <Row>
          <FormGroup>
            <Col sm={5}><label>Startregel [MWh]</label></Col>
            <Col sm={7}>
              <FormControl
                name="gas_boiler_start"
                onChange={this.handleChange}
                value={plant.gas_boiler_start}
              />

            </Col>
          </FormGroup>
        </Row>
        <br />
        <Row>
          <FormGroup>
            <Col sm={5}><label>Stopregel [MWh]</label></Col>
            <Col sm={7}>
              <FormControl
                name="gas_boiler_stop"
                onChange={this.handleChange}
                value={plant.gas_boiler_stop}
              />

            </Col>
          </FormGroup>
        </Row>
        <br />
        <Row>
          <FormGroup>
            <Col sm={5}><label>Produktion [MW]</label></Col>
            <Col sm={7}>
              <FormControl
                name="gas_boiler_max"
                onChange={this.handleChange}
                value={plant.gas_boiler_max}
              />

            </Col>
          </FormGroup>
        </Row>
      </Col>
      <br />
      <Col sm={12}>
        <br />
        <br />
        <p style={{ textAlign: 'center', fontSize: '16px' }}>
Virkningsgrader [η]
        </p>
        <div className="hr">
          <hr style={{
            border: ' 0,5px solid', color: 'grey', borderColor: 'grey', marginTop: '0px',
          }}
          />
        </div>
        <Col sm={6}>
          <Row>
            <FormGroup>
              <Col sm={5}><label>Biogasmotor, Varme-/El-produktion [MWh/MWh]</label></Col>
              <Col sm={7}>
                <FormControl
                  name="motor_bg_efficiency"
                  onChange={this.handleChange}
                  value={plant.motor_bg_efficiency}
                />

              </Col>
            </FormGroup>
          </Row>
        </Col>

        <Col sm={6}>
          <Row>
            <FormGroup>
              <Col sm={5}><label>Naturgasmotor, Varme-/El-produktion [MWh/MWh]</label></Col>
              <Col sm={7}>
                <FormControl
                  name="motor_ng_efficiency"
                  onChange={this.handleChange}
                  value={plant.motor_ng_efficiency}
                />
              </Col>
            </FormGroup>
          </Row>
        </Col>
      </Col>

      <Col sm={6}>
        <br />
        <br />
        <p style={{ textAlign: 'center', fontSize: '16px' }}>
Biogaskedler
        </p>
        <div className="hr">
          <hr style={{
            border: ' 0,5px solid', color: 'grey', borderColor: 'grey', marginTop: '0px',
          }}
          />
        </div>

        <Row>
          <FormGroup>
            <Col sm={5}><label>{t('begin')}</label></Col>
            <Col sm={7}>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <ThemeProvider theme={theme} >
                  <KeyboardDateTimePicker
                    minutesStep='5'
                    InputProps={{
                      disableUnderline: true,
                    }}
                    autoOk={true}
                    fullWidth
                    inputVariant={"filled"}
                    disableToolbar
                    ampm={false}
                    variant='inline'
                    format='MM/DD/YYYY HH:mm'
                    margin='normal'
                    id='date-picker-biogas-start'
                    value={new Date(plant.biogas_start_time)}
                    onChange={(date) => { this.handleDateChange('biogas_start_time', date)}}
                    KeyboardButtonProps={{
                      'aria-label': 'change date'
                    }}
                  />
                </ThemeProvider>
              </MuiPickersUtilsProvider>
            </Col>
          </FormGroup>
        </Row>
        <br />
        <Row>
          <FormGroup>
            <Col sm={5}><label>End</label></Col>
            <Col sm={7}>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <ThemeProvider theme={theme} >
                  <KeyboardDateTimePicker
                    minutesStep='5'
                    InputProps={{
                      disableUnderline: true,
                    }}
                    autoOk={true}
                    fullWidth
                    inputVariant={"filled"}
                    disableToolbar
                    ampm={false}
                    variant='inline'
                    format='MM/DD/YYYY HH:mm'
                    margin='normal'
                    id='date-picker-biogas-stop'
                    value={new Date(plant.biogas_stop_time)}
                    onChange={(date) => { this.handleDateChange('biogas_stop_time', date)}}
                    KeyboardButtonProps={{
                      'aria-label': 'change date'
                    }}
                  />
                </ThemeProvider>
              </MuiPickersUtilsProvider>

            </Col>
          </FormGroup>
        </Row>
        <br />
        <Row>
          <FormGroup>
            <Col sm={5}><label>Varme [MW]</label></Col>
            <Col sm={7}>
              <FormControl
                name="biogas_heat"
                onChange={this.handleChange}
                value={plant.biogas_heat}
              />

            </Col>
          </FormGroup>
        </Row>
        <br />
        <br />
      </Col>
      <Col sm={6}>
        <br />
        <br />
        <p style={{ textAlign: 'center', fontSize: '16px' }}>
Fliskedel
        </p>
        <div className="hr">
          <hr style={{
            border: ' 0,5px solid', color: 'grey', borderColor: 'grey', marginTop: '0px',
          }}
          />
        </div>
        <Row>
          <FormGroup>
            <Col sm={5}><label>Start</label></Col>
            <Col sm={7}>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <ThemeProvider theme={theme} >
                  <KeyboardDateTimePicker
                    minutesStep='5'
                    InputProps={{
                      disableUnderline: true,
                    }}
                    autoOk={true}
                    fullWidth
                    inputVariant={"filled"}
                    disableToolbar
                    ampm={false}
                    variant='inline'
                    format='MM/DD/YYYY HH:mm'
                    margin='normal'
                    id='date-picker-biomass-start'
                    value={new Date(plant.biomass_start_time)}
                    onChange={(date) => { this.handleDateChange('biomass_start_time', date)}}
                    KeyboardButtonProps={{
                      'aria-label': 'change date'
                    }}
                  />
                </ThemeProvider>
              </MuiPickersUtilsProvider>
            </Col>
          </FormGroup>
        </Row>
        <br />
        <Row>
          <FormGroup>
            <Col sm={5}><label>End</label></Col>
            <Col sm={7}>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <ThemeProvider theme={theme} >
                  <KeyboardDateTimePicker
                    minutesStep='5'
                    InputProps={{
                      disableUnderline: true,
                    }}
                    autoOk={true}
                    fullWidth
                    inputVariant={"filled"}
                    disableToolbar
                    ampm={false}
                    variant='inline'
                    format='MM/DD/YYYY HH:mm'
                    margin='normal'
                    id='date-picker-biomass-start'
                    value={new Date(plant.biomass_stop_time)}
                    onChange={(date) => { this.handleDateChange('biomass_stop_time', date)}}
                    KeyboardButtonProps={{
                      'aria-label': 'change date'
                    }}
                  />
                </ThemeProvider>
              </MuiPickersUtilsProvider>
            </Col>
          </FormGroup>
        </Row>
        <br />
        <Row>
          <FormGroup>
            <Col sm={5}><label>Varme [MW]</label></Col>
            <Col sm={7}>
              {' '}
              <FormControl
                name="biomass_heat"
                onChange={this.handleChange}
                value={plant.biomass_heat}
              />

            </Col>

          </FormGroup>
        </Row>
      </Col>
      <br />

      <Row>
        <Col sm={6}>
          <br/>
          <ButtonToolbar>
            <Button
              type="submit"
              bsStyle="primary"
              onClick={this.onSubmit}
              disabled={submitDisabled}
            >
              {t('save')}
            </Button>
            <Button onClick={onDismiss}>{t('cancel-and-close')}</Button>
          </ButtonToolbar>
        </Col>
      </Row>

    </Form>
  );
}
}
