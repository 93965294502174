import i18n from 'i18next';
import da from './utils/locales/da';
import en from './utils/locales/en';
import sv from './utils/locales/sv';
import no from './utils/locales/no';

i18n
  .init({
    fallbackLng: 'da',
    resources: {
      da: {
        translation: da,
      },
      en: {
        translation: en,
      },
      sv: {
        translation: sv,
      },
      no: {
        translation: no,
      },
    },
  });

export default i18n;
