import React from 'react';
import { inject, observer } from 'mobx-react';
import { translate } from 'react-i18next';
import { Button, Form, Modal } from 'react-bootstrap';
import { TextFieldGroup } from '../common/horizontal/TextFieldGroup';

@translate()
@inject('uiStore', 'plantStore', 'planStore') @observer
class PriceDependentPriceModal extends React.Component {
  mapStateToProps() {
    return {
      planStore: this.props.planStore,
      plantStore: this.props.plantStore,
      shouldShowPriceDependentPriceModal: this.props.plantStore.shouldShowPriceDependentPriceModal,
      priceDependentPrice: this.props.uiStore.selectedPlant.priceDependentPriceValue,
    };
  }

  render() {
    const { t } = this.props;
    const myProps = this.mapStateToProps();
    const currency = myProps.planStore.getCurrency();

    return (
      <div>
        <Button onClick={this.toggleModal}>{t('price-dependent-price')}</Button>
        <Modal show={myProps.shouldShowPriceDependentPriceModal} onHide={this.toggleModal} className="price-dependent-modal">
          <Modal.Header closeButton>
            <Modal.Title>{t('price-dependent-price')}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <Form horizontal onSubmit={this.submitPrice}>
                <TextFieldGroup
                  name={`${t('price')} [${currency}]`}
                  initValue={myProps.priceDependentPrice}
                  givenRef={(ref) => { this.priceDependentPrice = ref; }}
                />
              </Form>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              onClick={this.submitPrice}
              bsStyle="primary"
              type="submit"
              disabled={false}
            >
              {t('save')}
            </Button>
            <Button onClick={this.toggleModal}>{t('abort-close')}</Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }

  toggleModal = () => {
    const myProps = this.mapStateToProps();
    myProps.plantStore.togglePriceDependentModal();
  }

  submitPrice= (event) => {
    event.preventDefault();
    const myProps = this.mapStateToProps();
    myProps.plantStore.submitPriceDependentPrice(this.priceDependentPrice.value);
  }
}

export default PriceDependentPriceModal;
