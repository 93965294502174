import React from 'react';
import { inject, observer } from 'mobx-react';
import { translate } from 'react-i18next';
import {
  Button,
  FormControl,
  FormGroup,
  ButtonToolbar,
} from 'react-bootstrap';

import moment from 'moment';

import { minutesInAnHour } from '../../config/settings';

@translate()
@inject('uiStore', 'controlRoomStore') @observer
export default class NewProductionPlanEntryForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      begin: 0,
      power: '',
      submitDisabled: false,
      stateUpdating: false,
    };
  }

  validate = () => {
    this.setState(
      prevState => ({
        submitDisabled: !prevState.power,
      }),
    );
  };

  onBeginChange = (e) => {
    this.setState({ begin: e, stateUpdating: true }, () => {
      this.validate();
    });
  };

  onPowerChange = (e) => {
    this.setState({ power: e.target.value, stateUpdating: true }, () => {
      this.validate();
    });
  };

  onSubmit = (e) => {
    e.preventDefault();

    const { begin, power } = this.state;

    this.setState({
      stateUpdating: false,
    });

    this.props.onSubmit({
      begin,
      power,
    });
  }

  componentDidUpdate(prevProps) {
    const { init, show } = this.props;

    if (init && init.begin !== this.state.begin && init.power !== this.state.power && !this.state.stateUpdating) {
      this.setState({
        begin: init.begin,
        power: init.power,
      });
    }

    if (!prevProps.show && show) {
      this.powerInput.focus();
    }
  }

  beginSub = () => {
    const { begin } = this.state;

    if (begin === 0) {
      return;
    }

    this.setState({ begin: begin - 5 });
  }

  beginAdd = () => {
    const { begin } = this.state;

    if (begin === minutesInAnHour * this.props.uiStore.hoursInDay - 5) {
      return;
    }

    this.setState({ begin: begin + 5 });
  }

  render() {
    const { t, onDismiss } = this.props;
    const { begin, power, submitDisabled } = this.state;

    return (
      <form onSubmit={this.onSubmit}>
        <FormGroup>
          <label>{t('begin')}</label>
          <FormControl
            disabled
            value={moment(this.props.uiStore.selectedDate).startOf('day').add(begin, 'minute').format('HH:mm')}
          />
        </FormGroup>

        <FormGroup>
          <ButtonToolbar>
            <Button onClick={this.beginSub}>&#60;</Button>
            <Button onClick={this.beginAdd}>&#62;</Button>
          </ButtonToolbar>
        </FormGroup>


        <FormGroup>
          <label>{t('production')}</label>
          <FormControl
            inputRef={(input) => { this.powerInput = input; }}
            onChange={this.onPowerChange}
            value={power}
          />
        </FormGroup>

        <ButtonToolbar>
          <Button
            type="submit"
            bsStyle="primary"
            disabled={submitDisabled}
          >
            {t('save')}
          </Button>
          <Button onClick={onDismiss}>{t('cancel-and-close')}</Button>
        </ButtonToolbar>
      </form>
    );
  }
}
