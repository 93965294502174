// @flow
import _ from 'lodash';
import Price from './Price';

class Entry {
    minute: number;

    amountPowerOutputMegawatt: number;

    activationPrice: Price;

    standbyPrice: Price;

    classOf: string;

    fromJson(json) {
      this.minute = json.minute;
      this.amountPowerOutputMegawatt = json.amountPowerOutputMegawatt;
      if (json.activationPrice) this.activationPrice = new Price(json.activationPrice);
      if (json.standbyPrice) this.standbyPrice = new Price(json.standbyPrice);
      this.classOf = json.classOf;
      return this;
    }
}

// class ProductionPlan {
//
// }
//
// class ManualPlan { // extends priceplan
//
// }
//
// class FrequencyPlan { // extends priceplan
//
// }
//
// class BidPlan { // extends priceplan
//
// }

const created = 1;
// const approved = 3;
// const sent = 4;
// const lost = 5;

class Plan {
    data: {};

    entries: Array<Entry> = [];

    nameKey: string;

  status: number;

  classOf: string;

    replacedWith: string;

  up: boolean;

  readonly: boolean;

  state: number = created;

  // constructor(args: {
  //  nameKey: string,
  //  status: number,
  //  classOf: string,
  //  up: boolean,
  //  readonly?: boolean,
  //  entries: Array<any>
  // }){
  // }

  fromJson(json, hoursInDay = 24) {
    this.data = json;
    this.nameKey = json.nameKey;
    this.status = json.status;
    this.classOf = json.classOf;
    this.replacedWith = json.replacedWith;
    this.up = json.up;
    this.readonly = json.readonly || false;
    this.entries = json.entries ? json.entries.map(entryData => new Entry().fromJson(entryData)) : [];
    if (!this.entries || this.entries.length === 0) {
      this.initEntries(hoursInDay);
    }
    return this;
  }

  initEntries(hoursInDay): void {
    const minutesInAnHour = 60;
    const defaultMegawattOutput = 0;
    if (this.isBidPlan() || this.isManualPlan() || this.isIntradayPlan()) {
      // Adds an entry for each hour to bid-plan
      for (let i = 0; i < hoursInDay + 1; i++) {
        this.entries.push(this.createEntry(i * minutesInAnHour, defaultMegawattOutput));
      }
    } else {
      // Adds (0,0) and (max, 0) to plan
      this.entries.push(this.createEntry(0, defaultMegawattOutput));
      // FIXME
      // let hoursInDay = totalHoursInDay(this.uiStore.selectedDate);
      this.entries.push(this.createEntry(hoursInDay * 60, defaultMegawattOutput));
    }
  }

  createEntry(minute: number, amountPowerOutputMegawatt: number): Entry {
    this.status = 0; // redigeret og klar til at blive gemt og dermed oprettet
    const type = this.isPricePlan() ? 'PricePlanEntryDTO' : 'PlanEntryDTO';
    return new Entry().fromJson({ minute, amountPowerOutputMegawatt, classOf: type });
  }

  isPricePlan(): boolean {
    return _.includes(['BidPlanDTO', 'ManualPlanDTO', 'FrequencyPlanDTO', 'IntradayPlanDTO', 'PriceDependentPlanDTO'], this.classOf);
  }

  isBidPlan(): boolean {
    return this.nameKey === 'bid-plan';
  }

  isManualPlan(): boolean {
    return this.classOf === 'ManualPlanDTO';
  }

  isIntradayPlan(): boolean {
    return this.classOf === 'IntradayPlanDTO';
  }

  isPriceDependentPlan(): boolean {
    return this.classOf === 'PriceDependentPlanDTO';
  }
}

export default Plan;
