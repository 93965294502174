import * as React from 'react';
import { computed } from 'mobx';
import ReactTable from 'react-table';
import { inject, observer } from 'mobx-react';

@inject('controlRoomStore') @observer
export default class ActivationOverview extends React.Component {
  constructor() {
    super();
    this.createCol();
  }

  @computed get activations() {
    return this.props.controlRoomStore.activations.slice();
  }

  createCol() {
    this.actColumns = [
      {
        Header: 'Bud-id',
        accessor: 'regbidGroupId',
      },
      {
        Header: 'Prisområde',
        accessor: 'priceArea',
      },
      {
        Header: 'Værk',
        accessor: 'plantName',
      },
      {
        Header: 'Tidsrum',
        accessor: 'timeInterval',
        sortMethod: (a, b) => {
          const aEnd = a.split('-')[1];
          const bEnd = b.split('-')[1];
          // These two are special cases, as midnight, consisting of zeros, is higher than everything else
          if (aEnd === '00:00' && bEnd !== '00:00') {
            return -1;
          }
          if (aEnd !== '00:00' && bEnd === '00:00') {
            return 1;
          }
          if (aEnd > bEnd) {
            return -1;
          }
          if (aEnd < bEnd) {
            return 1;
          }
          return 0;
        },
      },
      {
        Header: 'Effekt',
        accessor: 'activatedPowerOutput',
      },
      {
        id: 'activationPrice',
        Header: 'Aktiveringspris',
        accessor: a => (a.regbidGroupId !== '' ? a.activationPrice : 'N/A'), // If it is industrial activation (without regbid group), we don't have price
      },
      {
        Header: 'Handling',
        accessor: 'activationAction',
      },
    ];
  }

  render() {
    return (
      <div>
        <ReactTable
          data={this.activations}
          showPagination={false}
          columns={this.actColumns}
          pageSize={this.activations.length}
          className="-striped -highlight"
          defaultSorted={[
            {
              id: 'timeInterval',
            },
          ]}
        />
      </div>
    );
  }
}
