// @flow
import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { translate } from 'react-i18next';
import { formatDate } from '../../actions/CommonAction';

@translate()
class CHPCOMStatusModal extends React.Component {
  render() {
    const { t, status } = this.props;

    if (status == null) return false;

    const body = (
      <div>
        <div className="row">
          <div className="col-xs-7">
            <strong>{t('setpoint')}</strong>
          </div>
          <div className="col-xs-4">
            {status.setpoint} {t('mw')}
          </div>

        </div>

          <div className="row">
              <div className="col-xs-7">
                  <strong>{t('onlineMeasurement')}</strong>
              </div>
              <div className="col-xs-4">
                  {status.onlineMeasurement} {t('mw')}
              </div>

          </div>

        <div className="row">
          <div className="col-xs-7">
            <strong>{t('is-remote')}</strong>
          </div>
          <div className="col-xs-4">
            {
              this.renderValue(
                status.isRemote,
                'chpcom-status-remote-true',
                'chpcom-status-remote-false'
              )
            }
          </div>

        </div>
        <div className="row">
          <div className="col-xs-7">
            <strong>{t('alarm')}</strong>
          </div>
          <div className="col-xs-4">
            {
              this.renderValue(
                status.alarm,
                'chpcom-status-alarm-true',
                'chpcom-status-alarm-false'
              )
            }
          </div>

        </div>
        <div className="row">
          <div className="col-xs-7">
            <strong>{t('in-operation')}</strong>
          </div>
          <div className="col-xs-4">
            {
              this.renderValue(
                status.inOperation,
                'chpcom-status-operation-true',
                'chpcom-status-operation-false'
              )
            }
          </div>

        </div>
          <div className="row">
              <div className="col-xs-7">
                  <strong>{t('last-updated-value')}</strong>
              </div>
              <div className="col-xs-4">
                  {this.renderOverlayTrigger(status._timestamp, status._lastTimestamp)}
              </div>
          </div>
      </div>
    );

    return (
      <Modal show={this.props.open} onHide={this.props.onClose} onShow={this.onShow}>
        <Modal.Header><strong>{t('chpcom-status')}</strong></Modal.Header>
        <Modal.Body>
          {body}
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={this.props.onClose}>{t('close')}</Button>
        </Modal.Footer>
      </Modal>
    );
  }

  renderOverlayTrigger = (plantTime, databaseTime) => (
    <OverlayTrigger
      placement="right"
      overlay={this.renderTooltip(databaseTime)}
    >
      <span>{formatDate(plantTime)}</span>
    </OverlayTrigger>
  );

  renderTooltip = (timestamp) => (
    <Tooltip id="chpcom-tooltip">
      Written to database at <br/>
      {formatDate(timestamp)}
    </Tooltip>
  );

  renderValue = (value, trueKey, falseKey) => {
    const { t } = this.props;

    if (value === true) return t(trueKey);
    if (value === false) return t(falseKey);

    return 'N/A';
  }
}

CHPCOMStatusModal.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default CHPCOMStatusModal;
