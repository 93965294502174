// @flow
import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from 'react-bootstrap';

class ModalComp extends React.Component {
    defaultFooter = (t => (
      <div>
        <Button bsStyle="primary" onClick={this.props.onConfirm}>{t('confirm')}</Button>
        <Button onClick={this.props.onClose}>{t('cancel')}</Button>
      </div>
    ));

    render() {
      const { t } = this.props;
      return (
        <div className="static-modal">
          <Modal.Dialog bsSize={this.props.bsSize}>
            <Modal.Header closeButton onHide={this.props.onClose}>
              <Modal.Title>{ this.props.title }</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              { this.props.body }
            </Modal.Body>
            <Modal.Footer>
              { this.props.footer || this.defaultFooter(t) }
            </Modal.Footer>
          </Modal.Dialog>
        </div>
      );
    }
}

ModalComp.defaultProps = {
  onConfirm() {},
};

ModalComp.propTypes = {
  title: PropTypes.string.isRequired,
  body: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func,
  footer: PropTypes.func,
};

export default ModalComp;
