import * as React from 'react';
import {
  Button, ButtonGroup, ButtonToolbar, Clearfix, Col, Panel, ProgressBar, Row,
} from 'react-bootstrap';
import { inject, observer } from 'mobx-react';
import { computed } from 'mobx';
import { toast } from 'react-toastify';
import {
  sendBlockBidToMarkedskraft,
  sendEffectPlan,
  sendFrequencyPlan, sendIntradayPlan,
  sendManualPlan,
  sendProductionPlan,
  sendRegBid,
} from '../../services/PlanService';
import Summary from '../classes/Summary';
import { translate } from 'react-i18next';
import { todaysDate, toTwoDecimalIfNumber } from '../../actions/CommonAction';
import PriceAreaPickerModal from './PriceAreaPickerModal';

@translate()
@inject('controlRoomStore', 'uiStore') @observer
export default class PlanningSummary extends React.Component {
  constructor(...args) {
    super(...args);

    this.sendProductionPlan = this.sendProductionPlan.bind(this);
    this.sendManualPlan = this.sendManualPlan.bind(this);
    this.sendFrequencyPlan = this.sendFrequencyPlan.bind(this);
    this.sendRegBid = this.sendRegBid.bind(this);
    this.sendBlockBidToMarkedskraftAndRefresh = this.sendBlockBidToMarkedskraftAndRefresh.bind(this);
    this.sendEffectPlan = this.sendEffectPlan.bind(this);
    this.sendIntradayPlan = this.sendIntradayPlan.bind(this);

    this.state = {
      priceAreaModalOpen: false,
      modalSubmitDisabled: false,
      defaultChosen: undefined,
    };
  }

  @computed get selectedItemName() {
    const item = this.props.controlRoomStore.selectedItem;
    return item ? item.name : '';
  }

  @computed
  get summary() {
    return this.props.controlRoomStore.summary || new Summary();
  }

  @computed
  get onlineValuesStatus() {
    const status = { ...this.props.controlRoomStore.selectedItemOnlineValuesStatus.get() };
    // I changed the implementation, so this function (onlineValuesStatus()) and selectedItemOnlineValuesStatus() use the
    // same endpoint. The data must just be mingled after being fetched.
    if (status) {
      status.totalDifference = status.dk1Difference + status.dk2Difference;
    }
    return (status) || { totalDifference: 'N/A', dk1Difference: 'N/A', dk2Difference: 'N/A' };
  }

  @computed
  get selectedItemOnlineValuesStatus() {
    const status = { ...this.props.controlRoomStore.selectedItemOnlineValuesStatus.get() };
    return (status && status.readingsAvailable) ? status : { totalDifference: 'N/A', dk1Difference: 'N/A', dk2Difference: 'N/A' };
  }

  onControlRoomButtonPressed = (e, okMessage, errorMessage, action, defaultChosen) => {
    const buttonText = e.currentTarget.textContent;
    this.setState({
      priceAreaModalOpen: true,
      okMessage,
      errorMessage,
      action,
      modalSubmitTitle: buttonText,
      defaultChosen,
    });
  }

  onPriceAreaModalSubmit = (selectedAreas) => {
    const { t } = this.props;
    const ok = this.state.okMessage; // So we dont lose message if different plan is sent while waiting
    const error = this.state.errorMessage;
    this.setState({ modalSubmitDisabled: true });
    this.setState({
      modalSubmitDisabled: false,
      priceAreaModalOpen: false,
    });
    const toastId = toast(t('sending'), {
      autoClose: 15000,
    });
    this.state.action(selectedAreas).then(
      () => {
        if (toast.isActive(toastId)) {
          toast.update(toastId, {
            render: () => ok,
            autoClose: 3000,
            type: 'success',
          });
        } else {
          toast(ok, { type: 'success', autoClose: 3000 });
        }
      },
      () => {
        if (toast.isActive(toastId)) {
          toast.update(toastId, {
            render: () => error,
            autoClose: 3000,
            type: 'error',
          });
        } else {
          toast(error, { type: 'error', autoClose: 3000 });
        }
        this.setState({ modalSubmitDisabled: false });
      },
    );
  }

  onModalDismiss = () => {
    this.setState({ priceAreaModalOpen: false });
  }

  render() {
    const { t } = this.props;
    const {
      priceAreaModalOpen, modalSubmitDisabled, modalSubmitTitle, defaultChosen,
    } = this.state;

    const SummaryEntry = ({ label, value }) => (
      <dl className="dl-horizontal">
        <dt>{label}</dt>
        <dd>{value}</dd>
      </dl>
    );

    return (
      <Panel header={t('status-for-planning-day', { date: todaysDate() })}>
        <Panel.Body>
          <Row>
            <Col md={4}>
              <SummaryEntry label="Godkendte planer" value={this.summary.progressLabel} />
              <SummaryEntry label="Samlet produktion" value={toTwoDecimalIfNumber(this.summary.totalProduction)} />
              <SummaryEntry label="Samlet forbrug" value={this.summary.totalConsumption} />
            </Col>
            <Col md={4}>
              <SummaryEntry label="Total difference" value={toTwoDecimalIfNumber(this.onlineValuesStatus.totalDifference) || t('n-a')} />
              <SummaryEntry label="Difference dk1" value={toTwoDecimalIfNumber(this.onlineValuesStatus.dk1Difference) || t('n-a')} />
              <SummaryEntry label="Difference dk2" value={toTwoDecimalIfNumber(this.onlineValuesStatus.dk2Difference) || t('n-a')} />
            </Col>
            <Col md={4}>
              <SummaryEntry label={t('chosen-entity')} value={this.selectedItemName || t('none-selected')} />
              <SummaryEntry label={t('difference')} value={toTwoDecimalIfNumber(this.selectedItemOnlineValuesStatus.totalDifference) || t('n-a')} />
            </Col>
          </Row>
          <Clearfix />
          <Row>
            <Col md={12}>
              <div>
                <ButtonToolbar>
                  <ButtonGroup>
                    <Button onClick={e => this.onControlRoomButtonPressed(
                      e,
                      'Pplan afsendt',
                      'Pplan ikke afsendt',
                      this.sendProductionPlan,
                      'denmark',
                    )}
                    >
                    Send pplan
                    </Button>
                    <Button onClick={e => this.onControlRoomButtonPressed(
                      e,
                      'Mplan afsendt',
                      'Mplan ikke afsendt',
                      this.sendManualPlan,
                      'all',
                    )}
                    >
                  Send mplan
                    </Button>
                    <Button onClick={e => this.onControlRoomButtonPressed(
                      e,
                      'Fplan afsendt',
                      'Fplan ikke afsendt',
                      this.sendFrequencyPlan,
                      'denmark',
                    )}
                    >
                Send fplan
                    </Button>
                    <Button
                      onClick={e => this.onControlRoomButtonPressed(
                        e,
                        'Intradayplaner sendt',
                        'Intradayplaner ikke sendt',
                        this.sendIntradayPlan,
                        'all',
                      )}
                    >
                      Send iplan
                    </Button>
                  </ButtonGroup>
                  <Button
                    onClick={e => this.onControlRoomButtonPressed(
                      e,
                      'Regbid sendt',
                      'Regbid ikke sendt',
                      this.sendRegBid,
                      'all',
                    )}
                  >
              Send regbid
                  </Button>
                  <Button
                    onClick={e => this.onControlRoomButtonPressed(
                      e,
                      'Blokbud sendt',
                      'Blokbud ikke sendt',
                      this.sendBlockBidToMarkedskraftAndRefresh,
                      'denmark',
                    )}
                  >
              Send blokbud
                  </Button>
                  <Button
                    onClick={e => this.onControlRoomButtonPressed(
                      e,
                      'Effektplan sendt',
                      'Effektplan ikke sendt',
                      this.sendEffectPlan,
                      'all',
                    )}
                  >
              Send effektplan
                  </Button>

                  <PriceAreaPickerModal
                    open={priceAreaModalOpen}
                    onDismiss={this.onModalDismiss}
                    onSubmit={this.onPriceAreaModalSubmit}
                    disabled={modalSubmitDisabled}
                    submitTitle={modalSubmitTitle}
                    defaultChosen={defaultChosen}
                  />

                </ButtonToolbar>
                <Clearfix className="padded-top" />
                <ProgressBar>
                  <ProgressBar
                    bsStyle={this.summary.progressClass}
                    now={this.summary.progress}
                    key={1}
                    label={this.summary.progressLabel}
                    style={{ minWidth: 50 }}
                  />
                </ProgressBar>
              </div>
            </Col>
          </Row>
        </Panel.Body>
      </Panel>
    );
  }

  sendProductionPlan(priceAreas) {
    return sendProductionPlan(this.props.uiStore.selectedDate, priceAreas);
  }

  sendManualPlan(priceAreas) {
    return sendManualPlan(this.props.uiStore.selectedDate, priceAreas);
  }

  sendFrequencyPlan(priceAreas) {
    return sendFrequencyPlan(this.props.uiStore.selectedDate, priceAreas);
  }

  sendRegBid(priceAreas) {
    return sendRegBid(this.props.uiStore.selectedDate, priceAreas);
  }

  sendBlockBidToMarkedskraftAndRefresh(priceAreas) {
    return sendBlockBidToMarkedskraft(this.props.uiStore.selectedDate, priceAreas).then(() => {
      this.props.controlRoomStore.refreshBlockBids();
    });
  }

  sendEffectPlan(priceAreas) {
    return sendEffectPlan(this.props.uiStore.selectedDate, priceAreas);
  }

  sendIntradayPlan(priceAreas) {
    return sendIntradayPlan(this.props.uiStore.selectedDate, priceAreas);
  }
}
