// @flow
import { action, computed, observable } from 'mobx';
import _ from 'lodash';
import Contact from './Contact';
import {
  addContact,
  deleteContact,
  updateContact, updateIntradayPrices,
  updateMarginalPrice, updatePriceDependentPrice,
  updateRegulatingPower,
} from '../services/PlantService';
import { toast } from 'react-toastify';
import { warn } from '../services/logService';

class Plant {
    uuid: string;

    @observable code: number;

    @observable name: string = '';

    ean: number;

    staticActivationGroup: string;

    activationAction: string;

    staticActivationGroupMaster: boolean;

    eicCode: string;

    dbvNumber: string;

    qGroup: boolean;

    deadTime: number;

    prodSum: number;

    @observable startGradient: number;

    @observable stopGradient: number;

    energinetDkId: string;

    @observable regulatingPowerUp : number = 0;

    @observable regulatingPowerDown : number = 0;

    @observable marginalPrice: number = -1;

    @observable priceAreaName: string = 'dk1';

    producer: boolean = true;

    // værk/kedel
    @observable capacityMegawatt: number;

    website: string;

    mkplusEnabled: boolean;

    tsoEnabled : boolean;

    @observable onlineValueSource : string = 'chpcom';

    reserve: boolean;

    @observable contacts: Array<Contact> = [];

    @observable onlineValues: Array<OnlineValue> = [];

    @observable onlineName: Array<string> = [];

    @observable notes: Array<string> = [];

    productionPlan: string;

    manualPlanUp: string;

    manualPlanDown: string;

    manualPlanUpPrice: string;

    manualPlanDownPrice: string;

    frequencyPlanUp: string;

    frequencyPlanDown: string;

    frequencyPlanUpPrice: string;

    frequencyPlanDownPrice: string;

    intradayPlanBuy: string;

    intradayPlanSell: string;

    intradayPlanBuyPrice: string;

    intradayPlanSellPrice: string;

    intradayEnabled: boolean;

    resultSeries: string;

    ftpEffectplan: boolean;

    priceDependentPriceValue: number = 0;

    priceDependentPrice: string;

    priceDependentVol: string;

    priceDependentEnabled: boolean;

    @observable intradayBuyPrice : number = 0;

    @observable intradaySellPrice : number = 0;

    @action
    fromJson(json) {
      for (const prop in json) if (json.hasOwnProperty(prop)) this[prop] = json[prop];
      return this;
    }

    get toJson() {
      const asJson = JSON.parse(JSON.stringify(this));
      delete asJson.onlineValues;
      return asJson;
    }

    @computed get contact1() {
      if (this.contacts.size > 0) {
        return this.contacts.slice()[0];
      }
    }

    @computed get contact2() {
      if (this.contacts.size > 1) {
        return this.contacts.slice()[1];
      }
    }

    @computed get contact3() {
      if (this.contacts.size > 2) {
        return this.contacts.slice()[2];
      }
    }

    @action
    deleteContact(contact: string) {
      deleteContact(contact, this.code)
        .then(
          (response) => {
            console.log('returned from delete');
            this._deleteContact(contact);
          }, (err) => {
            warn(err);
            toast('Kontaktperson kunne ikke slettes', {
              autoClose: 1400,
            });
          },
        );
    }

    @action
    updateContact(contact: Contact) {
      if (contact.isValid) {
        updateContact(contact.asJson, this.code)
          .then((response) => {
            const _contacts = this.contacts.slice();
            const index = _.findIndex(_contacts, { uuid: contact.uuid });
            this._updateContact(contact, index);
          })
          .catch((error) => {
            warn(error);
            toast('Kontaktperson kunne ikke gemmes', {
              autoClose: 1400,
            });
          });
      }
    }

    @action
    addContact(contact: Contact) {
      if (contact.isValid) {
        addContact(contact.asJson, this.code)
          .then(response => this._addContact(new Contact(response.data)))
          .catch((error) => {
            warn(error);
            toast('Kontaktperson kunne ikke gemmes', {
              autoClose: 1400,
            });
          });
      } else {
        // TODO add toastr message when validating.
        console.warn('contact was not valid');
        console.warn(contact);
      }
    }

    @action.bound
    _addContact(contact: Contact) {
      const _contacts = this.contacts.slice();
      _contacts.push(contact);
      this.contacts.replace(_contacts);
    }

    @action.bound
    _updateContact(contact: Contact, excistingIndex:Int) {
      const _contacts = this.contacts.slice();
      _contacts[excistingIndex] = contact;
      this.contacts.replace(_contacts);
    }

    @action.bound
    _deleteContact(contact: Contact) {
      this.contacts.remove(contact);
    }

    updateMarginalPrice(newPrice) {
      updateMarginalPrice(newPrice, this.code)
        .then((res) => {
          this._updateMarginalPrice(newPrice);
        }).catch(warn);
    }

    @action
    _updateMarginalPrice(newPrice) {
      this.marginalPrice = newPrice;
    }

    updateIntradayPrices(newBuy, newSell) {
      return updateIntradayPrices(newBuy, newSell, this.code)
        .then((res) => {
          this._updateIntradayPrices(newBuy, newSell);
        }).catch(warn);
    }

    updatePriceDependentPrice(newPrice) {
      return updatePriceDependentPrice(newPrice, this.code)
        .then((res) => {
          this._updatePriceDependentPrice(newPrice);
        }).catch(warn);
    }

    @action
    _updatePriceDependentPrice(newPrice) {
      this.priceDependentPriceValue = newPrice;
    }

    @action
    _updateIntradayPrices(newBuy, newSell) {
      this.intradayBuyPrice = newBuy;
      this.intradaySellPrice = newSell;
    }

    @action
    _updateRegulatingPower(newPowerUp, newPowerDown) {
      this.regulatingPowerUp = newPowerUp;
      this.regulatingPowerDown = newPowerDown;
    }

    @computed get isValid() {
      return this.errors.length === 0;
    }

    @computed get errors() {
      const errors = [];
      if (this.name === '') { errors.push({ msg: 'Navn mangler' }); }
      if (this.plantName === '') { errors.push({ msg: 'Navn mangler' }); }
      if (this.energinetDkId === '') { errors.push({ msg: 'Kortnavn mangler' }); }
      if (!this.code) { errors.push({ msg: 'Kode mangler' }); }
      if (this.code === '0') { errors.push({ msg: 'Koden må ikke være 0' }); }
      if (!this.capacityMegawatt && this.capacityMegawatt !== 0) { errors.push({ msg: 'Kapacitet mangler' }); }
      if (!this.startGradient && this.startGradient !== 0) { errors.push({ msg: 'Startgradient mangler' }); }
      if (!this.stopGradient && this.stopGradient !== 0) { errors.push({ msg: 'Stopgradient mangler' }); }
      return errors;
    }

    @computed get isNew() {
      return (!this.uuid || this.uuid.length === 0);
    }

    get isPlant() {
      return true;
    }

    get isPriceArea() {
      return false;
    }

    get priceAreaName() {
      return this.priceAreaName;
    }

    get onlineValueSource() {
      return this.onlineValueSource;
    }
}

export default Plant;
