import moment from 'moment-timezone';
import _ from 'lodash';

export const API_DATE_FORMAT = 'YYYYMMDD';

const DATE_TIME_FORMAT = 'DD/MM - YYYY [kl.] HH:mm';


export function convertMinutesToDateObj(minutes, date) {
  return moment(date).startOf('day').add(minutes, 'minute');
}

export function todaysDate() {
  return moment().format('DD/MM - YYYY');
}

export function convertDateObjToMinutes(date) {
  const start = date.clone().startOf('day');

  return date.diff(start, 'minutes');
}

export function differenceInMinutes(start, end) {
  return end.diff(start, 'minutes');
}

export function convertMinutesToDateObjForGraph(date, minutes) {
  return moment(date).startOf('day').add(minutes, 'minute').toDate();
}

export function convertHoursToDateObjForGraph(hours) {
  const dateCopy = moment();
  return dateCopy.startOf('day').add(hours, 'hour');
}

export function convertMinutesToHours(minutes) {
  const h = minutes / 60 | 0;
  const m = minutes % 60 | 0;

  return `${h.toString().padStart(2, '0')}:${m.toString().padStart(2, '0')}`;
}

export function convertMinutesToDate(minutes, date) {
  // workaround - mutability
  const dateCopy = moment(date);
  return dateCopy.startOf('day').add(minutes, 'minute').format(DATE_TIME_FORMAT);
}


export function formatDate(timestamp) {
  return timestamp
    ? moment(timestamp).format(DATE_TIME_FORMAT)
    : 'N/A';
}

/**
 * Utility function that makes a number two-decimal, but does nothing if it results in NaN.
 * @param numberOrOther
 * @returns numberOrOther if toFixed results in NaN.
 */
export function toTwoDecimalIfNumber(numberOrOther) {
  return isNaN(Number(numberOrOther)) ? numberOrOther : Number(numberOrOther).toFixed(2);
}

export function convertMinutesToClock(selectedDate, minutes) {
  return moment(selectedDate)
    .startOf('day')
    .add(minutes, 'minute')
    .format('HH:mm');
}

export function convertTimestampToMinutes(timestamp) {
  const m = moment(timestamp);
  return m.hour() * 60 + m.minute();
}

// Accepts 00:00 => 23:59 and single numbers 0:5.
const timeRegex = new RegExp('^([01]?[0-9]|2[0-3]):([0-5][0-9])$');

export function convertTimeToMinutes(time) {
  if (time === 0) return;
  const matches = time.match(timeRegex);
  if (matches === null) {
    return -1;
  }
  const h = parseInt(matches[1], 10) * 60;
  const m = parseInt(matches[2], 10);
  return h + m;
}

export function titleCase(str: String): string {
  return _.upperFirst(_.toLower(str));
}

export function dateForApi(date) {
  return moment(date).format(API_DATE_FORMAT);
}

/**
 * Maps BidPlanDTO, with entries, to UI table friendly listings with new entries when production values change.
 * Remember to flatten if you have one array per BidPlanDTO instance.
 * @param blockBidData
 * @returns {Array}
 * @private
 */
export function blockBidMapper(selectedDate, blockBidsArray) {
  if (typeof blockBidsArray === 'undefined') return [];

  const blockBids = [];

  blockBidsArray.forEach((blockBid, index) => {
    let start = null;
    let end = null;
    let power = 0;
    let price = null;
    let startMinute = 0;
    let endMinute = 0;

    blockBid.entries.forEach((entry) => {
      if (entry.amountPowerOutputMegawatt === 0) {
        if (start != null && end == null) {
          end = convertMinutesToClock(selectedDate, entry.minute);
          endMinute = entry.minute;
        }
      }

      if (entry.amountPowerOutputMegawatt !== 0) {
        if (start == null) {
          start = convertMinutesToClock(selectedDate, entry.minute);
          power = entry.amountPowerOutputMegawatt;
          price = entry.activationPrice;
          startMinute = entry.minute;
        }
      }
    });

    blockBids.push({
      plant: blockBid.plantName,
      state: blockBid.status,
      bidId: blockBid.bidId,
      start,
      end,
      production: power,
      price,
      startMinute,
      endMinute,
      index,
    });

    start = null;
    end = null;
  });

  return blockBids.sort((one, two) => one.startMinute - two.startMinute);
}

export function getStatusName(code) {
  switch (code) {
    case 0: return 'draft';
    case 1: return 'created';
    case 3: return 'approved';
    case 4: return 'sent';
    case 5: return 'lost';
    case 6: return 'won';
    case 7: return 'active';
    default: return code;
  }
}

export function getHoursInDay(date) {
  const a = moment(date).startOf('d');
  const b = moment(date).add('d', 1).startOf('d');
  return b.diff(a, 'h');
}

export function findCorrectSpotPrice(spotPrices, hour) {
  let priceToDisplay = 0;
  spotPrices.forEach((element) => {
    if (element.hour === hour) {
      priceToDisplay = element.priceAmount;
    }
  });
  return priceToDisplay;
}
export function findCorrectBalancePrice(balancePrices, hour) {
  const index = balancePrices.findIndex(bp => bp.hour === hour);
  if (index < 0) {
    return 'N/A';
  }
  return balancePrices[index].priceAmount;
}
