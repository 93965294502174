// @flow
import _ from 'lodash';
import moment from 'moment';
import PriceForecastEntry from './PriceForecastEntry';
import { getHoursInDay } from '../actions/CommonAction';
import { addMidnightEntry } from '../controlroom/classes/plans';

class GraphData {
    color: string;

    entries: Array<GraphEntry>;

    up: boolean = false;

    markType: string;

    readonly: boolean = true;

    status: number = -1;

    shouldCurveStep: boolean = false;
}

export class MarginalPriceGraphData extends GraphData {
  constructor(price, date) {
    super();
    this.color = 'black';
    this.entries = this._makeEntries(price, date);
  }

  _makeEntries(price, date) {
    const startDate = moment(date).startOf('d').toDate();
    const endDate = moment(date).startOf('d').add('d', 1).toDate();
    return [
      {
        date: startDate,
        priceAmount: price,
      },
      {
        date: endDate,
        priceAmount: price,
      },
    ];
  }

  get maxPrice() {
    if (this.entries && this.entries.length > 0) {
      return _.maxBy(this.entries, 'priceAmount').priceAmount;
    }
    return 0;
  }

  get minPrice() {
    if (this.entries && this.entries.length > 0) {
      return _.minBy(this.entries, 'priceAmount').priceAmount;
    }
    return 0;
  }
}

export class OnlineValuesGraphData extends GraphData {
  constructor(onlineValues: Array<OnlineValue>, selectedDate = undefined, hoursInDay = 24) {
    super();
    this.color = 'green'; // 'red' in control room.
    this.selectedDate = selectedDate;
    this.hoursInDay = hoursInDay;
    this.entries = onlineValues ? this._makeEntries(onlineValues) : [];
    this.enableTable = false;
  }

  _makeEntries(onlineValues: Array<OnlineValue>) {
    if (onlineValues.length === 0) return [];

    // TODO: RVA: This does not look good. Time zone should be selected differently (from price area) instead of being hardcoded.
    const targetTz = 'Europe/Copenhagen';

    const nowDay = moment(this.selectedDate).date();

    const result = onlineValues
      .sort((o1, o2) => o1.timestamp - o2.timestamp)
      .map(o => ({
        amountPowerOutputMegawatt: o.value,
        timestamp: o.timestamp,
        lastTimestamp: o.lastTimestamp,
      }))
      .filter((o) => {
        const tsMoment = moment.tz(o.timestamp, 'UTC').tz(targetTz);
        const lastTsMoment = moment.tz(o.lastTimestamp, 'UTC').tz(targetTz);
        if (tsMoment.date() !== nowDay) {
          console.log('Ignoring because timestamp is out of range', nowDay, tsMoment.date(), o, tsMoment.toDate());
          return false;
        }
        if (lastTsMoment.date() !== nowDay) {
          console.log('Ignoring because lastTimestamp is out of range', nowDay, lastTsMoment.date(), o, lastTsMoment.toDate());
          return false;
        }
        return true;
      })
      .map((o) => {
        const o1 = {
          amountPowerOutputMegawatt: o.amountPowerOutputMegawatt,
          timestamp: o.timestamp,
        };

        if (o.lastTimestamp !== o.timestamp) {
          const o2 = {
            amountPowerOutputMegawatt: o.amountPowerOutputMegawatt,
            timestamp: o.lastTimestamp,
          };

          return [
            o1,
            o2,
          ];
        }

        return o1;
      })
      .flat()
      .map(e => ({
        ...e,
        date: moment(e.timestamp).toDate(),
      }));

    return result;
  }

  get valid() {
    return this.entries.length > 0;
  }

  get maxPower() {
    if (this.entries && this.entries.length > 0) {
      return _.maxBy(this.entries, 'amountPowerOutputMegawatt').amountPowerOutputMegawatt;
    }
    return 0;
  }

  get minPower() {
    if (this.entries && this.entries.length > 0) {
      return _.minBy(this.entries, 'amountPowerOutputMegawatt').amountPowerOutputMegawatt;
    }
    return 0;
  }
}

export class PriceForecastGraphData extends GraphData {
  constructor(entries: Array<PriceForecastEntry>) {
    super();
    this.color = 'red';
    this.entries = this._makeEntries(entries);
    this.shouldCurveStep = true;
    this.enableTable = false;
  }

  _makeEntries(entriesData: Array<PriceForecastEntry>) {
    return entriesData.map((entry: PriceForecastEntry) => {
      const date = moment(entry.date);
      return {
        date: date.startOf('d').add(entry.hour, 'hours').toDate(),
        priceAmount: entry.priceAmount,
      };
    });
  }

  get valid() {
    return this.entries.length > 0;
  }

  get maxPrice() {
    if (this.entries && this.entries.length > 0) {
      return _.maxBy(
        this.entries, 'priceAmount',
      ).priceAmount;
    }
    return 0;
  }

  get minPrice() {
    if (this.entries && this.entries.length > 0) {
      return _.minBy(
        this.entries, 'priceAmount',
      ).priceAmount;
    }
    return 0;
  }
}

export class MKPlusGraphData extends GraphData {
  constructor(data = {}, key, color) {
    super();

    this.color = color;
    this.key = key;
    this.entries = this._makeEntries(data, key);
  }

  _makeEntries(data) {
    if (data.length === 0) {
      return [];
    }

    const { key } = this;

    const entries = data
        .sort((entry1, entry2) => new Date(entry1.time).getTime() - new Date(entry2.time).getTime())
        .map((entry) => {
          const entryTimeMoment = moment(entry.time);

          return {
            storage: (this.key === "storage"),
            minute: entryTimeMoment.hour() * 60,
            date: entryTimeMoment.toDate(),
            amountPowerOutputMegawatt: entry[key],
          };
        });

    return entries;
  }

  get valid() {
    return this.entries.length > 0;
  }

  get maxValue() {
    if (this.entries && this.entries.length > 0) {
      try {
        return _.maxBy(this.entries, 'amountPowerOutputMegawatt').amountPowerOutputMegawatt;
      } catch (e) {
        return 0;
      }
    }
    return 0;
  }

  get minValue() {
    if (this.entries && this.entries.length > 0) {
      return _.minBy(this.entries, 'amountPowerOutputMegawatt').amountPowerOutputMegawatt;
    }
    return 0;
  }
}
