// @flow
import React from 'react';
import PropTypes from 'prop-types';
import {
  Alert,
  Checkbox,
  Col,
  ControlLabel,
  Form,
  FormControl,
  FormGroup,
  InputGroup,
  Modal,
  Panel,
  Radio,
  Row,
  Table,
} from 'react-bootstrap';
import Plant from '../../classes/Plant';
import Button from 'react-bootstrap/es/Button';
import { inject, observer } from 'mobx-react';
import { toJS } from 'mobx';
import { toast } from 'react-toastify';
import { translate } from 'react-i18next';
import * as ReactDOM from 'react-dom';

@translate()
@inject('controlRoomStore') @observer
class PlantForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      errors: [],
      plant: new Plant(),
      inputFields: {},
    };

    this.onlineNamesInputRef = React.createRef();
  }

    onShow = () => {
      const plant = toJS(this.props.plant);

      const inputFields = {};
      if (plant.notes[0]) {
        inputFields.notes = plant.notes[0];
      }

      this.setState({
        errors: [],
        plant,
        inputFields,
      });
    }

    handleChange = (event) => {
      const { target } = event;
      const value = target.type === 'checkbox' ? target.checked : target.value;
      const field = target.name;
      const { plant } = this.state;
      plant[field] = value;
      this.setState({ plant });
    }

    handleLocalChange = (event) => {
      const { target } = event;
      const value = target.type === 'checkbox' ? target.checked : target.value;
      const field = target.name;
      const { inputFields } = this.state;
      inputFields[field] = value;
      this.setState({ inputFields });
    }

    onClose = (event) => {
      this.setState({
        errors: [],
        plant: new Plant(),
        inputFields: {},
      });
      this.props.onClose();
    }

    onConfirm = (event) => {
      event.preventDefault();
      const plant = new Plant().fromJson(this.state.plant);

      if (this.state.inputFields.notes) {
        plant.notes[0] = this.state.inputFields.notes;
      } else {
        plant.notes = [];
      }

      if (plant.isValid) {
        this.savePlant(plant);
      } else {
        this.setState({
          errors: plant.errors,
        });
      }
    }

    savePlant = (plant) => {
      this.props.controlRoomStore.savePlant(plant)
        .then((res) => {
          this.props.onClose();
          toast('Værk blev gemt');
          this.props.controlRoomStore.uiStore.triggerRefreshPlants();
          return res;
        })
        .catch((err) => {
          console.log(err);
          toast('Værket kunne ikke gemmes, prøv igen');
          if (typeof err.response !== 'undefined' && err.response.data.message === 'Plant code already exists') {
            this.setState({
              errors: [{ msg: err.response.data.message }],
            });
          } else {
            this.setState({
              errors: [{ msg: err.message }],
            });
          }
          return err;
        });
    }

    onlineNamesInputSubmit = (event) => {
      event.preventDefault();
      const newValue = this.state.inputFields.onlineNamesInput ? this.state.inputFields.onlineNamesInput.trim() : null;
      if (newValue && newValue !== '') {
        const newValues = new Set(this.state.plant.onlineName).add(newValue);
        const newPlant = this.state.plant;
        newPlant.onlineName = Array.from(newValues);
        this.setState({
          plant: newPlant,
        });
      }

      const focusRef = ReactDOM.findDOMNode(this.onlineNamesInputRef.current);
      focusRef.focus();
    };

    onlineNamesRemove = (name, event) => {
      const { plant } = this.state;
      plant.onlineName.splice(plant.onlineName.indexOf(name), 1);
      this.setState({
        plant,
      });
    };

    render() {
      const { t } = this.props;
      console.log(this.state.plant.typeName);

      const form = (
        <Form horizontal>
          <Row>
            <Col sm={4}>
              <p style={{ textAlign: 'center', fontSize: '16px' }}>
Plant Mapping
                <div className="hr">
                  <hr style={{
                    border: ' 0,5px solid', color: 'grey', borderColor: 'grey', marginTop: '0px',
                  }}
                  />
                </div>
              </p>
              <FormGroup controlId="nameGroup">
                <Col componentClass={ControlLabel} sm={4} smOffset={0}>{t('unit-name')}</Col>
                <Col sm={8}>
                  <FormControl name="name" type="text" value={this.state.plant.name} onChange={this.handleChange} />
                </Col>
              </FormGroup>
              <FormGroup controlId="energinetDkIdGroup">
                <Col componentClass={ControlLabel} sm={4} smOffset={0}>{t('map-name')}</Col>
                <Col sm={8}>
                  <FormControl
                    name="energinetDkId"
                    type="text"
                    value={this.state.plant.energinetDkId}
                    onChange={this.handleChange}
                  />
                </Col>
              </FormGroup>
              <FormGroup controlId="priceAreaNameGroup">
                <Col componentClass={ControlLabel} sm={4} smOffset={0}>{t('price-range')}</Col>
                <Col sm={8}>
                  <FormControl
                    name="priceAreaName"
                    componentClass="select"
                    onChange={this.handleChange}
                    value={this.state.plant.priceAreaName}
                  >
                    <option value="dk1">dk1</option>
                    <option value="dk2">dk2</option>
                    <option value="SE1">SE1</option>
                    <option value="SE2">SE2</option>
                    <option value="SE3">SE3</option>
                    <option value="SE4">SE4</option>
                    <option value="NO1">NO1</option>
                    <option value="NO2">NO2</option>
                    <option value="NO3">NO3</option>
                    <option value="NO4">NO4</option>
                    <option value="NO5">NO5</option>
                  </FormControl>
                </Col>
              </FormGroup>
              <FormGroup controlId="eanGroup">
                <Col componentClass={ControlLabel} sm={4} smOffset={0}>EAN</Col>
                <Col sm={8}>
                  <FormControl
                    name="ean"
                    type="text"
                    value={this.state.plant.ean}
                    onChange={this.handleChange}
                  />
                </Col>
              </FormGroup>
              <FormGroup controlId="codeGroup">
                <Col componentClass={ControlLabel} sm={4} smOffset={0}>{t('codeS')}</Col>
                <Col sm={8}>
                  <FormControl name="code" type="text" value={this.state.plant.code} onChange={this.handleChange} />
                </Col>
              </FormGroup>
              <FormGroup controlId="eicCodeGroup">
                <Col componentClass={ControlLabel} sm={4} smOffset={0}>Eic code</Col>
                <Col sm={8}>
                  <FormControl
                    name="eicCode"
                    type="text"
                    value={this.state.plant.eicCode}
                    onChange={this.handleChange}
                  />
                </Col>
              </FormGroup>
              <FormGroup controlId="dbvNumberGroup">
                <Col componentClass={ControlLabel} sm={4} smOffset={0}>DBV-nr.</Col>
                <Col sm={8}>
                  <FormControl
                    name="dbvNumber"
                    type="text"
                    value={this.state.plant.dbvNumber}
                    onChange={this.handleChange}
                  />
                </Col>
              </FormGroup>
              <br />
              <p style={{ textAlign: 'center', fontSize: '16px' }}>
Activation and Groups
                <div className="hr">
                  <hr style={{
                    border: ' 0,5px solid', color: 'grey', borderColor: 'grey', marginTop: '0px',
                  }}
                  />
                </div>
              </p>

              <FormGroup controlId="reserveGroup">
                <Col componentClass={ControlLabel} sm={4} smOffset={0}>Reserve</Col>
                <Col sm={1}>
                  <Checkbox checked={this.state.plant.reserve} name="reserve" onChange={this.handleChange} />
                </Col>

                <Col controlId="staticActivationGroupMasterGroup" componentClass={ControlLabel} sm={4} smOffset={0}>{t('group-master')}</Col>
                <Col sm={1}>
                  <Checkbox
                    checked={this.state.plant.staticActivationGroupMaster}
                    name="staticActivationGroupMaster"
                    onChange={this.handleChange}
                  />
                </Col>
              </FormGroup>

              <FormGroup controlId="qGroupGroup">
                <Col componentClass={ControlLabel} sm={4} smOffset={0}>{t('q-group')}</Col>
                <Col sm={1}>
                  <Checkbox checked={this.state.plant.qgroup} name="qgroup" onChange={this.handleChange} />
                </Col>
              </FormGroup>
              <FormGroup controlId="staticActivationGroupGroup">
                <Col componentClass={ControlLabel} sm={4} smOffset={0}>{t('static-activation-group')}</Col>
                <Col sm={8}>
                  <FormControl
                    name="staticActivationGroup"
                    type="text"
                    value={this.state.plant.staticActivationGroup}
                    onChange={this.handleChange}
                  />
                </Col>
              </FormGroup>

              <FormGroup controlId="activationActionGroup">
                <Col componentClass={ControlLabel} sm={4} smOffset={0}>{t('activation-action')}</Col>
                <Col sm={8}>
                  <FormControl
                    name="activationAction"
                    type="text"
                    value={this.state.plant.activationAction}
                    onChange={this.handleChange}
                  />
                </Col>
              </FormGroup>
              <p style={{ textAlign: 'center', fontSize: '16px' }}>
                Effectplan
                <div className="hr">
                  <hr style={{
                    border: ' 0,5px solid', color: 'grey', borderColor: 'grey', marginTop: '0px',
                  }}
                  />
                </div>
              </p>
              <FormGroup controlId="ftpEffectplan">
                <Col componentClass={ControlLabel} sm={4} smOffset={0}>FTP</Col>
                <Col sm={8}>
                  <Checkbox
                    checked={this.state.plant.ftpEffectplan}
                    name="ftpEffectplan"
                    onChange={this.handleChange}
                  />
                </Col>
              </FormGroup>
            </Col>
            <Col sm={4}>
              <p style={{ textAlign: 'center', fontSize: '16px' }}>
Plant Type
                <div className="hr">
                  <hr style={{
                    border: ' 0,5px solid', color: 'grey', borderColor: 'grey', marginTop: '0px',
                  }}
                  />
                </div>
              </p>

              <FormGroup controlId="producerGroup">
                <Col componentClass={ControlLabel} sm={4} smOffset={0}>Type*</Col>
                <Col sm={8}>
                  <Radio
                    onChange={this.handleProducerConsumerChanged}
                    checked={this.state.plant.producer}
                    value="producer"
                    name="producerConsumer"
                    inline
                  >
Værk
                  </Radio>
                  <Radio
                    onChange={this.handleProducerConsumerChanged}
                    checked={!this.state.plant.producer}
                    value="consumer"
                    name="producerConsumer"
                    inline
                  >
Kedel
                  </Radio>
                </Col>
              </FormGroup>

              <FormGroup controlId="capacityMegawattGroup">
                <Col componentClass={ControlLabel} sm={4} smOffset={0}>{t('capacityS')}</Col>
                <Col sm={8}>
                  <FormControl
                    name="capacityMegawatt"
                    type="text"
                    value={this.state.plant.capacityMegawatt}
                    onChange={this.handleChange}
                  />
                </Col>
              </FormGroup>
              <FormGroup controlId="startGradientGroup">
                <Col componentClass={ControlLabel} sm={4} smOffset={0}>{t('starting-gradient')}</Col>
                <Col sm={8}>
                  <FormControl
                    name="startGradient"
                    type="text"
                    value={this.state.plant.startGradient}
                    onChange={this.handleChange}
                  />
                </Col>
              </FormGroup>
              <FormGroup controlId="stopGradientGroup">
                <Col componentClass={ControlLabel} sm={4} smOffset={0}>
                  {t('stopping-gradient')}
*
                </Col>
                <Col sm={8}>
                  <FormControl
                    name="stopGradient"
                    type="text"
                    value={this.state.plant.stopGradient}
                    onChange={this.handleChange}
                  />
                </Col>
              </FormGroup>
              <FormGroup controlId="deadTimeGroup">
                <Col componentClass={ControlLabel} sm={4} smOffset={0}>{t('dead-time')}</Col>
                <Col sm={8}>
                  <FormControl
                    name="deadTime"
                    type="text"
                    value={this.state.plant.deadTime}
                    onChange={this.handleChange}
                  />
                </Col>
              </FormGroup>
              <br />
              <p style={{ textAlign: 'center', fontSize: '16px' }}>
Online Values
                <div className="hr">
                  <hr style={{
                    border: ' 0,5px solid', color: 'grey', borderColor: 'grey', marginTop: '0px',
                  }}
                  />
                </div>
              </p>


              <FormGroup controlId="tsoEnabled">
                <Col componentClass={ControlLabel} sm={4} smOffset={0}>{t('tsoEnabled')}</Col>
                <Col sm={8}>
                  <Checkbox
                    checked={this.state.plant.tsoEnabled}
                    name="tsoEnabled"
                    onChange={this.handleChange}
                  />
                </Col>
              </FormGroup>
              <FormGroup controlId="onlineValueSource">
                <Col componentClass={ControlLabel} sm={4} smOffset={0}>Value Source</Col>
                <Col sm={8}>
                  <FormControl
                    name="onlineValueSource"
                    componentClass="select"
                    onChange={this.handleChange}
                    value={this.state.plant.onlineValueSource}
                  >
                    <option value="chpcom">CHPCOM</option>
                    <option value="liab">Liab</option>
                    <option value="tso">TSO</option>
                  </FormControl>
                </Col>


              </FormGroup>
              <FormGroup controlId="websiteGroup">
                <Col componentClass={ControlLabel} sm={4} smOffset={0}>iFrameUrl</Col>
                <Col sm={8}>
                  <FormControl
                    name="website"
                    type="text"
                    value={this.state.plant.website}
                    onChange={this.handleChange}
                  />
                </Col>
              </FormGroup>

              <FormGroup controlId="onlineNamesInputGroup">
                <Col componentClass={ControlLabel} sm={4} smOffset={0}>{t('online-name')}</Col>
                <Col sm={8}>
                  <InputGroup>
                    <FormControl
                      name="onlineNamesInput"
                      type="text"
                      value={this.state.inputFields.onelineNamesInput}
                      ref={this.onlineNamesInputRef}
                      onChange={this.handleLocalChange}
                    />
                    <InputGroup.Button>
                      <Button onClick={this.onlineNamesInputSubmit}>
                        <span className="glyphicon glyphicon-plus" />
                      </Button>
                    </InputGroup.Button>
                  </InputGroup>
                </Col>
              </FormGroup>
              <FormGroup>
                <Col sm={5} />
                <Col sm={8}>
                  {this.state.plant.onlineName.map(name => (
                    <div className="input-group" key={name}>
                      <input
                        type="text"
                        className="form-control"
                        readOnly
                        placeholder="Amount"
                        value={name}
                        style={{ backgroundColor: 'white' }}
                      />
                      <span className="input-group-btn">
                        <Button onClick={() => this.onlineNamesRemove(name)}>
                          <span className="glyphicon glyphicon-minus" />
                        </Button>
                      </span>
                    </div>
                  ))}
                </Col>
              </FormGroup>
              <p style={{ textAlign: 'center', fontSize: '16px' }}>
MKPlus
                <div className="hr">
                  <hr style={{
                    border: ' 0,5px solid', color: 'grey', borderColor: 'grey', marginTop: '0px',
                  }}
                  />
                </div>
              </p>


              <FormGroup controlId="mkplusEnabledGroup">
                <Col componentClass={ControlLabel} sm={4} smOffset={0}>{t('mkplus-enabled')}</Col>
                <Col sm={8}>
                  <Checkbox
                    checked={this.state.plant.mkplusEnabled}
                    name="mkplusEnabled"
                    onChange={this.handleChange}
                  />
                </Col>
              </FormGroup>

              <p style={{ textAlign: 'center', fontSize: '16px' }}>
                Intraday
                <div className="hr">
                  <hr style={{
                    border: ' 0,5px solid', color: 'grey', borderColor: 'grey', marginTop: '0px',
                  }}
                  />
                </div>
              </p>


              <FormGroup controlId="intradayEnabled">
                <Col componentClass={ControlLabel} sm={4} smOffset={0}>{t('intraday-enabled')}</Col>
                <Col sm={8}>
                  <Checkbox
                    checked={this.state.plant.intradayEnabled}
                    name="intradayEnabled"
                    onChange={this.handleChange}
                  />
                </Col>
              </FormGroup>

              <p style={{ textAlign: 'center', fontSize: '16px' }}>
                {t('price-dependent-plan')}
                <div className="hr">
                  <hr style={{
                    border: ' 0,5px solid', color: 'grey', borderColor: 'grey', marginTop: '0px',
                  }}
                  />
                </div>
              </p>


              <FormGroup controlId="priceDependentEnabled">
                <Col componentClass={ControlLabel} sm={4} smOffset={0}>{t('price-dependent-enabled')}</Col>
                <Col sm={8}>
                  <Checkbox
                    checked={this.state.plant.priceDependentEnabled}
                    name="priceDependentEnabled"
                    onChange={this.handleChange}
                  />
                </Col>
              </FormGroup>

            </Col>
            <Col sm={4}>
              <p style={{ textAlign: 'center', fontSize: '16px' }}>
Nimbus mapping
                <div className="hr">
                  <hr style={{
                    border: ' 0,5px solid', color: 'grey', borderColor: 'grey', marginTop: '0px',
                  }}
                  />
                </div>
              </p>

              <FormGroup controlId="productionPlan">
                <Col componentClass={ControlLabel} sm={4} smOffset={0}>{t('productionCons')}</Col>
                <Col sm={8}>
                  <FormControl name="productionPlan" type="text" value={this.state.plant.productionPlan} onChange={this.handleChange} />
                </Col>
              </FormGroup>

              <p style={{ textAlign: 'center', fontSize: '16px' }}>
                {t('mplan')}
                <div className="hr">
                  <hr style={{
                    border: ' 0,5px solid', color: 'grey', borderColor: 'grey', marginTop: '0px',
                  }}
                  />
                </div>
              </p>

              <FormGroup controlId="manualPlanUp">
                <Col componentClass={ControlLabel} sm={4} smOffset={0}>{t('up-vol')}</Col>
                <Col sm={8}>
                  <FormControl name="manualPlanUp" type="text" value={this.state.plant.manualPlanUp} onChange={this.handleChange} />
                </Col>
              </FormGroup>
              <FormGroup controlId="manualPlanDown">
                <Col componentClass={ControlLabel} sm={4} smOffset={0}>{t('down-vol')}</Col>
                <Col sm={8}>
                  <FormControl name="manualPlanDown" type="text" value={this.state.plant.manualPlanDown} onChange={this.handleChange} />
                </Col>
              </FormGroup>
              <FormGroup controlId="manualPlanUpPrice">
                <Col componentClass={ControlLabel} sm={4} smOffset={0}>{t('up-price')}</Col>
                <Col sm={8}>
                  <FormControl name="manualPlanUpPrice" type="text" value={this.state.plant.manualPlanUpPrice} onChange={this.handleChange} />
                </Col>
              </FormGroup>
              <FormGroup controlId="manualPlanDownPrice">
                <Col componentClass={ControlLabel} sm={4} smOffset={0}>{t('down-price')}</Col>
                <Col sm={8}>
                  <FormControl name="manualPlanDownPrice" type="text" value={this.state.plant.manualPlanDownPrice} onChange={this.handleChange} />
                </Col>
              </FormGroup>

              <p style={{ textAlign: 'center', fontSize: '16px' }}>
                {t('fplan')}
                <div className="hr">
                  <hr style={{
                    border: ' 0,5px solid', color: 'grey', borderColor: 'grey', marginTop: '0px',
                  }}
                  />
                </div>
              </p>

              <FormGroup controlId="frequencyPlanUp">
                <Col componentClass={ControlLabel} sm={4} smOffset={0}>{t('up-vol')}</Col>
                <Col sm={8}>
                  <FormControl name="frequencyPlanUp" type="text" value={this.state.plant.frequencyPlanUp} onChange={this.handleChange} />
                </Col>
              </FormGroup>
              <FormGroup controlId="frequencyPlanDown">
                <Col componentClass={ControlLabel} sm={4} smOffset={0}>{t('down-vol')}</Col>
                <Col sm={8}>
                  <FormControl name="frequencyPlanDown" type="text" value={this.state.plant.frequencyPlanDown} onChange={this.handleChange} />
                </Col>
              </FormGroup>
              <FormGroup controlId="frequencyPlanUpPrice">
                <Col componentClass={ControlLabel} sm={4} smOffset={0}>{t('up-price')}</Col>
                <Col sm={8}>
                  <FormControl name="frequencyPlanUpPrice" type="text" value={this.state.plant.frequencyPlanUpPrice} onChange={this.handleChange} />
                </Col>
              </FormGroup>
              <FormGroup controlId="frequencyPlanDownPrice">
                <Col componentClass={ControlLabel} sm={4} smOffset={0}>{t('down-price')}</Col>
                <Col sm={8}>
                  <FormControl name="frequencyPlanDownPrice" type="text" value={this.state.plant.frequencyPlanDownPrice} onChange={this.handleChange} />
                </Col>
              </FormGroup>

              <p style={{ textAlign: 'center', fontSize: '16px' }}>
                {t('iplan')}
                <div className="hr">
                  <hr style={{
                    border: ' 0,5px solid', color: 'grey', borderColor: 'grey', marginTop: '0px',
                  }}
                  />
                </div>
              </p>

              <FormGroup controlId="intradayPlanBuy">
                <Col componentClass={ControlLabel} sm={4} smOffset={0}>{t('buy-vol')}</Col>
                <Col sm={8}>
                  <FormControl name="intradayPlanBuy" type="text" value={this.state.plant.intradayPlanBuy} onChange={this.handleChange} />
                </Col>
              </FormGroup>
              <FormGroup controlId="intradayPlanSell">
                <Col componentClass={ControlLabel} sm={4} smOffset={0}>{t('sell-vol')}</Col>
                <Col sm={8}>
                  <FormControl name="intradayPlanSell" type="text" value={this.state.plant.intradayPlanSell} onChange={this.handleChange} />
                </Col>
              </FormGroup>
              <FormGroup controlId="intradayPlanBuyPrice">
                <Col componentClass={ControlLabel} sm={4} smOffset={0}>{t('buy-price')}</Col>
                <Col sm={8}>
                  <FormControl name="intradayPlanBuyPrice" type="text" value={this.state.plant.intradayPlanBuyPrice} onChange={this.handleChange} />
                </Col>
              </FormGroup>
              <FormGroup controlId="intradayPlanSellPrice">
                <Col componentClass={ControlLabel} sm={4} smOffset={0}>{t('sell-price')}</Col>
                <Col sm={8}>
                  <FormControl name="intradayPlanSellPrice" type="text" value={this.state.plant.intradayPlanSellPrice} onChange={this.handleChange} />
                </Col>
              </FormGroup>
              <FormGroup controlId="resultSeries">
                <Col componentClass={ControlLabel} sm={4} smOffset={0}>{t('intraday-result-series')}</Col>
                <Col sm={8}>
                  <FormControl name="resultSeries" type="text" value={this.state.plant.resultSeries} onChange={this.handleChange} />
                </Col>
              </FormGroup>

              <p style={{ textAlign: 'center', fontSize: '16px' }}>
                {t('price-dependent-plan')}
                <div className="hr">
                  <hr style={{
                    border: ' 0,5px solid', color: 'grey', borderColor: 'grey', marginTop: '0px',
                  }}
                  />
                </div>
              </p>

              <FormGroup controlId="priceDependentVol">
                <Col componentClass={ControlLabel} sm={4} smOffset={0}>{t('productionCons')}</Col>
                <Col sm={8}>
                  <FormControl name="priceDependentVol" type="text" value={this.state.plant.priceDependentVol} onChange={this.handleChange} />
                </Col>
              </FormGroup>
              <FormGroup controlId="priceDependentPrice">
                <Col componentClass={ControlLabel} sm={4} smOffset={0}>{t('price')}</Col>
                <Col sm={8}>
                  <FormControl name="priceDependentPrice" type="text" value={this.state.plant.priceDependentPrice} onChange={this.handleChange} />
                </Col>
              </FormGroup>

            </Col>
          </Row>
        </Form>
      );
      const errors = (
        <div>
          {this.state.errors.map(error => (
            <Alert bsStyle="danger">{error.msg}</Alert>
          ))}
        </div>
      );

      const onlineNames = (
        <Form>
          <Row>

            <Col sm={12} />
            <Col sm={12}>
              <br />
              <p style={{ textAlign: 'center', fontSize: '16px' }}>
Customer Handling and Contact
                <div className="hr">
                  <hr style={{
                    border: ' 0,5px solid', color: 'grey', borderColor: 'grey', marginTop: '0px',
                  }}
                  />
                </div>
              </p>

              <FormGroup controlId="formControlsTextarea">
                <ControlLabel>
                  {t('notes')}
:
                </ControlLabel>
                <FormControl
                  componentClass="textarea"
                  rows={5}
                  placeholder={t('note')}
                  name="notes"
                  value={this.state.inputFields.notes}
                  onChange={this.handleLocalChange}
                />
              </FormGroup>
            </Col>
          </Row>
        </Form>
      );

      const contacts = (
        <Row>
          <Col sm={12}>
            <Panel header={t('contacts')}>
              <Table striped bordered>
                <thead>
                  <tr>
                    <th>{t('priority')}</th>
                    <th>{t('name')}</th>
                    <th>Tlf</th>
                    <th>Email</th>
                    <th>{t('primary')}</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.plant.contacts.map(contact => (
                    <tr key={contact.uuid}>
                      <td>{contact.priority}</td>
                      <td>{contact.fullName}</td>
                      <td>{contact.phoneNumber}</td>
                      <td>{contact.email}</td>
                      <td>{contact.primaryContact ? 'Ja' : 'Nej'}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Panel>
          </Col>
        </Row>
      );
      return (
        <Modal id="edit-p" show={this.props.open} onHide={this.onClose} bsSize="lg" onShow={this.onShow}>
          <Modal.Header>{t('edit-plant')}</Modal.Header>
          <Modal.Body>
            {errors}
            {form}
            {onlineNames}
            {contacts}
          </Modal.Body>
          <Modal.Footer>
            <Button bsStyle="primary" onClick={this.onConfirm}>{t('save')}</Button>
            <Button onClick={this.onClose}>{t('close')}</Button>
          </Modal.Footer>
        </Modal>

      );
    }

    handleProducerConsumerChanged = (event) => {
      const { plant } = this.state;
      plant.producer = event.target.value === 'producer';
      this.setState({
        plant,
      });
    }
}

PlantForm.propTypes = {
  onClose: PropTypes.func.isRequired,
  plant: PropTypes.instanceOf(Plant).isRequired,
};

export default PlantForm;
