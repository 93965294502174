// @flow
import { action, observable } from 'mobx';

/**
 * To accompany react modals in the classic 'visible', 'show/hide' and submit-action pattern.
 * Simply provide a submit action and you have your Store ready control.
 */
export default class ModalCtrl {
    @observable visible: boolean;

    constructor(submit) {
      this.visible = false;
      this.submit = submit;
    }

    @action
    toggle() {
      this.visible = !this.visible;
    }
}
