import React from 'react';
import { inject, observer } from 'mobx-react';
import { Tab, Tabs } from 'react-bootstrap';
import Chart from '../plant/Chart';
import ContactsTable from '../plant/ContactsTable';
import BidPlanTable from '../plant/BidPlanTable';
import MKPlusTest from '../plant/MKPlusTest';
import IFrameFromUrl from '../plant/IFrameFromUrl';
import IFrameContent from '../plant/IFrameContent';
import ErrorBoundary from '../common/ErrorBoundary';
import { translate } from 'react-i18next';
import PlanTable from '../plant/PlanTable';
import AppConfig from '../.././appconfig.json';

@translate()
@inject('planStore', 'uiStore') @observer
class Plant extends React.Component {
  showPage = tabKey => this.props.uiStore.visitedPages[tabKey];

  render() {
    const { planStore, t, uiStore } = this.props;
    const isTableEnabled = planStore.activePlan.enableTable;
    const selectedPlant = uiStore.selectedItem;

    // If plant has EIC Code - show the tab "MKPlus"
    const showMKPlusTab = !!selectedPlant.eicCode && selectedPlant.mkplusEnabled;

    // Rendering this page without selecting a plant, does not make sense, so graph is not rendered.
    const showChart = !!selectedPlant.isPlant;

    return (
      <Tabs bsStyle="pills" defaultActiveKey={1} id="tab-nav" activeKey={uiStore.currentTabKey} onSelect={this.onSelectHandler}>
        <Tab eventKey={0} title={t('frontpage')}>
          {this.showPage(0)
            && <IFrameContent url={selectedPlant.website} baseUrl={AppConfig.MKPlusTest} />
          }
        </Tab>
        <Tab eventKey={1} title={t('chart')}>
          {showChart
            && <Chart />
          }
        </Tab>
        <Tab eventKey={2} disabled={!isTableEnabled} title={t('tabel')}>
          {this.showPage(2)
            && <PlanTable />
          }
        </Tab>
        <Tab eventKey={3} title={t('bid-plan')}>
          {this.showPage(3)
            && <BidPlanTable />
          }
        </Tab>
        <Tab eventKey={4} title={t('contacts')}>
          {this.showPage(4) && (
          <ErrorBoundary errorMessage={t('error-opening-edit-contact')}>
            <ContactsTable />
          </ErrorBoundary>
          )}
        </Tab>
        {showMKPlusTab && (
          <Tab eventKey={5} title="MKPlus">
          {this.showPage(5) &&
            <ErrorBoundary errorMessage={t('error-opening-edit-contact')}>
              <MKPlusTest />
            </ErrorBoundary>
          }
        </Tab>)}
      </Tabs>
    );
  }

  componentDidUpdate() {
    const isTableEnabled = this.props.planStore.activePlan.enableTable;

    if (!isTableEnabled && this.props.uiStore.currentTabKey === 2) {
      this.props.uiStore.setCurrentTabKey(1);
    }
  }

  onSelectHandler = (tabKey) => {
    const visitedPages = this.props.uiStore.visitedPages.slice(0);

    visitedPages[tabKey] = true;

    this.props.uiStore.setCurrentTabKey(tabKey);
    this.props.uiStore.setVisitedPages(visitedPages);
  }
}

export default Plant;
