import React from 'react';
import { inject, observer } from 'mobx-react';
import { toast } from 'react-toastify';
import { translate } from 'react-i18next';

export default function (ComposedComponent) {
    @translate()
    @inject('authStore') @observer
  class isAdmin extends React.Component {
      componentDidMount() {
        if (!this.props.authStore.isAdmin) {
          toast(this.props.t('access-not-allowed-here'));
          this.props.history.push('/plants');
        }
      }

      componentDidUpdate(nextProps) {
        if (!nextProps.authStore.isAdmin) {
          this.props.history.push('/plants');
        }
      }

      render() {
        return (
          <ComposedComponent {...this.props} />
        );
      }
    }

    return isAdmin;
}
