import axios from 'axios';

export function setAuthorizationToken(token) {
  if (token) {
    // TODO: Must use this format `Bearer ${token}`
    axios.defaults.headers.common.Authorization = `Bearer ${token}`;

    const newCookie = createJwtCookie(token);
    document.cookie = newCookie;
  } else {
    delete axios.defaults.headers.common.Authorization;

    // Deletes the cookie.
    console.log(document.cookie);
    document.cookie = deleteJwtCookie();
  }
}

export function isAuthorizationTokenSet() {
  return axios.defaults.headers.common.Authorization !== undefined;
}

/**
 * Finds the top most domain name. Examples:
 * sub.domain.com => domain.com
 * domain.com => domain.com
 * com => com
 * @returns {string}
 */
function getTopDomainName() {
  const splitted = window.location.hostname.split('.').reverse();
  let startFrom = 1;
  if (splitted.length === 1) {
    // We have not a real domain name, maybe localhost.
    startFrom = 0;
  }
  let domain = splitted[0];
  for (let i = startFrom; i > 0; i--) {
    domain = `${splitted[i]}.${domain}`;
  }
  return domain;
}

/**
 * This creates a cookie with the current token. This allow iframes to send cookies.
 * @param token
 * @returns {string}
 */
function createJwtCookie(token) {
  const domain = getTopDomainName();
  return `jwt=${token};path=/;domain=${domain}`;
}

/**
 * Creates a cookie which can be used to overwrite the previous set cookie.
 * @returns {string}
 */
function deleteJwtCookie() {
  const domain = getTopDomainName();
  return `jwt=;path=/;domain=${domain};expires=0`;
}
