import React from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios'
import './index.css';
//styles
import './App.less';
import './AppStyl.css';

//import '../node_modules/bootstrap/dist/css/bootstrap.min.css';

import {configure} from 'mobx'
import {Provider} from 'mobx-react'

import {hydrateJwtToken, logout} from './actions/AuthAction'

import AuthStore from './stores/AuthStore'
import {UiStore} from "./stores/UiStore";
import {PlantStore} from './stores/PlantStore'
import MKPlusStore from './stores/MKPlusStore';
import {PlanStore} from './stores/PlanStore'
import {MessageStore} from './stores/MessageStore';

import {I18nextProvider} from 'react-i18next';
import i18n from './i18n'; // initialized i18next instance

import { BrowserRouter as Router } from 'react-router-dom';

import App from './App';

import {ControlRoomStore} from "./controlroom/stores/ControlRoomStore";

import AppConfig from './appconfig.json';

configure({ enforceActions: "observed" });

// AuthStore must be defined early, as it is used by axios interceptor definition.
const authStore = new AuthStore();

const baseURLHost = AppConfig.ApiUrl; 

axios.defaults.baseURL = baseURLHost + '/orwell-rest';
axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.interceptors.response.use(function (response) {
    return response;
}, function (error) {

    if (error.response && error.response.status === 401) {
        localStorage.clear();
        // Setting an empty user should trigger re-rendering of the app.g
        logout(authStore);
    }
    return Promise.reject(error);
})

if (localStorage.jwtToken) {
    hydrateJwtToken(authStore, localStorage.jwtToken);
}

const uiStore = new UiStore();
const messageStore = new MessageStore(uiStore, authStore);
const planStore = new PlanStore(uiStore);
const mkPlusStore = new MKPlusStore(uiStore);
const plantStore = new PlantStore(uiStore, authStore);
const controlRoomStore = new ControlRoomStore(uiStore, authStore);
const stores = {authStore, uiStore, plantStore, mkPlusStore, planStore, messageStore, controlRoomStore};
authStore.setStores([uiStore, plantStore, mkPlusStore, planStore, messageStore, controlRoomStore])

ReactDOM.render(
        <I18nextProvider i18n={i18n}>
            <Provider {...stores}>
                <Router>
                  <App />
                </Router>
            </Provider>
        </I18nextProvider>,
        document.getElementById('root')
);
