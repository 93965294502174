import React from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { translate } from 'react-i18next';
import { inject, observer } from 'mobx-react';
import { TextFieldGroup } from '../common/horizontal/TextFieldGroup';
import MyDatePicker from '../common/MyDatePicker';
import DayPicker from 'react-day-picker';
import moment from 'moment';
import { computed } from 'mobx';

@translate()
@inject('uiStore', 'plantStore') @observer
class IntradayPrices extends React.Component {
  mapStateToProps() {
    return {
      plantStore: this.props.plantStore,
      uiStore: this.props.uiStore,
      shouldShowIntradayPriceModal: this.props.plantStore.shouldShowIntradayPriceModal,
      intradayBuyPrice: this.props.uiStore.selectedPlant.intradayBuyPrice,
      intradaySellPrice: this.props.uiStore.selectedPlant.intradaySellPrice,
      refreshRegulatingPower: this.props.uiStore.refreshRegulatingPower,
    };
  }


  @computed get isIntradayEnabled() {
    return this.props.uiStore.selectedPlant.intradayEnabled;
  }

  render() {
    const { t } = this.props;
    const myProps = this.mapStateToProps();

    return (
      <div>
        <Button onClick={this.toggleModal}>{t('intraday-prices')}</Button>
        <Modal show={myProps.shouldShowIntradayPriceModal} onHide={this.toggleModal} className="regulating-power-modal">
          <Modal.Header closeButton>
            <Modal.Title>{t('intraday-prices')}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <Form horizontal onSubmit={this.submitIntradayPrices}>
                <TextFieldGroup
                  name={t('buy-price-modal')}
                  initValue={myProps.intradayBuyPrice}
                  givenRef={(ref) => { this.intradayBuyPriceField = ref; }}
                  disabled={!this.isIntradayEnabled}
                  onChange={this.handleChangePriceUp}


                />
                <TextFieldGroup
                  name={t('sell-price-modal')}
                  initValue={myProps.intradaySellPrice}
                  givenRef={(ref) => { this.intradaySellPriceField = ref; }}
                  disabled={!this.isIntradayEnabled}
                  onChange={this.handleChangePriceDown}

                />
              </Form>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              onClick={this.submitIntradayPrices}
              bsStyle="primary"
              type="submit"
              disabled={false}
            >
              {t('save')}
            </Button>
            <Button onClick={this.toggleModal}>{t('abort-close')}</Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }

  handleChangePriceUp= (e) => { this.setState({ intradayBuyPrice: e.target.value }); };

  handleChangePriceDown= (e) => { this.setState({ intradaySellPrice: e.target.value }); };


  toggleModal = () => {
    const myProps = this.mapStateToProps();
    myProps.plantStore.toggleIntradayPriceModal();
  }

  submitIntradayPrices = (event) => {
    event.preventDefault();
    const myProps = this.mapStateToProps();
    myProps.plantStore.submitIntradayPrices(this.intradayBuyPriceField.value, this.intradaySellPriceField.value);
  }
}

export default IntradayPrices;
