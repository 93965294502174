import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { computed } from 'mobx';
import { Glyphicon, Button, Panel } from 'react-bootstrap';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import { toast } from 'react-toastify';
import moment from 'moment';
import ReactTable from 'react-table';

import 'moment/locale/da';
import { translate } from 'react-i18next';
import DeleteButton from '../../components/common/DeleteButton';
import NewMessageModal from './NewMessageModal';

const dateformat = 'DD/MM/YYYY';

@translate()
@inject('controlRoomStore') @observer
export default class ControlRoomMessages extends React.Component {
  columns = [
    {
      id: 'from',
      Header: 'Fra',
      minWidth: 30,
      accessor: m => m.fromDate.format(dateformat),
    },
    {
      id: 'to',
      Header: 'Til',
      minWidth: 30,
      accessor: m => m.toDate.format(dateformat),
    },
    {
      Header: 'Besked',
      accessor: 'title',
      minWidth: 150,
    },
    {
      id: 'button',
      accessor: m => (
        <DeleteButton
          onConfirmation={() => this.deleteMessage(m)}
          t={this.props.t}
          title="Slet besked"
          message="Klik bekræft for at slette beskeden"
          className="btn-small"
        >
          <Glyphicon glyph="trash" />
        </DeleteButton>
      ),
      minWidth: 10,
    },
  ]

  constructor(props) {
    super(props);

    const from = moment().subtract(1, 'day');
    const to = moment().add(6, 'day');

    this.props.controlRoomStore.setMessagesFromDate(from);
    this.props.controlRoomStore.setMessagesToDate(to);

    this.state = {
      showTable: false,
    };
  }

    @computed get messages() {
    return this.props.controlRoomStore.messages.slice();
  }

    handleFromDayChange = (date) => {
      this.props.controlRoomStore.setMessagesFromDate(date);
    };

    handleToDayChange = (date) => {
      this.props.controlRoomStore.setMessagesToDate(date);
    };

    handleSearch = () => {
      this.setState({ showTable: true });

      this.props.controlRoomStore.refreshMessages();
    }

    render() {
      const { t } = this.props;
      const { showTable } = this.state;
      const formatDate = date => moment(date).format(dateformat);
      const MONTHS = [t('january'), t('february'), t('march'), t('april'), t('may'), t('june'), t('july'), t('august'), t('september'), t('october'), t('november'), t('december')];
      const WEEKDAYS_SHORT = [t('sunday-short'), t('monday-short'), t('tuesday-short'), t('wedensday-short'), t('thursday-short'), t('friday-short'), t('saturday-short')];
      return (
        <div>
          <Panel>
            <Panel.Body>
              <div className="custom-input-day-picker marg-bot">
                <DayPickerInput
                  onDayChange={day => this.handleFromDayChange(day)}
                  placeholder="Fra"
                  value={this.props.controlRoomStore.messagesFromDate.toDate()}
                  formatDate={formatDate}
                  dayPickerProps={{
                    months: MONTHS,
                    weekdaysShort: WEEKDAYS_SHORT,
                    firstDayOfWeek: 1,
                  }}
                />
              </div>
              <div className="custom-input-day-picker marg-bot">
                <DayPickerInput
                  onDayChange={day => this.handleToDayChange(day)}
                  placeholder="Til"
                  value={this.props.controlRoomStore.messagesToDate.toDate()}
                  formatDate={formatDate}
                  dayPickerProps={{
                    months: MONTHS,
                    weekdaysShort: WEEKDAYS_SHORT,
                    firstDayOfWeek: 1,
                  }}
                />
              </div>

              <Button onClick={this.handleSearch}>
                Søg
              </Button>

              <div className="text-right">
                <NewMessageModal />
              </div>
            </Panel.Body>
          </Panel>

          {showTable
            && (
              <ReactTable
                data={this.messages}
                columns={this.columns}
                showPagination={false}
                pageSize={this.messages.length === 0 ? 2 : this.messages.length}
                className="-striped -highlight"
                loadingText="Henter beskeder"
                loading={this.props.controlRoomStore.messagesLoading}
              />
            )
          }
        </div>
      );
    }

    deleteMessage = (message) => {
      this.props.controlRoomStore.deleteMessage(message)
        .then((response) => {
          toast('Besked blev slettet');
        })
        .catch((error) => {
          toast('Besked kunne ikke slettes. Prøv igen.');
        });
    }
}
