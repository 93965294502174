// @flow
import React from 'react';
import PropTypes from 'prop-types';
import {
  Form, FormGroup, FormControl, Col, ControlLabel, Modal, Button, Alert, Radio,
} from 'react-bootstrap';
import { computed } from 'mobx';
import Select from 'react-select';
import _ from 'lodash';
import User from '../classes/User';
import { toast } from 'react-toastify';
import { inject, observer } from 'mobx-react';
import { translate } from 'react-i18next';

@translate()
@inject('controlRoomStore') @observer
class UserFormModal extends React.Component {
  constructor(props) {
    super(props);
    this.onConfirm = this.onConfirm.bind(this);

    this.state = {
      errors: [],
      user: new User(),
      roles: [],
      plants: [],
    };
  }

  onConfirm = (event) => {
    event.preventDefault();
    const { user, roles, plants } = this.state;

    const mUser = new User().fromJson(user)
      .fromRolesAndPlants(roles, plants);

    console.log('User', mUser);

    if (mUser.isValid) {
      this.saveUser(mUser);
    } else {
      this.setState({
        errors: mUser.errors,
      });
    }
  }

  saveUser = (user) => {
    const { t } = this.props;

    this.props.controlRoomStore.saveUser(user)
      .then((res) => {
        toast(t('user-saved'));
        this.props.onClose();

        this.setState({
          user: new User(),
        });

        return res;
      })
      .catch((err) => {
        console.log(err);
        toast(t('user-not-saved'));

        this.setState({
          errors: [{ msg: err.message }],
        });
        return err;
      });
  }

  onShow = () => {
    const { user } = this.props;

    this.setState({
      errors: [],
      roles: user.getRoles(),
      plants: user.getPlants(),
      user,
    });
  }

  changeUserType = () => {
    const { user } = this.state;
    if (user.userType === 1) {
      user.userType = 0;
    } else {
      user.userType = 1;
    }
    this.setState({ user });
  }

  @computed get plants() {
    return _.sortBy(_.map(this.props.controlRoomStore.plants, plant => ({ code: plant.code, name: plant.name })), ['name']);
  }

  @computed get selectedPlants() {
    return _.map(this.props.controlRoomStore.plants, plant => ({ code: plant.code, name: plant.name }))
      .filter(plant => this.state.plants.includes(plant.code));
  }

  render() {
    const { t, roleOptions } = this.props;
    const { user } = this.state;

    const form = (
      <Form horizontal onSubmit={this.preventSubmit}>
        <FormGroup controlId="nameGroup">
          <Col componentClass={ControlLabel} sm={3}>{t('email')}</Col>
          <Col sm={8}>
            <FormControl
              disabled={!user.isNew}
              name="name"
              type="text"
              value={user.name}
              onChange={this.handleChange}
            />
          </Col>
        </FormGroup>
        {user.isNew}
        {user.isNew && (
        <FormGroup controlId="nameGroup">
          <Col componentClass={ControlLabel} sm={3}>Type</Col>
          <Col sm={8}>
            <Radio
              onChange={this.changeUserType}
              checked={user.userType}
              value="producer"
              name="producerConsumer"
              inline
            >
              UI
            </Radio>
            <Radio
              onChange={this.changeUserType}
              checked={!user.userType}
              value="producer"
              name="producerConsumer"
              inline
            >
                API
            </Radio>
          </Col>
        </FormGroup>
        )}
      </Form>
    );

    const roles = (
      <div>
        <Form horizontal onSubmit={this.preventSubmit}>
          <FormGroup controlId="rolesInputGroup">
            <Col componentClass={ControlLabel} sm={3}>Roller</Col>
            <Col sm={8}>
              <Select
                value={this.state.roles}
                isMulti
                getOptionLabel={r => r}
                getOptionValue={r => r}
                options={roleOptions}
                onChange={this.handleRoleInputChange}
                placeholder={t('select-roles')}
              />
            </Col>
          </FormGroup>
        </Form>
        {this.renderPlantSelector()}
      </div>
    );

    const errors = (
      <div>
        {this.state.errors.map((error, index) => (
          <Alert bsStyle="danger" key={index}>{t(error.msg)}</Alert>
        ))}
      </div>
    );

    return (
      <Modal show={this.props.open} onHide={this.onClose} onShow={this.onShow}>
        <Modal.Header>{t('edit-user')}</Modal.Header>
        <Modal.Body>
          {errors}
          {form}
          {roles}
        </Modal.Body>
        <Modal.Footer>
          <Button bsStyle="primary" onClick={this.onConfirm}>Gem</Button>
          <Button onClick={this.onClose}>{t('close')}</Button>
        </Modal.Footer>
      </Modal>
    );
  }

  renderPlantSelector = () => {
    const { t } = this.props;
    const indmelderSelected = this.state.roles.includes('indmelder');

    if (indmelderSelected) {
      return (
        <Form horizontal onSubmit={this.preventSubmit}>
          <FormGroup controlId="rolesInputGroup">
            <Col componentClass={ControlLabel} sm={3}>Vælg værker</Col>
            <Col sm={8}>
              <Select
                value={this.selectedPlants}
                isMulti
                options={this.plants}
                getOptionLabel={p => p.name}
                getOptionValue={p => p.code}
                onChange={this.handlePlantInputChange}
                placeholder={t('select-plants')}
              />
            </Col>
          </FormGroup>
        </Form>
      );
    }
  }

  handleRoleInputChange = (input) => {
    const roles = input == null
      ? []
      : input;

    this.setState({ roles });
  }

  handlePlantInputChange = (input) => {
    const plants = input == null
      ? []
      : input;

    this.setState({ plants: plants.map(p => p.code) });
  }

  handleChange = (event) => {
    const { target } = event;
    const value = target.type === 'checkuser' ? target.checked : target.value;
    const field = target.name;
    const { user } = this.state;
    user[field] = value;
    this.setState({ user });
  }

  onClose = (event) => {
    this.setState({ user: new User() });
    this.props.onClose();
  }

  preventSubmit = (e) => {
    e.preventDefault();
  }
}

UserFormModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  user: PropTypes.instanceOf(User).isRequired,
};

export default UserFormModal;
