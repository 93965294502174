import React from 'react';
import { computed } from 'mobx';
import { inject, observer } from 'mobx-react';
import {
  Button, ButtonGroup, Glyphicon, Col, Form, FormControl, FormGroup, Modal, Table,
} from 'react-bootstrap';
import { convertMinutesToDate, convertMinutesToHours, convertTimeToMinutes } from '../../actions/CommonAction';
import { toast } from 'react-toastify';
import { translate } from 'react-i18next';
import DeleteButton from '../common/DeleteButton';

@translate()
@inject('planStore', 'plantStore') @observer
export default class GenericPlanTable extends React.Component {
    state = {
      editModalShow: false,
      editModalPoint: {},
      editModalPointCopy: {},
      editModalTime: '',
    }

    @computed get planEntries() {
      let data = (this.props.planStore.plans.length > 0) ? this.props.planStore.activePlan.entries : [];

      // Added Sorting to Make the table sort reflect the point minute.
      data = data.sort((a, b) => a.minute - b.minute);

      return data;
    }

    render() {
      const { t } = this.props;
      const data = this.planEntries;
      const { selectedDate } = this.props.plantStore;
      const unit = t('mwh');

      return (
        <div>
          <Table striped bordered condensed hover>
            <thead>
              <tr>
                <th>{t('time')}</th>

                <th>{t('production')}</th>
                <th />
              </tr>
            </thead>
            <tbody>

              {data.map((point, index) =>
              // Changed to check selected date from uiStore inside Plantstore.
              // From this.props.plantStore.date ==> this.props.plantStore.uiStore.selectedDate
                (
                  <tr key={index}>
                    <td>{convertMinutesToDate(point.minute, selectedDate)}</td>
                    <td>{point.amountPowerOutputMegawatt === 0 || point.amountPowerOutputMegawatt ? point.amountPowerOutputMegawatt.toFixed(2) + unit : t('n-a')}</td>
                    <td>
                      <ButtonGroup>
                        <Button className="btn-small" onClick={() => this.handleRowClick(selectedDate, point)}>
                          <Glyphicon glyph="pencil" />
                        </Button>
                        <DeleteButton className="btn-small" onClick={this.onDeleteClick} onConfirmation={() => this.handleDeleteClick(point)} t={t}>
                          <Glyphicon glyph="trash" />
                        </DeleteButton>
                      </ButtonGroup>
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>

          <Modal show={this.state.editModalShow}>
            <Modal.Header>
              <Modal.Title>{t(this.props.planStore.activePlan.nameKey)}</Modal.Title>
            </Modal.Header>

            <Modal.Body>
              <Form horizontal>
                <FormGroup>
                  <Col sm={4} className="control-label">
                    {t('time')}
                    {' '}
(
                    {t('time-format')}
)
                  </Col>
                  <Col sm={8}>
                    <FormControl
                      name="time"
                      type="text"
                      value={this.state.editModalTime}
                      onChange={this.handleTime}
                    />
                  </Col>
                </FormGroup>

                <FormGroup>
                  <Col sm={4} className="control-label">
                    {t('production')}
                    {' '}
(
                    {unit}
)
                  </Col>
                  <Col sm={8}>
                    <FormControl
                      name="amountPowerOutputMegawatt"
                      type="number"
                      value={this.state.editModalPointCopy.amountPowerOutputMegawatt}
                      onChange={this.handleChange}
                    />
                  </Col>
                </FormGroup>
              </Form>
            </Modal.Body>

            <Modal.Footer>
              <Button bsStyle="primary" onClick={this.handleEditModalConfirm}>{t('ok')}</Button>
              <Button onClick={this.handleEditModalClose}>{t('close')}</Button>
            </Modal.Footer>
          </Modal>
        </div>
      );
    }

    handleRowClick = (date, point) => {
      this.setState({
        editModalShow: true,
        editModalPoint: point,
        editModalPointCopy: Object.assign({}, point),
        editModalTime: convertMinutesToHours(point.minute),
      });
    }

    handleChange = (event) => {
      event.preventDefault();
      const { target } = event;
      const value = target.type === 'checkbox' ? target.checked : target.value;
      const field = target.name;
      const object = this.state.editModalPointCopy;
      const type = typeof object[field];
      if (type === 'number') {
        // const n = Number(value)
        /* if (isNaN(n)) {
                value = n// Ignore the value.
            } else {
                value = n
            } */
      }

      object[field] = value;
      this.setState({ editModalPointCopy: object });
    }

    handleTime = (event) => {
      event.preventDefault();
      this.setState({
        editModalTime: event.target.value,
      });
    }

    handleEditModalClose = (event) => {
      event.preventDefault();
      this.setState({
        editModalShow: false,
      });
    }

    validateTime = (timeIn, uiObject) => {
      let time = convertTimeToMinutes(timeIn);
      if (time < 0) {
        toast.error('Tidspunkt er ikke valid, eksempel 13:15');
      } else if (time === this.state.editModalPoint.minute) {
        // We update the current entry. This is ok, and we should not look for time collision.
      } else {
        // Check that the time is unique.
        const found = this.planEntries.find(e => e.minute === time);

        if (found) {
          toast.error(`Tidspunkt er allerede i brug med værdi ${found.amountPowerOutputMegawatt}`);
          time = -1;
        }
      }

      return time;
    }

    handleEditModalConfirm = (event) => {
      event.preventDefault();

      const uiObject = this.state.editModalPointCopy;

      let validated = false;
      // Validate time.
      const time = this.validateTime(this.state.editModalTime, uiObject);
      if (time < 0) {
        // Do nothing, error has been reported.
      } else {
        uiObject.minute = time;
        validated = true;
      }

      if (validated) {
        uiObject.amountPowerOutputMegawatt = Number(uiObject.amountPowerOutputMegawatt);
        // Perform the actual update of mobx store.
        this.props.planStore.updateEntry(this.state.editModalPoint, uiObject);
        event.preventDefault();
        this.setState({
          editModalShow: false,
        });
        toast('Punkt blev opdateret.');
      }
    }

    handleDeleteClick = (point) => {
      this.props.planStore.removeEntry(point);
    }
}
