// @flow
import ModalComp from '../common/ModalComp';
import { titleCase } from '../../actions/CommonAction';
import React from 'react';
import PropTypes from 'prop-types';
import {
  Checkbox, Form, FormGroup, FormControl, Col,
} from 'react-bootstrap';
import Contact from '../../classes/Contact';

export const typeOptions = [{ name: 'Kontakt', value: 'CONTACT' }, { name: 'SMS', value: 'SMS' }];

class NewContactModal extends React.Component {
  constructor(props) {
    super(props);
    this.onConfirm = this.onConfirm.bind(this);
  }

  onConfirm(event) {
    const contact = new Contact({
      uuid: this.props.contact.uuid,
      priority: this.contactPriorityField.value,
      fullName: this.contactNameField.value,
      countryCode: this.contactCountryCodeField.value,
      phoneNumber: this.contactPhoneField.value,
      email: this.contactEmailField.value,
      receiverType: this.contactTypeField.value,
      primaryContact: this.contactPrimaryField.checked,
    });
    this.props.onConfirm(contact);
    event.preventDefault();
    this.props.onClose();
  }

  render() {
    const { t } = this.props;
    const ContactFormCheckboxField = ({ name, initValue, givenRef }) => (
      <FormGroup controlId="{ name + 'Group' }">
        <Col className="control-label" sm={2} xsOffset={1}>
          { name }
        </Col>
        <Col sm={8}>
          <Checkbox inputRef={givenRef} defaultChecked={initValue} />
        </Col>
      </FormGroup>
    );
    const ContactFormTextField = ({ name, initValue, givenRef }) => (
      <FormGroup controlId="{ name + 'Group' }">
        <Col className="control-label" sm={2} xsOffset={1}>
          { name }
        </Col>
        <Col sm={8}>
          <FormControl
            type="text"
            placeholder={titleCase(name)}
            inputRef={givenRef}
            defaultValue={initValue}
          />

        </Col>
      </FormGroup>
    );
    const ContactFormSelectField = ({
      name, initValue, givenRef, options,
    }) => (
      <FormGroup controlId="{ name + 'Group' }">
        <Col className="control-label" sm={2} xsOffset={1}>
          { name }
        </Col>
        <Col sm={8}>
          <FormControl
            componentClass="select"
            inputRef={givenRef}
            defaultValue={initValue}
          >
            {options.map((option, index) => (
              <option key={index} value={option.value}>{option.name}</option>
            ))}
          </FormControl>
        </Col>
      </FormGroup>
    );
    const body = (
      <Form horizontal>
        <ContactFormTextField
          name={t('name')}
          initValue={this.props.contact.fullName}
          givenRef={(ref) => { this.contactNameField = ref; }}
        />
        <ContactFormTextField
          name={t('country-code')}
          initValue={this.props.contact.countryCode}
          givenRef={(ref) => { this.contactCountryCodeField = ref; }}
        />
        <ContactFormTextField
          name={t('phone')}
          initValue={this.props.contact.phoneNumber}
          givenRef={(ref) => { this.contactPhoneField = ref; }}
        />
        <ContactFormTextField
          name={t('email')}
          initValue={this.props.contact.email}
          givenRef={(ref) => { this.contactEmailField = ref; }}
        />
        <ContactFormCheckboxField
          name={t('primary')}
          initValue={this.props.contact.primaryContact}
          givenRef={(ref) => { this.contactPrimaryField = ref; }}
        />
        <ContactFormTextField
          name={t('priority')}
          initValue={this.props.contact.priority}
          givenRef={(ref) => { this.contactPriorityField = ref; }}
        />
        <ContactFormSelectField
          name={t('type')}
          givenRef={(ref) => { this.contactTypeField = ref; }}
          initValue={this.props.contact.receiverType}
          options={typeOptions}
        />
      </Form>
    );
    return (
      <ModalComp
        title={t('create-contact')}
        body={body}
        t={t}
        onConfirm={this.onConfirm}
        onClose={this.props.onClose}
      />
    );
  }
}

NewContactModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  contact: PropTypes.instanceOf(Contact).isRequired,
};

export default NewContactModal;
