// @flow
import {
  action, computed, observable, reaction,
} from 'mobx';
import { now } from 'mobx-utils';
import Plant from '../classes/Plant';
import { MarginalPriceGraphData, PriceForecastGraphData } from '../classes/GraphData';
import {
  applyFavorite as applyFavoriteService, getBalancePrices,
  getFavorites,
  getForecast,
  getPlants,
  getSpotPrices,
  resetDay,
  saveFavorite, updateRegulatingPower,
} from '../services/PlantService';
import PriceForecastEntry from '../classes/PriceForecastEntry';
import { warn } from '../services/logService';
import ModalCtrl from './ModalCtrl';
import UiStore from './UiStore';
import AuthStore from './AuthStore';
import plansTemplates from '../actions/plansTemplate';
import { reject } from '../services/Service';

export class PlantStore {
    // The plants that the user has access to.
    @observable plants = [];

    /**
     * @deprecated
     */
    @observable selectedPlant: Plant;

    @observable favorites = [];

    validFavoriteKeys = ['weekday1', 'weekday2', 'weekend']


    constructor(uiStore: UiStore, authStore: AuthStore) {
      this.uiStore = uiStore;
      this.authStore = authStore;
      this._setupRecurring();

      reaction(
        () => [this.authStore.isAuthenticated, this.uiStore.plantsStateChanged],
        ([isAuthenticated, plantsStateChanged]) => {
          if (isAuthenticated) {
            getPlants().then(this._setPlants).catch(warn);
          }
        },
        { fireImmediately: true },
      );

      reaction(
        () => this.uiStore.selectedPlant.code,
        (plantCode) => {
          this._updateFavorites(plantCode);
        },
      );
    }

    @action
    reset = () => {
      this.plans = [];
      this.selectedPlant = undefined;
      this.favorites = [];
    }

    @action.bound
    _setPlants(plants: Array<Plant>) {
      this.plants.replace(plants);
      if (!this.uiStore.selectedItem.isPlant) {
        this.uiStore.setSelectedPlant(this.plants[0]);
      }
    }

    @observable shouldShowMarginalPriceModal: boolean = false;

    @action
    toggleMarginalPriceModal() {
      this.shouldShowMarginalPriceModal = !this.shouldShowMarginalPriceModal;
    }

    @observable shouldShowRegulatingPowerModal: boolean = false;

    @observable shouldShowIntradayPriceModal: boolean = false;

    @observable shouldShowPriceDependentPriceModal: boolean = false;

    @action
    toggleRegulatingPowerModal() {
      this.shouldShowRegulatingPowerModal = !this.shouldShowRegulatingPowerModal;
    }

    @action
    togglePriceDependentModal() {
      this.shouldShowPriceDependentPriceModal = !this.shouldShowPriceDependentPriceModal;
    }

    @action
    toggleIntradayPriceModal() {
      this.shouldShowIntradayPriceModal = !this.shouldShowIntradayPriceModal;
    }

    // PlantDataModal is the popup for Plant Data in MKPlus
    @observable shouldShowPlantDataModal: boolean= false;

    @action
    togglePlantdataModal() {
      this.shouldShowPlantDataModal = !this.shouldShowPlantDataModal;
    }

  @observable shouldShowHeatForecastModal: boolean= false;

    @action
  toggleHeatForecastModal() {
    this.shouldShowHeatForecastModal = !this.shouldShowHeatForecastModal;
  }

    @observable shouldShowResetDayModal: boolean = false;

    @action
    toggleResetDayModal() {
      this.shouldShowResetDayModal = !this.shouldShowResetDayModal;
    }


    @action
    resetDay() {
      resetDay(this.uiStore.selectedPlant.code, this.uiStore.selectedDate)
        .then((value) => {
          // toast('Dag nulstillet');
          this.toggleResetDayModal();
          this.uiStore.triggerRefreshPlans();
          // TODO reload plans
        }).catch((reason) => {
        // toast('Kunne ikke nulstille dag');
          this.toggleResetDayModal();
        });
    }

    @computed get spotPrice(): number {
      return this.uiStore.selectedPlant.spotPrice;
    }

    @computed get manualPrice(): number {
      return this.uiStore.selectedPlant.manualPrice;
    }

    @action submitManualPrice(manualPrice:number): void{
      this.uiStore.selectedPlant.updateManualPrice(manualPrice);
      this.toggleManualPriceModal();
    }

    @computed get marginalPrice(): number {
      return this.uiStore.selectedPlant.marginalPrice;
    }

    @action
    submitMarginalPrice(marginalPrice: number): void {
      this.uiStore.selectedPlant.updateMarginalPrice(marginalPrice);
      /* .then((res) => {
                // close modal.

        })
        .catch((error) => {
                // TODO display error;
                // TODO inc. error in Modal Display class.
        }); */
      this.toggleMarginalPriceModal();
    }

    @computed
    get marginalPriceForGraph() {
      return new MarginalPriceGraphData(this.marginalPrice, this.uiStore.selectedDate);
    }

    @computed get regulatingPower(): number {
      return (this.uiStore.selectedPlant.regulatingPowerUp && this.uiStore.selectedPlant.regulatingPowerDown);
    }

    @action
    submitRegulatingPower(regulatingPowerUp : number, regulatingPowerDown : number): void {
      return updateRegulatingPower(regulatingPowerUp, regulatingPowerDown, this.uiStore.selectedPlant.code).then((res) => {
        this.uiStore.triggerRefreshPlans();
        this.uiStore.selectedPlant._updateRegulatingPower(regulatingPowerUp, regulatingPowerDown);
        this.toggleRegulatingPowerModal();
        return res;
      }).catch(err => reject(err));
    }

    @action
    submitIntradayPrices(buyPrice : number, sellPrice : number): void {
      this.uiStore.selectedPlant.updateIntradayPrices(buyPrice, sellPrice).then(() => {
        this.uiStore.triggerRefreshPlans();
      });
      this.toggleIntradayPriceModal();
    }

    @action
    submitPriceDependentPrice(price): void {
      this.uiStore.selectedPlant.updatePriceDependentPrice(price).then(() => {
        this.uiStore.triggerRefreshPlans();
      });
      this.togglePriceDependentModal();
    }

    @observable priceForecast: Array<PriceForecastEntry> = [];

    @observable spotPrices: Array<PriceForecastEntry> = [];

    @observable balancePrices: Array<PriceForecastEntry> = []

    @action
    _updatePriceForecast(date, priceAreaName) {
      getForecast(date, priceAreaName)
        .then((res) => {
          this._replacePriceForecast(res);
        })
        .catch(warn);
    }

    @action.bound
    _replacePriceForecast(forecast) {
      this.priceForecast.replace(forecast);
    }

    @computed
    get priceForecastForGraph() {
      const result = new PriceForecastGraphData(this.priceForecast);
      // Find configuration for spot prices.
      const config = plansTemplates.find(e => e.nameKey === 'price-forecast');
      result.color = config.color;
      result.shouldCurveStep = config.shouldCurveStep;
      return result;
    }

  @action
    _updateSpotPrices(date, priceAreaName) {
      getSpotPrices(date, priceAreaName)
        .then((res) => {
          this._replaceSpotPrices(res);
        })
        .catch(warn);
    }

  @action.bound
  _replaceSpotPrices(prices) {
    this.spotPrices.replace(prices);
  }

  @computed
  get spotPricesForGraph() {
    const result = new PriceForecastGraphData(this.spotPrices);
    // Find configuration for spot prices.
    const config = plansTemplates.find(e => e.nameKey === 'spot-prices');
    result.color = config.color;
    result.shouldCurveStep = config.shouldCurveStep;
    return result;
  }

  @action
  _updateBalancePrices(date, priceAreaName) {
    getBalancePrices(date, priceAreaName)
      .then((res) => {
        this._replaceBalancePrices(res);
      })
      .catch(warn);
  }

  @action.bound
  _replaceBalancePrices(prices) {
    // Make sure these are sorted
    const sortedPrices = prices.sort((a, b) => a.hour - b.hour);
    this.balancePrices.replace(sortedPrices);
  }

  @computed
  get balancePricesForGraph() {
    const result = new PriceForecastGraphData(this.balancePrices);
    // Find configuration for balance prices.
    const config = plansTemplates.find(e => e.nameKey === 'balance-prices');
    result.color = config.color;
    result.shouldCurveStep = config.shouldCurveStep;
    return result;
  }

  _setupRecurring() {
    const refreshRateInMillis = 90000;
    reaction(
      () => [now(refreshRateInMillis), this.uiStore.selectedItem, this.uiStore.selectedDate],
      ([timestamp, selectedItem, selectedDate]) => {
        this._updatePriceForecast(selectedDate, selectedItem.priceAreaName);
        this._updateSpotPrices(selectedDate, selectedItem.priceAreaName);
        this._updateBalancePrices(selectedDate, selectedItem.priceAreaName);
      },
    );
  }

  // -----------------------------------------------------------------------------
  // Favorites
  // vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv

    @observable favoriteModalCtrl = new ModalCtrl(template => saveFavorite(
      this.uiStore.selectedPlant.code,
      this.uiStore.selectedDate,
      template,
    )
      .then((res) => {
        this._updateFavorites(this.uiStore.selectedPlant.code);
      })
      .catch(warn));

    @action
    _setFavorites(favorites) {
      this.favorites = favorites;
    }

    @action
    _updateFavorites(plantCode) {
      getFavorites(plantCode)
        .then((favorites) => {
          const validFavorites = favorites.filter(favorite => this.validFavoriteKeys.includes(favorite.name));
          this._setFavorites(validFavorites);
        }).catch(warn);
    }

    applyFavorite(favoriteName) {
      const targetDate = this.uiStore.selectedDate.startOf('day');

      const favorite = this.favorites.find(favorite => favorite.name === favoriteName);
      if (favorite) {
        return applyFavoriteService(favorite, targetDate)
          .then((res) => {
            this.uiStore.triggerRefreshPlans();
            return res;
          });
      }
      console.log('applyFavorite: no matching favorite found', favoriteName);
      return Promise.reject(`no matching favorite found for '${favoriteName}'`);
    }
}
