import React from 'react';
import { inject, observer } from 'mobx-react';
import DayPicker from 'react-day-picker';
import {
  ButtonGroup, Button, Col, Form, FormGroup, Modal, Radio, Row,
} from 'react-bootstrap';
import { toast } from 'react-toastify';
import moment from 'moment';
import { translate } from 'react-i18next';
import { titleCase } from '../../actions/CommonAction';

@translate()
@inject('uiStore', 'planStore', 'plantStore') @observer
export default class FavouriteAndCopyPlans extends React.Component<> {
  constructor(props) {
    super(props);

    this.state = {
      fromDate: this.props.uiStore.selectedDate,
      toDate: moment(this.props.uiStore.selectedDate.startOf('day')).add(7, 'day'),
      chosenTitle: 'weekday1',
      copyTemplateVisibility: false,
      copyTemplateName: '',
    };
  }

  mapStateToProps() {
    return {
      planStore: this.props.planStore,
      plantStore: this.props.plantStore,
      shouldShowCopyPlansModal: this.props.planStore.shouldShowCopyPlansModal,
      shouldShowFavoriteModal: this.props.planStore.shouldShowFavoriteModal,
      fromDate: this.state.fromDate,
      toDate: this.state.toDate,
      favoriteModalCtrl: this.props.plantStore.favoriteModalCtrl,
    };
  }

  render() {
    const myProps = this.mapStateToProps();
    const { t } = this.props;
    const { favorites } = this.props.plantStore;
    const uiSelectedDate = this.props.uiStore.selectedDate;

    const MONTHS = [t('january'), t('february'), t('march'), t('april'), t('may'), t('june'), t('july'), t('august'), t('september'), t('october'), t('november'), t('december')];
    const WEEKDAYS_SHORT = [t('sunday-short'), t('monday-short'), t('tuesday-short'), t('wedensday-short'), t('thursday-short'), t('friday-short'), t('saturday-short')];

    const favoriteButtons = this.props.plantStore.validFavoriteKeys.map((favoriteKey) => {
      const disabled = (favorites.findIndex(favorite => favorite.name === favoriteKey)) < 0;
      return (
        <Button onClick={() => this.showCopyTemplate(favoriteKey)} key={favoriteKey} disabled={disabled}>{t(favoriteKey)}</Button>
      );
    });

    return (
      <div>
        <FormGroup className="text-center">
          <ButtonGroup bsSize="xsmall">
            {favoriteButtons}
          </ButtonGroup>
        </FormGroup>

        <FormGroup className="text-center">
          <ButtonGroup bsSize="small">
            <Button onClick={this.toggleFavoriteModal}>
              {t('favorit')}
            </Button>
            <Button onClick={this.toggleCopyModal}>
              {t('copy-future')}
            </Button>
          </ButtonGroup>
        </FormGroup>

        <Modal show={this.state.copyTemplateVisibility} onHide={this.toggleCopyTemplateVisibility}>
          <Modal.Header closeButton>
            <Modal.Title>{t('confirm-load-template-title')}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {t('confirm-load-template-message', { template: t(this.state.copyTemplateName) })}
          </Modal.Body>
          <Modal.Footer>
            <Button bsStyle="primary" onClick={this.submitCopyTemplate}>{t('confirm')}</Button>
            <Button onClick={this.toggleCopyTemplateVisibility}>{titleCase(t('cancel'))}</Button>
          </Modal.Footer>
        </Modal>

        <Modal show={myProps.favoriteModalCtrl.visible} onHide={this.toggleFavoriteModal}>
          <Modal.Header closeButton>
            <Modal.Title>{t('favorit')}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
                            Vælg titel til skabelon.
            <Form horizontal>
              <Row>
                <Col sm={4} className="control-label">Titel</Col>
                <Col sm={8}>
                  <Radio onChange={this.handleChosenTitleChange} checked={this.state.chosenTitle === 'weekday1'} value="weekday1" name="title">{t('weekday1')}</Radio>
                  <Radio onChange={this.handleChosenTitleChange} checked={this.state.chosenTitle === 'weekday2'} value="weekday2" name="title">{t('weekday2')}</Radio>
                  <Radio onChange={this.handleChosenTitleChange} checked={this.state.chosenTitle === 'weekend'} value="weekend" name="title">{t('weekend')}</Radio>
                </Col>
              </Row>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.submitFavorite} bsStyle="primary">{t('save')}</Button>
            <Button onClick={this.toggleFavoriteModal}>{t('abort-close')}</Button>
          </Modal.Footer>
        </Modal>

        <Modal show={myProps.shouldShowCopyPlansModal} onHide={this.toggleCopyModal} className="copy-plans-modal">
          <Modal.Header closeButton>
            <Modal.Title>{t('copy-plans')}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              <strong>
                {t('copy-all-plans')}
                {' '}
                {uiSelectedDate.format('DD/MM YYYY')}
                {' '}
                {t('to-period')}
              </strong>
            </p>
            <Row>
              <Col xs={6} className="text-center">
                <i>
                  {t('from-date')}
:
                  {' '}
                  {myProps.fromDate.format('DD/MM YYYY')}
                </i>
                <div className="custom-input-day-picker marg-bot">
                  <DayPicker
                    firstDayOfWeek={1}
                    months={MONTHS}
                    weekdaysShort={WEEKDAYS_SHORT}
                    initialMonth={myProps.fromDate.toDate()}
                    selectedDays={myProps.fromDate.toDate()}
                    onDayClick={this.handleSelectedFromDateChange}
                  />
                </div>
              </Col>
              <Col xs={6} className="text-center">
                <i>
                  {t('to-date')}
:
                  {' '}
                  {myProps.toDate.format('DD/MM YYYY')}
                </i>
                <div className="custom-input-day-picker marg-bot">
                  <DayPicker
                    firstDayOfWeek={1}
                    months={MONTHS}
                    weekdaysShort={WEEKDAYS_SHORT}
                    initialMonth={myProps.toDate.toDate()}
                    selectedDays={myProps.toDate.toDate()}
                    onDayClick={this.handleSelectedToDateChange}
                  />
                </div>
              </Col>
            </Row>
            <div className="text-center">
              <i>
                {t('number-of-days')}
:
                {' '}
                {myProps.toDate.diff(myProps.fromDate, 'days') + 1}
              </i>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              onClick={this.submitCopyPlans}
              bsStyle="primary"
              disabled={myProps.toDate.diff(myProps.fromDate, 'days') < 0}
            >
              {t('copy-plans')}
            </Button>
            <Button onClick={this.toggleCopyModal}>{t('abort-close')}</Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }

    toggleCopyTemplateVisibility = () => {
      this.setState({ copyTemplateVisibility: !this.state.copyTemplateVisibility });
    };

    showCopyTemplate = (templateName) => {
      this.setState({
        copyTemplateName: templateName,
        copyTemplateVisibility: true,
      });
    }

    submitCopyTemplate = (event) => {
      this.props.plantStore.applyFavorite(this.state.copyTemplateName)
        .then((res) => {
          this.setState({ copyTemplateVisibility: false });
          toast(this.props.t('apply-favorit-success'));
        })
        .catch((err) => {
          console.log(err);
          toast.error(this.props.t('apply-favorit-failure'));
        });
    }

    handleChosenTitleChange = (event) => {
      this.setState({
        chosenTitle: event.target.value,
      });
    }

    toggleFavoriteModal = () => {
      const myProps = this.mapStateToProps();
      myProps.favoriteModalCtrl.toggle();
    }

    submitFavorite = () => {
      const myProps = this.mapStateToProps();
      myProps.favoriteModalCtrl.submit(this.state.chosenTitle)
        .then((res) => {
          toast(this.props.t('save-favorit-success'));
        })
        .catch((err) => {
          console.log(err);
          toast.error(this.prop.t('save-favorit-failure'));
        });
      this.toggleFavoriteModal();
    }

    toggleCopyModal = () => {
      const myProps = this.mapStateToProps();
      myProps.planStore.toggleCopyPlansModal();
    }

    submitCopyPlans = () => {
      this.props.planStore.copyPlans(this.state.fromDate, this.state.toDate);
    }

    handleSelectedFromDateChange = (e) => {
      this.setState({ fromDate: moment(e).startOf('day') });
    };

    handleSelectedToDateChange = (e) => {
      this.setState({ toDate: moment(e).startOf('day') });
    };
}
