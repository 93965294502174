import React from 'react';
import { inject, observer } from 'mobx-react';
import { toast } from 'react-toastify';
import { translate } from 'react-i18next';

export default function (ComposedComponent) {
    @translate()
    @inject('authStore') @observer
  class Authenticate extends React.Component {
      componentDidMount() {
        if (!this.props.authStore.isAuthenticated) {
          toast(this.props.t('login-required'));
          this.props.history.push('/login');
        }
      }

      componentDidUpdate(nextProps) {
        if (!nextProps.authStore.isAuthenticated) {
          this.props.history.push('/');
        }
      }

      render() {
        return (
          <ComposedComponent {...this.props} />
        );
      }
    }

    return Authenticate;
}
