import React from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { translate } from 'react-i18next';
import { inject, observer } from 'mobx-react';
import { TextFieldGroup } from '../common/horizontal/TextFieldGroup';
import MyDatePicker from '../common/MyDatePicker';
import DayPicker from 'react-day-picker';
import moment from 'moment';
import { computed } from 'mobx';
import {toast} from "react-toastify";

@translate()
@inject('uiStore', 'plantStore') @observer
class RegulatingPower extends React.Component {
  mapStateToProps() {
    return {
      plantStore: this.props.plantStore,
      uiStore: this.props.uiStore,
      shouldShowRegulatingPowerModal: this.props.plantStore.shouldShowRegulatingPowerModal,
      regulatingPowerUp: this.props.uiStore.selectedPlant.regulatingPowerUp,
      regulatingPowerDown: this.props.uiStore.selectedPlant.regulatingPowerDown,
      refreshRegulatingPower: this.props.uiStore.refreshRegulatingPower,
    };
  }


    @computed get isGroupMaster() {
    return this.props.uiStore.selectedPlant.staticActivationGroupMaster && this.props.uiStore.selectedPlant.staticActivationGroupMaster;
  }

    render() {
      const { t } = this.props;
      const myProps = this.mapStateToProps();

      return (
        <div>
          <Button onClick={this.toggleModal}>{t('regulating-power')}</Button>
          <Modal show={myProps.shouldShowRegulatingPowerModal} onHide={this.toggleModal} className="regulating-power-modal">
            <Modal.Header closeButton>
              <Modal.Title>{t('regulating-power')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <Form horizontal onSubmit={this.submitRegulatingPower}>
                  <TextFieldGroup
                    name={t('regulating-power-up')}
                    initValue={myProps.regulatingPowerUp}
                    givenRef={(ref) => { this.regulatingPowerUpField = ref; }}
                    disabled={!this.isGroupMaster}
                    onChange={this.handleChangePriceUp}
                  />
                  <TextFieldGroup
                    name={t('regulating-power-down')}
                    initValue={myProps.regulatingPowerDown}
                    givenRef={(ref) => { this.regulatingPowerDownField = ref; }}
                    disabled={!this.isGroupMaster}
                    onChange={this.handleChangePriceDown}
                  />
                </Form>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button
                onClick={this.submitRegulatingPower}
                bsStyle="primary"
                type="submit"
                disabled={false}
              >
                {t('save')}
              </Button>
              <Button onClick={this.toggleModal}>{t('abort-close')}</Button>
            </Modal.Footer>
          </Modal>
        </div>
      );
    }

     handleChangePriceUp= (e) => { this.setState({ regulatingPowerUp: e.target.value }); };

     handleChangePriceDown= (e) => { this.setState({ regulatingPowerDown: e.target.value }); };


    toggleModal = () => {
      const myProps = this.mapStateToProps();
      myProps.plantStore.toggleRegulatingPowerModal();
      this.props.uiStore.setSelectedDate(moment(this.selectedDate));
    }

    submitRegulatingPower = (event) => {
      event.preventDefault();
      const myProps = this.mapStateToProps();
      const { t } = this.props;
      myProps.plantStore.submitRegulatingPower(this.regulatingPowerUpField.value, this.regulatingPowerDownField.value)
        .then(() => toast.success(t('changes-saved')))
        .catch(() => toast.error(t('error-changes-not-saved')));
    }
}

export default RegulatingPower;
