import React from 'react';
import { inject, observer } from 'mobx-react';
import { Well } from 'react-bootstrap';

@inject('uiStore') @observer
class StaticMessage extends React.Component {
  render() {
    const { uiStore } = this.props;

    const dk = (
      <Well className="full-height messages">
        <h5>Volue Market Services</h5>
        <a href="https://www.volue.com/product-family/market-services">volue.com</a>
        <br />
        <p>
Vagt telefon:
          <a href="tel:89-40-70-45">89 40 70 45</a>
        </p>

        <a href="mailto:247.ms@volue.com" target="_top">247.ms@volue.com</a>
        <br />

        <h5>
Deadlines:
          <br />
        </h5>

        <p id="deadlines">
8.30  – Prisprognose (Volue)
          <br />

          08.50 - Resultat af primære reserver (Volue)
          <br />

          9.00  – Manuelle reserver
          <br />

          10.15 – Opdateret prisprognose (Volue)
          <br />

          10.30 – Spotindmelding
          <br />

          13.30 – Spot resultater (Volue)
          <br />

          14.00 – Primære reserver
          <br />

          Deltagelse i Regulerkraft kan ændres efter behov.
        </p>
      </Well>
    );

    const noMessage = (<div />);

    let renderThis;

    if (uiStore.selectedPlant.priceAreaName.toLowerCase().startsWith('dk')) {
      renderThis = dk;
    } else if (uiStore.selectedPlant.priceAreaName.toLowerCase().startsWith('se')) {
      renderThis = noMessage;
    } else if (uiStore.selectedPlant.priceAreaName.toLowerCase().startsWith('no')) {
      renderThis = noMessage;
    } else {
      renderThis = noMessage;
    }

    return renderThis;
  }
}

export default StaticMessage;
