import React from 'react';
import { inject, observer } from 'mobx-react';
import { translate } from 'react-i18next';
import { Modal } from 'react-bootstrap';

import PlantData from './PlantData';

@translate()
@inject('plantStore') @observer
export default class PlantDataModal extends React.Component {
  render() {
    const {
      t, onDismiss, onSubmit, open, init, plant,
    } = this.props;

    return (
      <Modal
        show={open}
        onHide={onDismiss}
        bsSize="large"
        backdrop="static"
      >
        <Modal.Header closeButton>Plant Data</Modal.Header>
        <Modal.Body>
          <PlantData
            plant={plant}
            init={init}
            onDismiss={onDismiss}
            onSubmit={onSubmit}
          />
        </Modal.Body>
      </Modal>

    );
  }
}
