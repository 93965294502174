import React from 'react';
import { inject, observer } from 'mobx-react';
import {
  Button, Checkbox,
} from 'react-bootstrap';
import { translate } from 'react-i18next';
import MKPlusChart from './MKPlusChart';
import PlantDataModal from './PlantDataModal';
import HeatForecastModal from './HeatForecastModal';
import Plantdata from "../../classes/Plantdata";

translate();
@inject('uiStore', 'plantStore', 'mkPlusStore') @observer
class MKPlusTest extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modalOpen: false,
      modalOpenHeat: false,
      editPlantData: null,
      editHeatForecast: null,
      plant: new Plantdata(),
      heatData: this.props.mkPlusStore.heatData,
    };
  }

  showHeatForecast = () => {
    this.setState({
      editHeatForecast: null,
      modalOpenHeat: true,
    });
  }

  onModalCloseHeat= () => {
    this.setState({
      modalOpenHeat: false,
    });
  }

  showPlantData = () => {
    const { mkPlusStore } = this.props;
    this.setState({
      editPlantData: null,
      modalOpen: true,
      plant: mkPlusStore.plant,
    });
  }

  onModalClose = () => {
    this.setState({
      modalOpen: false,
      plant: new Plantdata(),
    });
  }

  handleGraphSerieVisibilityChange = (event) => {
    const { mkPlusStore } = this.props;

    if (event.target.checked) {
      mkPlusStore.addSelectedGraphSerie(event.target.value);
    } else {
      mkPlusStore.removeSelectedGraphSerie(event.target.value);
    }
  }

  renderSerieInfo({ name, label, color }) {
    const { mkPlusStore } = this.props;

    return (
      <div key={name}>
        <Checkbox
          checked={mkPlusStore.selectedGraphSeries.indexOf(name) > -1}
          value={name}
          onChange={this.handleGraphSerieVisibilityChange}
        />
        <span className="graphSeriesChart-swatch" style={{ color }} />
        {label}
      </div>
    );
  }

  render() {
    const {
      modalOpen, modalOpenHeat, editPlantData, editHeatForecast,
    } = this.state;
    const {
      mkPlusStore,
    } = this.props;

    return (
      <div>
        <div className={['text-right']}>
          <Button onClick={this.showPlantData}>Plant Data</Button>
          {' '}
          <Button onClick={this.showHeatForecast}>Heat Forecast</Button>
        </div>

        <div className="graphSeriesChart">
          {mkPlusStore.graphSeries.filter(({ name }) => !mkPlusStore.nullGraphs.includes(name)).map(this.renderSerieInfo, this)}
        </div>

        <MKPlusChart />


        <PlantDataModal
          plant={this.state.plant}
          open={modalOpen}
          init={editHeatForecast}
          onDismiss={this.onModalClose}
          onSubmit={this.onModalSubmit}
        />

        <HeatForecastModal
          heatData={this.state.heatData}
          open={modalOpenHeat}
          init={editPlantData}
          onDismiss={this.onModalCloseHeat}
        />
      </div>
    );
  }
}

export default MKPlusTest;
