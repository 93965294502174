import * as React from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import { inject, observer } from 'mobx-react';
import { translate } from 'react-i18next';
import ActivationOverview from './ActivationOverview';
import ControlRoomMessages from './ControlRoomMessages';
import ApprovedPlans from './ApprovedPlans';
import BlockBid from './BlockBid';
import Plans from './Plans';
import ControlRoomChart from './ControlRoomChart';

@translate()
@inject('controlRoomStore') @observer
export default class PlanningDetails extends React.Component {
  render() {

      const { t } = this.props;

    return (
      <Tabs bsStyle="pills" defaultActiveKey={1} id="tab-nav">
        <Tab eventKey={1} title={t('control-room-chart')}>
          <ControlRoomChart />
        </Tab>
        <Tab eventKey={2} title={t('plans-navbar')}>
          <Plans />
        </Tab>
        <Tab eventKey={3} title={t('block-bid-navbar')}>
          <BlockBid />
        </Tab>
        <Tab eventKey={4} title={t('approved-plans')}>
          <ApprovedPlans />
        </Tab>
        <Tab eventKey={5} title={t('control-room-messages-nav')}>
          <ControlRoomMessages />
        </Tab>
        <Tab eventKey={6} title={t('activation-overview')}>
          <ActivationOverview />
        </Tab>
      </Tabs>
    );
  }
}
