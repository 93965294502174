import axios from 'axios';
/*
  Functions for handling mFRR alarms
 */

export function checkForAlarms() {
  return axios.get('api/mfrr/alarms').then(response => response.data);
}

export function handleAlarm(key) {
  return axios.post(`api/mfrr/alarm/${key}`);
}
