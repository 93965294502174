// @flow

import React from 'react';
import { inject, observer } from 'mobx-react';
import { computed } from 'mobx';
import { Button, ButtonGroup, Glyphicon } from 'react-bootstrap';
import DeleteButton from '../../components/common/DeleteButton';
import {
  pingBox, sendPlanToBox, pingCHPCOM,
} from '../ControlRoomService';
import { toast } from 'react-toastify';
import ReactDOM from 'react-dom';
import ReactTable from 'react-table';

import { translate } from 'react-i18next';

import CHPCOMFormModal from '../components/CHPCOMFormModal';
import CHPCOMStatusModal from '../components/CHPCOMStatusModal';

import BoxFormModal from '../components/BoxFormModal';
import BoxStatusModal from '../components/BoxStatusModal';
import Box from '../classes/Box';
import CHPCOM from '../classes/CHPCOM';
import CHPCOMStatus from '../classes/CHPCOMStatus';

@translate()
@inject('controlRoomStore') @observer
export default class PlantControl extends React.Component {
  constructor(props) {
    super(props);
    this.setupCHPCOMColumns();
    this.setupBoxesColumns();

    this.state = {
      chpcomModalOpen: false,
      chpcom: new CHPCOM(),
      boxModalOpen: false,
      box: new Box(),
      chpcomStatusModalOpen: false,
      chpcomStatus: null,
    };
  }

    @computed get boxes() {
    return this.props.controlRoomStore.boxes.slice();
  }

    @computed get CHPCOMs() {
      return this.props.controlRoomStore.CHPCOMs.slice();
    }

    render() {
      const { t } = this.props;

      return (
        <div>
          <div className="row">
            <div className="col-xs-6">
              <h4>{t('chpcom')}</h4>
            </div>
            <div className="col-xs-6">
              <div className="text-right">
                <Button onClick={() => this.showAddCHPCOMModal()}>{t('add-chpcom')}</Button>
              </div>
            </div>
          </div>

          <ReactTable
            data={this.CHPCOMs}
            columns={this.chpColumns}
            showPagination={false}
            pageSize={this.CHPCOMs.length === 0 ? 2 : this.CHPCOMs.length}
            className="-striped -highlight"
            defaultSorted={[
              {
                id: 'plantName',
              },
            ]}
          />

          <br />

          <div className="row">
            <div className="col-xs-6">
              <h4>{t('boxes')}</h4>
            </div>
            <div className="col-xs-6">
              <div className="text-right">
                <Button onClick={() => this.showAddBoxModal()}>{t('add-box')}</Button>
              </div>
            </div>
          </div>

          <ReactTable
            data={this.boxes}
            columns={this.boxesColumns}
            showPagination={false}
            pageSize={this.boxes.length === 0 ? 2 : this.boxes.length}
            className="-striped -highlight"
            defaultSorted={[
              {
                id: 'plantName',
              },
            ]}
          />

          <CHPCOMFormModal
            open={this.state.chpcomModalOpen}
            chpcom={this.state.chpcom}
            onClose={this.hideCHPCOMModal}
          />

          <CHPCOMStatusModal
            open={this.state.chpcomStatusModalOpen}
            status={this.state.chpcomStatus}
            onClose={this.hideCHPCOMStatusModal}
          />

          <BoxFormModal
            open={this.state.boxModalOpen}
            box={this.state.box}
            onClose={this.hideBoxModal}
          />
        </div>
      );
    }

    showAddCHPCOMModal() {
      this.setState({
        chpcom: new CHPCOM(),
        chpcomModalOpen: true,
      });
    }

    showEditCHPCOMModal(chpcom) {
      this.setState({
        chpcom,
        chpcomModalOpen: true,
      });
    }

    hideCHPCOMModal = () => {
      this.setState({
        chpcom: new CHPCOM(),
        chpcomModalOpen: false,
      });
    }

    deleteCHPCOM = (chpcom) => {
      const { t } = this.props;
      this.props.controlRoomStore.deleteCHPCOM(chpcom).then(() => {
        toast(t('chpcom-deleted'));
      }, (err) => {
        toast(err.message);
      });
    }

    pingCHPCOM = (chpcom) => {
      pingCHPCOM(chpcom).then((status) => {
        this.showCHPCOMStatusModal(new CHPCOMStatus().fromJson(status));
      }, (err) => {
        if (err) {
          toast(err.data.message);
        } else {
          const { t } = this.props;

          toast(t('unknown-error'));
        }
      });
    }

    showCHPCOMStatusModal(chpcomStatus) {
      this.setState({
        chpcomStatus,
        chpcomStatusModalOpen: true,
      });
    }

    hideCHPCOMStatusModal = () => {
      this.setState({
        chpcomStatus: null,
        chpcomStatusModalOpen: false,
      });
    }

    // Box modals
    showAddBoxModal() {
      this.setState({
        box: new Box(),
        boxModalOpen: true,
      });
    }

    showEditBoxModal(box) {
      this.setState({
        box,
        boxModalOpen: true,
      });
    }

    hideBoxModal = () => {
      this.setState({
        box: new Box(),
        boxModalOpen: false,
      });
    }

    showBoxStatus = (box, status) => {
      function dispose() {
        ReactDOM.unmountComponentAtNode(wrapper);
        wrapper.remove();
      }

      const wrapper = document.body.appendChild(document.createElement('div'));
      const boxModal = (
        <BoxStatusModal
          onConfirm={dispose}
          onClose={dispose}
          t={this.props.t}
          box={box}
          status={status}
          controlRoomStore={this.props.controlRoomStore}
          confirmationMessage="Ok"
        />
      );
      ReactDOM.render(boxModal, wrapper);
    };


    pingBox = (box) => {
      toast(`Pinger box: ${box.plantName}`);
      const pingReply = (pingResponse) => {
        console.log(pingResponse, 'box-ping-response');
        return pingResponse;
      };

      pingBox(box).then(
        (res) => {
          // toast(pingReply(res));
          this.showBoxStatus(box, pingReply(res), this.props.t);
        }, (err) => {
          toast.error(err.data.message); // err layout?
        },
      );
    };


    sendPlanToBox = (box) => {
      toast('sender plan for dags dato');
      sendPlanToBox(box.plantCode)
        .then((response) => {
          toast('plan afsendt');
        })
        .catch((error) => {
          toast(error);
        });
    }

    deleteBox = (box) => {
      const { t } = this.props;
      this.props.controlRoomStore.deleteBox(box).then(() => {
        toast(t('box-deleted'));
      }, (err) => {
        toast(err.message);
      });
    }

    setupCHPCOMColumns() {
      const { t } = this.props;

      this.chpColumns = [
        {
          Header: t('plant'),
          accessor: 'plantName',
        },
        {
          Header: 'CDGc id',
          accessor: 'cdgcId',
        },
        {
          Header: t('device'),
          accessor: 'deviceId',
        },
        {
          id: 'onlineValueEnabled',
          Header: t('online-value'),
          accessor: p => (p.onlineValueEnabled ? 'Ja' : 'Nej'),
          minWidth: 70,
        },
        {
          id: 'productionPlanEnabled',
          Header: t('production-plan'),
          accessor: p => (p.productionPlanEnabled ? 'Ja' : 'Nej'),
          minWidth: 70,
        },
        {
          id: 'frequencyPlanUpEnabled',
          Header: t('frequency-plan-up'),
          accessor: p => (p.frequencyPlanUpEnabled ? 'Ja' : 'Nej'),
          minWidth: 70,
        },
        {
          id: 'frequencyPlanDownEnabled',
          Header: t('frequency-plan-down'),
          accessor: p => (p.frequencyPlanDownEnabled ? 'Ja' : 'Nej'),
          minWidth: 70,
        },
        {
          id: 'buttons',
          Header: '',
          minWidth: 200,
          maxWidth: 200,
          accessor: chpcom => (
            <ButtonGroup>
              <Button
                className="btn-small"
                onClick={() => {
                  this.pingCHPCOM(chpcom);
                }}
              >
    Ping
              </Button>
              <Button
                className="btn-small"
                onClick={() => {
                  this.showEditCHPCOMModal(chpcom, this.props.t);
                }}
              >
                <Glyphicon glyph="pencil" />
              </Button>
              <DeleteButton
                className="btn-small"
                onConfirmation={() => {
                  this.deleteCHPCOM(chpcom);
                }}
                title={t('delete-chpcom')}
                message={t('confirm-delete-chpcom')}
                t={this.props.t}
              >
                <Glyphicon glyph="trash" />
              </DeleteButton>
            </ButtonGroup>
          ),
        },
      ];
    }

    setupBoxesColumns() {
      const { t } = this.props;

      this.boxesColumns = [
        {
          Header: t('plant'),
          accessor: 'plantName',
        },
        {
          Header: 'IP',
          accessor: 'ipAddress',
        },
        {
          Header: 'Port',
          accessor: 'port',
        },
        {
          Header: 'Mac',
          accessor: 'macAddress',
        },
        {
          Header: 'Ser. Nr.',
          accessor: 'serialNo',
        },
        {
          Header: 'Hw. vers.',
          accessor: 'hardwareVersion',
        },
        {
          Header: 'Sw. vers.',
          accessor: 'softwareVersion',
        },
        { 
          id: 'adc00Enabled',
          Header: t('adc00'),
          accessor: p => (p.adc00Enabled ? 'Ja' : 'Nej'),
          minWidth: 45,
        },
          {
         id: 'adc01Enabled',
          Header: t('adc01'),
          accessor: p => (p.adc01Enabled ? 'Ja' : 'Nej'),
          minWidth: 45,
        },
          {
          id: 'adc02Enabled',
          Header: t('adc02'),
          accessor: p => (p.adc02Enabled ? 'Ja' : 'Nej'),
          minWidth: 45,
        },
          {
          id: 'adc03Enabled',
          Header: t('adc03'),
          accessor: p => (p.adc03Enabled ? 'Ja' : 'Nej'),
          minWidth: 45,
        },
        {
          id: 'buttons',
          Header: '',
          minWidth: 200,
          maxWidth: 200,
          accessor: box => (
            <ButtonGroup>
              <Button
                className="btn-small"
                onClick={() => {
                  this.sendPlanToBox(box);
                }}
              >
                <Glyphicon glyph="send" />
              </Button>
              <Button
                className="btn-small"
                onClick={() => {
                  this.pingBox(box);
                }}
              >
  Ping
              </Button>
              <Button
                className="btn-small"
                onClick={() => {
                  this.showEditBoxModal(box);
                }}
              >
                <Glyphicon glyph="pencil" />
              </Button>
              <DeleteButton
                className="btn-small"
                onConfirmation={() => {
                  this.deleteBox(box);
                }}
                title={t('delete-box')}
                message={t('confirm-delete-box')}
                t={this.props.t}
              >
                <Glyphicon glyph="trash" />
              </DeleteButton>
            </ButtonGroup>
          ),
        },
      ];
    }
}
