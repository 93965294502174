// @flow

import { convertMinutesToDateObjForGraph } from '../../actions/CommonAction';
import _ from 'lodash';

class Plan {
    _entries: Array = [];

    status: number;

    type = 'line';

    shouldCurveStep = true;

    day;

    approvedTime = '';

    fromJson(json, date) {
      if (!date) {
        console.warn('Plan#fromJson invoked without date!');
      }
      if (json) {
        this.status = json.status;
        this._entries = json.entries.map(entryData => ({
          minute: entryData.minute,
          date: convertMinutesToDateObjForGraph(date, entryData.minute),
          amountPowerOutputMegawatt: entryData.amountPowerOutputMegawatt,
        }));
        this.day = json.day;
        this.approvedTime = json.approvedTime;
      }
    }

    get maxPower() {
      return this.entries.length === 0 ? 0 : (_.maxBy(this.entries, entry => entry.amountPowerOutputMegawatt || 0)).amountPowerOutputMegawatt;
    }

    get minPower() {
      return this.entries.length === 0 ? 0 : (_.minBy(this.entries, entry => entry.amountPowerOutputMegawatt || 0)).amountPowerOutputMegawatt;
    }

    get entries() {
      return this._entries;
    }

    set entries(newEntries) {
      this._entries = newEntries;
    }

    get entriesForChart() {
      const entries = this.entries.slice();
      return addMidnightEntry(this.day && this.day.hours, entries, this.key);
    }
}

export const addMidnightEntry = (hoursInDay, entries, nameKey) => {
  if (entries.length > 0 && hoursInDay) {
    // We only add an element if other elements are present.
    const lastEntry = Object.assign({}, entries[entries.length - 1]);
    const firstEntry = Object.assign({}, entries[0]);
    // const lastEntryMinute = uiSt

    if (lastEntry.minute !== hoursInDay * 60) {
      // We only add an element if a midnight element doesn't exist already.
      // Important to use firstEntry.date else a day can be skipped
      const newEntry = {
        minute: hoursInDay * 60,
        date: convertMinutesToDateObjForGraph(firstEntry.date, hoursInDay * 60),
        amountPowerOutputMegawatt: nameKey === 'bid-plan' ? 0 : lastEntry.amountPowerOutputMegawatt,
      };

      entries.push(newEntry);
    }
  }

  return entries;
};

export class ProductionPlan extends Plan {
    name = 'Produktionsplan';

    color = 'rgb(44, 160, 44)';

    key = 'production-plan';

    markType = 'circle';

    fromJson(json, date) {
      super.fromJson(json, date);
      return this;
    }
}

export class PriceDependentPlan extends Plan {
  name = 'Price Dependent Plan';

  key = 'price-dependent-plan';

  color = 'rgb(0, 153, 255)'

  markType = 'circle';

  fromJson(json, date) {
    super.fromJson(json, date);
    return this;
  }
}

export class ManualPlanUp extends Plan {
    name = 'Manuel plan op';

    up: boolean = true;

    color = 'orange';

    key = 'manual-plan-up';

    markType = 'tri';

    fromJson(json, date) {
      super.fromJson(json, date);
      return this;
    }
}

export class ManualPlanDown extends Plan {
    name = 'Manuel plan ned';

    up: boolean = false;

    color = 'yellowgreen';

    key = 'manual-plan-down';

    markType = 'tri';

    fromJson(json, date) {
      super.fromJson(json, date);
      return this;
    }
}

export class FrequencyPlanUp extends Plan {
    name = 'Frekvensplan op';

    up = true;

    color = 'purple';

    key = 'frequency-plan-up';

    markType = 'tri';

    fromJson(json, date) {
      super.fromJson(json, date);
      return this;
    }
}

export class FrequencyPlanDown extends Plan {
    name = 'Frekvensplan ned';

    up = false;

    color = 'brown';

    key = 'frequency-plan-down';

    markType = 'tri';

    fromJson(json, date) {
      super.fromJson(json, date);
      return this;
    }
}

export class BidPlan extends Plan {
    name = 'Budplan';

    color = 'blue';

    key = 'bid-plan';

    markType = 'rect';

    fromJson(json, date) {
      super.fromJson(json, date);
      return this;
    }
}

export class IntradayPlanBuy extends Plan {
  name = 'Intraday plan buy';

  buy = true;

  color = 'rgb(1, 112, 112)';

  key = 'intraday-plan-buy';

  markType = 'tri';

  fromJson(json, date) {
    super.fromJson(json, date);
    return this;
  }
}
export class IntradayPlanSell extends Plan {
  name = 'Intraday plan sell';

  buy = false;

  color = 'red';

  key = 'intraday-plan-sell';

  markType = 'tri';

  fromJson(json, date) {
    super.fromJson(json, date);
    return this;
  }
}
