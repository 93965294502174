export default class Plantdata {
  id: string;

  acu_min: number;

  acu_max: number;

  acu_cap: number;

  biogas_heat: number;

  biogas_start_time: string;

  biogas_stop_time: string;

  biomass_heat: number;

  biomass_start_time: string;

  biomass_stop_time: string;

  gas_boiler_max: number;

  gas_boiler_start: number;

  gas_boiler_stop: number;

  location: string;

  motor_bg_efficiency: number;

  motor_ng_efficiency: number;

  units_typed: string;

  constructor() {
    this.id = '';

    this.acu_min = 0;

    this.acu_max = 0;

    this.acu_cap = 0;

    this.biogas_heat = 0;

    this.biogas_start_time = '';

    this.biogas_stop_time = '';

    this.biomass_heat = 0;

    this.biomass_start_time = '';

    this.biomass_stop_time = '';

    this.gas_boiler_max = 0;

    this.gas_boiler_start = 0;

    this.gas_boiler_stop = 0;

    this.location = '';

    this.motor_bg_efficiency = 0;

    this.motor_ng_efficiency = 0;

    this.units_typed = '';

    return this;
  }

  fromJson(json) {
    if (json) {
      this.id = json.id;
      this.acu_min = json.acu_min;
      this.acu_max = json.acu_max;
      this.acu_cap = json.acu_cap;
      this.biogas_heat = json.biogas_heat;
      this.biogas_start_time = json.biogas_start_time;
      this.biogas_stop_time = json.biogas_stop_time;
      this.biomass_heat = json.biomass_heat;
      this.biomass_start_time = json.biomass_start_time;
      this.biomass_stop_time = json.biomass_stop_time;
      this.gas_boiler_max = json.gas_boiler_max;
      this.gas_boiler_start = json.gas_boiler_start;
      this.gas_boiler_stop = json.gas_boiler_stop;
      this.location = json.location;
      this.motor_bg_efficiency = json.motor_bg_efficiency;
      this.motor_ng_efficiency = json.motor_ng_efficiency;
      this.units_typed = json.units_typed;
    }
    return this;
  }

  toJson() {
    return JSON.parse(JSON.stringify(this));
  }
}