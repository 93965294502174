import { getDataFromC } from './Service';
import axios from "axios";

export function getGraphData(plantCode) {
  return getDataFromC(`/api/mkplus/${plantCode}/graphData`);
}

export function getPlantData(plantCode) {
  return getDataFromC(`/api/mkplus/${plantCode}/plantData`);
}

export function getHeatData(plantCode) {
  return getDataFromC(`/api/mkplus/${plantCode}/consumptionForecast`);
}

export function updatePlantData(plantCode, plantData) {
  return axios.post(`/api/mkplus/${plantCode}/plantData`, plantData);
}
