import React from 'react';
// import logo from './logo.svg';
// import DevTool from 'mobx-react-devtools';
import { ToastContainer } from 'react-toastify';

// styles
import './App.less';
import './AppStyl.css';
import 'react-toastify/dist/ReactToastify.min.css';
// import styles from './Modules.css';

import 'react-table/react-table.css';

import Routes from './routes.js';

class App extends React.Component {
  render() {
    return (
      <div className="App">
        {/* <DevTool /> */}
        <ToastContainer
          position="top-right"
          type="default"
          autoClose={2400}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          pauseOnHover
        />
        <Routes />
        {this.props.children}
      </div>
    );
  }
}

export default App;
