// @flow
export default class Summary {
    approvedPlans: number = 0;

    totalPlans: number = 0;

    totalProduction: number = 0;

    totalConsumption: number = 0;

    alarmStatus: string = '';

    fromJson(json) {
      this.approvedPlans = json.approvedPlans;
      this.totalPlans = json.totalPlans;
      this.totalProduction = json.totalProduction;
      this.totalConsumption = json.totalConsumption;
      this.alarmStatus = json.currentDayAlarm;
      return this;
    }

    get progress() {
      return this.totalPlans > 0 ? this.approvedPlans / this.totalPlans * 100 : 0;
    }

    get progressLabel() {
      return `${this.approvedPlans} / ${this.totalPlans}`;
    }

    get progressClass() {
      if (this.progress < 30) return 'danger';
      if (this.progress < 100) return 'warning';
      return 'success';
    }
}
