export const timePickerConfiguration = {
  stepIntervalInMinutes: 60,
  timeHourFormat: 24,
  dateFormat: 'DD/MM YYYY',
};

export const millisInASecond = 1000;

export const secondsInAMinute = 60;

export const minutesInAnHour = 60;

export const secondsInAnHour = 3600;
