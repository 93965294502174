import React from 'react';
import { inject, observer } from 'mobx-react';
import * as d3 from 'd3';
import { select } from 'd3-selection';
import { line } from 'd3-shape';
import { scaleLinear, scaleTime } from 'd3-scale';
import { axisBottom, axisLeft } from 'd3-axis';
import 'd3-transition';
import 'd3-time';

import moment from 'moment';
import { translate } from 'react-i18next';

import { MKPlusGraphData } from '../../classes/GraphData';

const margin = {
  top: 0, right: 0, bottom: 0, left: 0,
};
const fullWidth = 900;
const fullHeight = 300;
const width = fullWidth - margin.left - margin.right;
const height = fullHeight - margin.top - margin.bottom;

const x = scaleTime()
  .range([0, width]);

const y = scaleLinear()
  .range([0, height]);

const generateX = d => (isNaN(x(d.date)) ? 0 : x(d.date));

const generateY = d => y(d.amountPowerOutputMegawatt);

const lineGenerator = line()
  .x(generateX)
  .y(generateY)
  .curve(d3.curveStepAfter);

const lineGeneratorNoCurveStep = line()
  .x(generateX)
  .y(generateY);

const yDomainForPlant = (plant, maxSeriesValue = 0) => {
  const paddingFactor = 1.1;

  const baseYVal = Math.max(plant.capacityMegawatt, maxSeriesValue);

  const maxYVal = baseYVal * paddingFactor;
  const minYVal = -1 * baseYVal / 10;

  return {
    maxY: maxYVal,
    minY: minYVal,
  };
};

const yAxisString = (plant) => {
  if (plant.producer || plant.invertedConsumptionUnit) {
    return 'Production [MWh/h]';
  }
  return 'Consumption [MWh/h]';
};


@translate()
@inject('uiStore', 'mkPlusStore') @observer
export default class Chart extends React.Component<> {
  drawConfigurations = {
    crossHairStrokeWidth: 1, // The crosshair width.
    generalStrokeWidth: 2, // The general stroke line for all graphlines.
    blockTimeArea: {
      opacity: '0.07',
      color: 'black',
    },
  };

  setGraphRef = (ref) => {
    this.graph = ref;
  }

  storageRender = () => {
    const storageSeries = this.props.mkPlusStore.visibleGraphSeries.filter(({ name }) => name === 'storage');
    return storageSeries.length > 0;
  }

  calculateDisabledWidth = () => {
    const date = moment().toDate();
    const dateWidth = x(date);

    if (dateWidth > width) {
      return width;
    } if (dateWidth > 0) {
      return dateWidth;
    }

    return 0;
  }

  render() {
    const {
      t, uiStore, mkPlusStore,
    } = this.props;

    const seriesData = mkPlusStore.visibleGraphSeries.filter(({ name }) => name !== 'storage')
      .map(({ name, color }) => new MKPlusGraphData(mkPlusStore.graphData, name, color)).filter(graphData => graphData.valid);
    // eslint-disable-next-line prefer-spread
    const maxSeriesValue = seriesData.length > 0 ? Math.max.apply(Math, seriesData.map(data => data.maxValue)) : undefined;

    const plant = uiStore.selectedItem;

    const today = new Date();
    this.startDate = moment(today).subtract(3, 'day').startOf('d').toDate();
    this.endDate = moment(today).add(4, 'day').endOf('day').toDate();

    const newDateString = `${moment(this.startDate).format('DD/MM-YYYY')} - ${moment(this.endDate).format('DD/MM-YYYY')}`;

    x.domain([this.startDate, this.endDate]);

    const disabledWidth = this.calculateDisabledWidth();

    const { maxY, minY } = yDomainForPlant(plant, maxSeriesValue);
    this.maxY = maxY;
    this.minY = minY;
    y.domain([this.maxY, this.minY]);// yDomain);

    return (
      <div>
        {/* Canvas SVG-Drawing */}
        <svg
          id="notStorageSVG"
          height="100%"
          width="100%"
          viewBox={`-45 -20 ${fullWidth + 100} ${fullHeight + 100}`}
          ref={this.setGraphRef}
          className="graph"
        >
          <g id="g" transform={`translate(${margin.left},${margin.top})`}>
            {/* Axis */}
            <g className="axis" ref={r => this.xAxis = select(r)} transform={`translate(0, ${height})`} />
            <g className="axis" ref={r => this.yAxis = select(r)} />
            <g className="grid" ref={r => this.xGrid = select(r)} />
            <g className="grid" ref={r => this.yGrid = select(r)} />
            {/* Grey time rectangle */}
            <g className="time-rect">
              <rect width={disabledWidth} height={height} fill={this.drawConfigurations.blockTimeArea.color} opacity={this.drawConfigurations.blockTimeArea.opacity} />
            </g>

            {!this.storageRender() && <text x={width / 2 - 80} y={height + 40}>{newDateString}</text>}
            {/* TODO magic number */}
            <text y="-35" x={-height / 2 - 30} transform="rotate(-90)">{yAxisString(plant)}</text>

            {/* Crosshair Text and Lines */}
            <path
              style={{ strokeWidth: this.drawConfigurations.crossHairStrokeWidth }}
              className="crosshair-line"
              ref={r => this.horizontalLine = select(r)}
            />

            {/* Timeseries */}
            {seriesData.map(data => (
              <path
                id={data.key}
                key={data.key}
                className="line"
                strokeWidth={this.drawConfigurations.generalStrokeWidth}
                stroke={data.color}
                d={Chart.generateLine(data.entries, data.shouldCurveStep)}
              />
            ))}
          </g>
        </svg>
        <div ref={r => this.tooltip = select(r)} />
        {this.storageRender() && <ChartStorage /> }
      </div>
    );
  }

  static generateLine(entries, shouldCurveStep) {
    return (shouldCurveStep) ? lineGenerator(entries) : lineGeneratorNoCurveStep(entries);
  }

  isOutOfXAxis = x => (x < this.startDate || x > moment(this.endDate).subtract(5, 'minute').toDate());

  isOutOfYAxis = y => (y < this.minY || y > this.maxY);

  componentDidMount() {
    this.drawAxisAndGrid();
    const self = this;

    d3.select(this.graph).on('mousemove', () => {
      const point = d3.mouse(this.graph);

      const newData = {
        x: x.invert(point[0]),
        y: y.invert(point[1]),
      };

      const {
        mkPlusStore,
      } = this.props;

      const seriesData = this.props.mkPlusStore.visibleGraphSeries.filter(({ name }) => name !== 'storage')
        .map(({ name, color }) => new MKPlusGraphData(mkPlusStore.graphData, name, color)).filter(graphData => graphData.valid);
      const renderTooltip = seriesData.length > 0;

      if (this.isOutOfXAxis(newData.x) || this.isOutOfYAxis(newData.y) || !renderTooltip) {
        self.tooltip.style('display', 'none');
      } else {
        const filteredXData = seriesData[0].entries.map(elem => elem.date);
        let bisection = d3.bisectLeft(filteredXData, newData.x);
        bisection = bisection < 1 ? 1 : bisection;
        const bisectionDate = filteredXData[bisection - 1];
        const yValues = seriesData.map(sd => [sd.color, sd.key.split('_').map(d => d.charAt(0).toUpperCase() + d.slice(1)).join(' '), sd.entries[bisection - 1].amountPowerOutputMegawatt]);
        const spaced = yValues.map((d) => {
          const ret = d;
          const count = d[1].length < 7 ? 4 : d[1].length > 15 ? 1 : d[1].length < 9 ? 3 : 2; // Variable number of tabs
          ret[1] = d[1].concat(`:${'\t'.repeat(count)}`);
          return ret;
        });
        const nested = d3.nest().key(d => d[1]).entries(spaced);

        self.tooltip.html(moment(bisectionDate).format('DD/MM/YYYY HH:mm'))
          .style('position', 'absolute')
          .style('background-color', '#e0dcdc')
          .style('padding', '6px')
          .style('display', 'block')
          .style('border-radius', '4px')
          .style('left', `${d3.event.pageX + 20}px`)
          .style('top', `${d3.event.pageY - 20}px`)
          .style('font-size', '11.5px')
          .selectAll()
          .data(nested)
          .enter()
          .append('div')
          .style('font-size', '10px')
          .style('white-space', 'pre-wrap')
          .style('color', d => d.values[0][0])
          .html(d => d.key + d.values[0][2].toFixed(2));
      }

      const newHorizontalData = (!this.isOutOfXAxis(newData.x))
        ? [{ amountPowerOutputMegawatt: this.minY, date: newData.x }, {
          amountPowerOutputMegawatt: this.maxY,
          date: newData.x,
        }]
        : [{ amountPowerOutputMegawatt: this.minY, date: this.startDate }, {
          amountPowerOutputMegawatt: this.maxY,
          date: this.startDate,
        }];

      if (this.isOutOfXAxis(newData.x) || this.isOutOfYAxis(newData.y)) {
        self.horizontalLine.attr('d', lineGenerator(newHorizontalData)).style('opacity', 0);
      } else {
        self.horizontalLine.attr('d', lineGenerator(newHorizontalData)).style('opacity', 1);
      }
    });
  }

  componentDidUpdate() {
    this.drawAxisAndGrid();
  }

  drawAxisAndGrid() {
    this.xAxis.call(axisBottom().scale(x).ticks(7).tickFormat(d => moment(d).format('DD/MM')));
    this.yAxis.call(axisLeft().scale(y));
    this.xGrid.call(axisBottom().scale(x).ticks(24).tickSize(height, 0, 0)
      .tickFormat(''));
    this.yGrid.call(axisLeft().scale(y).ticks(24).tickSize(-width, 0, 0)
      .tickFormat(''));
  }
}

const x1 = scaleTime()
  .range([0, width]);

const y1 = scaleLinear()
  .range([0, height]);

const generateX1 = d => (isNaN(x1(d.date)) ? 0 : x1(d.date));

const generateY1 = d => y1(d.amountPowerOutputMegawatt);

const lineGenerator1 = line()
  .x(generateX1)
  .y(generateY1)
  .curve(d3.curveStepAfter);

const lineGeneratorNoCurveStep1 = line()
  .x(generateX1)
  .y(generateY1);

@translate()
@inject('uiStore', 'mkPlusStore') @observer
class ChartStorage extends React.Component<> {
  drawConfigurations = {
    crossHairStrokeWidth: 1, // The crosshair width.
    generalStrokeWidth: 2, // The general stroke line for all graphlines.
    blockTimeArea: {
      opacity: '0.07',
      color: 'black',
    },
  };

  setGraphRef = (ref) => {
    this.graph = ref;
  };

  calculateDisabledWidth = () => {
    const date = moment().toDate();
    const dateWidth = x1(date);

    if (dateWidth > width) {
      return width;
    } if (dateWidth > 0) {
      return dateWidth;
    }
    return 0;
  }

  render() {
    const {
      t, uiStore, mkPlusStore,
    } = this.props;

    const seriesData = mkPlusStore.visibleGraphSeries.filter(({ name }) => name === 'storage')
      .map(({ name, color }) => new MKPlusGraphData(mkPlusStore.graphData, name, color)).filter(graphData => graphData.valid);

    // eslint-disable-next-line prefer-spread
    const maxSeriesValue = seriesData.length > 0 ? Math.max.apply(Math, seriesData.map(data => data.maxValue)) : undefined;

    const today = new Date();
    this.startDate1 = moment(today).subtract(3, 'day').startOf('d').toDate();
    this.endDate1 = moment(today).add(4, 'day').endOf('day').toDate();
    const newDateString = `${moment(this.startDate1).format('DD/MM-YYYY')} - ${moment(this.endDate1).format('DD/MM-YYYY')}`;

    x1.domain([this.startDate1, this.endDate1]);

    const disabledWidth = this.calculateDisabledWidth();

    // const {maxY, minY} = yDomainForPlant(plant, maxSeriesValue);
    this.maxY1 = maxSeriesValue * 1.1;
    this.minY1 = 0;
    y1.domain([this.maxY1, this.minY1]);

    return (
      <div>
        {/* Canvas SVG-Drawing */}
        <svg
          id="storageSVG"
          height="100%"
          width="100%"
          viewBox={`-45 -20 ${fullWidth + 100} ${fullHeight + 100}`}
          ref={this.setGraphRef}
          className="graph"
        >
          <g id="storageG" transform={`translate(${margin.left},${margin.top})`}>
            {/* Axis */}
            <g className="axis" ref={r => this.xAxis = select(r)} transform={`translate(0, ${height})`} />
            <g className="axis" ref={r => this.yAxis = select(r)} />
            <g className="grid" ref={r => this.xGrid = select(r)} />
            <g className="grid" ref={r => this.yGrid = select(r)} />
            {/* Grey time rectangle */}
            <g className="time-rect">
              <rect width={disabledWidth} height={height} fill={this.drawConfigurations.blockTimeArea.color} opacity={this.drawConfigurations.blockTimeArea.opacity} />
            </g>

            {/* Axis Texts */}
            <text x={width / 2 - 80} y={height + 40}>{newDateString}</text>
            {/* TODO magic number */}
            <text y="-35" x={-height / 2 - 30} transform="rotate(-90)">Storage [MWh]</text>

            {/* Crosshair Text and Lines */}
            <path
              style={{ strokeWidth: this.drawConfigurations.crossHairStrokeWidth }}
              className="crosshair-line"
              ref={r => this.horizontalLine = select(r)}
            />

            {/* Timeseries */}
            {seriesData.map(data => (
              <path
                id={data.key}
                key={data.key}
                className="line"
                strokeWidth={this.drawConfigurations.generalStrokeWidth}
                stroke={data.color}
                d={ChartStorage.generateLineStorage(data.entries, data.shouldCurveStep)}
              />
            ))}
          </g>
        </svg>
        <div ref={r => this.tooltip = select(r)} />
      </div>
    );
  }

  static generateLineStorage(entries, shouldCurveStep) {
    return (shouldCurveStep) ? lineGenerator1(entries) : lineGeneratorNoCurveStep1(entries);
  }

  isOutOfXAxis = x => (x < this.startDate1 || x > moment(this.endDate1).subtract(5, 'minute').toDate());

  isOutOfYAxis = y => (y < this.minY1 || y > this.maxY1);

  componentDidMount() {
    this.drawAxisAndGrid();
    const self = this;

    d3.select(this.graph).on('mousemove', () => {
      const point = d3.mouse(this.graph);

      const newData = {
        x: x1.invert(point[0]),
        y: y1.invert(point[1]),
      };

      const {
        mkPlusStore,
      } = this.props;

      const seriesData = this.props.mkPlusStore.visibleGraphSeries.filter(({ name }) => name === 'storage')
        .map(({ name, color }) => new MKPlusGraphData(mkPlusStore.graphData, name, color)).filter(graphData => graphData.valid);
      const renderTooltip = seriesData.length > 0;

      if (this.isOutOfXAxis(newData.x) || this.isOutOfYAxis(newData.y) || !renderTooltip) {
        self.tooltip.style('display', 'none');
      } else {
        const filteredXData = seriesData[0].entries.map(elem => elem.date);
        let bisection = d3.bisectLeft(filteredXData, newData.x);
        bisection = bisection < 1 ? 1 : bisection;
        const bisectionDate = filteredXData[bisection - 1];
        const yValues = seriesData.map(sd => [sd.color, sd.key.split('_').map(d => d.charAt(0).toUpperCase() + d.slice(1)).join(' '), sd.entries[bisection - 1].amountPowerOutputMegawatt]);
        const spaced = yValues.map((d) => {
          const ret = d;
          const count = d[1].length < 7 ? 4 : d[1].length > 15 ? 1 : d[1].length < 9 ? 3 : 2; // Variable number of tabs
          ret[1] = d[1].concat(`:${'\t'.repeat(count)}`);
          return ret;
        });
        const nested = d3.nest().key(d => d[1]).entries(spaced);

        self.tooltip.html(moment(bisectionDate).format('DD/MM/YYYY HH:mm'))
          .style('position', 'absolute')
          .style('background-color', '#e0dcdc')
          .style('padding', '6px')
          .style('display', 'block')
          .style('border-radius', '4px')
          .style('left', `${d3.event.pageX + 20}px`)
          .style('top', `${d3.event.pageY - 20}px`)
          .style('font-size', '11.5px')
          .selectAll()
          .data(nested)
          .enter()
          .append('div')
          .style('font-size', '10px')
          .style('white-space', 'pre-wrap')
          .style('color', d => d.values[0][0])
          .html(d => d.key + d.values[0][2].toFixed(2));
      }

      const newHorizontalData = (!this.isOutOfXAxis(newData.x))
        ? [{ amountPowerOutputMegawatt: this.minY1, date: newData.x }, {
          amountPowerOutputMegawatt: this.maxY1,
          date: newData.x,
        }]
        : [{ amountPowerOutputMegawatt: this.minY1, date: this.startDate1 }, {
          amountPowerOutputMegawatt: this.maxY1,
          date: this.startDate,
        }];

      if (this.isOutOfXAxis(newData.x) || this.isOutOfYAxis(newData.y)) {
        self.horizontalLine.attr('d', lineGenerator1(newHorizontalData)).style('opacity', 0);
      } else {
        self.horizontalLine.attr('d', lineGenerator1(newHorizontalData)).style('opacity', 1);
      }
    });
  }

  componentDidUpdate() {
    this.drawAxisAndGrid();
  }

  drawAxisAndGrid() {
    this.xAxis.call(axisBottom().scale(x1).ticks(7).tickFormat(d => moment(d).format('DD/MM')));
    this.yAxis.call(axisLeft().scale(y1));
    this.xGrid.call(axisBottom().scale(x1).ticks(24).tickSize(height, 0, 0)
      .tickFormat(''));
    this.yGrid.call(axisLeft().scale(y1).ticks(24).tickSize(-width, 0, 0)
      .tickFormat(''));
  }
}
