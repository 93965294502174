import React from 'react';
import TextFieldGroup from '../common/TextFieldGroup';
import { checkPendingPassword, userChangePassword } from '../../actions/AuthAction';
import isEmpty from 'lodash/isEmpty';
import { inject, observer } from 'mobx-react';
import {
  Grid,
  Row,
  Col,
  Jumbotron,
  Button,
  FormGroup,
} from 'react-bootstrap';
import { toast } from 'react-toastify';
import { translate } from 'react-i18next';

function validateInput(data) {
  const errors = {};

  if (data.password.length < 12) {
    errors.passwordLength = 'Password must be at least 12 characters long';
  }
  if (data.password !== data.repeatedPassword) {
    errors.mismatchedPasswords = 'Passwords must match';
  }

  return {
    errors,
    isValid: isEmpty(errors),
  };
}

@translate()
@inject('authStore', 'plantStore', 'planStore')
@observer
export default class SetPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      password: '',
      repeatedPassword: '',
      errors: {},
      disabled: true,
      isLoading: false,
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
  }


  isValid() {
    const { errors, isValid } = validateInput(this.state, this.props);

    if (!isValid) {
      this.setState({ errors });
    }

    return isValid;
  }

  onSubmit(e) {
    e.preventDefault();
    if (this.isValid()) {
      const { t, match } = this.props;

      this.setState({ errors: {}, isLoading: true });
      userChangePassword(match.params.uuid, this.state.password).then((res) => {
        toast.success(t('password-saved'));
        this.props.history.push('/login');
      }).catch((err) => {
        toast.error(t('error'));
        this.setState({ errors: {}, isLoading: false });
      });
    }
  }

  async componentDidMount() {
    // If user is logged in, go to home page.
    if (this.props.authStore.isAuthenticated) {
      this.props.history.push(this.props.authStore.homePage);
    }
    // Check if uuid is pending a password
    const { match } = this.props;
    const pending = await checkPendingPassword(match.params.uuid);

    if (!pending) {
      this.props.history.push('/login');
    }
    // Allow password reset/set for uuid
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value }, function () {
      const { errors } = validateInput(this.state, this.props);
      this.setState({ errors });
    });
  }

  render() {
    const {
      errors, repeatedPassword, password, isLoading,
    } = this.state;

    const disableSave = !isEmpty(errors); // Cannot save if there are errors.

    return (
      <div className="set-password-form">
        <Grid>
          <Row>
            <Col lg={4} lgOffset={4} md={6} mdOffset={3}>
              <Jumbotron>
                <h1>Volue Market Services</h1>
                <p>
                  Planner
                </p>
              </Jumbotron>
              <form onSubmit={this.onSubmit}>

                <TextFieldGroup
                  field="password"
                  label="Password"
                  value={password}
                  error={errors.passwordLength}
                  onChange={this.onChange}
                  type="password"
                />

                <TextFieldGroup
                  field="repeatedPassword"
                  label="Repeat password"
                  value={repeatedPassword}
                  error={errors.mismatchedPasswords}
                  onChange={this.onChange}
                  type="password"
                />

                <FormGroup>
                  <Button type="submit" bsStyle="primary" disabled={isLoading || disableSave}>Save</Button>
                </FormGroup>
              </form>
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}
