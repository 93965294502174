// @flow
import { checkPriceAreaUpdate, getPriceAreas } from '../../services/PlantService';
import {
  action, autorun, computed, observable, reaction, toJS,
} from 'mobx';
import { getActivations, getApprovedPlans, getGraphPlans } from '../../services/PlanService';
import { controlRoomMessages, deleteMessage as messageService_delete } from '../../services/MessageService';
import moment from 'moment/moment';
import Summary from '../classes/Summary';
import Plant from '../../classes/Plant';
import { OnlineValuesGraphData } from '../../classes/GraphData';
import { now } from 'mobx-utils/lib/mobx-utils';
import {
  createBox,
  createCHPCOM,
  createPlant,
  createUser,
  deleteBox,
  deleteCHPCOM,
  deleteUser,
  getBlockBids,
  getBoxes,
  getCHPCOMs,
  getLogs,
  getOnlineValues,
  getOnlineValuesStatusForSelection,
  getSummary,
  getUserRoles,
  getUsers,
  savePlant,
  updateBox,
  updateCHPCOM,
  updateUser,
  userChangePassword,
} from '../ControlRoomService';
import { warn } from '../../services/logService';
import { createMessage } from '../MessageService';
import { blockBidMapper } from '../../actions/CommonAction';
import { ProductionPlan } from '../classes/plans';
import { promisedComputed } from 'computed-async-mobx';
import { checkForAlarms, handleAlarm } from '../../services/AlarmService';
import { toast } from 'react-toastify';

export class ControlRoomStore {
  @observable alarm;

  alarmStatus: string = '';

  @observable priceAreas;

  @observable summary;

  @observable activations;

  @observable messagesFromDate;

  @observable messagesToDate;

  @observable messages;

  @observable approvedPlans;

  @observable graphPlans;

  @observable onlineValues;

  @observable logsFromDate;

  @observable logsToDate;

  @observable logs;

  @observable logsLoading = false;

  @observable messagesLoading = false;

  @observable boxes;

  @observable CHPCOMs;

  @observable blockBids;

  @observable users;

  @observable pendingUsers;

  @observable userRoles;

  @observable mfrrAlarms;

  infrequentPoll = 60000;

  constructor(uiStore, authStore) {
    this.uiStore = uiStore;
    this.authStore = authStore;

    this.reset();

    autorun(() => {
      if (this.authStore.isAdmin) {
        if (this.uiStore.hash === undefined) return;
        getPriceAreas(this.uiStore.selectedDate, this.uiStore.hash).then(this._replacePriceAreas);
      }
    });

    autorun(() => {
      if (this.authStore.isAdmin) {
        getBoxes().then(this._replaceBoxes);
        getCHPCOMs().then(this._replaceCHPCOMs);
      }
    });

    reaction(
      () => now(this.infrequentPoll),
      (timestamp) => {
        getSummary(this.uiStore.selectedDate).then(this._setSummary).catch(warn);
        getOnlineValues(this.selectedItem, this.uiStore.selectedDate, this.uiStore.hoursInDay)
          .then(this._replaceOnlineValues)
          .catch((e) => {
            // Make sure to reset online values.
            this._replaceOnlineValues(new OnlineValuesGraphData([], this.uiStore.selectedDate, this.uiStore.hoursInDay));
          });
      },
      { fireImmediately: true },
    );

    reaction(
      () => this.uiStore.selectedDate,
      (selectedDate) => {
        getSummary(selectedDate).then(this._setSummary).catch(warn);
        getOnlineValues(this.selectedItem, selectedDate, this.uiStore.hoursInDay)
          .then(this._replaceOnlineValues)
          .catch((e) => {
            // Make sure to reset online values.
            this._replaceOnlineValues(new OnlineValuesGraphData([], selectedDate, this.uiStore.hoursInDay));
          });
      },
    );

    reaction(
      () => this.selectedItem,
      (plantOrPriceArea) => {
        // Ensuring its not an empty object - (fx after logout)
        if (Object.keys(toJS(plantOrPriceArea)).length > 0) {
          getSummary(this.uiStore.selectedDate).then(this._setSummary).catch(warn);
          getOnlineValues(plantOrPriceArea, this.uiStore.selectedDate, this.uiStore.hoursInDay)
            .then(this._replaceOnlineValues)
            .catch((e) => {
              // Make sure to reset online values.
              this._replaceOnlineValues(new OnlineValuesGraphData([], this.uiStore.selectedDate, this.uiStore.hoursInDay));
            });
          this.updatePriceAreasForTree();
        }
      },
    );

    const frequentPoll = 10000;

    reaction(
      () => now(frequentPoll),
      (timestamp) => {
        if (this.authStore.isAdmin) {
          checkForAlarms().then(data => this.replacemFRRAlarms(data)).catch(warn);
          if (this.uiStore.hash !== undefined) {
            checkPriceAreaUpdate(this.uiStore.selectedDate, this.uiStore.hash).then(this._handlePriceAreaUpdateCheck).catch(warn);
          }
        }
      },
    );

    reaction(
      () => this.dateOrPlantOrHashUpdated,
      (updated) => {
        if (this.authStore.isAdmin) {
          getActivations(this.uiStore.selectedDate).then(this._replaceActivations).catch(warn);
          getApprovedPlans(this.uiStore.selectedDate).then(this._replaceApprovedPlans).catch(warn);
          getBlockBids(this.uiStore.selectedDate).then(this._replaceBlockBids).catch(warn);
          getGraphPlans(this.selectedItem, this.uiStore.selectedDate).then(this._replaceGraphPlans).catch(warn);
        }
      },
    );
  }

  @action
  reset = () => {
    this.alarm = undefined;
    this.alarmStatus = '';

    this.priceAreas = observable([]);

    this.activations = observable.array([]);

    this.messagesFromDate = this.uiStore.selectedDate;
    this.messagesToDate = this.uiStore.selectedDate;
    this.messages = observable.array([]);

    this.graphPlans = { productionPlan: new ProductionPlan() };
    this.approvedPlans = observable.array([]);
    this.blockBids = observable.array([]);

    this.summary = new Summary();

    this.logsFromDate = this.uiStore.selectedDate;
    this.logsToDate = this.uiStore.selectedDate;
    this.logs = observable.array([]);

    this.boxes = observable.array([]);
    this.CHPCOMs = observable.array([]);

    this.users = observable.array([]);
    this.pendingUsers = observable.array([]);
    this.userRoles = observable.array([]);

    this.mfrrAlarms = observable.array([]);
  }

  @computed get selectedItem() {
    return this.uiStore.selectedItem;
  }

  @computed get dateOrPlantUpdated() {
    const result = (
      `${this.uiStore.selectedDate === undefined ? '' : this.uiStore.selectedDate.valueOf()
      }:${this.uiStore.selectedItem === undefined ? '' : this.uiStore.selectedItem.name
      }:${this.uiStore.hash === undefined ? '' : this.uiStore.hash}`
    );

    return result;
  }

  @computed get dateOrPlantOrHashUpdated() {
    const result = (
      `${this.uiStore.selectedDate === undefined ? '' : this.uiStore.selectedDate.valueOf()
      }:${this.uiStore.selectedItem === undefined ? '' : this.uiStore.selectedItem.name
      }:${this.uiStore.hash === undefined ? '' : this.uiStore.hash}
      :${this.uiStore.CRPlansStateChanged}`
    );

    return result;
  }

  @action.bound
  replacemFRRAlarms(alarms) {
    alarms.forEach((a) => {
      if (a.handled) {
        const alarmToUpdate = this.mfrrAlarms.find(existingAlarm => existingAlarm.key === a.key);
        if (alarmToUpdate !== undefined) {
          alarmToUpdate.handled = true;
        }
      } else {
        const alarmToUpdate = this.mfrrAlarms.find(existingAlarm => existingAlarm.key === a.key);
        if (alarmToUpdate === undefined) {
          this.mfrrAlarms.push(a);
          getActivations(this.uiStore.selectedDate).then(this._replaceActivations).catch(warn);
        }
      }
    });
    // For each new alarm we find
    //    If it has been handled
    //        We check if it is present in the UI right now,
    //          If it is, we update the handled variable and show handled in UI, meaning modify current in list
    //          If not, don't add, because somebody handled it before you came online and you shouldn't see it
    //    else we add it, as it is a new
  }

  @action
  removemFRRAlarm(key, active) {
    if (active) {
      // Wrap then function in action(), or else we won't be able to update observable
      handleAlarm(key).then(action(() => {
        const toRemove = this.mfrrAlarms.find(a => a.key === key);
        this.mfrrAlarms.remove(toRemove);
      })).catch(() => {
        toast.error('Could not close notification');
      });
    } else {
      const toRemove = this.mfrrAlarms.find(a => a.key === key);
      this.mfrrAlarms.remove(toRemove);
    }
  }

  @action
  disableAlarm() {
    this.alarm = false;
  }

  @computed get plants() {
    return this.priceAreas.map(priceArea => priceArea.plants.slice()).flat(1);
  }

  @action
  selectItem(item) {
    this.uiStore.setSelectedItem(item);
  }

  @action
  setLogsFromDate(date) {
    const parsedDate = moment(date);
    if (parsedDate.isValid()) {
      this.logsFromDate = parsedDate;
    }
  }

  @action
  setLogsToDate(date) {
    const parsedDate = moment(date);
    if (parsedDate.isValid()) {
      this.logsToDate = parsedDate;
    }
  }

  @action
  refreshBlockBids() {
    // Controlroom
    getBlockBids(this.uiStore.selectedDate).then(this._replaceBlockBids).catch(warn);

    // Indmelding
    this.uiStore.triggerRefreshPlans();
  }

  @action
  refreshLogs() {
    this.logsLoading = true;
    getLogs(this.logsFromDate, this.logsToDate).then(this._replaceLogs).catch(warn);
  }

  @action.bound
  _replaceLogs(logs) {
    this.logsLoading = false;
    this.logs.replace(logs);
  }

  @action.bound
  _replaceBoxes(boxes) {
    this.boxes.replace(boxes);
  }

  @action.bound
  _replaceCHPCOMs(records) {
    this.CHPCOMs.replace(records);
  }

  @action
  updatePriceAreas() {
    getPriceAreas(this.uiStore.selectedDate, this.uiStore.hash).then(this._replacePriceAreas);
  }

  @action
  setAllInactive(andUntoggle = false) {
    const priceAreas = this.priceAreas.splice(0);

    priceAreas.filter(p => p.toggled).forEach((priceArea) => {
      const activePlant = priceArea.children.find(plant => plant.active);

      if (activePlant) {
        activePlant.active = false;
      }
    });

    priceAreas.forEach((priceArea) => {
      priceArea.active = false;

      if (andUntoggle) {
        priceArea.toggled = false;
      }
    });

    this.priceAreas.replace(priceAreas);
  }

  @action
  updatePriceAreasForTree() {
    const { selectedItem } = this.uiStore;

    if (!selectedItem || this.priceAreas.length === 0) return;

    const selectedIsPriceArea = selectedItem.hasOwnProperty('children');

    if (!selectedIsPriceArea) {
      this.setAllInactive(true);

      const priceArea = this.priceAreas.find(p => p.name === selectedItem.priceAreaName);

      if (priceArea) {
        priceArea.toggled = true;

        const item = priceArea.children.find(p => p.name === selectedItem.name);

        item.active = true;
      }
    }
  }

  @action.bound
  _replacePriceAreas(priceAreas) {
    // all price area hashes are currently identical.
    if (priceAreas && priceAreas.length > 0) {
      this._updateLastUpdatedHash(priceAreas[0].currentDayHash);
    }

    this.priceAreas.replace(priceAreas);

    this.updatePriceAreasForTree();
  }

  @action.bound
  _setSummary(summary) {
    this.checkForAlarm(summary.alarmStatus);
    this.summary = summary;
  }

  @action
  checkForAlarm(newAlarmStatus) {
    const current = this.alarmStatus;
    if (newAlarmStatus.length > 0 && newAlarmStatus !== this.alarmStatus) {
      console.log('CheckForAlarm', current, newAlarmStatus);
      this.alarmStatus = newAlarmStatus;
      this.alarm = true;
    }
  }

  selectedItemOnlineValuesStatus = promisedComputed(null, () => {
    now(this.infrequentPoll);
    return getOnlineValuesStatusForSelection(this.selectedItem).catch(warn);
  });

  @action.bound
  _handlePriceAreaUpdateCheck(hash) {
    this._updateLastUpdatedHash(hash);
  }

  @action
  _updateLastUpdatedHash(hash) {
    if (hash && this.uiStore.hash !== hash) {
      this.uiStore.setHash(hash);
    }
  }

  @action.bound
  _replaceActivations(activations) {
    this.activations.replace(activations);
  }

  @action.bound
  _replaceOnlineValues(onlineValues) {
    const { entries } = onlineValues;

    if (entries.length > 1) {
      // The line must be drawn vertically straight up from 0. So we have to add superficial zeros.
      const newEntries = [entries[0]];
      for (let i = 1; i < entries.length; i++) {
        const current = entries[i];
        const previous = entries[i - 1];
        const currentPower = current.amountPowerOutputMegawatt;
        const previousPower = previous.amountPowerOutputMegawatt;
        const currentMinute = current.minute;
        const previousMinute = previous.minute;

        if (currentPower > 0 && previousPower === 0 && currentMinute !== previousMinute) {
          // We have two entries distinct in time (minute differs), and the current entry has raised from
          // 0 to a positive value. So we must render a 0 on the exact same time (minute) as the current entry.
          const newEntry = Object.assign({}, current, { amountPowerOutputMegawatt: 0, ny: true });
          newEntries.push(newEntry);
        } else if (previousPower > 0 && currentPower === 0 && currentMinute !== previousMinute) {
          // We have two entries distinct in time (minute differs), and the current entry has lowered from
          // a positive value to 0. We must insert a 0 in order to render the lowering to 0 as a steep
          // curve.
          const newEntry = Object.assign({}, previous, { amountPowerOutputMegawatt: 0, ny: true });
          newEntries.push(newEntry);
        }
        newEntries.push(current);
      }

      onlineValues.entries = newEntries;
    }
    this.onlineValues = onlineValues;
  }

  @action.bound
  _replaceBlockBids(blockBids) {
    this.blockBids.replace(blockBidMapper(this.uiStore.selectedDate, blockBids));
  }

  @action
  setMessagesFromDate(date) {
    const parsedDate = moment(date);
    if (parsedDate.isValid()) {
      this.messagesFromDate = parsedDate;
    }
  }

  @action
  setMessagesToDate(date) {
    const parsedDate = moment(date);
    if (parsedDate.isValid()) {
      this.messagesToDate = parsedDate;
    }
  }

  @action
  refreshMessages() {
    this.messagesLoading = true;
    controlRoomMessages(this.messagesFromDate, this.messagesToDate).then(this._replaceMessages).catch(warn);
  }

  @action.bound
  _removeMessage(message) {
    this.messages.remove(message);
  }

  @action.bound
  _replaceMessages(messages) {
    this.messages.replace(messages);
    this.messagesLoading = false;
  }

  @action.bound
  _replaceApprovedPlans(plans) {
    this.approvedPlans.replace(plans);
  }

  @action.bound
  _replaceGraphPlans(plans) {
    this.graphPlans = plans;
  }

  @action
  deleteBox(box) {
    return deleteBox(box).then(() => this._removeBox(box));
  }

  @action.bound
  _removeBox(box) {
    this.boxes.remove(box);
  }

  @action
  reloadBoxes() {
    if (this.authStore.isAdmin) {
      getBoxes().then(this._replaceBoxes);
    }
  }

  @action
  saveBox(box: Box) {
    if (box.isNew) {
      return createBox(box).then(() => getBoxes().then(this._replaceBoxes));
    }
    return updateBox(box).then(() => this.updateBoxLocal(box));
  }

  @action
  updateBoxLocal(box) {
    const boxIndex = this.boxes.findIndex(_box => _box.uuid === box.uuid);
    this.boxes[boxIndex] = box;
  }

  @action
  saveCHPCOM(chpcom: CHPCOM) {
    if (chpcom.isNew) {
      return createCHPCOM(chpcom).then(() => getCHPCOMs().then(this._replaceCHPCOMs));
    }

    return updateCHPCOM(chpcom).then(() => this.updateCHPCOMLocal(chpcom));
  }

  @action
  updateCHPCOMLocal(chpcom) {
    const chpcomIndex = this.CHPCOMs.findIndex(_chpcom => _chpcom.uuid === chpcom.uuid);
    this.CHPCOMs[chpcomIndex] = chpcom;
  }

  @action
  deleteCHPCOM(chpcom) {
    return deleteCHPCOM(chpcom).then(() => this._removeCHPCOM(chpcom));
  }

  @action.bound
  _removeCHPCOM(box) {
    this.CHPCOMs.remove(box);
  }

  @action
  savePlant(plant: Plant) {
    if (plant.isNew) {
      return createPlant(plant.toJson).then((res) => {
        this.updatePriceAreas();
        return this._addPlant(new Plant().fromJson(res.data));
      });
    }
    return savePlant(plant.toJson).then((res) => {
      this.updatePriceAreas();
      return this._updatePlant(new Plant().fromJson(res.data));
    });
  }

  @action.bound
  _updatePlant(plant: Plant) {
    const priceArea = this.priceAreas.find(_area => _area.name === plant.priceAreaName);
    const plantIndex = priceArea.plants.findIndex(_plant => _plant.uuid === plant.uuid);
    priceArea.plants[plantIndex] = plant;

    // If selected plant is updated, ensure it has the updated plant
    if (this.selectedItem.isPlant && this.selectedItem.uuid === plant.uuid) {
      this.uiStore.setSelectedItem(plant);
    }
  }

  @action.bound
  _addPlant(plant) {
    const relatedPriceArea = this.priceAreas.find(priceArea => priceArea.name === plant.priceAreaName);
    if (relatedPriceArea) {
      relatedPriceArea.plants.push(plant);
    }
  }

  @action
  saveMessage(message) {
    return createMessage(message)
      .then((response) => {
        controlRoomMessages(this.messagesFromDate, this.messagesToDate).then(this._replaceMessages).catch(warn);
        return response;
      });
  }

  @action
  deleteMessage(message) {
    return messageService_delete(message)
      .then((response) => {
        controlRoomMessages(this.messagesFromDate, this.messagesToDate).then(this._replaceMessages).catch(warn);
        return response;
      });
  }

  @action
  getUsers() {
    getUsers().then((users) => {
      this._replacePendingUsers(users.slice().filter(user => user.status === 1));
      this._replaceUsers(users.slice().filter(user => user.status !== 1));
    }).catch(warn);
  }

  @action.bound
  _replacePendingUsers(users) {
    this.pendingUsers.replace(users);
  }

  @action.bound
  _replaceUsers(users) {
    this.users.replace(users);
  }

  @action
  getUserRoles() {
    getUserRoles().then(this._replaceUserRoles).catch(warn);
  }

  @action.bound
  _replaceUserRoles(userRoles) {
    this.userRoles.replace(userRoles);
  }

  @action
  saveUser(user: User) {
    if (user.isNew) {
      return createUser(user).then(() => getUsers().then((users) => {
        this._replacePendingUsers(users.slice().filter(u => u.status === 1));
        this._replaceUsers(users.slice().filter(u => u.status !== 1));
      }));
    }

    return updateUser(user).then(() => getUsers().then(this._replaceUsers));
  }

  @action
  userChangePassword(user: User) {
    return userChangePassword(user);
  }

  @action
  deleteUser(user) {
    return deleteUser(user).then(() => this._removeUser(user));
  }

  @action.bound
  _removeUser(user) {
    this.users.remove(user);
    this.pendingUsers.remove(user);
  }
}
