import React from 'react';
import { inject, observer } from 'mobx-react';
import { translate } from 'react-i18next';
import {
  Button,
  FormControl,
  FormGroup,
  ButtonToolbar,
} from 'react-bootstrap';

import _ from 'lodash';

import TimePicker from './TimePicker';
import { timePickerConfiguration, minutesInAnHour } from '../../config/settings';

@translate()
@inject('controlRoomStore', 'uiStore') @observer
export default class NewBlockBidForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      begin: 0,
      end: 0,
      price: '',
      power: '',
      submitDisabled: true,
      prevInit: {},
    };
  }

  validate = () => {
    // Price is always an int
    this.setState(
      prevState => ({
        submitDisabled: (prevState.end - prevState.begin) < minutesInAnHour * 3 || !Number.isInteger(parseInt(prevState.price, 10)),
      }),
    );
  };

  onBeginChange = (e) => {
    this.setState({ begin: e.target.value }, () => {
      this.validate();
    });
  };

  onEndChange = (e) => {
    this.setState({ end: e.target.value }, () => {
      this.validate();
    });
  };

  onPriceChange = (e) => {
    this.setState({ price: e.target.value }, () => {
      this.validate();
    });
  };

  onPowerChange = (e) => {
    this.setState({ power: e.target.value }, () => {
      this.validate();
    });
  };

  onSubmit = (e) => {
    e.preventDefault();

    const {
      index, begin, end, price, power,
    } = this.state;

    this.props.onSubmit({
      index,
      begin,
      end,
      price,
      power,
    });

    this.setState({ submitDisabled: true });
  }

  componentDidMount() {
    const { init } = this.props;

    // Means that it is a edit - edit only triggers componentDidMount()
    if (init) {
      this.setState({
        index: init.index,
        begin: init.begin,
        end: init.end,
        power: init.power,
        price: init.price ? init.price : '',
        prevInit: init,
      });
    }
  }

  componentDidUpdate(prevProps) {
    const { init, show } = this.props;

    if (init && !_.isEqual(this.state.prevInit, init) && (init.begin !== this.state.begin || init.end !== this.state.end || init.power !== this.state.power)) {
      this.setState({
        begin: init.begin,
        end: init.end,
        power: init.power,
        price: '',
        prevInit: init,
      });
    }

    if (!prevProps.show && show) {
      this.priceInput.focus();
    }
  }

  render() {
    const { t, onDismiss } = this.props;
    const {
      begin, end, price, power, submitDisabled,
    } = this.state;

    return (
      <form onSubmit={this.onSubmit}>
        <FormGroup>
          <label>{t('begin')}</label>
          <TimePicker
            date={this.props.uiStore.selectedDate}
            step={timePickerConfiguration.stepIntervalInMinutes}
            format={timePickerConfiguration.timeHourFormat}
            end={timePickerConfiguration.endTime}
            onChange={this.onBeginChange}
            value={begin}
          />
        </FormGroup>
        <FormGroup>
          <label>{t('end')}</label>
          <TimePicker
            date={this.props.uiStore.selectedDate}
            step={timePickerConfiguration.stepIntervalInMinutes}
            format={timePickerConfiguration.timeHourFormat}
            end={timePickerConfiguration.endTime}
            onChange={this.onEndChange}
            value={end}
          />
        </FormGroup>
        <FormGroup>
          <label>{t('price')}</label>
          <FormControl
            onChange={this.onPriceChange}
            value={price}
            inputRef={(input) => { this.priceInput = input; }}
          />
        </FormGroup>
        <FormGroup>
          <label>{t('production')}</label>
          <FormControl
            onChange={this.onPowerChange}
            value={power}
          />
        </FormGroup>

        <ButtonToolbar>
          <Button
            type="submit"
            bsStyle="primary"
            disabled={submitDisabled}
          >
            {t('save')}
          </Button>
          <Button onClick={onDismiss}>{t('cancel-and-close')}</Button>
        </ButtonToolbar>
      </form>
    );
  }
}
