import React from 'react';
import { observer } from 'mobx-react';

@observer
class IFrameFromUrl extends React.Component {
  render() {
    const { url } = this.props;

    return (
      <iframe title="markedskraft.com" id="mkplus-iframe" src={url} width="100%" height="1000" />
    );
  }
}

export default IFrameFromUrl;
