// @flow
import moment from 'moment';

export default class Message {
    key: string;

    title: string;

    body: string;

    created: moment;

    fromDate: moment;

    toDate: moment;

    constructor(args) {
      if (args) {
        this.key = args.uuid;
        this.created = args.created;
        this.title = args.message;
        this.body = args.body;
        this.fromDate = moment(args.messageTime);
        this.toDate = moment(args.messageEndTime);
      }
    }

    toJSON() {
      return {
        created: this.created && this.created.toJSON(),
        messageTime: this.fromDate && this.fromDate.toJSON(),
        messageEndTime: this.toDate && this.toDate.toJSON(),
        message: this.title,
        body: this.body,
      };
    }
}
